import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IBaseCode } from '../../../../common/interfaces/iBaseCode';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { ISpecialty } from '../../../../fad/provider-details/models/iDetailsResponse';
import { ProviderCard } from '../../constants/result';
import { IDetail } from '../../interfaces/iProviderDetail';
import { IProviderPlan } from '../../interfaces/iProviderPlan';
import { BaseComponent } from './../../../common/components/core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-more-details-cmp',
  templateUrl: './providerMoreDetailsCmp.html'
})
export class ProviderMoreDetailsComponent extends BaseComponent {
  @Output() cardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  @Output() isMoreDetailsChange = new EventEmitter<boolean>();
  @Output() specialtySelected: EventEmitter<ISpecialty> = new EventEmitter<ISpecialty>();
  @Input() providerDetails: IDetail;
  showProviderLoader: boolean = true;
  moreDetailsContent = this.content?.result?.providerMoreDetails;
  genderPref: string;
  agePref: string;
  @Input() insurancePlans: IProviderPlan[];
  @Input() services: any[];
  showGenderPreference: boolean = false;
  isVirtualCareProvider: boolean = false;
  @Input() officeServices: Array<IBaseCode>;
  @Input() visionServices: Array<IBaseCode>;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnChanges(args: unknown): void {
    this.isVirtualCareProvider = this.providerDetails?.isVirtualProvider;
    if (this.providerDetails?.visibilityRule) {
      this.showGenderPreference = this.providerDetails.visibilityRule?.showGenderPreference;
    }
    this.genderPref = this.getGenderPref();
    this.agePref = this.getAgePref();
  }

  /**
   * Method to show Speciality Card in Provider Tab.
   */

  onShowSpeciality(specialty: ISpecialty) {
    const isMoreDetails = true;
    this.isMoreDetailsChange.emit(isMoreDetails);
    this.cardSelected.emit(ProviderCard.SPECIALITY);
    this.specialtySelected.emit(specialty);
  }

  /**
   * Method to show Insurance Card in Provider Tab.
   */
  onShowInsurance() {
    this.cardSelected.emit(ProviderCard.INSURANCE);
  }

  /**
   * Method to show Services Card in Provider Tab.
   */
  onShowServices() {
    this.cardSelected.emit(ProviderCard.SERVICE);
  }

  /**
   * Method to show License Card in Provider Tab.
   */
  onViewAllLicenses() {
    this.cardSelected.emit(ProviderCard.LICENSE);
  }

  getAgePref(): string {
    let agePrefString = '';
    const lowRange = parseInt(this.providerDetails?.agePrefLowRange, 10);
    const highRange = parseInt(this.providerDetails?.agePrefHighRange, 10);

    if (!isNaN(lowRange) && !isNaN(highRange)) {
      if (lowRange === 0) {
        agePrefString += this.moreDetailsContent?.preference?.birth;
      } else {
        agePrefString += `${lowRange} ${this.moreDetailsContent?.preference?.years}`;
      }

      if (highRange > 99) {
        agePrefString += this.moreDetailsContent?.preference?.older;
      } else {
        agePrefString += ` ${this.moreDetailsContent?.preference?.to} ${highRange} ${this.moreDetailsContent?.preference?.years}`;
      }
    }

    return agePrefString;
  }

  getGenderPref(): string {
    const genderPref = this.titleCase(this.providerDetails?.patientGenderPreference);
    if (genderPref === this.moreDetailsContent?.preference?.both) {
      return this.moreDetailsContent?.preference?.maleFemale;
    } else if (genderPref === this.moreDetailsContent?.preference?.all) {
      return this.moreDetailsContent?.preference?.all;
    }
    return genderPref;
  }

  titleCase(inputTxt: string) {
    return new TitleCasePipe().transform(inputTxt);
  }
}
