import { TitleCasePipe } from '@angular/common';
import { forOwn, isEmpty } from 'lodash';
import { CARE_TYPE, EMPTY_STRING, Locale, TILDE_SYMBOL } from '../../../common/constants/app-constants';
import { AppSession } from '../../../common/values/appSession';
import { MEDICAL_COVERAGETYPE, STATE_GA } from '../../../fad/search-providers/values/providerSearchConstants';
import { IProvider } from '../../result/interfaces/iProvider';
import { PageAlertHandler } from '../components/shareable/page-alert/service/pageAlertHandler';
import { PROVIDER_TYPE, SearchExecutionMode } from '../constants/common';
import { RESULT_PAGE_SEARCH_BLOCK_ALERT_CONFIG } from '../contents/common/alertTypeConfig';
import { RESULT_CONTENT_EN } from '../contents/result/resultContent';
import { RESULT_CONTENT_ES } from '../contents/result/resultContent.es';
import { FindCareFlag } from '../enums/findCareFlag';
import { FindCarePageType } from '../enums/findCarePages';
import { PageAlertID } from '../enums/pageAlertId';
import { IModalContent } from '../interfaces/iModalContent';
import { IAlertMessage } from '../interfaces/iPageAlert';
import { IProviderNetworkStatus } from '../interfaces/iProviderDetail';
import { FeatureManagerUtility } from './featureManagerUtil';

export class CommonUtility {
  /**
   * Determines if the member is in a secure state based on the app session.
   * @param appSession The application session.
   * @returns {boolean} True if the member is in a secure state, otherwise false.
   */
  static isMemberSecure(appSession: AppSession): boolean {
    // Check if the identification number is present and not empty
    const hasIdentificationNumber = appSession?.searchParams?.identificationNumber && appSession.searchParams.identificationNumber.trim() !== '';

    // If the identification number is present, the state is not secure
    if (hasIdentificationNumber) {
      return false;
    }

    // Otherwise, return the secure state from the app session
    return appSession.isSecureState;
  }

  static isCptSearch(appSession: AppSession): boolean {
    return appSession?.isMedicalCodeSearch;
  }

  static getPdtStrKey(selectedProvider: IProvider): string {
    if (CommonUtility.isValidString(selectedProvider?.pdtStrKey)) {
      return selectedProvider.pdtStrKey;
    } else if (!isEmpty(selectedProvider?.pdtKey)) {
      return (
        (selectedProvider.pdtKey.planCode ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.productId ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.providerNumber ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.providerMasterId ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.locationMasterId ?? EMPTY_STRING)
      );
    }
    return EMPTY_STRING;
  }

  static isValidString(key: unknown): boolean {
    if (key && typeof key === 'string' && key !== '' && key.trim().length) {
      return true;
    }
    return false;
  }

  static replaceContentWithLiterals(content: string = '', pattern: string, value: string): string {
    const regExPattern = new RegExp(`{${pattern}}`, 'gi');
    return content.replace(regExPattern, value);
  }

  static processModalContent(modalContent: IModalContent, brand: string): IModalContent {
    modalContent.title = CommonUtility.replaceContentWithLiterals(modalContent?.title, 'brand', brand);
    modalContent.body = CommonUtility.replaceContentWithLiterals(modalContent?.body, 'brand', brand);
    forOwn(modalContent.content, (value, key) => {
      modalContent.content[key] = CommonUtility.replaceContentWithLiterals(value, 'brand', brand);
    });
    return modalContent;
  }

  static getProviderTypes(appSession: AppSession): Array<string> {
    let allProviderTypes = appSession?.appState?.executionMode === SearchExecutionMode.SECURE ? appSession.providerTypes?.filter((x) => x.code !== PROVIDER_TYPE.PHARMACY) : appSession.providerTypes;
    const providerTypes = [];
    const opsIndicator = appSession?.appState?.opsIndicator;
    if (opsIndicator) {
      if (opsIndicator.isDentalStandAlone) {
        providerTypes.push(PROVIDER_TYPE.DENTAL);
      } else if (opsIndicator.isVisionStandAlone) {
        providerTypes.push(PROVIDER_TYPE.VISION);
      } else if (opsIndicator.isRxStandAlone) {
        providerTypes.push(PROVIDER_TYPE.PHARMACY);
      }
    }
    return providerTypes.length ? providerTypes : allProviderTypes?.map((x) => x.code) || [];
  }

  /** get the title case of the input text
   * @param inputTxt - The text to be converted to title case.
   * @returns { string } The title case text.
   * */
  static titleCase(inputTxt: string) {
    return new TitleCasePipe().transform(inputTxt);
  }

  /** get the formatted date based on the locale
   * @param value - The date value to be formatted.
   * @param locale - The locale from appSession. Default is English.
   * @returns {string} The formatted date.
   * */
  static getFormatedDate(value: string, locale: string = Locale.ENGLISH): string {
    // convert date format to "Month, dd, yyyy", eg: 06/22/2022 to June, 22, 2022
    if (typeof value !== 'string' || new Date(value).toString().toLowerCase() === 'invalid date') {
      return '';
    }
    const networkDate = new Date(value);
    const monthNames = this.getSummaryContent(locale).searchCriteriaComponent.monthNames;
    const month = monthNames[networkDate.getMonth()];
    const day = networkDate.getDate();
    const year = networkDate.getFullYear();
    const formattedDate = month + ' ' + day + ', ' + year;
    console.log('formattedDate ', day);
    return formattedDate;
  }

  /** get the summaryComponent data from resultContent files based on the locale
   * @param locale - The locale from appSession. Default is English.
   * @returns {any} The summaryComponent data from the resultContent files.
   * */
  static getSummaryContent(locale: string = Locale.ENGLISH): any {
    return locale === Locale.SPANISH ? RESULT_CONTENT_ES.summaryComponent : RESULT_CONTENT_EN.summaryComponent;
  }

  /**
   * Get the network status message based on the providerNetworkStatus object
   * @param providerNetworkStatus - The providerNetworkStatus object.
   * @param locale - The locale from appSession. Default is English.
   * @param isResultView - The flag to check if the status text is for provider list view.
   * @returns {string} The network status message.
   */
  static getProviderNetworkStatus(providerNetworkStatus: IProviderNetworkStatus, appSession: AppSession, isResultView?: boolean): string {
    let networktext = '';
    let typeCode = '';
    let networkDate = '';
    const locale = appSession?.metaData?.locale || Locale.ENGLISH;
    const isSecureState = appSession.isSecureState;
    const summaryContent = this.getSummaryContent(locale);
    const statusCode = providerNetworkStatus?.statusCode;
    if (statusCode) {
      networktext = summaryContent.networkStatus[statusCode];
      networkDate = this.getFormatedDate(providerNetworkStatus.date, locale);
      if (isSecureState) {
        // get the secured state network status message
        networktext = summaryContent.networkStatusSecured[providerNetworkStatus.statusCode];
      }
      if (isResultView) {
        // if status text is for provider list view
        networkDate = providerNetworkStatus.date;
        networktext = summaryContent.networkStatusSummary[providerNetworkStatus.statusCode];
      }
      if (networktext) {
        if (providerNetworkStatus.typeCode?.length) {
          providerNetworkStatus.typeCode.forEach((typeCd) => {
            // get the corresponding provider type code for each care type
            const providerType = CARE_TYPE.options.find((x) => x.value.toLocaleLowerCase() === typeCd.toLocaleLowerCase());
            // get the corresponding locale string for the providerType.type from wcs
            let typeText = summaryContent.searchCriteriaComponent.providerTypes[providerType?.type];
            if (locale === Locale.ENGLISH && isResultView) {
              typeText = this.titleCase(typeText);
            }
            typeCode += providerType?.type ? typeText + ', ' : '';
          });
          // remove the ',' from end of the string
          typeCode = typeCode?.length ? typeCode.substring(0, typeCode.length - 2) : '';
        }
        // replace {PLANTYPE} and {DATE} with the values in Network Status Text
        networktext = networktext.replace(/{PLANTYPE}/gi, typeCode ? typeCode : '');
        networktext = networktext.replace('{DATE}', networkDate);
      }
    }
    return networktext ? networktext : '';
  }

  /** get the provider type text based on the providerTypeCodeList
   * @param appSession - The application session.
   * @param content - The content from resultContent files.
   * @returns {string} The provider type text.
   * */
  static getProviderTypeText(_appSession: AppSession, date: string) {
    const content = _appSession?.metaData?.locale === Locale.SPANISH ? RESULT_CONTENT_ES : RESULT_CONTENT_EN;
    const suffixName = content?.summaryComponent?.pdfFileName?.search_on + date;

    const defaultName = content?.summaryComponent?.searchCriteriaComponent?.labels?.careProviders;

    if (!_appSession?.searchParams) {
      return defaultName + suffixName;
    }

    const { coverageTypeCode, providerTypeCodeList } = _appSession?.searchParams;
    const typeSelected = providerTypeCodeList?.[0];

    if (!typeSelected) {
      return defaultName + suffixName;
    }

    const providerTypes = [PROVIDER_TYPE.DENTAL.toString(), PROVIDER_TYPE.VISION.toString()];
    const resultType = content?.summaryComponent?.resultsProvType[typeSelected + coverageTypeCode];

    if (providerTypes.includes(typeSelected) && coverageTypeCode === MEDICAL_COVERAGETYPE && resultType) {
      return resultType + suffixName;
    }

    const providers = content?.commonHealthCareContent?.providers;
    const type = providers.find((x) => x.code === typeSelected);

    if (type?.name && !this.isDentalSearch(_appSession)) {
      return type.name + suffixName;
    } else if (this.isDentalSearch(_appSession)) {
      return content?.summaryComponent?.resultsProvType[PROVIDER_TYPE.DENTAL] + suffixName;
    } else {
      return content?.summaryComponent?.resultsProvType[typeSelected] + suffixName || defaultName + suffixName;
    }
  }
  /**
   * Check if the search is for dental providers
   * @param appSession  The appSession.
   * @returns {boolean} True if the search is for dental providers, otherwise false.
   */
  static isDentalSearch(appSession: AppSession): boolean {
    return (
      appSession.hasDentalEYC &&
      appSession.searchParams &&
      appSession.searchParams.eycSearchParam &&
      appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
      appSession.searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL
    );
  }

  static isResultSearchBlocked(appSession: AppSession): boolean {
    let isResultSearchBlocked = false;
    const pageAlerts = appSession.pageAlerts[FindCarePageType.Results].map((alert: IAlertMessage) => alert.id) || [];
    if (!isEmpty(pageAlerts)) {
      isResultSearchBlocked = pageAlerts.some((alertId: PageAlertID) => RESULT_PAGE_SEARCH_BLOCK_ALERT_CONFIG.indexOf(alertId) > -1);
    }
    return isResultSearchBlocked;
  }

  /**
   * Compares two string arrays for equality.
   * @param array1 The first string array.
   * @param array2 The second string array.
   * @returns {boolean} True if the arrays are equal, otherwise false.
   */
  static areStringArraysEqual(array1: string[] | null | undefined, array2: string[] | null | undefined): boolean {
    if (!array1 || !array2) {
      return false;
    }

    if (array1.length !== array2.length) {
      return false;
    }

    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();

    for (let i = 0; i < sortedArray1.length; i++) {
      if (sortedArray1[i] !== sortedArray2[i]) {
        return false;
      }
    }

    return true;
  }

  static displayStaticAlerts(_appSession: AppSession, pageType: FindCarePageType) {
    if (pageType === FindCarePageType.Landing) {
      // COUPE APERT MESSAGE
      if (_appSession.bootstrap.contracts?.length > 0 && FeatureManagerUtility.isFlagLive(FindCareFlag.MSG_COUPE_HEALTH_PRODUCT, _appSession)) {
        PageAlertHandler.buildStaticPageAlerts([PageAlertID.PA_COUPE_ALERT], _appSession, pageType);
      }
      // GA_TERMINATION ALERT
      const stateCode = _appSession.searchParams.stateCd?.code ? _appSession.searchParams.stateCd.code : '';
      if (STATE_GA === stateCode.toLocaleUpperCase()) {
        PageAlertHandler.buildStaticPageAlerts([PageAlertID.PA_GA_ALERT], _appSession, pageType);
      }
    }
  }

  static getPlanNetworkPrefixText(_appSession: AppSession, content?: any) {
    let _prefixLbl = '';
    if (content?.summaryComponent) {
      _prefixLbl = content.summaryComponent.resultsHeader?.prefix;
    }
    if (_appSession.searchParams.plan?.identifier && _appSession.searchParams.plan?.name) {
      return _appSession.searchParams.plan.name;
    } else if (_appSession.deeplinkParams.alphaprefix && _appSession.searchParams.plan?.name.toLocaleLowerCase() === _appSession.deeplinkParams.alphaprefix?.toLocaleLowerCase()) {
      return _appSession.deeplinkParams.alphaprefix + ' ' + _prefixLbl;
    } else if (_appSession.deeplinkParams.alphaprefix && _appSession.searchParams.plan?.name && _appSession.searchParams.plan.name !== '') {
      return _appSession.searchParams.plan.name;
    } else if (_appSession.appState.selectedEligibilityProduct?.selectedContract?.coverages[0]?.planNm) {
      return _appSession.appState.selectedEligibilityProduct.selectedContract.coverages[0]?.planNm;
    }
  }
}
