import { IFilterChangePlan } from '../../../common/components/change-plan/models/iChangePlanParam';
import { ADDRESS_SELECT, AppConstants } from '../../../common/constants/app-constants';
import { AppSession } from '../../../common/values/appSession';
import { IAddressDetails, IAddressForm } from '../../../fad/search-providers/interfaces/iAddressForm';
import { IPlan } from '../../../fad/search-providers/interfaces/iPlan';
import { ContractStatus, MbrAddressType, PROVIDER_SEARCH_DISTANCE, ProviderCareType, SearchExecutionMode } from '../constants/common';
import { IContract, IMemberAddress } from '../interfaces/iContract';
import { CommonUtility } from './commonUtil';

export class AppSessionUtility {
  /**
   * Sets the application session values from the selected contract.
   * @param appSession The application session object.
   * @param contract The selected contract containing session values.
   */
  static setAppSessionFromContract(appSession: AppSession, contract: IContract): void {
    if (contract) {
      // Set session values from selected contract
      this.setSessionValuesFromContract(appSession, contract);

      // Set ops indicators
      this.setAppSessionIndicators(appSession);

      // Set address details
      this.setAppSessionAddressDetails(appSession, contract.addresses);

      // Set search parameters
      this.setAppSessionSearchParameters(appSession, contract);
    }
  }

  /**
   * Sets the session values from the selected contract.
   * @param appSession The application session object.
   * @param contract The selected contract containing session values.
   */
  private static setSessionValuesFromContract(appSession: AppSession, contract: IContract): void {
    if (contract && appSession) {
      appSession.mbu = contract.mbuCd;
      appSession.underwritingStateCode = contract.underwritingStateCd;
      appSession.metaData.contractUid = contract.contractUid;
      appSession.hcidPrefix = contract.hcidPrefix;
      appSession.groupId = contract.groupId;
      appSession.sourceSystem = contract.sourceSystemId;
      appSession.metaData.appContract = { ...appSession.metaData.appContract, contractUid: contract.contractUid, mbrUid: contract.mbrUid };
    }
  }

  /**
   * Sets the ops indicators in the session.
   * @param appSession The application session object.
   */
  private static setAppSessionIndicators(appSession: AppSession): void {
    const opsIndicator = appSession?.appState?.opsIndicator;
    if (opsIndicator) {
      appSession.hasVPC = opsIndicator.hasVpc;
      appSession.hasVirtualUrgentCare = opsIndicator.hasVirtualUrgentCare;
      appSession.hasTalkSpaceSSO = opsIndicator.hasTalkSpaceSSO;
      appSession.hasTelehealth = opsIndicator.hasTelehealth;
      appSession.hasANPEligibleSourceSystem = opsIndicator.isAnpEnabled;
      appSession.hasRxCvsIndicator = opsIndicator.hasRxCVS;
      appSession.isDentalStandAlone = opsIndicator.isDentalStandAlone;
      appSession.isVisionStandAlone = opsIndicator.isVisionStandAlone;
      appSession.hasPharmacyPlan = opsIndicator.hasRxCoverage;
      appSession.isPharmacyStandAlone = opsIndicator.isRxStandAlone;
      appSession.hasLHO = opsIndicator.hasLHO;
      appSession.hasMedicalPlan = opsIndicator.hasMedicalCoverage;
      appSession.isEycbanner = !opsIndicator.isTccMedicalEnabled && opsIndicator.hasEyc;
      appSession.hasEYC = opsIndicator.hasEyc && !appSession.isEycbanner;
      appSession.hasDentalEYC = opsIndicator.isTccDentalEnabled;
      appSession.hasHealthWellness = opsIndicator.hasHealthWellness;
      appSession.hasHealthSystem = opsIndicator.hasHealthSystem;
      appSession.hasCostTransparency = opsIndicator.isMedicalCodeSearchEnabled;
      appSession.hasFindCareFts = opsIndicator.hasFindCareFts;
      appSession.hasStandaloneDentalContract = opsIndicator.isDentalStandAlone;
      appSession.hasStandaloneVisionContract = opsIndicator.isVisionStandAlone;
      appSession.hasVirtualCareHeadway = opsIndicator.hasVirtualCareHeadway;

      appSession.filterChangePlan = {} as IFilterChangePlan;
      appSession.filterChangePlan.careType = opsIndicator.isDentalStandAlone
        ? ProviderCareType.DENTAL
        : opsIndicator.isVisionStandAlone
          ? ProviderCareType.VISION
          : opsIndicator.isRxStandAlone
            ? ProviderCareType.PHARMACY
            : undefined;
    }
  }

  /**
   * Sets the address details in the session.
   * @param appSession The application session object.
   * @param addresses The list of member addresses.
   */
  private static setAppSessionAddressDetails(appSession: AppSession, addresses: IMemberAddress[]): void {
    const addressDetails = {} as IAddressDetails;
    const homeAddress = {} as IAddressForm;
    const workAddress = {} as IAddressForm;
    const customAddress = {} as IAddressForm;
    let postalCode = appSession.searchParams?.zip;

    if (appSession.isSecureState === true || appSession.appState?.executionMode === SearchExecutionMode.SECURE) {
      (addresses || []).forEach((address: IMemberAddress) => {
        if (address) {
          if (address.addressType === MbrAddressType.HOME) {
            homeAddress.streetAddress = address.addressLine1;
            homeAddress.city = address.city;
            homeAddress.state = address.stateCd;
            homeAddress.zipCode = address.postalCd;
            homeAddress.isHomeAddress = true;
            homeAddress.isFormValid = this.validateAddress(address);
            addressDetails.homeAddress = homeAddress;
            postalCode = address.postalCd;
          } else if (address.addressType === MbrAddressType.WORK) {
            workAddress.streetAddress = address.addressLine1;
            workAddress.city = address.city;
            workAddress.state = address.stateCd;
            workAddress.zipCode = address.postalCd;
            workAddress.isHomeAddress = false;
            workAddress.isFormValid = true;
            addressDetails.workAddress = workAddress;
            postalCode = address.postalCd;
          }
          appSession.addressObj = addressDetails;
          appSession.addressObj.addressModel = ADDRESS_SELECT.HOME;
        }
      });
    } else if (addresses?.length) {
      addresses.forEach((address: IMemberAddress) => {
        customAddress.streetAddress = address.addressLine1;
        customAddress.city = address.city;
        customAddress.state = address.stateCd;
        customAddress.zipCode = address.postalCd;
        customAddress.isHomeAddress = false;
        customAddress.isFormValid = true;
        addressDetails.customAddress = customAddress;
        postalCode = address.postalCd;
      });
      appSession.addressObj = addressDetails;
      appSession.addressObj.addressModel = ADDRESS_SELECT.CUSTOM_ADDRESS;
    }

    if (appSession.searchParams) {
      appSession.searchParams.zip = postalCode;
      appSession.location = {
        zip: appSession.searchParams.zip
      };
    }
  }

  /**
   * Validates the given address to ensure all required fields are present and the postal code is valid.
   * @param address The member address to validate.
   * @returns {boolean} True if the address is valid, otherwise false.
   */
  private static validateAddress(address: IMemberAddress): boolean {
    return address && address.addressLine1?.trim() !== '' && address.city?.trim() !== '' && address.stateCd?.trim() !== '' && address.postalCd?.length === 5;
  }

  /**
   * Sets the search parameters in the session based on the selected contract.
   * @param appSession The application session object.
   * @param selectedContract The selected contract containing search parameters.
   */
  private static setAppSessionSearchParameters(appSession: AppSession, selectedContract: IContract): void {
    const { prefixes, networks } = selectedContract;
    const { searchParams, addressObj } = appSession;

    if (searchParams) {
      searchParams.zip = addressObj?.homeAddress?.zipCode || '';
      appSession.location = { zip: searchParams.zip };
      if (appSession.deeplinkParams?.zipcode) {
        searchParams.zip = appSession.deeplinkParams.zipcode;
      }

      const plan: IPlan = {
        securePlanLabelKey: AppConstants.MyPlan,
        networkList: networks || [],
        alphaPrefix: prefixes?.length ? prefixes[0] : '',
        stateCd: selectedContract.underwritingStateCd
      };
      searchParams.plan = plan;
      searchParams.providerTypeCodeList = CommonUtility.getProviderTypes(appSession);
      searchParams.typeSelectNm = searchParams.providerTypeCodeList?.length === 1 ? searchParams.providerTypeCodeList[0] : undefined;
      searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
      searchParams.includePrimeGroupSearch = true;
      appSession.isFutureCoverage = selectedContract.statusCd === ContractStatus.FUTURE;
    }
  }
}
