import { AfterViewInit, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppEvents } from '../../../common/enums/appEvents';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppSession } from '../../../common/values/appSession';
import { VEP } from '../../../fad/search-providers/values/providerSearchConstants';
import { BaseComponent } from '../../common/components/core/baseCmp';
import { GenericModalService } from '../../common/components/shareable/modal/services/genericModalSvc';
import { PageAlertHandler } from '../../common/components/shareable/page-alert/service/pageAlertHandler';
import { PageAlertSvc } from '../../common/components/shareable/page-alert/service/pageAlertSvc';
import { FindCarePageType } from '../../common/enums/findCarePages';
import { IModalMetaInfo } from '../../common/interfaces/iModalContent';
import { IPageTitle } from '../../common/interfaces/iPageTitle';
import { VEP_BEH_HEALTH } from './../../../fad/search-providers/values/providerSearchConstants';
@Component({
  moduleId: module.id,
  selector: 'app-fc-landing-cmp',
  templateUrl: './landingCmp.html'
})
export class LandingComponent extends BaseComponent implements OnInit, AfterViewInit {
  page = FindCarePageType.Landing;
  private _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _genericModalService: GenericModalService,
    private _pageAlertSvc: PageAlertSvc
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.onPreLoad();
    this.setPageHeader();
    this._pageAlertSvc.getPageAlerts(PageAlertHandler.buildPageAlertRequest(FindCarePageType.Landing, this._appSession));
    this._appSession.searchTerm = '';
  }

  ngAfterViewInit() {
    this.checkLeavingDisclaimerModal();
  }

  setPageHeader() {
    if (this.content && this.content.common && this.content.common.pageHeader && this.content.common.pageTitle) {
      const pageTitleObj: IPageTitle = {
        header: this.content.common.pageHeader.fadPageTitle,
        title: this.content.common.pageTitle.searchCriteria
      };
      this._headerEvent.emit(pageTitleObj);
      this._eventHandler.get(AppEvents[AppEvents.APP_BRAND]).emit(this._appSession.metaData.brandCd);
    }
  }

  checkLeavingDisclaimerModal() {
    const prefix = this._appSession.searchParams?.plan?.alphaPrefix ?? this._appSession?.deeplinkParams?.alphaprefix;
    if (prefix && prefix.toUpperCase() === VEP) {
      const modalMetaInfo: IModalMetaInfo = {} as IModalMetaInfo;
      modalMetaInfo.modalId = 'MD_LEAVING_SITE_VEP';
      modalMetaInfo.urlToBeRedirected = VEP_BEH_HEALTH;
      this._genericModalService.openGenericSidePanel(modalMetaInfo);
    }
  }
}
