import { IOutputFlags } from '../../result/interfaces/iCompareProvider';
import { IBaseCode } from './../../../common/interfaces/iBaseCode';

export interface ISearchRequest {
  specialCountRequest?: boolean;
  orchestrationRequest?: boolean;
  pageNumber?: string;
  pageSize?: string;
  sortOption?: string;
  searchCriteria?: SearchCriteria;
  eycProcedureCriteria?: EycProcedureCriteria;
  outputFlags?: IOutputFlags;
  productName?: string;
  isSecureState?: boolean;
}
export class EycSearchParam {
  procedureDescription?: string;
  eycProcedureCriteria?: EycProcedureCriteria;
  costDescription?: string;
}
export class EycProcedureCriteria {
  procedureName?: string;
  procedureCode: string;
  facilityServiceCode?: string;
  professionalServiceCode?: string;
  serviceCode?: string;
  serviceType?: string;
  providerCategoryCode?: string;
}
export class SearchCriteria {
  providerTypeCode: string;
  providerTypeCodeList?: Array<string>;
  providerName?: string;
  cityName?: string;
  stateCode?: string;
  countyCode?: string;
  postalCode?: string;
  latitude: string;
  longitude: string;
  distance: string;
  blueDistinction?: string = 'false';
  blueDistinctionList?: Array<IBlueDistinctCode>;
  blueDistinctionType?: string;
  cooperativeCareOnly?: boolean;
  sosOnly?: boolean;
  nyOMHOnly?: boolean;
  nyOASASOnly?: boolean;
  bluePrecision?: string;
  patientCenteredCare?: string;
  medicaid?: string;
  ecpprovider?: string;
  ableToServeAsPCP?: string;
  scheduleAptmntAvailable?: string;
  acceptingNewPatients?: string;
  specialtyCategoryList?: Array<string>;
  subSpecialtyList?: Array<string>;
  levelOfCareList?: Array<string>;
  ethnicityList?: Array<string>;
  patientPopulationList?: Array<string>;
  areaOfExpertiseList?: Array<string>;
  ofcSrvcList?: Array<string>;
  languages?: Array<string>;
  genderCode?: string;
  genderCodeList?: Array<string>;
  planStateCode?: string;
  networkList?: Array<IBaseCode>;
  planList?: Array<IPlan>;
  includePlans?: string;
  applyPersonalizedMatch: boolean;
  alphaPrefix?: string;
  identificationNumber?: string;
  mbrUid?: string;
  contractUid?: string;
  returnTypeofProvider?: string;
  coverageTypeCode?: string;
  boardCertification?: string;
  providerTier?: string;
  providerPhone?: string;
  providerEmail?: string;
  providerLicenseNumber?: string;
  providerLicenseType?: string;
  nationalProviderIdentifier?: string;
  visionSrvcAvailableList?: Array<string>;
  affiliationOption?: string;
  physicianAssociationAffiliation?: string;
  hospitalAffiliation?: string;
  healthCareArrangementCode?: string;
  brandCode?: string;
  cobrandCode?: string;
  locale?: string;
  memberSeqNum?: string;
  dispenseTypeCode?: Array<string>;
  pharmacyLevel?: string;
  allPharmacyFeatures?: boolean;
  open24HoursADay?: boolean;
  rxM90Pharmacy?: boolean;
  driveThru?: boolean;
  onsiteMedicalClinic?: boolean;
  bloodPressureScreening?: boolean;
  providesFluShots?: boolean;
  homeDelivery?: boolean;
  vaccinationIndicator?: boolean;
  preparesCompounds?: boolean;
  dme?: boolean;
  ninetyDayRetail?: boolean;
  open7DaysAWeek?: string;
  prescriptionDeliveryService?: string;
  preferredPharmacy?: string;
  isInitialSearch: boolean = false;
  planCategory?: string;
  standAloneDental?: boolean;
  standAloneVision?: boolean;
  planTypeList?: Array<string>;
  featureSearchType?: string;
  featureSearchText?: string;
  blueCarePrimePreferredSpecialistOnly?: boolean;
  isFuturePlan?: boolean;
  telehealth?: boolean;
  taxonomyList?: Array<string>;
  upswingOnly?: boolean;
  hasCcare?: boolean;
  customAddressSearch?: boolean;
  streetAddress?: string;
  addressLine2?: string;
  eycPrefix?: string;
  isPrefixStateEnabledForProcSearch?: boolean;
  caHMOOtherAdrsSearch?: boolean;
  isChangePCP?: boolean;
  recognitions?: IRecognition;
  extendedHoursOnly?: boolean;
  includeVirtualProviders?: boolean;
  cmeDesignationList?: Array<string>;
  recognitionCodes?: Array<string>;
}

export interface IRecognition {
  centerOfExcellenceOnly?: boolean;
  providerOfDistinctionOnly?: boolean;
  mspOnly?: boolean;
  valueBasedProviderOnly?: boolean;
  lgbtqProviderOnly?: boolean;
  greenRibbonOnly?: boolean;
  snyDentalOnly?: boolean;
}

export interface IPlan {
  identifier: string;
  isNationalPlan?: boolean;
}

export interface IBlueDistinctCode {
  code: string;
  name?: string;
  description?: string;
}

export class TravelTimeRequest {
  origins: string;
  destinations: string;
}

export interface IHealthWiseRequest {
  procedureCategoryCode?: Array<string>;
  specialtyCategoryList?: Array<string>;
  subSpecialtyList?: Array<string>;
  taxonomyList?: Array<string>;
  specialtyCategoryFilter?: Array<string>;
  providerTypeCodeList?: Array<string>;
  locale?: string;
}
export interface IHealthWiseResponse {
  hwContentDesciptionList: Array<IHealthwiseBaseCode>;
}

export interface IHealthwiseBaseCode extends IBaseCode {
  isHealthwiseContent?: boolean;
  specialityCode?: string;
  taxonomyCode?: string;
  providerType?: string;
  header?: string;
}
