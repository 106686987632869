import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ISecureTccMedicalSearchFilterRequest, SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import { IPlanNetworkPrefixContent } from '../../../common/interfaces/iPlanNetworkPrefixContent';
import { ISecureTccMedicalEmailRequest } from '../../../common/interfaces/iSecureTccMedicalEmailRequest';
import {
  IMedicalEycProcedureCriteriaRequest,
  ISecureTccMedicalOutputFlags,
  ISecureTccMedicalSearchCriteria,
  ISecureTccMedicalSearchRequest
} from '../../../common/interfaces/iSecureTccMedicalSearchRequest';
import { ISecureTccMedicalSearchResponse } from '../../../common/interfaces/iSecureTccMedicalSearchResponse';
import { BaseService } from '../../../common/services/baseService';
import { SecureV1TccMedical } from '../../../common/services/secureV1TccMedicalSvc';
import { SummaryEmailSvc } from '../../../common/services/summaryEmailSvc';
import { SummaryPrintSvc } from '../../../common/services/summaryPrintSvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { IAddressSummary, IProvider, IProviderList } from '../../../findcare/result/interfaces/iProvider';

import { isEmpty } from 'lodash';
import { IEycAffliation } from '../../../fad/search-results/interfaces/iSummaryResp';
import { API_NAME } from '../../../fad/search-results/values/providerSearchConstants';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { SearchRequestUtil } from '../../../fad/utilities/searchRequestUtil';
import { SearchResponseUtil } from '../../../fad/utilities/searchResponseUtil';
import { SearchFilter } from '../../result/services/searchFilter';
import { HttpClientService } from './../../../common/services/httpClientService';

@Injectable({
  providedIn: 'root'
})
export class SecureTccMedicalAdapterSvc extends BaseService {
  private pdfPageNumber = 1;
  private pdfPageSize = environment.paginationSize.summaryPrintPage;

  constructor(
    appSession: AppSession,
    private _secureTccMedicalSvc: SecureV1TccMedical,
    private _summaryPrintSvc: SummaryPrintSvc,
    private _summaryEmailSvc: SummaryEmailSvc,
    appUtility: AppUtility,
    httpClientSvc: HttpClientService,
    private _searchFilter: SearchFilter
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  async search(pageNumber: number, pageSize: number) {
    const transformedRequest = this.transformRequest(pageNumber, pageSize);
    const apiResponse = await this._secureTccMedicalSvc.executeV2(transformedRequest);
    this._searchFilter.initialize(this.transformSearchFilterRequest(pageNumber, pageSize));
    return this.transformResponse(apiResponse);
  }

  print<T extends { summaryComponent: IPlanNetworkPrefixContent }>(content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const printRequest: ISecureTccMedicalSearchRequest = { ...searchRequest };
    const apiName = SearchRequestUtil.getApiName(API_NAME.TCC_MEDICAL);
    return this._summaryPrintSvc.execute(printRequest, apiName);
  }

  email<T extends { summaryComponent: IPlanNetworkPrefixContent }>(email: string, content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const emailRequest: ISecureTccMedicalEmailRequest = { ...searchRequest, email };
    const apiName = SearchRequestUtil.getApiName(API_NAME.TCC_MEDICAL);
    return this._summaryEmailSvc.execute(emailRequest, apiName);
  }

  transformSearchFilterRequest(pageNumber: number, pageSize: number): ISecureTccMedicalSearchFilterRequest {
    const tccMedicalSecureSearchReq = this.transformRequest(pageNumber, pageSize);
    const filterReq: ISecureTccMedicalSearchFilterRequest = {
      ...tccMedicalSecureSearchReq,
      scenario: SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL
    };
    return filterReq;
  }

  private transformRequest(pageNumber: number, pageSize: number): ISecureTccMedicalSearchRequest {
    const searchParams = this.appSession.searchParams;
    const commonSearchRequest = SearchRequestUtil.buildCommonSearchRequest(this.appSession, pageNumber, pageSize);
    // remove plan and planNames from request payload
    commonSearchRequest.plan = undefined;
    commonSearchRequest.planNames = undefined;
    // redefine required output-flags
    const { imposeAddressConsolidation, improveSearchCriteria, includeDisclaimerRules, includeProviderNetworks, includeReviewRatings, includeTaxonomy, includeVisibilityRules } =
      commonSearchRequest.outputFlags || {};
    const outputFlags: ISecureTccMedicalOutputFlags = {
      imposeAddressConsolidation,
      improveSearchCriteria,
      includeDisclaimerRules,
      includeProviderNetworks,
      includeReviewRatings,
      includeTaxonomy,
      includeVisibilityRules
    };
    const demographicFilter = SearchRequestUtil.buildDemographicFilterRequest(this.appSession);
    const miscFilter = SearchRequestUtil.buildMiscFilterRequest(searchParams);
    const returnTypeofProvider = CommonUtil.getProcedureProviderReturnType(searchParams?.eycSearchParam?.eycProcedureCriteria?.providerCategoryCode);

    const searchCriteria: ISecureTccMedicalSearchCriteria = {
      brandCode: this.appSession?.metaData?.brandCd,
      cobrandCode: SearchRequestUtil.setCobrandCode(this.appSession),
      isInitialSearch: this.appSession?.initialSearch,
      miscFilter: {
        extendedHoursOnly: miscFilter?.extendedHoursOnly,
        patientPopulationList: miscFilter?.patientPopulationList,
        recognition: {
          blueDistinction: false,
          blueDistinctionCodes: miscFilter?.recognition?.blueDistinctionCodes,
          bluePrecision: miscFilter?.recognition?.bluePrecision,
          patientCenteredCare: searchParams?.enhancedPersonalCare,
          providerTier: searchParams?.providerTier
        },
        recognitionV2: { recognitionCodes: miscFilter?.recognitionV2?.recognitionCodes },
        smartSearchRequestId: miscFilter?.smartSearchRequestId
      }
    };
    // binding demogrphic filters if available
    if (demographicFilter) {
      // initialize demographicFilter in searchCriteria
      searchCriteria.demographicFilter = demographicFilter;
    }
    const eycProcedureCriteria: IMedicalEycProcedureCriteriaRequest = {
      procedureCode: searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode,
      providerCategoryCode: searchParams?.eycSearchParam?.eycProcedureCriteria?.providerCategoryCode,
      returnTypeofProvider
    };

    const request: ISecureTccMedicalSearchRequest = {
      ...commonSearchRequest,
      eycProcedureCriteria,
      searchCriteria,
      alphaPrefix: this.appSession?.searchParams?.plan?.alphaPrefix,
      outputFlags
    };
    return request;
  }

  private transformResponse(apiResponse: ISecureTccMedicalSearchResponse): IProviderList {
    let response = {} as IProviderList;
    response.pageNumber = apiResponse?.pageNumber;
    response.pageSize = apiResponse?.pageSize;
    response.totalPages = apiResponse?.totalPages;
    response.totalRecords = apiResponse?.totalRecords;
    response.defaultSort = apiResponse?.sortBy;
    response.costRange = apiResponse?.costRange;
    response.sortOptions = apiResponse?.sortOptions;
    response.criteriaChange = apiResponse?.criteriaChange;
    response.accntBalanceInfo = apiResponse?.accntBalanceInfo;
    response.oopEligible = apiResponse?.oopEligible;
    response.memberDeductibleAmount = apiResponse?.memberDeductibleAmount;
    const finalProviders: IProvider[] = [];
    if (!isEmpty(apiResponse)) {
      response.rules = apiResponse?.rules || [];
      if (apiResponse?.providers?.length) {
        (apiResponse.providers || []).forEach((provider) => {
          let finalProvider = {} as IProvider;
          if (provider) {
            finalProvider = SearchResponseUtil.getCommonProviderInformation(provider);
            finalProvider.eycCostInfo = provider.eycCostInfo;
            finalProvider.isRecordFromSPFI = provider.isRecordFromSPFI;
            if (provider.eycAffliation) {
              const facilityInfo = {} as IEycAffliation;
              facilityInfo.isVisibleAffiliation = provider.eycAffliation.isVisibleAffiliation;
              facilityInfo.affiliationList = [];
              (provider.eycAffliation.affiliationList || []).forEach((facility) => {
                const facilityProvider = SearchResponseUtil.getCommonProviderInformation(facility);
                facilityProvider.eycCostInfo = facility.eycCostInfo;
                facilityInfo.affiliationList.push(facilityProvider);
              });
              finalProvider.eycAffliation = facilityInfo;
            }
            const { address } = provider.location;
            const { taxonomies } = provider.specialty;

            finalProvider.addressSummary = {
              phoneNumberList: [address.phone],
              addressIdentifier: address.addressId,
              addressOne: address.addressOne,
              addressTwo: address.addressTwo,
              cityName: address.city,
              stateCode: address.state,
              postalCode: address.postalCode,
              latitude: address.latitude,
              longitude: address.longitude,
              distance: address.distance.toString(),
              taxonomies: taxonomies.map(({ code, name }) => ({ code, name }))
            } as IAddressSummary;

            finalProviders.push(finalProvider);
          }
        });
        response = { ...apiResponse, providers: finalProviders };
      }
    }
    response.providers = finalProviders;

    return response;
  }
}
