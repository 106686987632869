import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum-slim';
import { isEmpty } from 'lodash';
import { IRebrandConfig } from '../../../../common/interfaces/iBrandConfig';
import { AppConfig } from '../../../../common/values/appConfig';
import { SearchExecutionMode } from '../../../../findcare/common/constants/common';
import { FindCareFeature } from '../../../../findcare/common/enums/findCareFeature';
import { BootstrapService } from '../../../../findcare/common/store/bootstrapSvc';
import { FeatureManagerUtility } from '../../../../findcare/common/utilities/featureManagerUtil';
import { DeeplinkHandler } from '../../../services/deeplinkHandler';
import { UserInteraction } from '../../../services/userInteraction';
import { AppUtility } from '../../../utilities/appUtil';
import { IFilterChangePlan } from '../../change-plan/models/iChangePlanParam';
import { IDeeplink } from '../models/iDeeplink';
import { InfoListService } from './../../../../common/components/info-list/services/infoListSvc';
import { HttpClientService } from './../../../../common/services/httpClientService';
import { ProviderOrchestrationService } from './../../../../fad/provider-details/services/providerOrchestrationSvc';
import { IPlanRuleParameters } from './../../../../fad/rules/interfaces/iRulesParameters';
import { RulesService } from './../../../../fad/rules/services/RulesSvc';
import { IAddressDetails, IAddressForm } from './../../../../fad/search-providers/interfaces/iAddressForm';
import { IPlan, IPlanCategory } from './../../../../fad/search-providers/interfaces/iPlan';
import { IPlanRule } from './../../../../fad/search-providers/interfaces/iPlanRule';
import { ISearchParameters } from './../../../../fad/search-providers/interfaces/iSearchParameters';
import { ProviderUtilityService } from './../../../../fad/search-providers/services/providerUtilitySvc';
import { SAVNavigationService } from './../../../../fad/search-providers/services/savNavigationSvc';
import { DOS, PF_APP_NAME, PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE } from './../../../../fad/search-providers/values/providerSearchConstants';
import { CommonUtil } from './../../../../fad/utilities/commonUtil';
import {
  ADDRESS_SELECT,
  AppConstants,
  AppErrorMsg,
  BRAND_CODE,
  ERROR_TYPES,
  FindCareWebIntegrationClient,
  Locale,
  MODAL_TYPE,
  NETWORK_TYPECODE,
  REBRAND_CONFIG
} from './../../../constants/app-constants';
import { AppNavigations } from './../../../constants/app-navigations';
import { AppEvents } from './../../../enums/appEvents';
import { DeeplinkUrl } from './../../../enums/deepLinkUrls';
import { CoverageStatus, IAppContract, ICoverageDetails, IOptions } from './../../../interfaces/iAppMetadata';
import { IEventDetail } from './../../../interfaces/iEventDetail';
import { DataHelper } from './../../../services/dataHelper';
import { EventHandler } from './../../../services/eventHandler';
import { NavigationService } from './../../../services/navigationService';
import { AppSession } from './../../../values/appSession';
import { ContentHelper } from './../../../values/contentHelper';
import { IChangePlanOptions } from './../../change-plan/interfaces/iChangePlanOptions';
import { IAddresses, IMetadata } from './../models/iMetadata';
import { MemberPlanProgressService } from './memberPlanProgressSvc';
import { MemberSummaryService } from './memberSummarySvc';

@Injectable()
export class SecureStateHandler {
  private _metadata: IMetadata;
  private _pfMetadataErrorEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.METADATA_VALIDATION]);
  private _pfMemberSummaryErrorEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.MEMBER_SUMMARY_ERR]);
  private _multiCoverageFeatureEnabled = false;

  private _appConfig: AppConfig;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _appUtility: AppUtility,
    private _httpClientService: HttpClientService,
    private _memberSummaryService: MemberSummaryService,
    private _navigationService: NavigationService,
    private _memberPlanProgressSvc: MemberPlanProgressService,
    private _dataHelper: DataHelper,
    private _providerUtilityService: ProviderUtilityService,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _deeplinkHandler: DeeplinkHandler,
    private _infoListSvc: InfoListService,
    private _ruleService: RulesService,
    private _providerOrchestrationService: ProviderOrchestrationService,
    private _userInteraction: UserInteraction,
    private _bootstrapSvc: BootstrapService,
    private _savNavigationSav?: SAVNavigationService
  ) {
    this._appConfig = this._appSession.appConfig;
  }

  initializeSecureState(metadata: string) {
    if (!this.validateBasicMetadata(metadata)) {
      return;
    } else {
      this.setClient();
    }
    if (!this.validateSecureStateMetadata()) {
      return;
    }
    if (this._appConfig?.datadog.enabled) {
      datadogRum.init(this._appConfig.datadog.rumConfiguration);
      datadogRum.setUser({
        id: this._metadata.mbrUid
      });
    }
    // fcr - setting opsState for header
    this._appSession.appState.opsState.encryptedMbrUid = this._metadata?.mbrUid || '';
    this._appSession.appState.opsState.encryptedUserName = this._metadata?.userName || '';
    this._appSession.appState.opsState.jwtToken = this._metadata?.acstkn || this._metadata?.idToken || '';

    this._appSession.searchParams = this._appSession.searchParams ?? ({} as ISearchParameters);
    this.setDefaultSearchParams();
    this._deeplinkHandler.resetPromises = [];
    this._appSession.isEyc = false;
    this._appSession.isCareProvider = false;
    this._appSession.integratedSearch = true;
    this._appSession.hasAppInitialized = true;
    this._appSession.addressObj = {};
    let navigation: AppNavigations;
    this._appSession.networkList = [];
    this._appSession.isSecureState = this._metadata.isSecureState;
    navigation = this.setNavigation(this._deeplinkHandler.findDeeplinkParams(this._metadata.dplUrl));

    if (navigation === AppNavigations.CARE_TEAM_PATH || navigation === AppNavigations.CARE_CIRCLE_PATH) {
      this._metadata.isCareTeam = true;
    }

    if (this._metadata?.addresses?.length) {
      this._appSession.metaData.addresses = this._metadata.addresses;
      this.setAddresses(this._metadata.addresses);
    }
    this._appSession.isEmulation = this._metadata.isEmulateFlag;
    this._appSession.isWebView = this._metadata.isWebView;
    this._appSession.mobileOS = this._metadata.mobileOS;
    // fcr - setting appState > opsState for header
    this._appSession.appState.opsState.isEmulation = this._dataHelper.isTrue(this._metadata.isEmulateFlag);
    this._appSession.appState.opsState.isWebView = this._dataHelper.isTrue(this._metadata?.isWebView) || (typeof this._metadata?.mobileOS?.length === 'number' && this._metadata.mobileOS.length > 0);
    this._appSession.appState.opsState.brandCd = this._metadata?.brand || BRAND_CODE.ABCBS;

    this._appSession.isChangePCP = this._metadata.isChangePCP;
    this._appSession.metaData.brandCd = this._metadata.brand.toLocaleUpperCase();
    this.updateBrand();
    this._appSession.useSecureUrl = !this._dataHelper.isEmptyString(this._metadata.mbrUid) ? true : false;
    const _idToken = !this._dataHelper.isEmptyString(this._metadata.idToken) ? this._metadata.idToken : '';
    this._appSession.metaData.idToken = _idToken;
    this._appSession.changePlanMetaData = this._metadata;
    this._appSession.filterChangePlan = {} as IFilterChangePlan;
    this._appSession.filterChangePlan.isPreviouState = false;
    const _appName = this.getAppNameFromRoute(navigation);
    //Reset disclaimer list obj.
    this._infoListSvc.reset();
    this._appSession.encryptedUserName = !this._dataHelper.isEmptyString(this._metadata.userName) ? this._metadata.userName : '';
    this._appSession.encryptedGroupId = this._dataHelper.isEmptyString(this._metadata.groupId) ? '' : this._metadata.groupId;
    this._metadata.locale = this._dataHelper.areEqualStrings(this._appSession?.metaData?.locale?.toString(), Locale.SPANISH) ? Locale.SPANISH : Locale.ENGLISH;
    if (!this._dataHelper.isEmptyString(this._metadata.personalizedSessionId)) {
      this._appSession.personalizedSessionId = this._metadata.personalizedSessionId;
      // fcr - setting appState > opsState > dsSessionId for header
      this._appSession.appState.opsState.dsSessionId = this._metadata.personalizedSessionId;
    }
    this._providerUtilityService.getDataModified(this._metadata.mbrUid, this._metadata.userName, _appName).then(
      (result: any) => {
        //if the maintenance flag is turned on for the request, application will route to the
        // maitenance page without executing further, token will not set at this point
        if (result && result.isMaintenanceEnabled) {
          this._navigationService.navigateByUrl(AppNavigations.MAINTENANCE_PAGE_PATH);
        } else if (result.codeTypeList && result.codeTypeList.length > 0) {
          // maintenance flag for procedure search
          this._appSession.isEycMaintenanceEnabled = result.isEycMaintenanceEnabled;
          this._appSession.provDataModifiedOn = result.codeTypeList[0].code;
          // set jwt token for header
          this._appSession.appState.opsState.jwtToken = result.token;
          this._appSession.metaData.token = result.token;

          // Set the bootstrap response in the bootstrap service
          this._bootstrapSvc.initialize().then(() => {
            if (!this._dataHelper.isEmptyString(this._metadata.mbrUid)) {
              this._userInteraction.setHandlers();
            }
            if (this._appSession.provDataModifiedOn) {
              this.navigateToLandingPage(this._metadata, navigation);
            }
          });
        }
      },
      (error: any) => {
        this.onError(ERROR_TYPES.PROV_DATA_MDFD_DT, error);
      }
    );
  }

  updateBrand(): void {
    const brand = (this._appSession.metaData?.brandCd || '').toUpperCase();
    const rebrandConfig: IRebrandConfig = REBRAND_CONFIG[brand];
    if (!isEmpty(rebrandConfig)) {
      const prefix = this._dataHelper.isEmptyString(this._appSession.changePlanMetaData?.alphaPrefix) ? rebrandConfig.prefix : this._appSession.changePlanMetaData.alphaPrefix;
      this._appSession.metaData.brandCd = rebrandConfig.brand;
      this._appSession.rebrandConfig = { ...rebrandConfig, prefix };
    }
  }

  setAppSessionVariables(contract: IAppContract, changePlanOptions?: IChangePlanOptions) {
    const plan = {} as IPlan;
    const planCategory = {} as IPlanCategory;
    let coverageDetails: ICoverageDetails;
    this._multiCoverageFeatureEnabled = FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_MBR_MULTICONTRACT, this._appSession);
    this._appSession.metaData.appContract.coverageDetails = {} as ICoverageDetails;
    const deeplink = this._deeplinkHandler.findDeeplinkParams(this._metadata.dplUrl);
    this._appSession.hcidPrefix = contract && contract.hcidPrefix ? contract.hcidPrefix.trim() : '';
    this._appSession.hcidPrefixList = (contract && contract.hcidPrefixList) || [];
    this._appSession.hasVPC = contract?.hasVPC || false;
    this._appSession.hasVirtualUrgentCare = contract?.hasVirtualUrgentCare || false;
    this._appSession.hasVirtualWebPage = contract?.hasVirtualWebPage || false;
    this._appSession.hasVirtualCareHubWeb = contract?.hasVirtualCareHubWeb || false;
    this._appSession.hasTalkSpaceSSO = contract?.hasTalkSpaceSSO || false;
    this._appSession.hasTelehealth = contract?.hasTelehealth || false;
    this._appSession.groupId = contract?.groupId || '';
    this._appSession.hasANPEligibleSourceSystem = contract?.hasANPEligibleSourceSystem;

    let selectedMember: Array<IOptions>;

    if (
      typeof changePlanOptions !== 'undefined' &&
      changePlanOptions !== null &&
      typeof changePlanOptions.mbrUid !== 'undefined' &&
      changePlanOptions.mbrUid !== null &&
      changePlanOptions.mbrUid !== ''
    ) {
      selectedMember = contract.memberList.filter((m) => m.mbrUid === changePlanOptions.mbrUid);
    } else {
      selectedMember = contract.memberList.filter((m) => m.isChecked === true);
    }

    this.setAppSessionAddressObj(selectedMember[0]);

    if (typeof selectedMember !== 'undefined' && selectedMember !== null && selectedMember.length > 0) {
      if (typeof selectedMember[0].address !== 'undefined' && selectedMember[0].address !== null) {
        // update zip code to session variable only if option is null or changePlanOptions.updatePostalCode is true
        if (typeof changePlanOptions === 'undefined' || changePlanOptions == null || (typeof changePlanOptions !== 'undefined' && changePlanOptions !== null && changePlanOptions.updatePostalCode)) {
          this._appSession.searchParams.zip = selectedMember[0].address.postalCode;
          //Set zipcode in location object for Free Text Search
          this._appSession.location = {
            zip: this._appSession.searchParams.zip
          };
          if (this._appSession?.deeplinkParams?.zipcode) {
            this._appSession.searchParams.zip = this._appSession.deeplinkParams.zipcode;
          }
        }
      }

      // Code to consider active coverage by default plan selection when member login when active plan is present
      if (!this._multiCoverageFeatureEnabled && typeof selectedMember !== 'undefined' && selectedMember !== null && selectedMember.length > 0) {
        if (typeof selectedMember[0].coverageDetails !== 'undefined' && selectedMember[0].coverageDetails !== null) {
          const futureCoverageCheck =
            typeof selectedMember[0].coverageDetails.coverageEffectiveDate !== 'undefined' &&
            selectedMember[0].coverageDetails.coverageEffectiveDate !== null &&
            !this._dataHelper.isEmptyString(selectedMember[0].coverageDetails.coverageEffectiveDate);
          const futureChangePlanCheck =
            typeof changePlanOptions !== 'undefined' &&
            changePlanOptions !== null &&
            typeof changePlanOptions.planStartingDate !== 'undefined' &&
            changePlanOptions.planStartingDate !== null &&
            changePlanOptions.planStartingDate !== '';
          if (futureChangePlanCheck) {
            coverageDetails = selectedMember[0].futureCoverageDetails;
            this._appSession.isFutureCoverage = true;
          } else if (futureCoverageCheck) {
            coverageDetails = selectedMember[0].coverageDetails;
            this._appSession.isFutureCoverage = true;
          } else {
            coverageDetails = selectedMember[0].coverageDetails;
            this._appSession.isFutureCoverage = false;
          }
        }
      } else {
        if (Array.isArray(selectedMember) && selectedMember.length && selectedMember[0].coverageDetailsV2?.length) {
          let futureCoverageDetails = {} as ICoverageDetails;
          if (this._dataHelper.isEmptyString(changePlanOptions?.coverageUid)) {
            coverageDetails = selectedMember[0].coverageDetailsV2[0];
            this._appSession.coverageUid = coverageDetails?.coverageUid;
          } else {
            coverageDetails = selectedMember[0].coverageDetailsV2.find((coverage) => coverage.coverageUid === changePlanOptions?.coverageUid);
            this._appSession.coverageUid = coverageDetails?.coverageUid;
            if (coverageDetails.coverageStatus === CoverageStatus.PENDING) {
              futureCoverageDetails = coverageDetails;
            }
            const futureCoverageCheck =
              typeof coverageDetails.coverageEffectiveDate !== 'undefined' && coverageDetails.coverageEffectiveDate !== null && !this._dataHelper.isEmptyString(coverageDetails.coverageEffectiveDate);

            const futureChangePlanCheck =
              typeof changePlanOptions !== 'undefined' &&
              changePlanOptions !== null &&
              typeof changePlanOptions.planStartingDate !== 'undefined' &&
              changePlanOptions.planStartingDate !== null &&
              changePlanOptions.planStartingDate !== '';

            if (futureChangePlanCheck) {
              this._appSession.isFutureCoverage = true;
            } else if (futureCoverageCheck) {
              coverageDetails = futureCoverageDetails;
              this._appSession.isFutureCoverage = true;
            } else {
              this._appSession.isFutureCoverage = false;
            }
          }
        }
      }
    }

    if (
      typeof coverageDetails !== 'undefined' &&
      coverageDetails !== null &&
      (coverageDetails.hasMedicalPlan ||
        coverageDetails.hasDentalPlan ||
        coverageDetails.isDentalStandAlone ||
        coverageDetails.hasVisionPlan ||
        coverageDetails.isVisionStandAlone ||
        coverageDetails.hasPharmacyPlan ||
        coverageDetails.isPharmacyStandAlone)
    ) {
      if (
        typeof coverageDetails !== 'undefined' &&
        coverageDetails !== null &&
        coverageDetails.hasMedicalPlan &&
        this._dataHelper.isEmptyString(coverageDetails.surrogatePrefix) &&
        coverageDetails?.productType !== MODAL_TYPE.MEDICARE_SUPPLEMENT
      ) {
        plan.securePlanLabelKey = AppConstants.PlanUnknown;
      } else {
        plan.securePlanLabelKey = AppConstants.MyPlan;
      }
    } else {
      plan.securePlanLabelKey = AppConstants.PlanUnknown;
    }

    if (typeof coverageDetails !== 'undefined' && coverageDetails !== null) {
      planCategory.catCode = coverageDetails.planCategory;
      plan.alphaPrefix = coverageDetails.surrogatePrefix;
      if (coverageDetails?.hasVisionNetwork && coverageDetails?.networkList?.length) {
        coverageDetails.networkList.forEach((network) => {
          if (network.networkType === NETWORK_TYPECODE.VISION) {
            this._appSession.networkList.push(network.code);
          }
        });
      }

      if (coverageDetails.hasMedicalPlan === false && (coverageDetails.hasDentalPlan || coverageDetails.isDentalStandAlone === true)) {
        this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.DENTAL;
      } else if (coverageDetails.hasMedicalPlan === false && (coverageDetails.hasVisionPlan || coverageDetails.isVisionStandAlone === true)) {
        this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.VISION;
      } else if (coverageDetails.hasMedicalPlan === false && (coverageDetails.hasPharmacyPlan || coverageDetails.isPharmacyStandAlone === true)) {
        this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.PHARMACY;
      }
      if (this._appSession.searchParams.typeSelectNm && this._appSession.searchParams.typeSelectNm.length > 0) {
        this._appSession.searchParams.providerTypeCodeList = [this._appSession?.deeplinkParams?.provtype ? this._appSession?.deeplinkParams?.provtype : this._appSession.searchParams.typeSelectNm];
      }
      if (this._appSession.searchParams.planTypeList?.length > 0) {
        this._appSession.searchParams.planTypeList = [];
      }

      this._appSession.hasRxCvsIndicator = coverageDetails.hasRxCVS;
      this._appSession.hasRxEsiIndicator = coverageDetails.hasRxESI;
      this._appSession.isDentalStandAlone = coverageDetails.isDentalStandAlone;
      this._appSession.isVisionStandAlone = coverageDetails.isVisionStandAlone;
      this._appSession.hasPharmacyPlan = coverageDetails.hasPharmacyPlan;
      this._appSession.isPharmacyStandAlone = coverageDetails.isPharmacyStandAlone;
      this._appSession.searchParams.includePrimeGroupSearch = true;
      this._appSession.hasLHO = contract.hasLHO;
      this._appSession.hasMedicalPlan = coverageDetails.hasMedicalPlan;
      this._appSession.subGroupId = coverageDetails?.subGroupId || '';
    }

    // Needed secure state Medicare category, state and prefix
    plan.category = planCategory;
    plan.stateCd = contract.underwritingStateCode;

    this._appSession.searchParams.plan = plan;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.isSecureState = true;
    this._appSession.isEycbanner = this._appSession.isEycMaintenanceEnabled && (contract.hasEYC || contract.hasSydneyBenefits);

    // set features in member's contract to app session
    this.setFeatureFlags(contract);

    // get member programs from app session if programs are not present in app contract
    contract = this.getClinicalProgramsFromSession(contract);

    if (
      typeof coverageDetails !== 'undefined' &&
      coverageDetails !== null &&
      typeof coverageDetails.coverageEffectiveDate !== 'undefined' &&
      coverageDetails.coverageEffectiveDate !== null &&
      coverageDetails.coverageEffectiveDate !== ''
    ) {
      this._appSession.hasEYC = false;
      this._appSession.hasDentalEYC = false;
    }
    this._appSession.sourceSystem = contract.sourceSystem;

    this._appSession.metaData.appContract = contract;
    this._appSession.pcpProductType = contract?.pcpProductType;

    // Need to set below values after result object is assigned to metadata.
    // Otherwise result object will override member UID and coverage details
    if (typeof selectedMember !== 'undefined' && selectedMember !== null && selectedMember.length > 0) {
      this._appSession.metaData.appContract.mbrUid = selectedMember[0].mbrUid;
      this._appSession.metaData.appContract.mbrUid = selectedMember[0].mbrUid;
      this._appSession.metaData.appContract.memberSeqNum = selectedMember[0].memberNumber;
    }

    if (typeof coverageDetails !== 'undefined' && coverageDetails !== null) {
      this._appSession.metaData.appContract.coverageDetails = coverageDetails;
    }

    //Append member zipcode to the dplUrl.
    if ((deeplink.provtype || deeplink.proccode || deeplink.cptcode || deeplink.taxonomies || deeplink.pcpid) && !deeplink.zipcode && this._appSession.searchParams.zip) {
      this._metadata.dplUrl = this._metadata.dplUrl + '&zipcode=' + this._appSession.searchParams.zip;
    }

    //Location for change pcp
    if (this._appSession.isChangePCP && !deeplink.zipcode && this._appSession.searchParams.zip) {
      if (this._metadata.dplUrl && this._metadata.dplUrl.includes('?')) {
        this._metadata.dplUrl = this._metadata.dplUrl + '&zipcode=' + this._appSession.searchParams.zip;
      } else {
        this._metadata.dplUrl = this._metadata.dplUrl + '?zipcode=' + this._appSession.searchParams.zip;
      }
    }
  }

  set pfEmMode(value) {
    this._appSession.metaData.pfEmMode = value;
  }

  /**
   * this method will retain the session value in contract object so that programs
   * will not overwritten when user change plan/contract.
   * @param contract
   */
  private getClinicalProgramsFromSession(contract: IAppContract): IAppContract {
    if (
      this._appSession.metaData &&
      this._appSession.metaData.appContract &&
      this._appSession.metaData.appContract.programs &&
      this._appSession.metaData.appContract.programs.length &&
      (!contract.programs || !contract.programs.length)
    ) {
      // retain programs if exist in session.
      contract.programs = this._appSession.metaData.appContract.programs;
    }

    if (this._appSession?.metaData?.appContract?.thirdPartyPrograms?.length && (!contract.thirdPartyPrograms || !contract.thirdPartyPrograms.length)) {
      contract.thirdPartyPrograms = this._appSession.metaData.appContract.thirdPartyPrograms;
    }
    return contract;
  }

  private validateSecureStateMetadata(): boolean {
    if (this._metadata.isSecureState) {
      if (this._dataHelper.isEmptyString(this._metadata.hcid)) {
        this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_IDENTIFICATION_NBR, AppErrorMsg.INVALID_IDENTIFICATION_NBR));
        return false;
      } else if (this._dataHelper.isEmptyString(this._metadata.mbrUid)) {
        this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_MEMBER_UID, AppErrorMsg.INVALID_MEMBER_UID));
        return false;
      } else if (this._dataHelper.isEmptyString(this._metadata.groupId)) {
        this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_GROUP_ID, AppErrorMsg.INVALID_GROUP_ID));
        return false;
      } else if (this._dataHelper.isEmptyString(this._metadata.userName)) {
        this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_USER_NAME, AppErrorMsg.INVALID_USER_NAME));
        return false;
      } else if (this._dataHelper.isEmptyString(this._metadata.firstName)) {
        this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_FIRST_NAME, AppErrorMsg.INVALID_FIRST_NAME));
        return false;
      } else if (this._dataHelper.isEmptyString(this._metadata.lastName)) {
        this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_LAST_NAME, AppErrorMsg.INVALID_LAST_NAME));
        return false;
      } else if (this._dataHelper.isEmptyString(this._metadata.sourceSystem)) {
        this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_SOURCE_SYSTEM, AppErrorMsg.INVALID_SOURCE_SYSTEM));
        return false;
      }
      if (this._appSession.deeplinkParams.client !== FindCareWebIntegrationClient.SYDNEYMEDICAID) this.validateNonMedicaidMetadata();
    }
    return true;
  }

  private validateNonMedicaidMetadata(): boolean {
    if (this._dataHelper.isEmptyString(this._metadata.dob)) {
      this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_DOB, AppErrorMsg.INVALID_DOB));
      return false;
    } else if (this._dataHelper.isEmptyString(this._metadata.mbrSequenceNbr)) {
      this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_SEQ_NBR, AppErrorMsg.INVALID_SEQ_NBR));
      return false;
    } else if (this._dataHelper.isEmptyString(this._metadata.webGuid)) {
      this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_WEB_GUID, AppErrorMsg.INVALID_WEB_GUID));
      return false;
    } else if (this._dataHelper.isEmptyString(this._metadata.contractUid)) {
      this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_CONTRACTUID, AppErrorMsg.INVALID_CONTRACTUID));
      return false;
    }
  }

  private validateBasicMetadata(metadata: string): boolean {
    if (this._dataHelper.isEmptyString(metadata)) {
      this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_METADATA, AppErrorMsg.INVALID_METADATA));
      return false;
    }
    this._metadata = JSON.parse(metadata);
    this._metadata.isCastlightTransparency = AppUtility.strToBoolean(this._metadata.isCastlightTransparency);
    if (this._dataHelper.isEmptyString(this._metadata.brand)) {
      this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_BRAND, AppErrorMsg.INVALID_BRAND));
      return false;
    } else if (this._dataHelper.isEmptyString(this._metadata.dplUrl)) {
      this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_DPL_URL, AppErrorMsg.INVALID_DPL_URL));
      return false;
    }
    return true;
  }

  private navigateToLandingPage(metadata: IMetadata, navigation: AppNavigations) {
    if (this._appSession.metaData.pfEmMode) {
      this._contentHelper.resetContent();
    }
    if (this._appSession.appState.opsIndicator?.isFcrEnabled && [SearchExecutionMode.SECURE, SearchExecutionMode.HCID_SEARCH].includes(this._appSession.appState.executionMode)) {
      if (CommonUtil.isCoupeCoverageOnly(this._appSession)) {
        this._navigationService.navigateByUrl(AppNavigations.DENIAL_OF_SERVICE_PATH);
      } else {
        if (this._appSession.appState.executionMode === SearchExecutionMode.SECURE) {
          this.handleSecureSearch(metadata, navigation);
        } else if (this._appSession.appState.executionMode === SearchExecutionMode.HCID_SEARCH) {
          this.handleHcidSearch(metadata);
        }
      }
    } else {
      if (metadata.isSecureState === true || this._appSession.appState.executionMode === SearchExecutionMode.SECURE) {
        const secureMemberSummary = this.getSecureSummaryPromise(metadata);
        this.findMemberDetails(secureMemberSummary, metadata, navigation);
      } else if (this.isHCIDSearch) {
        const publicMemberSummary = this.getPublicSummaryPromise(metadata);
        this.findMemberDetails(publicMemberSummary, metadata, navigation);
      } else {
        this._deeplinkHandler.setPublicDeeplinkParams(this._metadata.dplUrl);

        this._appSession.isSecureState = false;

        if (this._appSession.isDeepLink === true) {
          // Wait for all HTTP calls to complete before navigate to the page.
          this.handlePromiseAll(navigation);
        } else {
          this.alphaPrefixSearch(metadata, navigation);
        }
      }
    }
  }

  /**
   * Wait for all HTTP calls to complete before navigate to the page.
   * @param navigation
   */
  private handlePromiseAll(navigation: AppNavigations) {
    Promise.all(this._deeplinkHandler.getPromises).then(() => {
      let _hasLocation = !(
        this._dataHelper.isEmptyString(this._appSession.searchParams.zip) ||
        !this._appSession.searchParams?.coordinates ||
        this._appSession.searchParams.coordinates.latitude === '' ||
        this._appSession.searchParams.coordinates.longitude === ''
      );
      if (this._appSession.isChangePCP && this._appSession.pcpRule && this._appSession.pcpRule.isPCPReadOnly) {
        _hasLocation = false;
      }
      if (this._contentHelper.isSearchResultRoute(navigation)) {
        if (_hasLocation && this._appSession.searchParams.plan) {
          if (this._appSession.isCareProvider) {
            this._navigationService.navigateByUrl(AppNavigations.CARE_TEAM_PATH);
          } else {
            if (this._appSession.searchParams.plan && this._appSession.searchParams.plan.securePlanLabelKey && this._appSession.searchParams.plan.securePlanLabelKey !== AppConstants.PlanUnknown) {
              this._navigationService.navigateByUrl(navigation);
            } else {
              this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
            }
          }
        } else {
          this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
        }
      } else {
        //With the default minimum search params the search should conduct and land on summary page.
        // const _deeplink = this._deeplinkHandler.findDeeplinkParams(this._metadata.dplUrl);
        if (
          _hasLocation &&
          !this._appSession.isEyc &&
          this._appSession.searchParams.typeSelectNm &&
          this._appSession.searchParams.plan &&
          this._appSession.deeplinkParams?.client !== FindCareWebIntegrationClient.PEGAWGS &&
          !this._appSession.isMedicalCodeSearch &&
          this._dataHelper.isEmptyString(this._appSession.deeplinkParams?.cptcode)
        ) {
          if (this._metadata.isSecureState === false) {
            this._appSession.initialSearch = true;
            this.appInit();
          } else if (this._metadata.isSecureState === true && this._appSession.searchParams.plan.securePlanLabelKey === AppConstants.MyPlan) {
            if (this._dataHelper.areEqualStrings(this._appSession.searchParams?.typeSelectNm, PROVIDER_TYPE.PHARMACY) && !this.isSecurePharmacySearch() && this._appSession.deeplinkParams?.provtype) {
              this._appSession.isDeeplinkErrorMsg = true;
              this._navigationService.navigateByUrl(navigation);
            } else {
              if (this._appSession.isChangePCP) {
                this._appSession.searchParams.typeSelectNm = '';
                this._appSession.searchParams.providerTypeCodeList = [];
              }
              this._appSession.initialSearch = true;
              this._appSession.customAddressSearch = false;
              this.appInit();
            }
          } else if (this._appSession.dos) {
            this._navigationService.navigateByUrl(AppNavigations.DENIAL_OF_SERVICE_PATH);
          } else {
            this._navigationService.navigateByUrl(navigation);
          }
        } else if (
          _hasLocation &&
          (this._appSession.hasEYC || this._appSession.hasDentalEYC) &&
          this._metadata.isSecureState === true &&
          this._appSession.searchParams &&
          this._appSession.searchParams.plan &&
          this._appSession.searchParams.plan.securePlanLabelKey === AppConstants.MyPlan &&
          this._appSession.searchParams.eycSearchParam &&
          this._appSession.deeplinkParams?.client !== FindCareWebIntegrationClient.PEGAWGS
        ) {
          this._appSession.initialSearch = true;
          this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH, { rootRoute: true });
        } else if (_hasLocation && this._appSession.isMedicalCodeSearch && CommonUtil.isMemberSecure(this._appSession)) {
          this._appSession.initialSearch = true;
          this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH, { rootRoute: true });
        } else {
          if (
            (CommonUtil.isMemberSecure(this._appSession) && (!this._appSession?.hasEYC || !this._appSession?.hasDentalEYC) && this._appSession.deeplinkParams?.proccode) ||
            (!this._appSession?.isMedicalCodeSearch && this._appSession?.deeplinkParams?.cptcode)
          ) {
            this._appSession.isDeeplinkErrorMsg = true;
          }
          if (this._appSession.deeplinkParams?.deeplinkUrl === DeeplinkUrl.PROVIDER_DETAILS && !this._appSession.searchParams?.coordinates) {
            this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
          } else if (this._appSession.dos) {
            this._navigationService.navigateByUrl(AppNavigations.DENIAL_OF_SERVICE_PATH);
          } else {
            this._navigationService.navigateByUrl(navigation);
          }
        }
      }
    });
  }

  private async findMemberDetails(summaryPromise: Promise<any>, metadata: IMetadata, navigation: AppNavigations) {
    // Override default values with secure deeplink from RCP
    await this._deeplinkHandler.setSecureDeeplinkParams(this._metadata.dplUrl, navigation);
    summaryPromise.then(
      (result: IAppContract) => {
        if (result) {
          this._appSession.metaData.mbrUid = metadata.mbrUid;
          // Identification number will be assigned from UI only for HCID search.
          // In secure state Node API will take care of HCID.
          if (!metadata.mbrUid) {
            this._appSession.searchParams.identificationNumber = metadata.identificationNumber;
            // When result memberList is empty then throw an error msg in plan selection cmp
            if (typeof result.memberList !== 'undefined' && result.memberList !== null && result.memberList.length === 0) {
              this._pfMemberSummaryErrorEvent.emit(
                this.setErrorDetail(
                  AppEvents[AppEvents.HCID_ERR],
                  this.content && this.content.planSelectionComponent && this.content.planSelectionComponent.alerts && this.content.planSelectionComponent.alerts.invalidHCID
                    ? this.content.planSelectionComponent.alerts.invalidHCID
                    : AppErrorMsg.DEFAULT_ERROR_MSG,
                  AppErrorMsg.SERVICE_UNAVAILABLE
                )
              );
              this.onError(ERROR_TYPES.INVALID_HCID, AppErrorMsg.DEFAULT_ERROR_MSG);
              return;
            }
          }

          this._appSession.mbu = result.mbu;
          this._appSession.underwritingStateCode = result.underwritingStateCode;
          this._appSession.metaData.pcpIndicator = metadata.pcpIndicator;
          this._appSession.metaData.contractUid = result.contractUid;
          this.onMemberDetailsSuccess(result, navigation);
        } else {
          const plan = {} as IPlan;
          plan.securePlanLabelKey = AppConstants.PlanUnknown;
          this._appSession.searchParams.plan = plan;
          this._appSession.isSecureState = this._metadata.isSecureState;
          if (navigation === AppNavigations.CARE_TEAM_PATH || navigation === AppNavigations.CARE_CIRCLE_PATH) {
            this._navigationService.navigateByUrl(navigation);
          } else {
            this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
          }
        }
      },
      (error: any) => {
        this.onError(ERROR_TYPES.MEMBER_DETAILS, error, navigation);
      }
    );
  }

  private getSecureSummaryPromise(metadata: IMetadata) {
    return this._memberSummaryService.getMemberDetails(metadata, this._appSession?.metaData?.pfEmMode, this._appSession?.deeplinkParams?.client, this._appSession?.isHcidSearch);
  }

  private getPublicSummaryPromise(metadata: IMetadata) {
    return this._memberSummaryService.getPublicSummary(metadata, this._appSession?.metaData?.pfEmMode);
  }

  private async onMemberDetailsSuccess(result: IAppContract, navigation: AppNavigations) {
    //TODO: Implement app session handling
    if (!isEmpty(result)) {
      this.setAppSessionVariables(result);

      // Override default values with secure deeplink from RCP
      await this._deeplinkHandler.setSecureDeeplinkParams(this._metadata.dplUrl, navigation);
    }

    // Wait for all HTTP calls to complete before navigating to the page.
    this.handlePromiseAll(navigation);
  }

  private alphaPrefixSearch(metadata: IMetadata, navigation: AppNavigations) {
    if (metadata.alphaPrefix) {
      this._providerUtilityService.getAlphaPrefix(metadata.alphaPrefix, Locale.ENGLISH).then(
        (result: any) => {
          if (result && result.codeTypeList && result.codeTypeList.length > 0) {
            const _networkCode = result.codeTypeList[0].code;
            const _networkName = result.codeTypeList[0].name;
            const selectedPlan: IPlan = {
              identifier: '',
              name: _networkName ? _networkName : _networkCode,
              isNationalPlan: null,
              stateCd: '',
              networkList: [],
              alphaPrefix: metadata.alphaPrefix
            };
            this._appSession.searchParams.plan = selectedPlan;
            this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;

            this._navigationService.navigateByUrl(navigation);
          }
        },
        (error: any) => {
          this.onError(ERROR_TYPES.ALPHA_PREFIX, error);
        }
      );
    } else {
      // Reset to navigate plan selection page.
      this._appSession.searchParams = undefined;
      this._navigationService.navigateByUrl(navigation);
    }
  }

  private async onError(errorType: string, error: any, navigation?: AppNavigations) {
    // Navigate to error page on member summary failure and display message for public search.
    if (this._appSession.isWebView === true && errorType === ERROR_TYPES.MEMBER_DETAILS && this._appSession.isEyc === false) {
      this._appSession.isSecureState = this._metadata.isSecureState;
      this._appSession.appEvent = AppEvents.MEMBER_SUMMARY_ERR;
      this._navigationService.navigateByUrl(AppNavigations.ERROR_PATH);
      throw error;
    }

    if (error instanceof HttpErrorResponse) {
      // Server Error
      if (error.status === 0) {
        this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.SERVICE_UNAVAILABLE, AppErrorMsg.SERVICE_UNAVAILABLE));
      }

      let _errorCode = 0;
      if (error.error && error.error.exceptionMsg && error.error.exceptionMsg.Exceptions && error.error.exceptionMsg.Exceptions.length > 0) {
        _errorCode = error.error.exceptionMsg.Exceptions[0].code;
      }

      if (_errorCode === 888 && this._metadata.isSecureState === false) {
        // Custom errorcode to handle multi contract scenario in public state.
        this._pfMemberSummaryErrorEvent.emit(
          this.setErrorDetail(
            AppEvents[AppEvents.HCID_ERR],
            this.content && this.content.planSelectionComponent && this.content.planSelectionComponent.alerts && this.content.planSelectionComponent.alerts.multiContractError
              ? this.content.planSelectionComponent.alerts.multiContractError
              : AppErrorMsg.DEFAULT_ERROR_MSG,
            AppErrorMsg.SERVICE_UNAVAILABLE
          )
        );
      } else if (_errorCode === 999 && this._metadata.isSecureState === false) {
        // Custom errorcode to handle hcid no record scenario in public state.
        this._pfMemberSummaryErrorEvent.emit(
          this.setErrorDetail(
            AppEvents[AppEvents.HCID_ERR],
            this.content && this.content.planSelectionComponent && this.content.planSelectionComponent.alerts && this.content.planSelectionComponent.alerts.hcidError
              ? this.content.planSelectionComponent.alerts.hcidError
              : AppErrorMsg.DEFAULT_ERROR_MSG,
            AppErrorMsg.SERVICE_UNAVAILABLE
          )
        );
      } else {
        if (errorType === ERROR_TYPES.MEMBER_DETAILS) {
          // Takes care of member summary error in public state.
          // In public state PF will display the error message.
          if (this._metadata.isSecureState === true && this._appSession.isIdentifactionSearch === false && (error.status === 400 || error.status === 500 || error.status === 405)) {
            // Expectation is error status codes 400 or 500 will return in case of
            // When PF is unable to determine the coverage to display for a member.
            const plan = {} as IPlan;
            plan.securePlanLabelKey = AppConstants.PlanUnknown;
            this._appSession.searchParams.plan = plan;
            this._appSession.isSecureState = this._metadata.isSecureState;
            // Override default values with secure deeplink from RCP
            await this._deeplinkHandler.setSecureDeeplinkParams(this._metadata.dplUrl, navigation);
            if (navigation === AppNavigations.CARE_TEAM_PATH || navigation === AppNavigations.CARE_CIRCLE_PATH) {
              this._navigationService.navigateByUrl(navigation);
            } else {
              this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
            }
            // PF app will throw error to RCP to display error message on RCP side.
          } else if (this._metadata.isSecureState === true && this._appSession.isIdentifactionSearch === false) {
            this._pfMetadataErrorEvent.emit(
              this.setErrorDetail(
                AppEvents[AppEvents.PF_APP_LOAD_ERR],
                this.content && this.content.appErrorMsg && this.content.appErrorMsg.defaultErrorMsg ? this.content.appErrorMsg.defaultErrorMsg : AppErrorMsg.DEFAULT_ERROR_MSG,
                AppErrorMsg.SERVICE_UNAVAILABLE
              )
            );
          } else {
            this._pfMemberSummaryErrorEvent.emit(
              this.setErrorDetail(
                AppEvents[AppEvents.HCID_ERR],
                this.content && this.content.appErrorMsg && this.content.appErrorMsg.defaultErrorMsg ? this.content.appErrorMsg.defaultErrorMsg : AppErrorMsg.DEFAULT_ERROR_MSG,
                AppErrorMsg.SERVICE_UNAVAILABLE
              )
            );
          }
        } else {
          this._pfMetadataErrorEvent.emit(
            this.setErrorDetail(
              AppEvents[AppEvents.PF_APP_LOAD_ERR],
              this.content && this.content.appErrorMsg && this.content.appErrorMsg.defaultErrorMsg ? this.content.appErrorMsg.defaultErrorMsg : AppErrorMsg.DEFAULT_ERROR_MSG,
              AppErrorMsg.SERVICE_UNAVAILABLE
            )
          );
        }
      }
    } else {
      if (errorType === ERROR_TYPES.INVALID_HCID) {
        this._pfMetadataErrorEvent.emit(this.setErrorDetail(AppEvents[AppEvents.PF_APP_LOAD_ERR], AppErrorMsg.INVALID_IDENTIFICATION_NBR, AppErrorMsg.SERVICE_UNAVAILABLE));
      } else {
        this._pfMetadataErrorEvent.emit(
          this.setErrorDetail(
            AppEvents[AppEvents.PF_APP_LOAD_ERR],
            this.content && this.content.appErrorMsg && this.content.appErrorMsg.defaultErrorMsg ? this.content.appErrorMsg.defaultErrorMsg : AppErrorMsg.DEFAULT_ERROR_MSG,
            AppErrorMsg.SERVICE_UNAVAILABLE
          )
        );
      }
    }

    throw error;
  }

  private setErrorDetail(type: string, message: string, details: string) {
    const errorDetail = {} as IEventDetail;

    errorDetail.type = type;
    errorDetail.message = message;
    errorDetail.details = details;

    return errorDetail;
  }

  private get content(): any {
    return this._contentHelper.getContent('PFSearchContainerComponent');
  }

  private setNavigation(dplParams: IDeeplink): AppNavigations {
    let navigation: AppNavigations;

    if (dplParams && dplParams.deeplinkUrl === DeeplinkUrl.COST_SEARCH) {
      navigation = AppNavigations.COST_SEARCH_PATH;
      this._appSession.isEyc = true;
    } else if (dplParams && dplParams.deeplinkUrl === DeeplinkUrl.PROVIDER_DETAILS) {
      navigation = AppNavigations.PROVIDER_DETAILS_PATH;
      this._appSession.initialSearch = true;
    } else if (dplParams && dplParams.deeplinkUrl === DeeplinkUrl.SEARCH_RESULTS) {
      navigation = AppNavigations.SEARCH_RESULTS_PATH;
      this._appSession.initialSearch = true;
    } else if (dplParams && dplParams.deeplinkUrl === DeeplinkUrl.CARE_TEAM) {
      navigation = AppNavigations.CARE_TEAM_PATH;
      this._appSession.initialSearch = true;
    } else if (dplParams && dplParams.deeplinkUrl === DeeplinkUrl.CARE_CIRCLE) {
      navigation = AppNavigations.CARE_CIRCLE_PATH;
      this._appSession.initialSearch = true;
    } else if (dplParams && dplParams.deeplinkUrl === DeeplinkUrl.PROMOTED_PROCEDURES) {
      navigation = AppNavigations.PROMOTED_PROCEDURES_PATH;
      this._appSession.initialSearch = true;
    } else if (dplParams && dplParams.deeplinkUrl === DeeplinkUrl.VIRTUAL_CARE) {
      navigation = AppNavigations.VIRTUAL_CARE_PATH;
      this._appSession.initialSearch = true;
    } else if (dplParams && dplParams.deeplinkUrl === DeeplinkUrl.CARE_NOW) {
      navigation = AppNavigations.CARE_NOW;
      this._appSession.initialSearch = true;
    } else {
      navigation = AppNavigations.SEARCH_PROVIDERS_PATH;
    }

    return navigation;
  }

  private setDefaultSearchParams() {
    this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
    this._appSession.searchParams.providerTypeCodeList = [this._appSession.searchParams.typeSelectNm];
    this._appSession.searchParams.blueDistinctionCenter = [];
    this._appSession.searchParams.genderCd = '';
    this._appSession.searchParams.boardCertification = false;
    this._appSession.searchParams.provNm = '';
    this._appSession.searchParams.includePrimeGroupSearch = false;
    this._appSession.metaData.locale = this._appSession.hasAppInitialized
      ? this._appSession.metaData.locale
      : this._metadata?.locale && this._dataHelper.areEqualStrings(this._metadata.locale, Locale.SPANISH)
        ? Locale.SPANISH
        : Locale.ENGLISH;
  }

  private get isHCIDSearch(): boolean {
    if (this._appSession.appState.executionMode === SearchExecutionMode.HCID_SEARCH || (this._metadata.isSecureState === false && this._metadata.hcid)) {
      this._appSession.isHcidSearch = true;
      this._metadata.identificationNumber = this._metadata.hcid;
      this._metadata.hcid = undefined;
      return true;
    }
    return false;
  }

  //AppMaintenance change:- Identifies if the current page is FAD/EYC from the route and
  //the page name will later sent to api to check for page availability
  private getAppNameFromRoute(navigation: AppNavigations): string {
    //To set the functionality in PROD environment only
    if (this._appConfig?.production) {
      switch (navigation) {
        case AppNavigations.COST_SEARCH_PATH:
          return PF_APP_NAME.PAGE_EYC;
        case AppNavigations.SEARCH_PROVIDERS_PATH:
        case AppNavigations.PROVIDER_DETAILS_PATH:
        case AppNavigations.SEARCH_RESULTS_PATH:
          return PF_APP_NAME.PAGE_FAD;
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  /**
   * set client property if consumer is passing the value
   */
  private setClient() {
    const deeplinkParams: IDeeplink = this._deeplinkHandler.findDeeplinkParams(this._metadata.dplUrl);
    const client: string = (deeplinkParams.client || '').toUpperCase();

    if (this._appSession.deeplinkParams && client in FindCareWebIntegrationClient) {
      this._appSession.deeplinkParams.client = client;
      // fcr - setting appState > opsState > client
      this._appSession.appState.opsState.client = client as FindCareWebIntegrationClient;
    }

    const _metaSrcEnv = !this._dataHelper.isEmptyString(this._metadata.metaSrcEnv) ? this._metadata.metaSrcEnv : '';
    this._appSession.metaData.metaSrcEnv = _metaSrcEnv;
  }

  private setAppSessionAddressObj(member) {
    if (member?.addresses?.length > 0) {
      this.setAddresses(member.addresses);
    }
  }

  private setAddresses(addresses: Array<IAddresses>): void {
    const addressDetails = {} as IAddressDetails;
    const homeAddress = {} as IAddressForm;
    const workAddress = {} as IAddressForm;
    const customAddress = {} as IAddressForm;
    if (this._appSession.isSecureState === true) {
      (addresses || []).forEach((address: IAddresses) => {
        if (address?.type?.toLowerCase() === ADDRESS_SELECT.HOME) {
          homeAddress.streetAddress = address?.addressLineOne;
          homeAddress.addressLine2 = address?.addressLineTwo;
          homeAddress.city = address?.city;
          homeAddress.state = address?.state;
          homeAddress.zipCode = address?.postalCode;
          homeAddress.isHomeAddress = true;
          homeAddress.isFormValid = this.validateAddress(address);
          addressDetails.homeAddress = homeAddress;
        } else if (address?.type?.toLowerCase() === ADDRESS_SELECT.WORK) {
          workAddress.streetAddress = address?.addressLineOne;
          workAddress.addressLine2 = address?.addressLineTwo;
          workAddress.city = address?.city;
          workAddress.state = address?.state;
          workAddress.zipCode = address?.postalCode;
          workAddress.isHomeAddress = false;
          workAddress.isFormValid = true;
          addressDetails.workAddress = workAddress;
        }
        this._appSession.addressObj = addressDetails;
        this._appSession.addressObj.addressModel = ADDRESS_SELECT.HOME;
      });
    } else if (addresses?.length) {
      addresses.forEach((address: IAddresses) => {
        customAddress.streetAddress = address?.addressLineOne;
        customAddress.addressLine2 = address?.addressLineTwo;
        customAddress.city = address?.city;
        customAddress.state = address?.state;
        customAddress.zipCode = address?.postalCode;
        customAddress.isHomeAddress = false;
        customAddress.isFormValid = true;
        addressDetails.customAddress = customAddress;
      });
      this._appSession.addressObj = addressDetails;
      this._appSession.addressObj.addressModel = ADDRESS_SELECT.CUSTOM_ADDRESS;
    }
  }

  private validateAddress(address: IAddresses) {
    return address?.addressLineOne && address?.city && address?.state && address?.postalCode.length === 5;
  }

  private appInit(): void {
    const planRule = {} as IPlanRule;
    const planRequest: IPlanRuleParameters = CommonUtil.buildPlanRuleRequest(this._appSession.searchParams, this._appSession);
    this._ruleService.getAppInitializationRules(planRequest).then(
      (result: IPlanRule) => {
        planRule.isCaHmoDmhc = result?.isCaHmoDmhc;
        planRule.cobrandRule = result?.cobrandRule;
        this._appSession.planRule = planRule;
        this._appSession.isCaHmoDmhc = result?.isCaHmoDmhc;
        if (Array.isArray(result?.availableProviders) && result?.availableProviders?.length) {
          this._appSession.providerTypes = CommonUtil.populateProviderTypes(result.availableProviders, this._appSession, true);
        }
        if (this._appSession?.planRule?.isCaHmoDmhc && this._appSession?.deeplinkParams?.provtype) {
          this._savNavigationSav.navigate();
        } else if (this._appSession?.planRule?.isCaHmoDmhc && !this._appSession?.deeplinkParams?.provtype) {
          this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH, { rootRoute: true } as Params);
        } else {
          this._appSession?.deeplinkParams?.adrskey && this._appSession?.deeplinkParams?.provkey
            ? this._navigationService.navigateByUrl(AppNavigations.PROVIDER_DETAILS_PATH)
            : this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
        }
      },
      (error: any) => {
        this.onError(ERROR_TYPES.PLAN_RULE, error);
      }
    );
  }

  /**
   * method is responsible for setting features available in member contract to app session
   * @param contract
   */
  private setFeatureFlags(contract: IAppContract): void {
    this._appSession.hasEYC = contract.hasEYC && !this._appSession.isEycMaintenanceEnabled;
    this._appSession.hasDentalEYC = contract.hasDentalEYC;
    this._appSession.hasSydneyBenefits = contract.hasSydneyBenefits && !this._appSession.isEycMaintenanceEnabled;
    // TODO: contract.hasEngagement and contract.hasHealthWellness can be removed once RCP go live with feature auth API.
    this._appSession.hasEngagement = contract.hasEngagement || (contract.v2Features && contract.v2Features.hasEngagement);
    this._appSession.hasHealthWellness = contract.hasHealthWellness || (contract.v2Features && contract.v2Features.hasHealthWellness);
    this._appSession.hasHealthSystem = contract.hasHealthSystem;
    this._appSession.hasCostTransparency = contract.hasCostTransparency;
    this._appSession.hasFindCareFts = contract.hasFindCareFts;
    this._appSession.dos = contract.hasCoupeCoverageOnly ? DOS.COUPE_HEALTH : '';
  }

  /**
   * This method will check if Pharmacy search is applicable to the member or not
   * @returns boolean
   */
  isSecurePharmacySearch(): boolean {
    let isValidPharmacySearch = false;
    if (CommonUtil.isMemberSecure(this._appSession) && (this._appSession?.hasPharmacyPlan || this._appSession?.isPharmacyStandAlone)) {
      isValidPharmacySearch = true;
    }
    return isValidPharmacySearch;
  }

  /**
   * Handles secure search by setting session values and navigating to the appropriate path.
   * @param metadata The metadata containing member information.
   * @param navigation The navigation path.
   */
  private handleSecureSearch(metadata: IMetadata, navigation: AppNavigations) {
    this._appSession.metaData.mbrUid = metadata.mbrUid;
    const selectedContract = this._appSession.appState?.selectedEligibilityProduct?.selectedContract;

    if (selectedContract) {
      const { searchParams } = this._appSession;

      // Find deeplink parameters
      const deeplink = this._deeplinkHandler.findDeeplinkParams(this._metadata.dplUrl);
      this._deeplinkHandler.setSecureDeeplinkParams(this._metadata.dplUrl, navigation);

      // Update deeplink URL with zipcode
      this.updateDeeplinkUrl(deeplink, searchParams.zip);

      // Wait for all HTTP calls to complete before navigating to the page.
      this.handlePromiseAll(navigation);
    } else {
      this.handleNoSelectedContract(navigation);
    }
  }

  /**
   * Handles HCID search by setting session values and navigating to the appropriate path.
   * @param metadata The metadata containing member information.
   */
  private handleHcidSearch(metadata: IMetadata) {
    this._appSession.isHcidSearch = true;
    this._appSession.searchParams.identificationNumber = this._metadata.identificationNumber = metadata.hcid;
    delete this._metadata.hcid;

    const selectedContract = this._appSession.appState?.selectedEligibilityProduct?.selectedContract;

    if (selectedContract) {
      this._navigationService.navigateByUrl(AppNavigations.FCR_LANDING_PATH);
    } else {
      this.emitHcidError();
      this.onError(ERROR_TYPES.INVALID_HCID, AppErrorMsg.DEFAULT_ERROR_MSG);
      return;
    }
  }

  /**
   * Handles the case when no selected contract is found.
   * @param navigation The navigation path.
   */
  private handleNoSelectedContract(navigation: AppNavigations) {
    const plan = {} as IPlan;
    plan.securePlanLabelKey = AppConstants.PlanUnknown;
    this._appSession.searchParams.plan = plan;
    this._appSession.isSecureState = this._metadata.isSecureState;
    if (navigation === AppNavigations.CARE_TEAM_PATH || navigation === AppNavigations.CARE_CIRCLE_PATH) {
      this._navigationService.navigateByUrl(navigation);
    } else {
      this._navigationService.navigateByUrl(AppNavigations.FCR_LANDING_PATH);
    }
  }

  /**
   * Emits an HCID error event with the appropriate error details.
   */
  private emitHcidError() {
    this._pfMemberSummaryErrorEvent.emit(
      this.setErrorDetail(AppEvents[AppEvents.HCID_ERR], this.content?.planSelectionComponent?.alerts?.invalidHCID || AppErrorMsg.DEFAULT_ERROR_MSG, AppErrorMsg.SERVICE_UNAVAILABLE)
    );
  }

  /**
   * Updates the deeplink URL with the provided zipcode.
   * @param deeplink The deeplink parameters.
   * @param zipcode The zipcode to append to the URL.
   */
  private updateDeeplinkUrl(deeplink: any, zipcode: string) {
    if ((deeplink.provtype || deeplink.proccode || deeplink.cptcode || deeplink.taxonomies || deeplink.pcpid) && !deeplink.zipcode && zipcode) {
      this._metadata.dplUrl = `${this._metadata.dplUrl}&zipcode=${zipcode}`;
    }

    if (this._appSession.isChangePCP && !deeplink.zipcode && zipcode) {
      if (this._metadata.dplUrl.includes('?')) {
        this._metadata.dplUrl = `${this._metadata.dplUrl}&zipcode=${zipcode}`;
      } else {
        this._metadata.dplUrl = `${this._metadata.dplUrl}?zipcode=${zipcode}`;
      }
    }
  }
}
