export const BING_MAPS_CUSTOM_STYLE: Microsoft.Maps.ICustomMapStyle = {
  elements: {
    area: { fillColor: '#a7dab2' },
    water: { fillColor: '#8bb1f7' },
    highway: { fillColor: '#fdde81', strokeColor: '#f5a111' }
  },
  settings: {
    landColor: '#e2e5e9',
    shadedReliefVisible: false
  },
  version: '1.0'
};

export enum FilterCategory {
  GENDER = 'gender',
  SPECIALTY = 'specialty',
  TAXONOMY = 'taxonomy',
  PROVIDER_TYPE = 'type',
  LANGUAGE = 'language',
  ADAACCSIBILE = 'adaAccessible',
  AREAOFEXPERTISE = 'areaOfExpertise',
  BILLINGCODE = 'billingCode',
  ETHNICITY = 'ethnicities',
  LEVELOFCARE = 'levelOfCares',
  OFFICESERVICES = 'officeServices',
  OFFICEFEATURES = 'officeFeatures',
  MEDICALGROUP = 'medicalGroup',
  HOSPITALAFFILIATION = 'hospitalAffiliation',
  PROFILE = 'profile',
  SERVICES_AVAILABLE = 'servicesAvailable',
  PATIENT_POPULATION = 'patientPopulation',
  KEYFILTERS = 'keyFilters',
  DISTANCE = 'distance'
}

export const ADOBE_TAGS_DISCLAIMER = {
  discResults: 'showDisclaimerProviderResults',
  discEycResults: 'showDisclaimerEycProviderResults'
};

export enum ProviderTabs {
  OVERVIEW = 'OVERVIEW',
  MORE_DETAILS = 'MORE_DETAILS',
  COST_ESTIMATE = 'COST_ESTIMATE'
}
export enum PDF_FILE_NAME {
  MEDICAL_PROCEDURE = 'medical_procedure',
  DENTAL_PROCEDURE = 'dental_procedure',
  MEDICAL_CODE = 'medical_code',
  SEARCH_ON = 'search_on'
}

export const UNDERSCORE = '_';
export const HYPHEN = '-';
export const INDEX_ZERO = 0;
export const INDEX_FIFTY = 50;
export enum ProviderCard {
  INSURANCE = 'INSURANCE',
  SPECIALITY = 'SPECIALITY',
  PCP = 'PCP',
  RECOGNITION = 'RECOGNITION',
  SERVICE = 'SERVICE',
  OVERVIEW = 'OVERVIEW',
  AFFILIATION = 'AFFILIATION',
  LICENSE = 'LICENSE',
  MORE_DETAILS = 'MORE_DETAILS',
  PHARMACY = 'PHARMACY',
  PROCEDURE = 'PROCEDURE',
  COST_DETAILS = 'COST_DETAILS',
  CPT = 'CPT'
}

export enum ProviderCardNavigation {
  AFFILIATION = 'AFFILIATION',
  LOCATION = 'LOCATION',
  PROVIDER = 'PROVIDER',
  COMPARE = 'COMPARE'
}

export enum FilterValue {
  ABLE_TO_SERVE_AS_PCP = 'SERVE_AS_PCP',
  ACCEPT_PATIENTS = 'ACCEPTING_NEW_PATIENT',
  TELEHEALTH = 'teleHealthProvider',
  EXTENDED_HOURS = 'extendedHours',
  MEDICAID = 'MDCFLG'
}

export const PERSONALIZED_MATCH = 'PersonalizedMatch';
