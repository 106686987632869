import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from '../../../../../../../../environments/environment';
import { AppExternalCommands, Locale, SEARCH_LOCATION_TYPE, SEARCH_TYPE, SERVE_PCP_Y } from '../../../../../../../common/constants/app-constants';
import { AppNavigations } from '../../../../../../../common/constants/app-navigations';
import { SEARCH_SCENARIOS } from '../../../../../../../common/constants/strategy-search-constant';
import { AppEvents } from '../../../../../../../common/enums/appEvents';
import { IEventDetail } from '../../../../../../../common/interfaces/iEventDetail';
import { LocationResponse } from '../../../../../../../common/models/locationAddress';
import { HighlightPipe } from '../../../../../../../common/pipes/highlightPipe';
import { EventHandler } from '../../../../../../../common/services/eventHandler';
import { SearchSvc } from '../../../../../../../common/services/searchSvc';
import { AppUtility } from '../../../../../../../common/utilities/appUtil';
import { AppSession } from '../../../../../../../common/values/appSession';
import { ContentHelper } from '../../../../../../../common/values/contentHelper';
import { IRulesResponse } from '../../../../../../../fad/rules/interfaces/iRulesResponse';
import { RulesService } from '../../../../../../../fad/rules/services/RulesSvc';
import { ITranslation } from '../../../../../../../fad/search-providers/interfaces/iAdaListResp';
import { ICptCode } from '../../../../../../../fad/search-providers/interfaces/iCptLookup';
import { IMap } from '../../../../../../../fad/search-providers/interfaces/iMap';
import { ISearchParameters } from '../../../../../../../fad/search-providers/interfaces/iSearchParameters';
import { ProviderUtilityService } from '../../../../../../../fad/search-providers/services/providerUtilitySvc';
import { SAVNavigationService } from '../../../../../../../fad/search-providers/services/savNavigationSvc';
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE } from '../../../../../../../fad/search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../../../../../../fad/utilities/commonUtil';
import { FtsSuggestionSelectService } from '../../../../../../result/services/ftsSuggestionSelectSvc';
import {
  IFtsAssistanceSelection,
  IFtsClientAssistanceResponse,
  IFtsClientRequest,
  IFtsLocationDetails,
  IFtsSuggestion,
  IFtsSuggestionResponse,
  IMlAssistanceItem
} from '../../../../../interfaces/iFtsClientInteraction';
import { IMember } from '../../../../../interfaces/iMember';
import { IProcedureLookup } from '../../../../../interfaces/iProcedureLookup';
import { EycProcedureCriteria, EycSearchParam } from '../../../../../interfaces/iSearchRequest';
import { ActionService } from '../../../../../services/actionSvc';
import { FreeTextSearchSvc } from '../../../../../services/freeTextSearchSvc';
import { FtsAvailableSuggestionService } from '../../../../../services/ftsAvailableSuggestionSvc';
import { BaseComponent } from '../../../../core/baseCmp';
import { GenericModalService } from '../../../modal/services/genericModalSvc';

declare let window: any;
@Component({
  moduleId: module.id,
  selector: 'app-fc-free-text-search-cmp',
  templateUrl: './freeTextSearchCmp.html',
  providers: []
})
export class FreeTextSearchComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  searchParams: ISearchParameters;
  @Output()
  reloadSearch: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  loadCahmoPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('searchTermIdentifier')
  searchTermInput: ElementRef;
  @ViewChild('ftsMoreExamples')
  ftsMoreExamplesSidePanel: TemplateRef<HTMLElement>;
  @ViewChild('dialogWidth') parentElement: ElementRef;
  previous_searchParams: ISearchParameters;
  locale: string;
  searchWatermark = '';
  searchCriteria: ISearchParameters;
  showLookUp = false;
  showClearText = false;
  suggestionError = false;
  showExample = false;
  noSearchCriteria = false;
  searchTerm = '';
  previousSearchTerm = '';
  ftsPhrase = '';
  assistanceSelection: Array<IFtsAssistanceSelection>;
  ftsAssistanceList: IMlAssistanceItem[]; //Assistance phrase options to help complete the search texts - returned by AssistanceAPI
  availableSuggestionList: IFtsSuggestion<ITranslation>[];
  locationDetails: IFtsLocationDetails;
  inputMinLength = environment.features.lookUp.inputLength;
  inputMaxLength = environment.features.lookUp.inputMaxLength;
  executionCount: number = 0;
  medicalCodeCriteria: {
    billingType: string;
    medicalCode: string;
    medicalModifierOptionCode?: string;
    medicalPosCode?: string;
  } = {
    billingType: '',
    medicalCode: ''
  };
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;

  private _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);
  private selectedSuggestionSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _freeTextSearchSvc: FreeTextSearchSvc,
    private _highlightPipe: HighlightPipe,
    private _ruleService: RulesService,
    private _elementRef: ElementRef,
    private _sidePanel: SidePanel,
    private _savNavigationService: SAVNavigationService,
    private _availableSuggestionSvc: FtsAvailableSuggestionService,
    private _suggestionSelectService: FtsSuggestionSelectService,
    private _providerUtilityService: ProviderUtilityService,
    private _appUtility: AppUtility,
    private searchService: SearchSvc,
    private _genericModalService: GenericModalService,
    private _actionService: ActionService
  ) {
    super(_route, _eventHandler, _appSession);
    this.selectedSuggestionSubscription = this._suggestionSelectService.getSelectedSuggestion().subscribe(({ searchTerm, suggestion }) => {
      if (searchTerm && suggestion) {
        this.bindSuggestionsFromRelatedSearchesClick(searchTerm, suggestion);
      }
    });
  }
  ngOnDestroy(): void {
    //When switching from Free Text Search to Regular search
    if (!this.isFtsFeatureActive) {
      this.clearLookUpList();
      this.resetSearchCriteria();
      this.resetAppSessionParams();
    }
    this.resetInitialLocation();
    this.selectedSuggestionSubscription.unsubscribe();
  }

  resetInitialLocation(): void {
    this.searchCriteria = {
      ...this.searchCriteria,
      ...this._appSession.location
    };
    this.searchParams = { ...this.searchParams, ...this._appSession.location };
  }

  ngOnInit() {
    if (this.waitUntilAppReload) return;
    this.locale = this._appSession?.metaData?.locale?.toString() ?? Locale.ENGLISH;

    if (this._appSession) {
      this._appSession.integratedSearch = true;
      this.searchTerm = this._appSession.searchTerm ?? '';
      //TODO: Include only if it s not implemented in the NodeJS
      // this.hasEYC = this.isMemberSecure && (this._appSession.hasEYC || this._appSession.hasDentalEYC);
      // this.hasCostTransparency = this.isMedicalCodeSearchEnabled;
      if (!this._appSession.location.coordinates) {
        this._providerUtilityService.getGeoLocation(this._appSession.location.zip).then((result: LocationResponse[]) => {
          if (result && Object.keys(result).length) {
            const results = this._appUtility.buildLocationResponse(result);
            this._appSession.location.coordinates = {
              latitude: results[Object.keys(results)[0]].lat,
              longitude: results[Object.keys(results)[0]].long
            } as IMap;
          }
        });
      }
      this.setBrowserLocation();
    }
    this.searchWatermark = this.content?.common?.pageHeader?.freeTextSearch?.searchTextHint;
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_SEARCH_BAR_LOAD];
    eventDetail.message = AppExternalCommands.PF_APP_SEARCH_BAR_LOADED.MSG;
    eventDetail.target = AppExternalCommands.PF_APP_SEARCH_BAR_LOADED.CMD;
    this._pfAppCommandEvent.emit(eventDetail);
    this.resetAssistanceSelection();
  }

  /*
   * Lifecycle hook that is called after Angular has fully initialized a component's view.
   */
  ngAfterViewInit() {
    const parentWidth = this.parentElement.nativeElement.offsetWidth;

    fromEvent(this.searchTermInput.nativeElement, 'keyup')
      .pipe(
        // Time in milliseconds between key events
        debounceTime(200),
        // If previous query is diffent from current
        distinctUntilChanged(),
        // get value
        map((event) => {
          return event;
        })
      )
      .subscribe((event: KeyboardEvent) => {
        this.onKeyUpEvent(event);
      });
  }

  /*
   * Method to handle the focus event on the search input field
   */
  onFocus() {
    if (this.searchTerm === '') {
      this.showExample = true;
      this.showLookUp = true;
      this.showClearText = false;
    } else {
      this.showClearText = true;
    }
  }

  /*
   * Method to handle the changes in the search parameters
   */
  ngOnChanges(args: any) {
    if (args['searchParams'] && args['searchParams'].currentValue) {
      this.setSearchParams(args['searchParams'].currentValue);
    }
  }

  setSearchParams(params: ISearchParameters) {
    this.searchCriteria = params;
  }
  get showLoading() {
    return this.hasLookUpItems();
  }
  initiateSearchTermActions(_searchTerm: string) {
    if (this.previousSearchTerm === _searchTerm) {
      return;
    }
    this.previousSearchTerm = _searchTerm;
    this.clearLookUpList();
    this.suggestionError = false;
    this.noSearchCriteria = false;

    if (_searchTerm === '') {
      this.showExample = true;
      this.showClearText = false;
      return;
    }
    this.showClearText = true;
    this.showExample = false;
    if (_searchTerm.length > this.inputMinLength) {
      if (this.isFtsFeatureActive) {
        const assistanceRequest = this.bindFtsAssistanceRequest(_searchTerm);
        this.getFreeTxtSearchAssistance(assistanceRequest);
      }
    } else {
      this.resetAssistanceSelection();
    }
  }

  onKeyUpEvent(event: KeyboardEvent) {
    //TODO: add logic to display example when empty string in searchTerm
    const _searchTerm = this.searchTerm;
    switch (event.key) {
      case 'Enter':
        this.initiateSearch();
        break;
      case 'backspace':
      case 'delete':
        this.resetAssistanceSelection();
        break;
      case 'ArrowDown':
        if (this.ftsAssistanceList?.length) {
          const listItems = document.querySelectorAll('.look-up-item-border div');
          (listItems?.[0] as HTMLElement).focus();
        }
        break;
      default:
        this.initiateSearchTermActions(_searchTerm);
    }
  }

  initiateSearch(): void {
    this.clearLookUpList();
    //TODO: remove/update the resetAssistanceSelection after suggestion API results are fully stable
    this.resetAssistanceSelection();
    if (this.isLocationValid()) {
      this.bindSuggestions(this.searchTerm);
    }
  }

  /* Method to handle the keydown event of tab and arrowdown */
  onKeydownFromSearchLink(event: KeyboardEvent) {
    switch (event.key) {
      case 'Tab':
        const addressLink = document.getElementById('address-selection-link');
        addressLink.focus();
        event.preventDefault();
        this.hideLookup();
        break;
      case 'ArrowDown':
        if (this.ftsAssistanceList.length) {
          const listItems = document.querySelectorAll('.look-up-item-border div');
          (listItems?.[0] as HTMLElement).focus();
        }
        break;
    }
  }
  /* Method to handle the keydown event */
  onKeydownThroughAssistance(event: KeyboardEvent, assistance: IMlAssistanceItem, index: number) {
    const listItems = document.querySelectorAll('.look-up-item-border div');
    const inputField = document.getElementById('fts-txt-search-field');
    switch (event.key) {
      case 'Enter':
      case ' ':
        this.onAssistanceSelect(assistance);
        event.preventDefault();
        break;
      case 'ArrowUp':
        (listItems[(index - 1 + listItems.length) % listItems.length] as HTMLElement).focus();
        event.preventDefault();
        break;
      case 'ArrowDown':
        (listItems[(index + 1) % listItems.length] as HTMLElement).focus();
        event.preventDefault();
        break;
      case 'Tab':
        inputField.focus();
        event.preventDefault();
        break;
    }
  }

  hideLookup(): void {
    this.showLookUp = false;
  }

  /**
   * Binds suggestions based on the provided search term.
   * @param {string} searchTerm - The term to search for.
   */
  bindSuggestions(searchTerm: string): void {
    this._appSession.searchTerm = searchTerm;
    // Create a suggestion request based on the search term
    const suggestionRequest = this.bindFtsAssistanceRequest(searchTerm);
    // Call the getSuggestion method from the _freeTxtSearchSvc service
    this._freeTextSearchSvc.getSuggestion(suggestionRequest).then(
      (result: IFtsSuggestionResponse) => {
        if (!result?.locationDetails || !result?.suggestionList || result?.suggestionList?.length === 0) {
          this.showLookUp = true;
          this.suggestionError = !result?.locationDetails || !result?.suggestionList;
          this.noSearchCriteria = result?.suggestionList?.length === 0;
          return;
        }
        if (this.hasValidSearchCriteria(result.suggestionList?.[0])) {
          this._availableSuggestionSvc.setSuggestion(result.suggestionList);
          this.searchForSuggestion(result.suggestionList[0], result.locationDetails);
        } else {
          this.showLookUp = true;
          this.noSearchCriteria = true;
        }
      },
      (error: unknown) => {
        try {
          // If there's an error, call the onCategoryError method with the error
          this.onCategoryError('suggestionError', error);
          this.showLookUp = true;
          this.suggestionError = true;
        } catch (e) {}
      }
    );
  }

  /*
   * Method to reset the search criteria
   */
  resetSearchCriteria() {
    const { providerTypeCodeList, eycSearchParam } = this.searchCriteria;
    this.clearSearchCriteria();
    this.searchCriteria.typeSelectNm = PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
    this.searchCriteria.providerTypeCodeList = providerTypeCodeList;
    this.searchCriteria.eycSearchParam = eycSearchParam;

    if (this.searchCriteria.typeSelectNm === PROVIDER_TYPE.PHARMACY) {
      this.searchCriteria.coverageTypeCode = CommonUtil.getPharmacyCoverageType(this.searchCriteria.typeSelectNm, this._appSession);
    }
  }

  resetAppSessionParams(eyc: boolean = false) {
    this._appSession.pageNumber = undefined;
    this._appSession.searchTerm = this.searchTerm;
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchCriteria;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = eyc;
    this._appSession.billingType = this.medicalCodeCriteria.billingType;
    this._appSession.medicalCode = this.medicalCodeCriteria.medicalCode;
    this._appSession.medicalModifierOptionCode = this.medicalCodeCriteria.medicalModifierOptionCode;
    this._appSession.medicalPosCode = this.medicalCodeCriteria.medicalPosCode;
  }

  //Method to handle summary page navigation and provider summary page update event.
  reloadProviderSummary() {
    this._appSession.filterSearchParams = undefined;
    this._appSession.initialSearch = true;
    const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
    if (
      this._appSession &&
      _routeUrl === AppNavigations.SEARCH_PROVIDERS_PATH &&
      this._appSession.feature &&
      this._appSession.feature.showBCP &&
      this._appSession.feature.showPreFilterBCP &&
      this._appSession.searchParams
    ) {
      this._appSession.searchParams.blueCarePrimePreferredSpecialistOnly = true;
    }
    if (this._contentHelper.isSearchResultRoute(_routeUrl) && this._appSession.isCaHmoDmhc && !this._appSession.serviceAreaValidated) {
      this._savNavigationService.navigate();
    } else if (this._contentHelper.isSearchResultRoute(_routeUrl)) {
      this.reloadSearch.emit(true);
    } else {
      this._savNavigationService.navigate();
    }
    this._actionService.setSearchClick(true);
  }

  /*
   * Method to handle the free text search
   */
  onDataChanged(data: string) {
    const _searchTerm = data.trim();
    if (this._appSession) {
      this._appSession.searchTerm = _searchTerm;
    }
    this._appSession.isSearchTerm = true;
    const _routeUrl: string = '/fc-result';
    if (_searchTerm.length === 0 && this._contentHelper.isSearchResultRoute(_routeUrl)) {
      this.resetSearchCriteria();
      this._appSession.searchTerm = this.searchTerm;
      this._appSession.searchParams = this.searchCriteria;
      this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
      this._appSession.isSearchTerm = false;
    }
  }

  //Method to highlight the search term in the assistance list
  highlightText(value: string) {
    return this._highlightPipe.transform(value, this.searchTerm, 'Multi-Word-Match');
  }

  /*
   * Method to handle the error in the free text search
   */
  onCategoryError(type: string, error: unknown) {
    throw error;
  }

  /*
   * Method to clear the look up list
   */
  clearLookUpList() {
    this.showLookUp = false;
    this.ftsAssistanceList = [];
  }

  /*
   * Method to clear the search criteria
   */
  clearSearchCriteria() {
    const { location, searchParams, deeplinkParams } = this._appSession || {};
    const { plan, coverageTypeCode, planCategoryDdlValues, planDdlValues, stateDdlValues, identificationNumber } = this.searchCriteria || {};

    this.searchCriteria = {
      ...location,
      plan,
      acceptingNewPatients: undefined,
      aoeSelectNm: [],
      blueDistinctionCenter: [],
      categoryDescription: {},
      coverageTypeCode,
      eycPrefix: searchParams?.eycPrefix,
      genderCodeList: [],
      includePrimeGroupSearch: true,
      isPrefixStateEnabledForProcSearch: searchParams?.isPrefixStateEnabledForProcSearch,
      languages: [],
      nationalProviderIdentifier: '',
      npi: '',
      ofcSrvcSelectNm: [],
      planCategoryDdlValues,
      planDdlValues,
      providerTypeCodeList: [],
      provNm: '',
      specialtySelectNm: [],
      stateDdlValues,
      taxonomySelectNm: [],
      typeOfCareNm: 'medical',
      typeSelectNm: '',
      visionSrvcAvailableList: [],
      identificationNumber: identificationNumber || undefined,
      ableToServePcp: deeplinkParams?.pcponly?.toLocaleLowerCase() === SERVE_PCP_Y ? searchParams?.ableToServePcp : undefined
    };

    this._appSession.isAoeSearch = false;
    this._appSession.isEyc = false;
    this._appSession.isMedicalCodeSearch = false;
    this._appSession.isOfcSrvcSearch = false;
    this._appSession.isSpecialitySearch = false;
  }

  isLocationValid() {
    return this._appSession.location.coordinates && this._appSession.location.coordinates.latitude !== '' && this._appSession.location.coordinates.longitude !== '';
  }

  //Method to reload the provider summary page based on the search criteria.
  loadProviderSummary() {
    if (this.searchTerm && this.searchCriteria) {
      if (this.searchCriteria.specialtySelectNm?.length) {
        this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchCriteria, this._appSession)).then(
          (rules: IRulesResponse) => {
            const isActionBlockedByModal = this._genericModalService.isActionBlockedByModal(rules);
            if (!isActionBlockedByModal) {
              this._appSession.feature = rules;
            }
          },
          (error: Error) => {
            try {
              this.onCategoryError('RULE', error);
            } catch (e) {}
          }
        );
      }
      this.resetAppSessionParams(true);
      this.reloadProviderSummary();
    }
  }

  resetforHPN() {
    this.searchTerm = this._appSession.searchTerm;
  }

  get noResultsText() {
    return this.content.common.pageHeader.searchCriteria.labels.noResultsMsg.replace(/{TERM}/gi, this.searchTerm.trim());
  }

  hasLookUpItems() {
    return this.ftsAssistanceList?.length > 0;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (
      typeof this._elementRef.nativeElement !== 'undefined' &&
      this._elementRef.nativeElement !== null &&
      typeof event !== 'undefined' &&
      event !== null &&
      typeof event.target !== 'undefined' &&
      event.target !== null &&
      !this._elementRef.nativeElement.contains(event.target) &&
      event.target.className !== 'search-bar-container' &&
      !this._appSession.isCaHmoDmhc
    ) {
      this.executionCount++;
      if (!document.getElementById('fts-txt-search-field').contains(event.target)) {
        this.showClearText = false;
      }
      if (event.target.className !== 'btn btn-primary' && !this.isLocationValid()) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      } else {
        this.hideLookup();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target && event.target.screen && event.target.screen.availWidth) {
      //Handle search text box watermark for mobile screen.
      if (event.target.screen.availWidth < 900) {
        this.searchWatermark = this.content.common.pageHeader.searchCriteria.labels.search;
      }
    }
  }

  isCaHmoDmhc() {
    return this._appSession.isCaHmoDmhc;
  }

  loadCaHmo() {
    this.loadCahmoPage.emit(true);
  }

  //Method to bind the free text search assistance request
  bindFtsAssistanceRequest(_searchTerm: string): IFtsClientRequest {
    const selectedContract = this._appSession.appState?.selectedEligibilityProduct?.selectedContract;
    return {
      text: encodeURI(_searchTerm),
      cursorPosition: this.trackCursor(),
      memberCoordinates: {
        latitude: this._appSession.location.coordinates?.latitude?.toString(),
        longitude: this._appSession.location.coordinates?.longitude?.toString()
      },
      clientCoordinates: this._appSession.clientCoordinates,
      distance: this._appSession.searchParams?.distanceNm?.toString(),
      contractUid: selectedContract?.contractUid,
      coverageUid: this._appSession.coverageUid,
      mbrUid: selectedContract?.mbrUid,
      assistanceSelection: this.assistanceSelection
    };
  }

  //Method to get the free text search assistance
  getFreeTxtSearchAssistance(assistanceRequest: IFtsClientRequest): void {
    this._freeTextSearchSvc.getAssistance(assistanceRequest).then(
      (result: IFtsClientAssistanceResponse) => {
        if (result) {
          this.ftsPhrase = result.phrase;
          if (result.assistanceList?.length > 0) {
            this.ftsAssistanceList = result.assistanceList;
          } else {
            this.ftsAssistanceList = [];
          }

          if (this.hasLookUpItems()) {
            this.showLookUp = true;
          } else {
            this.hideLookup();
          }
        }
      },
      (error: Error) => {
        try {
          this.onCategoryError('getAssistance', error); ///assistanceAPI error
        } catch (e) {}
      }
    );
  }

  resetAssistanceSelection() {
    this.assistanceSelection = [];
  }

  addAssistanceSelection(assistance: IMlAssistanceItem) {
    if (assistance) {
      const item = { [assistance.type]: assistance.text };
      this.assistanceSelection.unshift(item);
      this.assistanceSelection.splice(1, 1);
    }
  }

  createTccCptMetadata(suggestion) {
    switch (suggestion.type) {
      case SEARCH_TYPE.PROCEDURE:
        return {
          providerCategoryCode: {
            code: suggestion.metaData?.providerCategoryCode ?? ''
          },
          procedureCode: suggestion.criteria?.procedure ?? ''
        };
      case SEARCH_TYPE.MEDICAL_CODE:
        return {
          medicalTypeCd: suggestion.metaData?.medicalTypeCode ?? '',
          medicalCd: suggestion.medicalCode,
          searchDesc: suggestion.text
        };
      default:
        return {};
    }
  }

  bindProcedures(procedure: IProcedureLookup) {
    this._appSession.isEyc = true;

    if (procedure) {
      // if (!this.isFirstQuery) {
      //   this.searchTerm = procedure.procedureCode.name;
      // }
      let eycCriteria = new EycProcedureCriteria();
      let eycSearchParam = new EycSearchParam();

      this.searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureProcedureScenario(procedure.providerCategoryCode.code as PROVIDER_TYPE) : SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL;
      eycCriteria = {
        ...eycCriteria,
        procedureCode: procedure.procedureCode?.code ?? '',
        providerCategoryCode: procedure.providerCategoryCode?.code,
        procedureName: procedure.procedureCode?.name
      };
      eycSearchParam = {
        ...eycSearchParam,
        procedureDescription: procedure.procedureCode?.description ?? '',
        costDescription: procedure.costExplainationText ?? '',
        eycProcedureCriteria: eycCriteria
      };

      this.searchCriteria = {
        ...this.searchCriteria,
        eycSearchParam: eycSearchParam,
        brandCode: this._appSession.metaData.brandCd
      };

      if (this._appSession?.appState?.selectedEligibilityProduct?.selectedContract) {
        const _index = this._appSession.appState.selectedEligibilityProduct.members.findIndex((x) => x.mbrUid === this._appSession.appState.selectedEligibilityProduct.selectedContract.mbrUid);
        if (_index > -1) {
          const _member: IMember = this._appSession.appState.selectedEligibilityProduct.members[_index];
          this.searchCriteria.selectedMem = _member.firstNm + ' ' + _member.lastNm;
        }
      }

      if (this.isLocationValid()) {
        this.loadProviderSummary();
      }
    }
  }

  initialMedicalCodeSearch(selectedMedicalCode: ICptCode) {
    const { medicalTypeCd, medicalCd } = selectedMedicalCode;
    this.medicalCodeCriteria = {
      billingType: medicalTypeCd,
      medicalCode: medicalCd
    };
    this._appSession.isMedicalCodeSearch = true;
    this.searchService.currentScenario = SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL;
    this._appSession.searchTerm = this.searchTerm;
    this._appSession.billingType = medicalTypeCd;
    this._appSession.medicalCode = medicalCd;
    this.searchCriteria.providerTypeCodeList = [];

    if (this.isLocationValid()) {
      this.loadProviderSummary();
    }
  }

  searchForSuggestion(suggestion: IFtsSuggestion<ITranslation>, locationDetails: IFtsLocationDetails = {}) {
    this.clearSearchCriteria();
    if (!suggestion?.criteria) {
      return;
    }

    if (locationDetails) {
      this.searchCriteria.zip = this.searchCriteria.city = this.searchCriteria.countyCode = '';
      this.searchCriteria.coordinates = {
        latitude: locationDetails.latitude,
        longitude: locationDetails.longitude
      };
      this.searchCriteria.stateCd = {
        code: locationDetails.stateCode
      };
      switch (locationDetails.locationType) {
        case SEARCH_LOCATION_TYPE.CITY:
          this.searchCriteria.city = locationDetails.city;
          this.searchCriteria.zip = locationDetails.displayName;
          break;
        case SEARCH_LOCATION_TYPE.COUNTY:
          this.searchCriteria.countyCode = locationDetails.countyCode;
          this.searchCriteria.city = locationDetails.countyName?.toLowerCase().includes('county') ? locationDetails.countyName : locationDetails.countyName + ' County';
          this.searchCriteria.zip = locationDetails.stateCode ? this.searchCriteria.city + ', ' + locationDetails.stateCode : this.searchCriteria.city;
          this.searchCriteria.coordinates = {
            latitude: '0',
            longitude: '0'
          };
          break;
        default: //when locationType is ZIP, DEFAULT
          this.searchCriteria.zip = locationDetails.zipCode;
      }
    } else {
      this.searchCriteria = {
        ...this.searchCriteria,
        ...this._appSession.location
      };
    }
    // assign distance
    this.searchCriteria.distanceNm = locationDetails ? locationDetails.distance : PROVIDER_SEARCH_DISTANCE;
    switch (suggestion.type) {
      case SEARCH_TYPE.RETAIL_PHARMACY:
        if (this._appSession.appState?.opsIndicator?.hasRxCVS && suggestion.criteria.specialtyCategoryList?.[0]?.code === '201') {
          this.searchService.currentScenario = SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY;
          this.searchCriteria.typeSelectNm = PROVIDER_TYPE.PHARMACY;
          //TODO: recheck if we still need this function call
          this.searchCriteria.coverageTypeCode = CommonUtil.getPharmacyCoverageType(this.searchCriteria.typeSelectNm, this._appSession);
          this.searchCriteria.providerTypeCodeList = [PROVIDER_TYPE.PHARMACY];
        }
        break;
      case SEARCH_TYPE.PROCEDURE:
        this.bindProcedures(this.createTccCptMetadata(suggestion) as IProcedureLookup);
        break;
      case SEARCH_TYPE.MEDICAL_CODE:
        this.initialMedicalCodeSearch(this.createTccCptMetadata(suggestion) as ICptCode);
        break;
      default:
        // specialty search
        this.searchService.currentScenario = this.isMemberSecure ? SEARCH_SCENARIOS.SECURE_V1_SPECIALTY : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
        break;
    }
    if (suggestion.type === SEARCH_TYPE.RETAIL_PHARMACY || suggestion.type === SEARCH_TYPE.SPECIALTY) {
      // set ableToServePcp, acceptingNewPatients, language list & gender list in search criteria
      this.searchCriteria.ableToServePcp = suggestion.criteria.ableToServeAsPcp;
      this.searchCriteria.acceptingNewPatients = suggestion.criteria.acceptsNewPatient;
      this.searchCriteria.languages = suggestion.criteria.languageList?.length > 0 ? suggestion.criteria.languageList?.map((language) => language.code) : undefined;
      this.searchCriteria.genderCodeList = suggestion.criteria.genderList?.length > 0 ? suggestion.criteria.genderList : undefined;

      // define specialtyCategory list & taxonomy list in search criteria
      this.searchCriteria.specialtySelectNm = suggestion.criteria.specialtyCategoryList?.map((category) => category.code);
      this.searchCriteria.taxonomySelectNm = (suggestion.criteria.taxonomyList || []).map((category) => category.code);
      // define provider name, npi  in search criteria
      this.searchCriteria.provNm = suggestion.criteria.providerName;
      this.searchCriteria.npi = suggestion.criteria.npi;
      this.searchCriteria.nationalProviderIdentifier = suggestion.criteria.npi;
      this._appSession.isSpecialitySearch = true;

      this.searchCriteria = CommonUtil.buildStandAlone(this.searchCriteria, this._appSession);
      if (this.isLocationValid()) {
        this.loadProviderSummary();
      }
    }
  }

  //When user selects an assistance word from the free text search lookup
  onAssistanceSelect(assistance: IMlAssistanceItem) {
    this.clearLookUpList();
    if (this.searchTerm && this.ftsPhrase) {
      const index = this.searchTerm.toLowerCase().lastIndexOf(this.ftsPhrase.toLowerCase());
      if (index !== -1) {
        this.searchTerm = this.searchTerm.slice(0, index) + assistance.text + this.searchTerm.slice(index + this.ftsPhrase.length);
      }
    }
    this.addAssistanceSelection(assistance);
    document.getElementById('fts-txt-search-field').focus();
  }

  onClose() {
    if (!this.isLocationValid()) {
      this.searchParams = this.previous_searchParams;
      this._appSession.searchParams = this.searchParams;
    } else {
      this.sidePanelRef.close();
      this.loadProviderSummary();
    }
  }

  //Method to open the more examples side panel
  openFtsMoreExamplesPanel() {
    this.sidePanelRef = this._sidePanel.open('right', this.ftsMoreExamplesSidePanel);
  }

  trackCursor() {
    return this.searchTermInput.nativeElement.selectionStart;
  }

  //Method to get the current geo position
  async setBrowserLocation() {
    if (!this._appSession.clientCoordinates && navigator.geolocation) {
      try {
        const userPosition = await this.getCurrentGeoPosition();
        if (userPosition?.coords) {
          this._appSession.clientCoordinates = {
            latitude: userPosition.coords.latitude.toString(),
            longitude: userPosition.coords.longitude.toString()
          };
        }
      } catch (error) {
        console.error('Error getting user location', error);
      }
    }
  }

  hasValidSearchCriteria(suggestion: IFtsSuggestion<ITranslation>): boolean {
    const criteria = suggestion.criteria;
    return (
      criteria?.specialtyCategoryList?.length > 0 ||
      criteria?.taxonomyList?.length > 0 ||
      criteria?.providerName !== '' ||
      criteria?.npi !== '' ||
      !!suggestion.procedureCode ||
      !!suggestion.medicalCode
    );
  }

  async bindSuggestionsFromRelatedSearchesClick(searchTerm: string, suggestion: IFtsSuggestion<ITranslation>): Promise<void> {
    console.log('***In bindSuggestionsFromRelatedSearchesClick', searchTerm, suggestion);
    this.searchTerm = searchTerm;
    const { zip, coordinates, stateCd, city, countyCode, distanceNm } = this.searchCriteria;
    this.searchCriteria = this._appSession.searchParams;
    this.clearSearchCriteria();
    Object.assign(this.searchCriteria, { zip, coordinates, stateCd, city, countyCode, distanceNm });

    this._availableSuggestionSvc.setSuggestion([]);
    switch (suggestion.type) {
      case SEARCH_TYPE.SPECIALTY:
        const suggestionRequest = this.bindFtsAssistanceRequest(this.searchTerm);
        const suggestionResult = await this._freeTextSearchSvc.getSuggestion(suggestionRequest);
        const hasValidSuggestionSearchCriteria = this.hasValidSearchCriteria(suggestionResult?.suggestionList?.[0]);

        if (hasValidSuggestionSearchCriteria && suggestionResult?.suggestionList?.length && suggestionResult?.locationDetails) {
          this._availableSuggestionSvc.setSuggestion(suggestionResult?.suggestionList || []);
          this.searchForSuggestion(suggestionResult?.suggestionList[0], suggestionResult?.locationDetails);
        } else {
          this.showLookUp = true;
          this.noSearchCriteria = true;
        }
        break;

      case SEARCH_TYPE.PROCEDURE:
        this.bindProcedures(this.createTccCptMetadata(suggestion) as IProcedureLookup);
        break;

      case SEARCH_TYPE.MEDICAL_CODE:
        const selectedMedicalCode = this.createTccCptMetadata(suggestion) as ICptCode;
        this.searchTerm = selectedMedicalCode?.searchDesc?.slice(0, this.inputMaxLength);
        this.initialMedicalCodeSearch(selectedMedicalCode);
        break;
    }
  }

  getMedicalCodeLookup(selectedMedicalCode: ICptCode) {
    this.searchTerm = selectedMedicalCode?.searchDesc?.slice(0, this.inputMaxLength);
    this.initialMedicalCodeSearch(selectedMedicalCode);
  }

  onClearSearch() {
    this.searchTerm = '';
    this.showClearText = false;
    this.clearLookUpList();
  }
}
