import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { isEmpty } from 'lodash';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { LocationComponent } from '../../../common/components/location/locationCmp';
import { AppExternalCommands, Locale, PROCEDURE_LIST_TYPE, SEARCH_LOCATION_TYPE, SEARCH_OPTIONS, SEARCH_TYPE } from '../../../common/constants/app-constants';
import { AppNavigations } from '../../../common/constants/app-navigations';
import { SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import { AppEvents } from '../../../common/enums/appEvents';
import { IntentScenario } from '../../../common/enums/intent';
import { IEventDetail } from '../../../common/interfaces/iEventDetail';
import { IWindow } from '../../../common/interfaces/iWindow';
import { LocationResponse } from '../../../common/models/locationAddress';
import { HighlightPipe } from '../../../common/pipes/highlightPipe';
import { DataHelper } from '../../../common/services/dataHelper';
import { DataService } from '../../../common/services/dataService';
import { EventHandler } from '../../../common/services/eventHandler';
import { IntentService } from '../../../common/services/intentSvc';
import { SearchSvc } from '../../../common/services/searchSvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppConfig } from '../../../common/values/appConfig';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { ProviderUtilityService } from '../../../fad/search-providers/services/providerUtilitySvc';
import { ISelectedProvider } from '../../provider-details/models/iSelectedProvider';
import { ProviderDetailsNavigationService } from '../../provider-details/services/providerDetailsNavigationSvc';
import { IRulesResponse } from '../../rules/interfaces/iRulesResponse';
import { RulesService } from '../../rules/services/RulesSvc';
import { EycProcedureCriteria, EycSearchParam } from '../../search-results/interfaces/iSearchRequest';
import { CommonUtil } from '../../utilities/commonUtil';
import { IApiCallResult, ProcedureList, medicalCodeCriteria } from '../interfaces/ftsProcedureList';
import { ITranslation } from '../interfaces/iAdaListResp';
import { ICptCode, ICptLookUpList } from '../interfaces/iCptLookup';
import {
  IFtsAssistanceSelection,
  IFtsClientAssistanceResponse,
  IFtsClientRequest,
  IFtsLocationDetails,
  IFtsProviderInfo,
  IFtsSpecialty,
  IFtsSuggestion,
  IFtsSuggestionResponse,
  IMlAssistanceItem
} from '../interfaces/iFtsClientInteraction';
import { IMap } from '../interfaces/iMap';
import { IProviderType } from '../interfaces/iProviderType';
import { ISearchParameters } from '../interfaces/iSearchParameters';
import { ISmartSearchLookupRequest } from '../interfaces/iSmartSearchLookupRequest';
import { ISmartSearchLookupResponse } from '../interfaces/iSmartSearchLookupResponse';
import { CptLookupService } from '../services/cptUtilitySvc';
import { FtsSvc } from '../services/ftsSvc';
import { SAVNavigationService } from '../services/savNavigationSvc';
import { SmartSearchService } from '../services/smartSearchSvc';
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE } from '../values/providerSearchConstants';
import { AvailableSuggestionService } from './../../search-results/services/availableSuggestionSvc';
import { IProcedureLookup } from './../interfaces/iProcedureLookup';
declare let window: any;
@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-ft-search-cmp',
  templateUrl: '../views/pfFtsCmp.html',
  providers: []
})
export class PFFreeTxtSearchComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  searchParams: ISearchParameters;
  @Input()
  showChangeLocation: boolean;
  @ViewChild('openAddressSelection')
  locationSidePanel: TemplateRef<HTMLElement>;
  @Output()
  reloadSearch: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  openSwitchLocation: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  loadCahmoPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('searchTermIdentifier')
  searchTermInput: ElementRef;
  @ViewChild('location')
  locationCmp: LocationComponent;
  @ViewChild('ftsMoreExamples')
  ftsMoreExamplesSidePanel: TemplateRef<HTMLElement>;
  previous_searchParams: ISearchParameters;
  locale: string;
  searchWatermark: string = '';
  searchCriteria: ISearchParameters;
  showLookUp: boolean = false;
  suggestionError: boolean = false;
  suggestionFailure: boolean = false;
  noSearchCriteria: boolean = false;
  specialtyMore: boolean = false;
  providerMore: boolean = false;
  rulesInvoked: boolean = false;
  showFlyOut: boolean = false;
  hasCostTransparency: boolean;
  showSmartSearchLoader: boolean = true;
  optionSelected: string = SEARCH_OPTIONS.SEARCH_ALL;
  procedure: IProcedureLookup;
  cptLookupList: ICptLookUpList;
  smartSearchRequestId: string = '';
  searchTerm: string = '';
  previousSearchTerm: string = '';
  ftsPhrase: string = '';
  assistanceSelection: Array<IFtsAssistanceSelection>;
  ftsSuggestionLookUpList: IFtsSuggestion<ITranslation>[] = [];
  procedureList: ProcedureList[] = [];
  ftsSuggestionLookUpTopList: IFtsSuggestion<ITranslation>[] = [];
  specialtyLookUpList: IFtsSpecialty<string | ITranslation>[] = [];
  specialtyLookUpTop5List: IFtsSpecialty<string | ITranslation>[] = [];
  providerLookUpList: IFtsProviderInfo[] = [];
  providerLookUpTop5List: IFtsProviderInfo[] = [];
  smartSearchRequest: ISmartSearchLookupRequest = undefined;
  smartSearchResult: IApiCallResult<ISmartSearchLookupResponse> = undefined;
  cptLookupRequest: ISmartSearchLookupRequest = undefined;
  cptLookupResult: IApiCallResult<ICptLookUpList> = undefined;
  suggestionRequest: IFtsClientRequest = undefined;
  suggestionResult: IApiCallResult<IFtsSuggestionResponse> = undefined;
  medicalCodeCriteria: medicalCodeCriteria = { billingType: '', medicalCode: '' };
  ftsAssistanceList: IMlAssistanceItem[]; //Assistance phrase options to help complete the search texts - returned by AssistanceAPI
  ftsAssistanceTopList: IMlAssistanceItem[];
  availableSuggestionList: IFtsSuggestion<ITranslation>[];
  locationDetails: IFtsLocationDetails;
  isMemberSecure: boolean = false;
  appConfig: AppConfig;

  inputMinLength: number;
  inputMaxLength: number;
  executionCount: number = 0;
  sidePanelRef: ModalRef<any, any>;
  intentScenario = IntentScenario;
  userIntent: IntentScenario = IntentScenario.IGNORE;

  private _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);
  noResultsMessage: any;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _freeTxtSearchSvc: FtsSvc,
    private _highlightPipe: HighlightPipe,
    private _ruleService: RulesService,
    private _dataService: DataService,
    private _elementRef: ElementRef,
    @Inject('Window')
    private _window: IWindow,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService,
    private _sidePanel: SidePanel,
    private _savNavigationService: SAVNavigationService,
    private _smartSearchService: SmartSearchService,
    private _cptLookupSvc: CptLookupService,
    private _availableSuggestionSvc: AvailableSuggestionService,
    private _providerUtilityService: ProviderUtilityService,
    private _appUtility: AppUtility,
    private searchService: SearchSvc,
    private _intentService: IntentService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
  }
  ngOnDestroy(): void {
    //When switching from Free Text Search to Regular search
    if (!this.isFtsFeatureLive) {
      this.clearLookUpList();
      this.resetSearchCriteria();
      this.resetAppSessionParams();
    }
    this.resetInitialLocation();
  }

  resetInitialLocation(): void {
    this.searchCriteria = {
      ...this.searchCriteria,
      ...this._appSession.location
    };
    this.searchParams = { ...this.searchParams, ...this._appSession.location };
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.appConfig = this._appSession.appConfig;
    this.inputMinLength = this.appConfig?.features.lookUp.inputLength;
    this.inputMaxLength = this.appConfig?.features.lookUp.inputMaxLength;
    this.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;

    if (this._appSession) {
      this._appSession.integratedSearch = true;
      this.isMemberSecure = CommonUtil.isMemberSecure(this._appSession);
      this.searchTerm = this._appSession.searchTerm ? this._appSession.searchTerm : '';
      if (!this._appSession.location.coordinates) {
        this._providerUtilityService.getGeoLocation(this._appSession.location.zip).then((result: LocationResponse[]) => {
          if (result && Object.keys(result).length > 0) {
            const results = this._appUtility.buildLocationResponse(result);
            this._appSession.location.coordinates = {
              latitude: results[Object.keys(results)[0]].lat,
              longitude: results[Object.keys(results)[0]].long
            } as IMap;
          }
        });
      }
      this.setBrowserLocation();
    }
    this.searchWatermark = this.content?.freeTextSearch?.searchTextHint;
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_SEARCH_BAR_LOAD];
    eventDetail.message = AppExternalCommands.PF_APP_SEARCH_BAR_LOADED.MSG;
    eventDetail.target = AppExternalCommands.PF_APP_SEARCH_BAR_LOADED.CMD;
    this._pfAppCommandEvent.emit(eventDetail);
    this.resetAssistanceSelection();
    // Set the focus on the search input field
    document.getElementById('fts-txt-search-field').focus();
  }

  ngAfterViewInit() {
    fromEvent(this.searchTermInput.nativeElement, 'keyup')
      .pipe(
        // Time in milliseconds between key events
        debounceTime(200),
        // If previous query is diffent from current
        distinctUntilChanged(),
        // get value
        map((event) => {
          return event;
        })
      )
      .subscribe((event: KeyboardEvent) => {
        this.onKeyUpEvent(event);
      });
  }

  ngOnChanges(args: any) {
    if (args['searchParams'] && args['searchParams'].currentValue) {
      this.setSearchParams(args['searchParams'].currentValue);
    }
  }

  setSearchParams(params: ISearchParameters) {
    this.searchCriteria = params;
  }

  initiateSearchTermActions(_searchTerm: string) {
    if (this.previousSearchTerm === _searchTerm) {
      return;
    }
    this.previousSearchTerm = _searchTerm;
    this.clearLookUpList();
    this.suggestionError = false;
    this.suggestionFailure = false;
    this.noSearchCriteria = false;
    if (_searchTerm.length > this.inputMinLength) {
      if (this.isFtsFeatureLive) {
        const assistanceRequest = this.bindFtsAssistanceRequest(_searchTerm);
        this.getFreeTxtSearchAssistance(assistanceRequest);
        this.getIntent();
      }
    } else {
      this.resetAssistanceSelection();
    }
  }

  onKeyUpEvent(event: KeyboardEvent) {
    const _searchTerm = this.searchTerm;
    switch (event.key) {
      case 'Enter':
        this.initiateSearch();
        break;
      case 'backspace':
      case 'delete':
        this.resetAssistanceSelection();
        break;
      case 'ArrowDown':
        if (this.ftsAssistanceList?.length) {
          const listItems = document.querySelectorAll('.look-up-item-border div');
          (listItems?.[0] as HTMLElement).focus();
        }
        break;
      default:
        this.initiateSearchTermActions(_searchTerm);
    }
  }

  initiateSearch(): void {
    this.clearLookUpList();
    //TODO: remove/update the resetAssistanceSelection after suggestion API results are fully stable
    this.resetAssistanceSelection();
    this.hideLookup();
    if (this.isLocationValid()) {
      this.bindSuggestions(this.searchTerm);
    }
  }

  onKeydownFromSearchLink(event: KeyboardEvent) {
    switch (event.key) {
      case 'Tab':
        const addressLink = document.getElementById('address-selection-link');
        addressLink.focus();
        event.preventDefault();
        this.hideLookup();
        break;
      case 'ArrowDown':
        if (this.ftsAssistanceList.length) {
          const listItems = document.querySelectorAll('.look-up-item-border div');
          (listItems?.[0] as HTMLElement).focus();
        }
        break;
    }
  }
  onKeydownThroughAssistance(event: KeyboardEvent, assistance: IMlAssistanceItem, index: number) {
    const listItems = document.querySelectorAll('.look-up-item-border div');
    const inputField = document.getElementById('fts-txt-search-field');
    switch (event.key) {
      case 'Enter':
      case ' ':
        this.onAssistanceSelect(assistance);
        event.preventDefault();
        break;
      case 'ArrowUp':
        (listItems[(index - 1 + listItems.length) % listItems.length] as HTMLElement).focus();
        event.preventDefault();
        break;
      case 'ArrowDown':
        (listItems[(index + 1) % listItems.length] as HTMLElement).focus();
        event.preventDefault();
        break;
      case 'Tab':
        inputField.focus();
        event.preventDefault();
        break;
    }
  }

  hideLookup(): void {
    this.showLookUp = false;
  }

  /**
   * Binds suggestions based on the provided search term.
   * @param {string} searchTerm - The term to search for.
   */
  bindProviderType(searchParams: ISearchParameters, appSession: AppSession, excludePharmacy: boolean = true) {
    const _searchParams: ISearchParameters = searchParams;
    _searchParams.standAloneDental = undefined;
    _searchParams.standAloneVision = undefined;
    _searchParams.coverageTypeCode = '';
    _searchParams.typeSelectNm = '';
    _searchParams.providerTypeCodeList = [];

    if (appSession?.isSecureState) {
      let _providerTypes: IProviderType[] = excludePharmacy ? this._appSession.providerTypes.filter((x) => x.code !== PROVIDER_TYPE.PHARMACY) : this._appSession.providerTypes;

      if (this._appSession?.planRule?.isMedicareGov) {
        _providerTypes = _providerTypes.filter((x) => x.code === PROVIDER_TYPE.VISION);
        _searchParams.standAloneVision = true;
      }

      if (_providerTypes?.length) {
        _searchParams.providerTypeCodeList = _providerTypes.map((provType) => provType.code);
      }

      _searchParams.standAloneDental = appSession.isDentalStandAlone || false;
      _searchParams.standAloneVision = appSession.isVisionStandAlone || _searchParams.standAloneVision || false;
    }

    return _searchParams;
  }
  bindSmartSearchRequest(_searchTerm: string): ISmartSearchLookupRequest {
    let _productType = !this._appSession.isPlanUnknown ? this._appSession.metaData?.appContract?.coverageDetails?.productType : '';
    let _searchCriteria: ISearchParameters = Object.assign({}, this.searchCriteria);
    _searchCriteria = this.bindProviderType(_searchCriteria, this._appSession, false);
    const _ruleRequest: ISmartSearchLookupRequest = {
      brand: this._appSession.metaData?.brandCd,
      state: this.searchCriteria?.plan?.stateCd ?? '',
      planCategory: this.searchCriteria?.plan?.category?.catCode ?? '',
      productType: _productType,
      identification: this.searchCriteria.identificationNumber ? this.searchCriteria.identificationNumber : '',
      locale: this.locale,
      network: this.searchCriteria.plan?.networkList?.length ? this.searchCriteria.plan.networkList : [],
      prefix: CommonUtil.getPrefix(this.searchCriteria, this._appSession),
      contractUid: !this._appSession.isPlanUnknown ? this.getContractUid() : undefined,
      mbrUid: this._appSession.metaData?.appContract?.mbrUid,
      providerTypeList: _searchCriteria.providerTypeCodeList,
      searchTerm: encodeURI(_searchTerm),
      isPrefixProcSearchEnabled: false
    };
    return _ruleRequest;
  }

  bindProcedures(procedure: IProcedureLookup, isFirstQuery: boolean = false) {
    this.clearLookUpList();
    this._appSession.isEyc = true;

    if (procedure) {
      if (!isFirstQuery) {
        this.searchTerm = procedure.procedureCode.name;
      }
      let eycCriteria = new EycProcedureCriteria();
      let eycSearchParam = new EycSearchParam();

      this.searchCriteria.providerTypeCodeList = [];
      this.searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureProcedureScenario(procedure.providerCategoryCode.code as PROVIDER_TYPE) : SEARCH_SCENARIOS.PUBLIC_V1_TCC_MEDICAL;
      eycCriteria = {
        ...eycCriteria,
        procedureCode: procedure.procedureCode?.code ?? '',
        providerCategoryCode: procedure.providerCategoryCode?.code,
        procedureName: procedure.procedureCode?.name
      };
      eycSearchParam = {
        ...eycSearchParam,
        procedureDescription: procedure.procedureCode?.description ?? '',
        costDescription: procedure.costExplainationText ?? '',
        eycProcedureCriteria: eycCriteria
      };

      this.searchCriteria = {
        ...this.searchCriteria,
        eycSearchParam: eycSearchParam,
        brandCode: this._appSession.metaData.brandCd,
        smartSearchRequestId: this.smartSearchRequestId
      };

      const appContract = this._appSession.metaData?.appContract;
      const memberUid = appContract?.mbrUid;
      if (memberUid) {
        const member = appContract.memberList?.find((x) => x.mbrUid === memberUid);
        if (member) {
          this.searchCriteria.selectedMem = `${member.firstName} ${member.lastName}`;
        }
        this.searchCriteria.memberSeqNum = appContract.memberSeqNum;
      }

      if (this.isLocationValid()) {
        this.loadProviderSummary();
      }
    }
  }

  getMedicalCodeLookup(selectedMedicalCode: ICptCode, isFirstQuery: boolean = false) {
    this.clearLookUpList();
    const { medicalTypeCd, medicalCd } = selectedMedicalCode;
    this.medicalCodeCriteria = {
      billingType: medicalTypeCd,
      medicalCode: medicalCd
    };
    if (!isFirstQuery) {
      this.searchTerm = selectedMedicalCode?.searchDesc?.slice(0, this.inputMaxLength);
    }
    this._appSession.isMedicalCodeSearch = true;
    this.searchService.currentScenario = SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL;
    this._appSession.searchTerm = this.searchTerm;
    this._appSession.billingType = medicalTypeCd;
    this._appSession.medicalCode = medicalCd;
    this.searchCriteria.providerTypeCodeList = [];
    if (this.isLocationValid()) {
      this.loadProviderSummary();
    }
  }

  getProcedureList(smartSearchResult: ISmartSearchLookupResponse, cptLookupResult: ICptLookUpList): ProcedureList[] {
    const tccProcedures =
      smartSearchResult?.procedures?.slice(0, this.inputMinLength).map((procedure) => ({
        code: procedure.procedureCode.code,
        name: procedure.procedureCode.name,
        category: PROCEDURE_LIST_TYPE.TCC,
        metadata: procedure
      })) || [];

    const cptCodes =
      cptLookupResult?.cptCodes?.slice(0, this.inputMinLength).map((cptCode) => ({
        code: cptCode.medicalCd,
        name: cptCode.searchDesc,
        category: PROCEDURE_LIST_TYPE.CPT,
        metadata: cptCode
      })) || [];

    this.procedureList = [...tccProcedures, ...cptCodes];

    return this.procedureList;
  }

  getContractUid(): string {
    return this._appSession?.metaData?.appContract?.contractUid ?? '';
  }

  async handleApiCall<T>(apiCall: () => Promise<T>, errorMessage: string): Promise<IApiCallResult<T>> {
    try {
      const data = await apiCall();
      return { data, error: false };
    } catch (error) {
      if (error.message.includes(errorMessage)) {
        return { error: true, message: error.message };
      }
      return { error: false };
    }
  }

  handleApiCallErrors(suggestionResult: IApiCallResult<IFtsSuggestionResponse>, smartSearchResult: IApiCallResult<ISmartSearchLookupResponse>, cptLookupResult: IApiCallResult<ICptLookUpList>): void {
    if (suggestionResult?.error && smartSearchResult?.error && cptLookupResult?.error) {
      this.suggestionError = true;
      this.suggestionFailure = true;
      this.onCategoryError('suggestionError', suggestionResult?.error);
      this.showLookUp = true;
    }
  }

  hasSpecialty(suggestion: IFtsSuggestion<ITranslation>): boolean {
    const criteria = suggestion?.criteria;
    return criteria?.specialtyCategoryList?.length > 0 || criteria?.taxonomyList?.length > 0 || criteria?.providerName !== '' || criteria?.npi !== '' || false;
  }

  hasProcedure(suggestion: IFtsSuggestion<ITranslation>): boolean {
    return (suggestion?.criteria?.procedure?.name !== '' && suggestion?.procedureCode !== '' && suggestion?.metaData?.providerCategoryCode !== '') || false;
  }

  hasMedicalCode(suggestion: IFtsSuggestion<ITranslation>) {
    return (suggestion?.medicalCode !== '' && suggestion?.metaData?.medicalTypeCode !== '' && suggestion?.text !== '') || false;
  }

  hasValidSuggestionSearchCriteria(suggestion: IFtsSuggestion<ITranslation>) {
    return suggestion && (this.hasSpecialty(suggestion) || this.hasProcedure(suggestion) || this.hasMedicalCode(suggestion));
  }

  handleAlternateSuggestions(procedureList: ProcedureList[], suggestionResult: IApiCallResult<IFtsSuggestionResponse>): void {
    const hasValidSuggestionSearchCriteria = this.hasValidSuggestionSearchCriteria(suggestionResult?.data?.suggestionList?.[0]);

    if (hasValidSuggestionSearchCriteria && suggestionResult?.data?.suggestionList?.length && suggestionResult?.data?.locationDetails) {
      this._availableSuggestionSvc.setSuggestion(suggestionResult?.data?.suggestionList || []);
      this._availableSuggestionSvc.setProcedureList(procedureList);
      this.searchForSuggestion(suggestionResult?.data?.suggestionList[0], suggestionResult?.data?.locationDetails);
    } else {
      this.showLookUp = true;
      this.noSearchCriteria = true;
    }
  }

  async bindSuggestionsFromRelatedSearchesClick(searchTerm: string, suggestion: IFtsSuggestion<ITranslation>): Promise<void> {
    this._appSession.searchTerm = searchTerm;
    const { zip, coordinates, stateCd, city, countyCode, distanceNm } = this.searchCriteria;
    this.clearSearchCriteria();
    Object.assign(this.searchCriteria, { zip, coordinates, stateCd, city, countyCode, distanceNm });

    switch (suggestion.type) {
      case SEARCH_TYPE.SPECIALTY:
        this.suggestionRequest = this.bindFtsAssistanceRequest(searchTerm);
        if (!isEmpty(this.suggestionRequest)) {
          this.suggestionResult = await this.handleApiCall(() => this._freeTxtSearchSvc.getSuggestion(this.suggestionRequest), 'suggestionError');
        }
        this.handleApiCallErrors(this.suggestionResult, undefined, undefined);
        this.handleAlternateSuggestions([], this.suggestionResult);
        break;

      case SEARCH_TYPE.PROCEDURE:
        this._availableSuggestionSvc.setSuggestion([]);
        this._availableSuggestionSvc.setProcedureList([]);
        this.bindProcedures(this.createTccCptMetadata(suggestion) as IProcedureLookup);
        break;

      case SEARCH_TYPE.MEDICAL_CODE:
        this._availableSuggestionSvc.setSuggestion([]);
        this._availableSuggestionSvc.setProcedureList([]);
        this.getMedicalCodeLookup(this.createTccCptMetadata(suggestion) as ICptCode);
        break;
    }
  }

  async bindSuggestions(searchTerm: string): Promise<void> {
    const contractUid: string = this.getContractUid();
    this._appSession.searchTerm = searchTerm;
    this.suggestionRequest = this.bindFtsAssistanceRequest(searchTerm);
    if (this.suggestionRequest) {
      this.suggestionResult = await this.handleApiCall(() => this._freeTxtSearchSvc.getSuggestion(this.suggestionRequest), 'suggestionError');
    }
    if (this.ftsProcedureLookup) {
      const searchTermEncoded = encodeURI(searchTerm);
      const commonRequest = { searchTerm: searchTermEncoded, locale: this.locale, contractUid: contractUid, providerTypeList: [] };

      if (this.showSmartSearch) {
        this.smartSearchRequest = this.bindSmartSearchRequest(searchTerm);
        this.smartSearchResult = await this.handleApiCall(() => this._smartSearchService.getSmartSearchLookup(this.smartSearchRequest), 'SMART_SEARCH_LOOKUP');
      }

      this.cptLookupRequest = commonRequest;
      this.cptLookupResult = await this.handleApiCall(() => this._cptLookupSvc.getCptLookupList(this.cptLookupRequest), 'CPT_LOOKUP');
    }

    this.handleApiCallErrors(this.suggestionResult, this.smartSearchResult, this.cptLookupResult);
    this.procedureList = this.getProcedureList(this.smartSearchResult?.data, this.cptLookupResult?.data);
    this.handleAlternateSuggestions(this.procedureList, this.suggestionResult);
  }

  resetSearchCriteria() {
    const _providerTypeCode = this.searchCriteria.typeSelectNm;
    const _providerTypeCodeList = this.searchCriteria.providerTypeCodeList;
    const _eycSearchParam = this.searchCriteria.eycSearchParam;
    this.clearSearchCriteria();
    this.searchCriteria.typeSelectNm = _providerTypeCode;
    this.searchCriteria.providerTypeCodeList = _providerTypeCodeList;
    this.searchCriteria.eycSearchParam = _eycSearchParam;

    if (this.searchCriteria.typeSelectNm === PROVIDER_TYPE.PHARMACY) {
      this.searchCriteria.coverageTypeCode = CommonUtil.getPharmacyCoverageType(this.searchCriteria.typeSelectNm, this._appSession);
    }
  }

  getErrorMessage(): string | void {
    if (this.suggestionFailure) {
      return this.content?.freeTextSearch?.suggestionFailure;
    }
    if (this.noSearchCriteria) {
      return this.content?.freeTextSearch?.noSuggestionCriteriaFound;
    }
  }

  showProvidersMore() {
    this.providerMore = !this.providerMore;
    return false;
  }
  showSpecialtyMore() {
    this.specialtyMore = !this.specialtyMore;
    return false;
  }

  resetAppSessionParams(eyc: boolean = false) {
    this._appSession.pageNumber = undefined;
    this._appSession.searchTerm = this.searchTerm;
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchCriteria;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = eyc;
  }

  //Method to handle summary page navigation and provider summary page update event.
  reloadProviderSummary() {
    this._appSession.filterSearchParams = undefined;
    this._appSession.initialSearch = true;
    const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
    if (
      this._appSession &&
      _routeUrl === AppNavigations.SEARCH_PROVIDERS_PATH &&
      this._appSession.feature &&
      this._appSession.feature.showBCP &&
      this._appSession.feature.showPreFilterBCP &&
      this._appSession.searchParams
    ) {
      this._appSession.searchParams.blueCarePrimePreferredSpecialistOnly = true;
    }
    if (this._contentHelper.isSearchResultRoute(_routeUrl) && this._appSession.isCaHmoDmhc && !this._appSession.serviceAreaValidated) {
      this._savNavigationService.navigate();
    } else if (this._contentHelper.isSearchResultRoute(_routeUrl)) {
      this.reloadSearch.emit(true);
    } else {
      this._savNavigationService.navigate();
    }
  }

  onDataChanged(data: string) {
    const _searchTerm = data.trim();
    if (this._appSession) {
      this._appSession.searchTerm = _searchTerm;
    }
    this._appSession.isSearchTerm = true;
    const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
    if (_searchTerm.length === 0 && this._contentHelper.isSearchResultRoute(_routeUrl)) {
      this.resetSearchCriteria();
      this._appSession.searchTerm = this.searchTerm;
      this._appSession.searchParams = this.searchCriteria;
      this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
      this._appSession.isSearchTerm = false;
    }
  }

  highlightText(value: string) {
    return this._highlightPipe.transform(value, this.searchTerm, 'Multi-Word-Match');
  }

  onSpecialtySelect(specialty: any) {
    this.clearLookUpList();
    this.clearSearchCriteria();

    // Specialty & Sub-specialty search params setting.
    if (specialty.criteria.specialtyCategoryList?.length) {
      this.searchCriteria.specialtySelectNm = [];
      this.searchCriteria.taxonomySelectNm = [];
      for (const specialtyCategory of specialty.criteria.specialtyCategoryList) {
        this.searchCriteria.specialtySelectNm.push(specialtyCategory.code);
      }
      for (const taxonomy of specialty.criteria.taxonomyList) {
        this.searchCriteria.taxonomySelectNm.push(taxonomy.code);
      }
    } else {
      for (const taxonomy of specialty.criteria.taxonomyList) {
        this.searchCriteria.specialtySelectNm.push(taxonomy.code);
      }
    }
    this.loadSpecialtyProvider();
  }

  onProviderSelect(provider: IFtsProviderInfo) {
    this.clearLookUpList();
    this.clearSearchCriteria();

    this.searchTerm = provider.providerName.trim();
    const pageFadObj = {
      header: this.content.globalHeaderComponent.pageHeader.fadPageTitle,
      title: this.content.globalHeaderComponent.pageTitle.details
    };
    this._dataService.setPageTitle(pageFadObj);
    const selectedProvider = {
      providerIdentifier: provider.id,
      providerName: provider.providerName,
      location: {
        address: {
          addressId: provider.addressId,
          addressOne: provider.addressOne,
          addressTwo: provider.addressTwo,
          city: provider.city,
          state: provider.state,
          postalCode: provider.postalCode
        }
      }
    } as unknown as ISelectedProvider;
    this.searchCriteria.provNm = this.searchTerm;
    this.searchCriteria = CommonUtil.buildStandAlone(this.searchCriteria, this._appSession);

    this.resetAppSessionParams();
    this._providerDetailsNavigationService.navigateToProviderDetails(selectedProvider);
  }

  onCategoryError(type: string, error: unknown) {
    throw error;
  }

  clearLookUpList() {
    this.hideLookup();
    this.ftsAssistanceList = [];
    this.ftsAssistanceTopList = [];
    this.ftsSuggestionLookUpList = [];
    this.ftsSuggestionLookUpTopList = [];

    this.specialtyMore = false;
    this.specialtyLookUpTop5List = [];
    this.specialtyLookUpList = [];

    this.providerMore = false;
    this.providerLookUpList = [];
    this.providerLookUpTop5List = [];
    this.userIntent = IntentScenario.IGNORE;
  }

  clearSearchCriteria() {
    let _searchCriteria: ISearchParameters = { ...this._appSession.location };
    _searchCriteria.plan = this.searchCriteria.plan;
    _searchCriteria.typeOfCareNm = this.searchCriteria.typeOfCareNm;
    _searchCriteria.stateDdlValues = this.searchCriteria.stateDdlValues;
    _searchCriteria.planCategoryDdlValues = this.searchCriteria.planCategoryDdlValues;
    _searchCriteria.planDdlValues = this.searchCriteria.planDdlValues;
    _searchCriteria.coverageTypeCode = this.searchCriteria.coverageTypeCode;
    if (this.searchCriteria.identificationNumber) {
      _searchCriteria.identificationNumber = this.searchCriteria.identificationNumber;
    }
    _searchCriteria.typeSelectNm = '';
    _searchCriteria.specialtySelectNm = [];
    _searchCriteria.taxonomySelectNm = [];
    _searchCriteria.providerTypeCodeList = [];
    _searchCriteria.blueDistinctionCenter = [];
    _searchCriteria.categoryDescription = {};
    _searchCriteria.visionSrvcAvailableList = [];
    _searchCriteria.provNm = '';
    _searchCriteria.aoeSelectNm = [];
    _searchCriteria.ofcSrvcSelectNm = [];
    _searchCriteria.nationalProviderIdentifier = '';
    _searchCriteria.npi = '';
    _searchCriteria.ableToServePcp = undefined;
    _searchCriteria.acceptingNewPatients = undefined;
    _searchCriteria.languages = [];
    _searchCriteria.genderCodeList = [];
    if (this._appSession.deeplinkParams && this._appSession.deeplinkParams.pcponly && this._appSession.deeplinkParams.pcponly.toLocaleLowerCase() === 'y') {
      _searchCriteria.ableToServePcp = this._appSession.searchParams.ableToServePcp;
    }
    _searchCriteria.includePrimeGroupSearch = true;
    _searchCriteria.eycPrefix = this._appSession?.searchParams?.eycPrefix;
    _searchCriteria.isPrefixStateEnabledForProcSearch = this._appSession?.searchParams?.isPrefixStateEnabledForProcSearch;
    this.searchCriteria = _searchCriteria;
    this._appSession.isSpecialitySearch = false;
    this._appSession.isAoeSearch = false;
    this._appSession.isOfcSrvcSearch = false;
    this._appSession.isMedicalCodeSearch = false;
    this._appSession.isEyc = false;
  }

  isLocationValid() {
    return this._appSession?.location?.coordinates && this._appSession?.location?.coordinates?.latitude !== '' && this._appSession?.location?.coordinates?.longitude !== '';
  }

  loadProviderSummary() {
    if (this.searchTerm) {
      this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchCriteria, this._appSession)).then(
        (rules: IRulesResponse) => {
          this.invokeActions(rules);
          this.rulesInvoked = true;
        },
        (error: any) => {
          try {
            this.onCategoryError('RULE', error);
          } catch (e) {}
        }
      );
    }
  }

  resetforHPN() {
    this.searchTerm = this._appSession.searchTerm;
  }

  get noResultsText() {
    return this.content.searchCriteriaComponent.labels.noResultsMsg.replace(/{TERM}/gi, this.searchTerm.trim());
  }

  // Logic to display defaultSearchText info. msg in search bar
  get defaultSearchText() {
    return this.content.searchCriteriaComponent.labels.defaultSearchMsg.replace(/{TERM}/gi, this.searchTerm.trim());
  }

  hasLookUpItems() {
    return this.ftsAssistanceList?.length || this.ftsSuggestionLookUpList?.length || this.specialtyLookUpList?.length || this.providerLookUpList?.length;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (
      typeof this._elementRef.nativeElement !== 'undefined' &&
      this._elementRef.nativeElement !== null &&
      typeof event !== 'undefined' &&
      event !== null &&
      typeof event.target !== 'undefined' &&
      event.target !== null &&
      !this._elementRef.nativeElement.contains(event.target) &&
      event.target.className !== 'search-bar-container' &&
      !this._appSession.isCaHmoDmhc
    ) {
      this.executionCount++;
      if (event.target.className !== 'btn btn-primary' && !this.isLocationValid()) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      } else {
        this.hideLookup();
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target && event.target.screen && event.target.screen.availWidth) {
      //Handle search text box watermark for mobile screen.
      if (event.target.screen.availWidth < 900) {
        this.searchWatermark = this.content.searchCriteriaComponent.search;
      }
    }
  }

  private invokeActions(rules: IRulesResponse) {
    if (rules) {
      this.resetAppSessionParams();
      this._appSession.feature = rules;
      this.reloadProviderSummary();
    }
  }

  isCaHmoDmhc() {
    return this._appSession.isCaHmoDmhc;
  }

  loadCaHmo() {
    this.loadCahmoPage.emit(true);
  }

  bindFtsAssistanceRequest(_searchTerm: string): IFtsClientRequest {
    return {
      text: encodeURI(_searchTerm),
      cursorPosition: this.trackCursor(),
      memberCoordinates: {
        latitude: this._appSession.location.coordinates?.latitude?.toString(),
        longitude: this._appSession.location.coordinates?.longitude?.toString()
      },
      clientCoordinates: this._appSession.clientCoordinates,
      distance: this._appSession.searchParams?.distanceNm?.toString(),
      contractUid: this.getContractUid(),
      coverageUid: this._appSession.coverageUid,
      mbrUid: this._appSession.metaData?.appContract?.mbrUid,
      assistanceSelection: this.assistanceSelection
    };
  }

  getFreeTxtSearchAssistance(assistanceRequest: IFtsClientRequest): void {
    this._freeTxtSearchSvc.getAssistance(assistanceRequest).then(
      (result: IFtsClientAssistanceResponse) => {
        if (result) {
          this.ftsPhrase = result.phrase;
          if (result.assistanceList?.length > 0) {
            this.ftsAssistanceList = result.assistanceList;
            this.ftsAssistanceTopList = result.assistanceList.slice(0, 10);
          } else {
            this.ftsAssistanceList = [];
            this.ftsAssistanceTopList = [];
          }
          if (result.suggestionList?.length > 0) {
            this.ftsSuggestionLookUpList = result.suggestionList;
            this.ftsSuggestionLookUpTopList = result.suggestionList.slice(0, this.appConfig?.features.lookUp.minCount);
          } else {
            this.ftsSuggestionLookUpList = [];
            this.ftsSuggestionLookUpTopList = [];
          }

          if (result.specialtyList?.length > 0) {
            this.specialtyLookUpList = result.specialtyList;
            this.specialtyLookUpTop5List = result.specialtyList.slice(0, this.appConfig?.features.lookUp.minCount);
          } else {
            this.specialtyLookUpList = [];
            this.specialtyLookUpTop5List = [];
          }

          if (result.providerList?.length > 0) {
            this.providerLookUpList = result.providerList;
            this.providerLookUpTop5List = result.providerList.slice(0, this.appConfig?.features.lookUp.minCount);
          } else {
            this.providerLookUpList = [];
            this.providerLookUpTop5List = [];
          }
          if (this.hasLookUpItems()) {
            this.showLookUp = true;
          } else {
            this.hideLookup();
          }
        }
      },
      (error: any) => {
        try {
          this.onCategoryError('getAssistance', error); ///assistanceAPI error
        } catch (e) {}
      }
    );
  }
  /**
   * Asynchronously retrieves the intent based on the current search term.
   * If the intent is either `BH_CRISIS` or `BH_NON_CRISIS`, it assigns the intent to `userIntent`.
   * Otherwise, it clears the `userIntent`.
   * In case of an error, it clears the `userIntent` and calls `onCategoryError` with the error details.
   *
   * @returns {Promise<void>} A promise that resolves when the intent lookup is complete.
   */
  async getIntent() {
    if (!this.isIntentEnabled) return;
    const searchTerm = {
      query: encodeURI(this.searchTerm)
    };
    try {
      this.userIntent = IntentScenario.IGNORE;
      const response = await this._intentService.getIntentLookup(searchTerm);
      this.userIntent = response.intent;
    } catch (error) {
      this.onCategoryError('getIntent', error);
    }
  }

  resetAssistanceSelection() {
    this.assistanceSelection = [];
  }

  addAssistanceSelection(assistance: IMlAssistanceItem) {
    if (assistance) {
      const item = { [assistance.type]: assistance.text };
      this.assistanceSelection.unshift(item);
      this.assistanceSelection.splice(1, 1);
    }
  }

  createTccCptMetadata(suggestion) {
    switch (suggestion.type) {
      case SEARCH_TYPE.PROCEDURE:
        return {
          providerCategoryCode: {
            code: suggestion.metaData?.providerCategoryCode ?? ''
          },
          procedureCode: suggestion.criteria?.procedure ?? ''
        };
      case SEARCH_TYPE.MEDICAL_CODE:
        return {
          medicalTypeCd: suggestion.metaData?.medicalTypeCode ?? '',
          medicalCd: suggestion.medicalCode,
          searchDesc: suggestion.text
        };
      default:
        return {};
    }
  }

  searchForSuggestion(suggestion: IFtsSuggestion<ITranslation>, locationDetails: IFtsLocationDetails = {}) {
    this.clearLookUpList();
    this.clearSearchCriteria();
    if (suggestion.criteria) {
      if (locationDetails) {
        this.searchCriteria.zip = this.searchCriteria.city = this.searchCriteria.countyCode = '';
        this.searchCriteria.coordinates = {
          latitude: locationDetails.latitude,
          longitude: locationDetails.longitude
        };
        this.searchCriteria.stateCd = {
          code: locationDetails.stateCode
        };
        switch (locationDetails.locationType) {
          case SEARCH_LOCATION_TYPE.CITY:
            this.searchCriteria.city = locationDetails.city;
            this.searchCriteria.zip = locationDetails.displayName;
            break;
          case SEARCH_LOCATION_TYPE.COUNTY:
            this.searchCriteria.countyCode = locationDetails.countyCode;
            this.searchCriteria.city = locationDetails.countyName?.toLowerCase().includes('county') ? locationDetails.countyName : locationDetails.countyName + ' County';
            this.searchCriteria.zip = locationDetails.stateCode ? this.searchCriteria.city + ', ' + locationDetails.stateCode : this.searchCriteria.city;
            this.searchCriteria.coordinates = {
              latitude: '0',
              longitude: '0'
            };
            break;
          default: //when locationType is ZIP, DEFAULT
            this.searchCriteria.zip = locationDetails.zipCode;
        }
      } else {
        this.searchCriteria = {
          ...this.searchCriteria,
          ...this._appSession.location
        };
      }
      // assign distance
      this.searchCriteria.distanceNm = locationDetails ? locationDetails.distance : PROVIDER_SEARCH_DISTANCE;

      //pharmacy search
      switch (suggestion.type) {
        case SEARCH_TYPE.RETAIL_PHARMACY:
          if (this._appSession.hasRxCvsIndicator) {
            this.searchService.currentScenario = SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY;
            this.searchCriteria.typeSelectNm = PROVIDER_TYPE.PHARMACY;
            this.searchCriteria.coverageTypeCode = CommonUtil.getPharmacyCoverageType(this.searchCriteria.typeSelectNm, this._appSession);
            this.searchCriteria.providerTypeCodeList = [PROVIDER_TYPE.PHARMACY];
          }
          break;
        case SEARCH_TYPE.PROCEDURE:
          return this.bindProcedures(this.createTccCptMetadata(suggestion) as IProcedureLookup, true);
        case SEARCH_TYPE.MEDICAL_CODE:
          return this.getMedicalCodeLookup(this.createTccCptMetadata(suggestion) as ICptCode, true);
        default:
          // specialty search
          this.searchService.currentScenario = this.isMemberSecure ? SEARCH_SCENARIOS.SECURE_V1_SPECIALTY : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
          break;
      }
      // set ableToServePcp, acceptingNewPatients, language list & gender list in search criteria
      this.searchCriteria.ableToServePcp = suggestion.criteria.ableToServeAsPcp;
      this.searchCriteria.acceptingNewPatients = suggestion.criteria.acceptsNewPatient;
      this.searchCriteria.languages = suggestion.criteria.languageList?.length > 0 ? suggestion.criteria.languageList?.map((language) => language.code) : undefined;
      this.searchCriteria.genderCodeList = suggestion.criteria.genderList?.length > 0 ? suggestion.criteria.genderList : undefined;

      // define specialtyCategory list & taxonomy list in search criteria
      this.searchCriteria.specialtySelectNm = suggestion.criteria.specialtyCategoryList?.map((category) => category.code);
      this.searchCriteria.taxonomySelectNm = (suggestion.criteria.taxonomyList || []).map((category) => category.code);
      // define provider name, npi  in search criteria
      this.searchCriteria.provNm = suggestion.criteria.providerName;
      this.searchCriteria.npi = suggestion.criteria.npi;
      this.searchCriteria.nationalProviderIdentifier = suggestion.criteria.npi;
      this.loadSpecialtyProvider();
    }
  }

  loadSpecialtyProvider() {
    this._appSession.isSpecialitySearch = true;

    this.searchCriteria = CommonUtil.buildStandAlone(this.searchCriteria, this._appSession);
    if (this.isLocationValid()) {
      this.loadProviderSummary();
    }
  }

  //When user selects an assistance word from the free text search lookup
  onAssistanceSelect(assistance: IMlAssistanceItem) {
    this.clearLookUpList();
    this.clearSearchCriteria();
    if (this.searchTerm && this.ftsPhrase) {
      const index = this.searchTerm.toLowerCase().lastIndexOf(this.ftsPhrase.toLowerCase());
      if (index !== -1) {
        this.searchTerm = this.searchTerm.slice(0, index) + assistance.text + this.searchTerm.slice(index + this.ftsPhrase.length);
      }
    }
    this.addAssistanceSelection(assistance);
    document.getElementById('fts-txt-search-field').focus();
  }

  openAddressSelectionPanel() {
    this.sidePanelRef = this._sidePanel.open('right', this.locationSidePanel);
  }

  onClose() {
    if (!this.isLocationValid()) {
      this.searchParams = this.previous_searchParams;
      this._appSession.searchParams = this.searchParams;
    } else {
      this.sidePanelRef.close();
      this.loadProviderSummary();
    }
  }

  openFtsMoreExamplesPanel() {
    this.sidePanelRef = this._sidePanel.open('right', this.ftsMoreExamplesSidePanel);
  }

  updateZipcode(zipcode: string) {
    this.searchParams.postalCode = zipcode;
    this.searchParams.zip = zipcode;
    this.locationCmp.locationTxt = zipcode;
    this.locationCmp.getGeoCoordinates(false);
  }

  trackCursor() {
    return this.searchTermInput.nativeElement.selectionStart;
  }

  async setBrowserLocation() {
    if (!this._appSession.clientCoordinates && navigator.geolocation) {
      try {
        const userPosition = await this.getCurrentGeoPosition();
        if (userPosition?.coords) {
          this._appSession.clientCoordinates = {
            latitude: userPosition.coords.latitude.toString(),
            longitude: userPosition.coords.longitude.toString()
          };
        }
      } catch (error) {
        console.error('Error getting user location', error);
      }
    }
  }
}
