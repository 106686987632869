import { PAGE_ALERTS_ES } from '../pageAlerts/pageAlert_es';
import { CommonContent } from './commonContent';

// Define the Spanish content for the common components, ensuring each key in the English content has a corresponding key in the Spanish content
export const COMMON_CONTENT_ES: CommonContent = {
  alerts: {
    emailAddress: 'Ingresa una dirección de correo electrónico.',
    invalidemailAddress: 'Ingresa una dirección de correo electrónico válida.',
    emailSent: '¡Email enviado!',
    emailSending: 'Envío de correo electrónico.',
    emailFailed: 'Hubo un problema y no se envió el correo electrónico. Vuelve a intentar más tarde.'
  },
  labels: {
    btnCancel: 'Cancelar',
    btnClose: 'Cerrar',
    btnSent: 'Enviar',
    btnOk: 'OK',
    printPdf: 'Imprimir detalles de la página',
    emailpdf: 'Detalles de la página de correo electrónico',
    emaillbl: 'Ingresa la dirección de correo electrónico del destinatario:',
    defaultEmailText: 'Ingresa la dirección de correo electrónico',
    closeModal: 'botón, seleccione para cerrar el modal',
    sendEmailModal: ' botón, seleccione para enviar el correo electrónico',
    myPlan: 'Mi plan'
  },
  freeTextSearchResults: {
    acceptsNewPatients: 'Accepts New patients',
    close: 'Cerrar',
    femaleDoctor: 'Female Doctor',
    maleDoctor: 'Male Doctor',
    name: 'name ',
    npi: 'NPI',
    serveAsPcp: 'Serve as PCP',
    similarSearches: 'Similar Searches',
    viewSimilarSearches: 'View similar searches',
    whoSpeaks: 'Speaks ',
    yourSearch: 'Your search: '
  },
  pageHeader: {
    englishSpanishToggleLabel: 'English',
    chineseLinkLabel: '寻找护理',
    fadPageTitle: 'Encontrar cuidado médico',
    viewAllLabel: 'Ver todo',
    closeModal: 'botón, seleccione para cerrar el modal',
    btnCancel: 'Cancelar',
    btnContinue: 'Continuar',
    btnSave: 'Guardar',
    lookUpInfo: 'Ingresa 3 o mas caracteres para iniciar una busqueda. Usa las teclas de flecha arriba y abajo para consultar las sugerencias de busqueda. ',
    btnClose: 'Cerrar',
    btnOk: 'OK',
    tooltipInfo: 'Estos miembros de la familia no están cubiertos por el plan seleccionado. Elija "Cambiar plan" para editar su selección de plan',
    freeTextSearch: {
      wishToTryBeta: 'Prueba la versión beta de Encontrar cuidado médico',
      returnToClassicSearch: 'Volver a cambiar',
      searchTextHint: '¿Qué tipo de cuidado buscas?',
      suggestionFailure: 'Hubo un problema. Vuelve a intentar.',
      noSuggestionCriteriaFound: 'Agrega más detalles a tu búsqueda',
      searchExample: '<strong>Ejemplo de búsqueda con texto simple</strong>: Busco una doctora para tratar mis migrañas',
      moreExamples: {
        moreExamplesLink: 'Más ejemplos',
        heading: 'Ejemplos de búsqueda',
        examplesList: [
          {
            heading: 'Agrega todos los detalles que quieras:',
            examples: [
              'Busco un doctor.',
              'Busco una doctora.',
              'Busco una doctora para tratar mis migrañas.',
              'Busco una doctora para tratar mis migrañas que hable español.',
              'Busco una doctora para tratar mis migrañas que hable español y esté aceptando nuevos pacientes.',
              'Busco una doctora para tratar mis migrañas que hable español, acepte nuevos pacientes y pueda actuar como médico de atención primaria.',
              'Busco una doctora para tratar mis migrañas que hable español, acepte nuevos pacientes y pueda servir como como médico de atención primaria y esté en Richmond, Virginia.'
            ]
          },
          {
            heading: 'Otros ejemplos de búsqueda:',
            examples: ['Ortopedista', 'Doctor John W. Smith', 'Médico de atención primaria ', '<i>(Ingresa un número NPI de 10 dígitos)</i> 1234567890', 'Doctor Miller en Los Ángeles, CA']
          },
          { heading: 'Agregar una ubicación de búsqueda:', examples: ['Richmond, Virginia', 'Condado de Henrico', 'Virginia', '23173'] }
        ]
      },
      assistanceHelpText: 'Selecciona para agregar una sugerencia a tu búsqueda.',

      search: 'Búsqueda por médico (nombre o especialidad),',

      accessibilityLabelforSearch: 'Use keyboard down arrow to navigate through suggestions and use enter key or space bar to select a suggestion. Or, press enter key now to search'
    },
    searchCriteria: {
      labels: {
        lookUpInfo: 'Ingresa 3 o mas caracteres para iniciar una busqueda. Usa las teclas de flecha arriba y abajo para consultar las sugerencias de busqueda. ',
        planUnknown: 'Plan / red Desconocido',
        searchAll: 'Buscar todo',
        searchSpecialtyName: 'Buscar por médico (nombre o especialidad), hospital, procedimiento y más',
        careTeamFor: 'Equipo de cuidado médico para {NAME}',
        selectedPlan: 'Encontrar cuidado médico en <span>{PLAN}</span>',
        selectedMemberPlan: 'Encontrar cuidado en el <span>{PLAN}</span> de <span>{NAME}</span>',
        findingCarePrefix: 'Encontrar cuidado en el prefijo del número de identificación {{PREFIX}}',
        noResultsMsg:
          'No se encontró nada para "<span class="ant-font-b">{TERM}</span>". (Sugerencia: si no puedes encontrar un proveedor de cuidado médico por su nombre, esto podría significar que no está en la red de tu plan).',
        notAnExactMatch:
          'No pudimos encontrar una coincidencia exacta para "<span class="ant-font-b">{TERM}</span>". Estas son las coincidencias más cercanas. (Sugerencia: si no puedes encontrar un proveedor de cuidado médico por nombre, esto podría significar que no está en la red de tu plan).',
        defaultSearchMsg:
          'Estas son las coincidencias más cercanas a "<span class="ant-font-b">{TERM}</span>". (Sugerencia: si no puedes encontrar un proveedor de cuidado médico por su nombre, esto podría significar que no está en la red de tu plan).',
        search: 'Buscar',
        providerName: 'Nombre del proveedor',
        specialties: 'Especialidades',
        specialtySuggestion: 'Especialidad',
        aoe: 'Área de experiencia',
        officeService: 'Servicios en el consultorio',
        nationalProviderIdentifier: 'Identificación Nacional del Proveedor',
        licenseNumber: 'Número de licencia',
        providersInNetwork: 'Proveedores de atención en la red de su plan',
        procedures: 'Por procedimiento',

        searchBy: 'Buscar por',
        searchSpecialtyProcedureName: 'Buscar por médico (nombre o especialidad), hospital, procedimiento y más',
        searchLookUpBy: 'Buscar cuidado por {CATEGORY}',
        specialty: 'especialidad',
        procedure: 'Procedimiento',

        county: 'Condado',
        serving: 'Servicio',
        cities: 'Ciudades',
        counties: 'Condados',

        name: 'nombre',
        showMore: 'Ver más',
        showLess: 'Muestra menos',
        seeAll: 'Ver todo',
        showNotInNtwrkProvsLink: 'Busca {searchTerm} fuera de la red de tu plan',

        showMoreInfo: 'mostrar más enlace enumerará todas las coincidencias ',
        showLessInfo: 'mostrar menos enlace mostrará las 5 primeras ',
        ariaLabelForViewAllProviders: 'Haz clic en Ver todo para más nombres de proveedores',
        needHelp: '¿Necesitas ayuda?',
        liveChat: 'Chatea con un agente en vivo',
        noResultsAlert: '<span class="ant-font-b">No se encontró nada para "{TERM}</span>". ¿Necesita ayuda?',
        ariaLabelForProviderSearch: 'selecciona Enter para buscar proveedores basados en procedimientos',
        ariaLabelForSpecialtySearch: 'selecciona Enter para buscar proveedores basados en especialidades',
        ariaLabelForAreaOfExpertiseSearch: 'selecciona Enter para buscar proveedores basados en àrea de experiencia',
        ariaLabelForOfficeServiceSearch: 'selecciona Enter para buscar proveedores basados en servicios en el consultorio',
        ariaLabelForProviderDetails: 'selecciona Enter para ver los detalles del proveedor',
        miles: 'millas de distancia',
        medicalCode: 'Código médico',
        billingCode: 'Código de facturación',
        medicalCodeToolTipDesc:
          'Los códigos médicos se utilizan para ayudar a determinar los montos de las reclamaciones y los pagos. Ten en cuenta que un procedimiento o servicio médico puede incluir varios códigos. Buscar por procedimiento en lugar de código médico te dará el mejor estimado de los costos generales.',
        planSelected: 'Quiero buscar en este plan/red:',
        OONInfo1: '¿No encuentras a quién buscas? Intente buscar fuera de la red de su plan para ',
        OONInfo2: 'proveedores fuera de la red.',
        primaryCarePhysician: 'Médico de atención primaria (PCP)',
        pcpDescription: 'Médicos, enfermeras practicantes y otras personas que pueden actuar como su PCP.',
        primaryCareTerms: ['PCP', 'ATENCIÓN PRIMARIA', 'MÉDICO PRIMARIO', 'MÉDICO DE CABECERA', 'MÉDICO DE ATENCIÓN PRIMARIA'],
        bhCrisisMsg: {
          text: 'La ayuda está disponible. Hable con un consejero de crisis las 24 horas del día, los 7 días de la semana marcando <strong>988</strong> o use el ',
          LifeLineText: 'Chat de la Línea de Vida para el Suicidio y la Crisis. ',
          chatText: 'También puede conectarse con un ',
          chatLinkText: 'Agente en Vivo',
          chatLink: 'https://chat.988lifeline.org/'
        },
        bhNonCrisisMsg: {
          text: 'Podemos ayudarlo a encontrar servicios de salud conductual. ',
          chatLink: 'https://chat.988lifeline.org/',
          chatLinkText: 'Use el Asistente de búsqueda'
        },
        clearSearch: 'clear search'
      },
      cptDataAnalytics: {
        medDropdownMenuSearch: 'medCodeHomeFindCare',
        expandMedCodeHomeFindCare: 'expandMedCodeHomeFindCare',
        collapseMedCodeHomeFindCare: 'collapseMedCodeHomeFindCare',
        showAllMedCodeHomeFindCare: 'showAllMedCodeHomeFindCare',
        showMoreMedCodeHomeFindCare: 'showMoreDropSearchHomeFindCare',
        showLessMedCodeHomeFindCare: 'showLessMedCodeHomeFindCare',
        infoIconMedCodeHomeFindCare: 'infoIconMedCodeHomeFindCare'
      },
      alerts: {
        zipcodeError: 'No encontramos esa ubicación. Intenta más tarde.',
        stateZipCodeError: 'Ese código postal no se encuentra en ese estado. Revisa el estado y el código postal y vuelva a intentar.',
        invalidError: 'Por favor ingrese un nombre valido.',
        networkUnknown:
          'Lo sentimos, no podemos recuperar la información de una o más de las coberturas de tu plan. Selecciona entre una de las coberturas disponibles del plan que se muestran para completar tu búsqueda.',
        networkUnknownMsg: 'Estamos teniendo problemas para cargar la información de tu plan/red.',
        planSelectionMsg: 'Busca seleccionando tu plan.',
        memberPlanError: 'Tenemos problemas para cargar la información de tu plan/red. Puedes intentar más tarde o ',
        memberPlanErrorLink: 'buscar seleccionando tu plan',

        primeGroupSearchMsg:
          'Estos resultados de búsqueda muestran proveedores de cuidado en tu grupo médico. Si quieres buscar fuera de tu grupo médico, borra el filtro "Grupo médico o IPA". Necesitarás ser referido por tu médico de atención primaria (PCP, por sus siglas en inglés) antes de recibir servicios que no son de emergencia.',
        clinicalPrograms: {
          TP_ATSM_SPCTRM_DSRD: {
            id: 'lnk-atsm-spctrm-dsrd',
            dataAnalytics: 'autismProgramLinkFindCare',
            ariaLabel: 'haga clic aquí para navegar a {PROGRAM_NAME}',
            msgText: 'Recibe apoyo y recursos para tu familia.',
            programName: 'Programa para el Trastorno del Espectro Autista'
          },
          TP_BH_HLTH_RSRC_CNTR: {
            id: 'lnk-bh-hlth-rsrc-cntr',
            dataAnalytics: 'behavioralProgramLinkFindCare',
            ariaLabel: 'haga clic aquí para navegar a {PROGRAM_NAME}',
            msgText: 'Soporte 24/7 para problemas de salud emocional.',
            programName: 'Administración de recursos de salud conductual'
          },
          TP_CSMNGT: {
            id: 'lnk-csmngt',
            dataAnalytics: 'caseProgramLinkFindCare',
            ariaLabel: 'haga clic aquí para navegar a {PROGRAM_NAME}',
            msgText: 'Coordinación del cuidado médico y apoyo después de una enfermedad.',
            programName: 'Manejo de casos'
          },
          TP_CNDCR: {
            id: 'lnk-cndcr',
            dataAnalytics: 'conditionProgramLinkFindCare',
            ariaLabel: 'haga clic aquí para navegar a {PROGRAM_NAME}',
            msgText: 'Apoyo y herramientas para ayudarte a manejar tu condición.',
            programName: 'Programa ConditionCare'
          },
          TP_FTRMM: {
            id: 'lnk-ftrmm',
            dataAnalytics: 'futureProgramLinkFindCare',
            ariaLabel: 'haga clic aquí para navegar a {PROGRAM_NAME}',
            msgText: 'Recursos para ayudarte a tener un embarazo saludable.',
            programName: 'Programa Future Moms'
          },
          TP_LHO_LC: {
            id: 'lnk-lho-lc',
            dataAnalytics: 'lactationProgramLinkFindCare',
            ariaLabel: 'haga clic aquí para navegar a {PROGRAM_NAME}',
            msgText: 'Apoyo a la lactancia desde la comodidad del hogar.',
            programName: 'Consulta de lactancia en línea'
          },
          TP_247NRSLN: {
            id: 'lnk-nrsln',
            dataAnalytics: 'nurselineProgramLinkFindCare',
            ariaLabel: 'haga clic aquí para navegar a {PROGRAM_NAME}',
            msgText: 'Consulta con una enfermera registrada las 24 horas.',
            programName: 'Línea de enfermería 24/7'
          },
          TP_WBC: {
            id: 'lnk-wbc',
            dataAnalytics: 'wellBeingProgramLinkFindCare',
            ariaLabel: 'haga clic aquí para navegar a {PROGRAM_NAME}',
            msgText: 'Tu asesor o coach está aquí para guiarte, apoyarte e inspirarte.',
            programName: 'Asesor de Bienestar'
          },
          TP_SSPR_SSO: {
            id: 'lnk-smrtshopper',
            dataAnalytics: 'smartShopperProgramAlertFindCare',
            ariaLabel: 'haga clic aquí para navegar a {PROGRAM_NAME}',
            msgText: 'Vale la pena comparar precios para tu cuidado médico.',
            programName: 'Programa Smart Shopper'
          },
          TP_BPG: {
            id: 'lnk-bpg',
            dataAnalytics: 'bpgProgramAlertFindCare',
            ariaLabel: 'haga clic aquí para navegar a {PROGRAM_NAME}',
            msgText: 'Programa en línea para ayudar a reducir el dolor de espalda y las articulaciones.',
            programName: 'Guía para el dolor de espalda y las articulaciones'
          },
          TP_DPP: {
            id: 'lnk-dpp',
            dataAnalytics: 'diabetesPreventLinkResultsFindCare',
            ariaLabel: 'haga clic aquí para navegar a {PROGRAM_NAME}',
            msgText: 'La asesoría virtual de Lark reduce en gran medida tu riesgo.',
            programName: 'Programa de Prevención de la Diabetes'
          }
        },
        infoListDisclaimers: {
          TP_HCS: [
            {
              id: 'hcs-label-text',
              displayText: 'Hearing Care Solutions maneja algunas partes de tu beneficio de audición.',
              orderSequence: '1',
              ariaLabel: 'Hearing Care Solutions maneja algunas partes de tu beneficio de audición.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'hcs-link-text',
              displayText: 'Visita el {linkLabel} para encontrar un profesional de la audición en tu red.',
              orderSequence: '2',
              ariaLabel: 'Visita el sitio web de Hearing Care Solutions para encontrar un profesional de la audición en tu red.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://hearingcaresolutions.com/anthem/',
              linkLabel: 'sitio web de Hearing Care Solutions'
            }
          ],
          TP_DENTAQUEST: [
            {
              id: 'dentaquest-label-text',
              displayText: 'DentaQuest administra algunas partes de tu beneficio dental.',
              orderSequence: '1',
              ariaLabel: 'DentaQuest administra algunas partes de tu beneficio dental.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'dentaquest-link-text',
              displayText: 'Visita el {linkLabel} para encontrar un profesional dental en tu red.',
              orderSequence: '2',
              ariaLabel: 'Visita el sitio web de DentaQuest para encontrar un profesional dental en tu red.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'http://www.dentaquest.com/members/',
              linkLabel: 'sitio web de DentaQuest'
            }
          ],
          TP_OHDENTAQUEST: [
            {
              id: 'ohdentaquest-label-text',
              displayText: 'DentaQuest administra algunas partes de tu beneficio dental.',
              orderSequence: '1',
              ariaLabel: 'DentaQuest administra algunas partes de tu beneficio dental.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'ohdentaquest-link-text',
              displayText: 'Visita el {linkLabel} para encontrar un profesional dental en tu red.',
              orderSequence: '2',
              ariaLabel: 'Visita el sitio web de DentaQuest para encontrar un profesional dental en tu red.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl:
                'https://dentaquest.healthsparq.com/healthsparq/public/#/one/city=&state=&postalCode=&country=&insurerCode=DENTAQUEST_I&brandCode=DENTAQUEST/results/allRemote=false&alphaPrefix=&isPromotionSearch=true&key=&location=Ohio%252C%2520US&maxLatitude=&maxLongitude=&minLatitude=&minLongitude=&page=1&patientAge=&providerType=&query=&radius=25&searchType=default&searchCategory=GENERAL&sort=DEFAULT&waitForOop=false&doWebAlert=true&productCode=OH-ANT-CAID',
              linkLabel: 'sitio web de DentaQuest'
            }
          ],
          TP_LIBERTY: [
            {
              id: 'liberty-label-text',
              displayText: 'Liberty Dental maneja algunas partes de tu beneficio dental.',
              orderSequence: '1',
              ariaLabel: 'Liberty Dental maneja algunas partes de tu beneficio dental.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'liberty-link-text',
              displayText: 'Visita el {linkLabel} para encontrar un profesional dental en tu red.',
              orderSequence: '2',
              ariaLabel: 'Visita el sitio web de Liberty Dental para encontrar un profesional dental en tu red.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://client.libertydentalplan.com/anthem/FindADentist',
              linkLabel: 'sitio web de Liberty Dental'
            }
          ],
          TP_MAGELLAN_PHARMACY: [
            {
              id: 'pharmacy-label-text',
              displayText: 'Magellan administra algunas partes de su beneficio de Farmacia.',
              orderSequence: '1',
              ariaLabel: 'Magellan administra algunas partes de su beneficio de Farmacia.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'pharmacy-label-text',
              displayText: 'Vaya a {linkLabel} para encontrar una farmacia dentro de su red.',
              orderSequence: '2',
              ariaLabel: 'Vaya al sitio web de la farmacia para encontrar una farmacia dentro de su red.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://www.lamcopbmpharmacy.com',
              linkLabel: 'Sitio web de Magellanl'
            }
          ],
          TP_TN_DENTAQUEST: [
            {
              id: 'tndentaquest-label-text',
              displayText: 'Algunos de tus beneficios dentales para 2022 son administrados por DentaQuest. ',
              orderSequence: '1',
              ariaLabel: 'Algunos de tus beneficios dentales para 2022 son administrados por DentaQuest',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'tndentaquest-link-text',
              displayText: 'Para encontrar un profesional dental en tu plan dental 2022, visita el {linkLabel}.',
              orderSequence: '2',
              ariaLabel: 'Para encontrar un profesional dental en tu plan dental 2022, visita el sitio web de DentaQuest',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'http://www.dentaquest.com/members/',
              linkLabel: 'sitio web de DentaQuest'
            }
          ],
          TP_TN_LIBERTY: [
            {
              id: 'tnliberty-label-text',
              displayText: 'Liberty Dental administrará algunos de tus beneficios dentales para 2023. ',
              orderSequence: '1',
              ariaLabel: 'Liberty Dental administrará algunos de tus beneficios dentales para 2023',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'tnliberty-link-text',
              displayText: 'Para encontrar un profesional dental en tu plan dental 2023, visita el {linkLabel}.',
              orderSequence: '2',
              ariaLabel: 'Para encontrar un profesional dental en tu plan dental 2023, visita el sitio web de Liberty Dental',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://client.libertydentalplan.com/anthem/FindADentist',
              linkLabel: 'sitio web de Liberty Dental'
            }
          ],
          TP_VSP: [
            {
              id: 'vsp-label-text',
              displayText: 'VSP maneja algunas partes de tu beneficio de visión.',
              orderSequence: '1',
              ariaLabel: 'VSP maneja algunas partes de tu beneficio de visión.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'vsp-link-text',
              displayText: 'Visita el {linkLabel} para encontrar un profesional de la visión en tu red.',
              orderSequence: '2',
              ariaLabel: 'Visita el sitio web de VSP para encontrar un profesional de la visión en tu red.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://www.vsp.com/find-eye-doctors.html?id=medicaid',
              linkLabel: 'sitio web de VSP'
            }
          ],
          TP_EYEQUEST: [
            {
              id: 'eyequest-label-text',
              displayText: 'EyeQuest maneja algunas partes de tu beneficio de visión.',
              orderSequence: '1',
              ariaLabel: 'EyeQuest maneja algunas partes de tu beneficio de visión.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'eyequest-link-text',
              displayText: 'Visita el {linkLabel} para encontrar un profesional de la visión en tu red.',
              orderSequence: '2',
              ariaLabel: 'Visita el sitio web de EyeQuest para encontrar un profesional de la visión en tu red.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://govservices10.dentaquest.com/Router.jsp?component=Main&source=Logon&action=ProviderDirectory',
              linkLabel: 'sitio web de EyeQuest'
            }
          ],
          TP_SUPERIOR: [
            {
              id: 'superior-label-text',
              displayText: 'Superior maneja algunas partes de tu beneficio de visión.',
              orderSequence: '1',
              ariaLabel: 'Superior maneja algunas partes de tu beneficio de visión.',
              isAnchorText: false,
              navigationUrl: '',
              inAppNavigation: false
            },
            {
              id: 'superior-link-text',
              displayText: 'Visita el {linkLabel} para encontrar un profesional de la visión en tu red.',
              orderSequence: '2',
              ariaLabel: 'Visita el sitio web de Superior vision para encontrar un profesional de la visión en tu red.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://superiorvision.com/',
              linkLabel: 'sitio web de Superior vision'
            }
          ],
          TP_GA_Termination_Providers: [
            {
              id: 'termination-Providers-link-text',
              displayText: 'Revisa esta lista de {linkLabel} durante el próximo año del plan.',
              orderSequence: '1',
              ariaLabel: 'Revisa esta lista de proveedores de cuidado médico que pueden dejar la red de tu plan durante el próximo año del plan.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: '/assets/documents/gaterminatingproviders_es.pdf',
              linkLabel: 'proveedores de cuidado médico que pueden dejar la red de tu plan',
              linkDataAnalyticsTag: 'providersLeftNetworkAlertFindCare',
              mobileLinkDataAnalyticsTag: 'PROVIDERS_LEFT_NETWORK_ALERT_FIND_CARE'
            }
          ],

          TP_SUPPRESSED_MEDSUPP: [
            {
              id: 'suppressed-medsupp-alert-label-text',
              displayText: 'Para encontrar un proveedor de cuidado médico que acepte la Asignación de Medicare (uno que acepte el monto aprobado por Medicare como pago total), visita {linkLabel}.',
              orderSequence: '1',
              ariaLabel:
                'Para encontrar un proveedor de cuidado médico que acepte la Asignación de Medicare (uno que acepte el monto aprobado por Medicare como pago total), visita es.medicare.gov/care-compare.',
              isAnchorText: true,
              inAppNavigation: false,
              navigationUrl: 'https://es.medicare.gov/care-compare/',
              linkLabel: 'es.medicare.gov/care-compare'
            }
          ],
          TP_PrimeGroupSearchMsg: [
            {
              id: 'PrimeGroupSearchMsg-label-text',
              displayText:
                'Estos resultados de búsqueda muestran proveedores de cuidado en tu grupo médico. Si quieres buscar fuera de tu grupo médico, borra el filtro "Grupo médico o IPA". Necesitarás ser referido por tu médico de atención primaria (PCP, por sus siglas en inglés) antes de recibir servicios que no son de emergencia.'
            }
          ]
        },
        costForInitialVisit: {
          msgText:
            'Los costos estimados que mostramos corresponden a una visita inicial. Para ver información detallada sobre los beneficios de este procedimiento, incluidos los límites de servicio, ve a {BENEFITS_LINK}.',
          linkText: 'Buscar beneficios',
          linkUrl: '/member/benefits?covtype=med',
          ariaLabel: 'haga clic aquí para navegar a Buscar beneficios'
        },
        costForMoreThanOneVisit: {
          msgText:
            'Los costos estimados que mostramos son para las visitas 1-{VISITS_VALUE}. Para ver la información de cobertura para este procedimiento, incluidos los límites de servicio, ve a {BENEFITS_LINK}.',
          linkText: 'Buscar beneficios',
          linkUrl: '/member/benefits?covtype=med',
          ariaLabel: 'haga clic aquí para navegar a Buscar beneficios'
        },
        zipcodeEmptyError: 'Ingresa una ubicación.',
        yourMedicalGroupSearchMsg:
          'Estos resultados de búsqueda muestran proveedores de cuidado médico dentro de tu grupo médico. Si quieres buscar fuera de tu grupo médico, selecciona "Sin preferencia" en el filtro de Tu grupo médico. Necesitarás ser referido por tu médico de atención primaria antes de recibir servicios que no sean de emergencia.',
        yourMedicalHealthSystemMsg:
          'Estos resultados de búsqueda muestran a los proveedores de cuidado en tu grupo médico. Para buscar fuera de tu grupo médico, elige otra opción en el filtro "Tu sistema de salud". Sin embargo, si buscas cuidado médico que no sea de emergencia fuera de tu grupo médico, necesitarás ser referido por tu médico de atención primaria.'
      },
      homeHealthCareContent: {
        url: 'https://providers.carelonmedicalbenefitsmanagement.com/postacute/finder/',

        publicHeader: 'Lo sentimos, no podemos completar tu búsqueda',
        publicContent: 'Si necesitas asistencia inmediata para encontrar Cuidado médico a domicilio, llama al número de Servicio a miembros que figura en tu tarjeta de identificación de miembro.',
        secureHeader: 'Servicios de cuidado médico en el hogar',
        secureContent:
          'Nos asociamos con myNEXUS para ayudarte a administrar tus servicios de cuidado médico a domicilio. Para encontrar un proveedor de servicios de cuidado médico a domicilio, usa el buscador de proveedores myNEXUS.',
        Ok: 'Ok',

        ariaLabel: 'OK botón, haz clic aquí para cerrar la ventana modal del carelon'
      },
      homeHealthCareSecureContentInfo: {
        MY_NEXUS_SECURE_CONTENT: [
          {
            id: 'carelon-label-text',
            orderSequence: '1',
            displayText: 'Nos asociamos con las Soluciones Post Agudas de Carelon para ayudar a manejar los servicios de cuidado médico en el hogar. ',
            ariaLabel: 'Nos asociamos con las Soluciones Post Agudas de Carelon para ayudar a manejar los servicios de cuidado médico en el hogar.',
            isAnchorText: false,
            inAppNavigation: false,
            mobileInAppNavigationUrl: '',
            navigationUrl: '',
            linkLabel: '',
            targetAttribute: ''
          },
          {
            id: 'carelon-care-link-text',
            orderSequence: '2',
            displayText: 'Obtén más información {linkLabel} o llamando al 844-411-9622. ',
            ariaLabel: 'Obtén más información visitando su sitio web o llamando al 844-411-9622.',
            isAnchorText: true,
            inAppNavigation: false,
            mobileInAppNavigationUrl: '',
            navigationUrl: 'https://www.careloninsights.com/medical-benefits-management/post-acute-care',
            linkLabel: 'visitando su sitio web',
            targetAttribute: '_blank'
          },
          {
            id: 'carelon-provider-link-text',
            orderSequence: '3',
            displayText: '{linkLabel}.',
            ariaLabel: 'Encontrar servicios de cuidado médico en el hogar.',
            isAnchorText: true,
            inAppNavigation: false,
            mobileInAppNavigationUrl: '',
            navigationUrl: 'https://providers.carelonmedicalbenefitsmanagement.com/postacute/finder/',
            linkLabel: 'Encontrar servicios de cuidado médico en el hogar',
            targetAttribute: '_blank'
          }
        ]
      },
      transportationContent: {
        header: 'Transporte',
        prefixBasedHeader: 'Transporte (que no sea de emergencia)',
        evidenceCoverageText: 'Consulta la Evidencia de Cobertura de tu plan para obtener información detallada sobre los beneficios.',
        medicalAppointmentText: 'Llama a los siguientes números para programar el transporte para tus citas médicas:',
        prefixBasedMedicalAppointmentText: 'Llame al siguiente número para programar el transporte para citas médicas:',
        countiesText: 'Condados de servicio: ',
        reservationsText: 'Reservas: ',
        rideAssistText: 'Asistencia con el transporte: ',
        ttyText: 'TTY: ',
        close: 'Cerrar',
        print: 'Imprimir',
        tryAgain: 'Intentar otra vez',
        goBack: 'Volver',
        ariaClose: 'Cerrar botón, haz clic aquí para cerrar la ventana modal del transporte',
        ariaPrint: 'Imprimir, haz clic aquí para imprimir los detalles del transporte',
        contactUsSecure:
          'Hay beneficios de transporte adicionales disponibles para usted a través de Medi-Cal. Para obtener más información, comuníquese con <a target="_blank" href="/member/contact-us">Servicios para Miembros.</a>',
        contactUsPublic:
          'Hay beneficios de transporte adicionales disponibles para usted a través de Medi-Cal. Para obtener más información, comuníquese con <a target="_blank" href="/contact-us">Servicios para Miembros.</a>'
      },
      precareSearchErrorMessages: {
        genericMessage: {
          header: 'Unable to Complete Your Search',
          message: [
            {
              id: 'deeplink_negative_msg',
              orderSequence: '1',
              displayText: "For some reason, we're unable to complete your search. Please {linkLabel} if you have questions about your benefits and coverage.",
              isAnchorText: true,
              inAppNavigation: true,
              mobileInAppNavigationUrl: 'CONTACT_US_OVERVIEW',
              navigationUrl: '/member/contact-us',
              linkLabel: 'contact us',
              targetAttribute: '_self',
              ariaLabel: "For some reason, we're unable to complete your search. Please contact us if you have questions about your benefits and coverage."
            }
          ],
          close: 'Close',
          closeAriaLabel: 'Close button, select to close the modal'
        }
      }
    },
    updateLocationWrapper: {
      locationButton: 'Actualizar ubicación',
      searchLocation: 'Buscar ubicación',
      currentLocation: {
        formHeader: 'Ubicación actual',
        alertMsg: 'Nota: si estás usando un VPN, tu ubicación actual será la misma que la del VPN al que estás conectado.',
        formFields: {
          city: { label: 'Ciudad, estado', placeholder: 'Código postal' },
          zipcode: { label: 'Ingresa el código postal', placeholder: 'Otra dirección' }
        }
      },
      otherAddress: {
        formHeader: 'Dirección postal',
        formFields: {
          streetAddress: { label: 'Ingresa la dirección postal', placeholder: 'Ingresa la dirección postal' },
          streetAddress2: { label: 'Dirección postal, línea 2 (opcional)', placeholder: 'Ingresa la dirección postal' },
          zipcodeOnly: { label: 'Código postal', placeholder: 'Ingrese el Código postal' },
          cityOrCounty: { label: 'Ciudad, Estad', placeholder: 'Ingrese Ciudad' }
        }
      },
      homeAddress: { label: 'Dirección particular' },

      zipcode: { label: 'Código postal, ciudad o condado', placeholder: 'Ciudad, condado o código postal' },
      continue: 'Continuar para encontrar cuidado médico',
      cancel: 'Cancelar',
      errorMessage: {
        zipCodeError: 'No encontramos esa ubicación. Vuelva a intentar.',
        emptyZipError: 'Ingrese una ubicación.',
        emptyZipOnlyError: 'Ingrese un código postal.',
        addressError: 'Ingrese una dirección.',
        pcpError: 'Este plan no está disponible para la dirección ingresada.',
        currentLocationError: 'Ese código postal no se encuentra en ese estado. Revise el estado y el código postal y vuelva a intentar',
        addessMismatch: 'La ubicación no está habilitada para este navegador. Actualice sus permisos.',
        currentLocationNotEnabled: 'Para continuar con esta búsqueda, la ubicación geográfica debe estar habilitada.',
        emptyCityOrCountyError: 'Por favor, ingrese ciudad o estado.',
        cityOrCountyError: 'Por favor, ingrese una ciudad o estado válido.'
      },
      addressTypes: {
        homeAddressDisclaimer: 'Búsqueda basada en la dirección de tu casa.',
        workAddressDisclaimer: 'Búsqueda basada en la dirección de tu trabajo.',
        zipCodeDisclaimer: 'Búsqueda basada en el código postal.',
        changeLocation: 'Buscar por dirección',

        addressSection: {
          enter: {
            code: 'enter',
            label: 'enter Address'
          },
          home: { code: 'HOME', label: 'Dirección de casa' },
          work: { code: 'WORK', label: 'Dirección del trabajo' },
          update: { code: 'update', label: 'Actualizar la dirección de casa o del trabajo' }
        },
        customSection: {
          heading: 'Encontrar Otros tipos de cuidado',
          subHeading: 'Para todos los demás tipos de búsquedas, ingresa una ciudad, condado o código postal',
          customZip: { label: 'ingresar dirección', code: 'CUSTOMZIP' }
        },
        hintText: ''
      }
    },
    carePlanWrapper: {
      planNetworks: 'redes del plan',
      changePlanPublic: 'Cambiar plan',
      changePlanSecure: 'Cambiar plan o miembro',
      changePlanPublicMsg: 'Cambiar redes de planes',
      planStart: ' (A partir del {DATE})',
      changeMember: 'Cambiar miembro',
      selectedMemberPlans: 'Encontrar cuidado en TODAS las redes de planes de <span>{NAME}</span>',
      careTeamPlans: 'Viendo el equipo de cuidado médico para <span class="ant-font-b">{NAME}</span> en',
      selectedMemberPlan: 'Encontrar cuidado en el <span>{PLAN}</span> de <span>{NAME}</span>',
      selectedPlan: 'Encontrar cuidado médico en <span>{PLAN}</span>',
      customZip: { label: 'ingresar dirección', code: 'CUSTOMZIP' },
      planNetworksToutHeader: 'Las siguientes redes de planes se muestran en los resultados de búsqueda.',
      closeModal: 'botón, seleccione para cerrar el modal',
      planLabelMsg: '¿Cuál red de plan?',
      careLabel: '¿Qué tipo de cuidado médico estás buscando?',
      memberLabel: '¿Para quién es este cuidado médico?',
      planNetworkLabel: 'Your {{NETWORK}} Plan Network',
      alerts: {
        coverageDetailErrorMsg: 'Selected member dosent have any coverage. So please select another member'
      },
      changePlanLabel: {
        choosePlan: 'Elija un plan',
        choosePlanTitle: 'Algunos miembros de la familia pueden tener diferentes planes. Seleccionar un plan diferente cambiará los miembros que puede buscar.',
        includes: 'Incluye:',
        eligibleMembers: 'Miembro elegible:'
      }
    }
  },
  searchHeader: {
    headerTitle: 'Encontrar cuidado médico',
    headerTitleSuffix: 'para',
    changePlan: 'Cambiar de plan',
    selectFamilyMember: 'Seleccione un miembro de la familiar',
    contractChangeSuccess: '¡Éxito! Su selección de plan ha cambiado.',
    startOver: 'Comenzar de nuevo'
  },
  pageTitle: { searchCriteria: 'Encontrar cuidado médico de criterio', searchResults: 'Encontrar cuidado médico de resultados', details: 'Detalles del proveedor' },
  actionItems: {
    actionItemButton: 'seleccionar para {ACTION}'
  },
  hideQuickLinkShopForProcedure: {
    iAmInterested: {
      name: 'Estoy interesado',
      header: 'Gracias por dejarnos saber',
      description: 'Usaremos tus comentarios para mejorar nuestro sistema y mostrarte más de lo que podría ser útil. Haznos saber por qué.',
      dataAnalytics: {
        sendFeedbackIntModalFindCare: 'sendFeedbackIntModalFindCare',
        cancelFeedbackIntModalFindCare: 'cancelFeedbackIntModalFindCare',
        backFeedbackIntModalFindCare: 'backFeedbackIntModalFindCare',
        interestedOptionFindCare: 'interestedOptionFindCare',
        interestedOptionShopProcFindCare: 'interestedOptionShopProcFindCare'
      }
    },
    notInterested: {
      name: 'No estoy interesado',
      header: 'Dinos por qué no estás interesado',
      description:
        'Usaremos tus comentarios para mejorar nuestro sistema y mostrarte más de lo que podría ser útil. Dependiendo de tus comentarios, eliminaremos este botón de tu experiencia de Encontrar cuidado médico durante un tiempo. Si necesitas buscar algo que se haya eliminado, usa la barra de búsqueda de Encontrar cuidado médico.',
      dataAnalytics: {
        sendFeedbackNotIntModalFindCare: 'sendFeedbackNotIntModalFindCare',
        cancelFeedbackNotIntModalFindCare: 'cancelFeedbackNotIntModalFindCare',
        backFeedbackNotIntModalFindCare: 'backFeedbackNotIntModalFindCare',
        notInterestedOptionFindCare: 'notInterestedOptionFindCare',
        notInterestedOptionShopProcFindCare: 'notInterestedOptionShopProcFindCare'
      }
    },
    cancel: 'Cancelar',
    sendFeedback: 'Enviar comentarios',
    other: 'Otros',
    closeModal: 'botón, seleccione para cerrar el modal',
    otherTextBoxPlaceholder: 'Ingresa motivo'
  },
  newLandingComponent: {
    labels: {
      TP_PROMOTED_PROCEDURE: "{MESSAGE}<br><a href='javascript:void(0);' target='{TARGET}' aria-label='{ARIA-LABEL}' data-analytics='{DATA-ANALYTICS}' class='{CATEGORY}'>{LABEL}</a>"
    }
  },
  locationFormComponent: {
    formHeader: 'Dirección para búsqueda de atención primaria',
    formHeaderMessage:
      'Al buscar un proveedor de atención primaria, necesitaremos usar la dirección de tu casas o trabajo como punto de partida. (Podrás buscar otros tipos de atención con base en cualquier ubicación).',
    homeAddress: 'Dirección de casa',
    workAddress: 'Continuación de dirección (opcional)',
    streetAddress: { label: 'Dirección', placeholder: 'Ingresa la dirección' },
    streetAddress2: { label: 'Dirección (Cont.)', placeholder: 'Ingresa la dirección' },
    city: { label: 'Ciudad', placeholder: 'Ingresa la ciudad' },
    state: { label: 'Estado', placeholder: 'Selecciona el estado' },
    zipcode: { label: 'Código postal', placeholder: 'Ingresa el código postal' },
    continue: 'Continuar a Encontrar Cuidado Médico',
    cancel: 'Cancelar',
    errorMessage: { zipCodeError: 'Revisa la dirección', pcpError: 'Este plan no está disponible para la dirección ingresada.' }
  },
  appErrorMsg: {
    sessionExpired: 'Lo sentimos, su sesión puede haber expirado. Actualice la página e intente nuevamente.',
    defaultErrorMsg: 'Lo sentimos, estamos experimentando dificultades técnicas en este momento. Por favor, inténtelo de nuevo más tarde.',
    ErrorMsg: 'Estamos teniendo problemas para encontrar esa dirección. Revisa otra vez la dirección que ingresaste y vuelve a intentar.'
  },
  leavingDisclaimerComponent: {
    closeBtnText: 'Close',
    btnContinue: 'Continuar',
    continueBtnText: 'Continue',
    header: 'Estás saliendo de {BRAND}.com',
    content1:
      'Al seleccionar el botón Continuar a continuación, saldrá de este sitio y se vinculará a un sitio creado y/o mantenido por otra entidad y reconoce que no controlamos, garantizamos, respaldamos o aprobamos la información, los productos o servicios disponibles en el Sitio externo o la seguridad de las transmisiones entre usted y el Sitio externo.',
    content2:
      'Una vez vinculado, estará sujeto a los términos de uso, privacidad, derechos de autor y políticas de seguridad del Sitio externo. Proporcionamos estos enlaces únicamente para su información y conveniencia. Miembros: puede que su plan de salud no cubra todos los servicios o productos descritos en el Sitio externo. Consulte su folleto de beneficios. La información contenida en el Sitio externo no debe interpretarse como un consejo o tratamiento médico.',
    btnClose: 'Regresar a {BRAND}.com',

    externalSite: 'botón, seleccione para navegar al sitio externo',
    closeModal: 'botón, seleccione para cerrar el modal',
    VEP: {
      header: '¿Buscas un médico o un hospital en la red EPO de Anthem Alliance?',
      content1: 'Haz clic en el botón de proveedores médicos a continuación para buscar proveedores médicos.',
      content2: 'Haz clic en el botón de proveedores de salud mental a continuación para buscar proveedores de salud mental.',
      content3:
        'Para los servicios de salud conductual, cuentas con el respaldo de la red de Salud Conductual Administrada por Mercy (MMBH, por sus siglas en inglés). Al seleccionar "salud conductual", estarás saliendo del sitio de Anthem y dirigiéndote al sitio web de MMBH y reconoces que Anthem no controla, garantiza o aprueba la información disponible en el sitio externo ni la seguridad o la transmisión entre tú y el sitio externo.',
      btnClose: 'Proveedores médicos',
      btnContinue: 'Proveedores de salud conductual'
    }
  },
  medicareSupplementContent: {
    heading: 'Información importante para clientes de seguro complementario de Medicare',
    description:
      'Para encontrar proveedores que aceptan la asignación de Medicare (aquellos proveedores que aceptan el monto aprobado por Medicare como pago total), deberás visitar Medicare.gov y hacer clic en la herramienta Physician Compare (Comparar médicos).',
    btnCancel: 'Cancelar',
    btnContinue: 'Continuar',
    redirect: 'Buscador de proveedores Medicare',
    url: 'https://es.medicare.gov/care-compare/'
  },
  pageAlerts: {
    ...PAGE_ALERTS_ES
  },
  pageAlertLinks: {
    eyeQuestVision: 'EyeQuest manages some parts of your vision benefit. Use the Search button to go to the EyeQuest website to find a vision professional in your network.',
    eyeQuestVisionUrl: 'https://govservices10.dentaquest.com/Router.jsp?component=Main&source=Logon&action=ProviderDirectory',
    superiorVision: 'Superior manages some parts of your vision benefit. Use the Search button to go to the Superior website to find a vision professional in your network.',
    superiorVisionUrl: 'https://www.superiorvision.com',
    eyeMedVision: 'EyeMed manages some parts of your vision benefit. Go to the EyeMed website to find a vision professional in your network.',
    eyeMedVisionUrl: 'https://eyedoclocator.eyemedvisioncare.com/ahkplus/?networkDDDisabled=true',
    oheyeMedVisionUrl: 'https://eyedoclocator.eyemedvisioncare.com/anthemohmd',
    marchVision: 'March Vision Care manages some parts of your vision benefit. Go to the March Vision Care website to find a vision professional in your network.',
    marchVisionUrl: 'https://www.marchvisioncare.com/',
    avesisUrl: 'https://www.fap.avesis.com/amerigroupga/provider/search',
    marylandUrl: 'https://mdmwp.sciondental.com/MWP/Landing',
    navitusPdfUrl: 'https://www.navitus.com/getmedia/25f6cef8-46f9-4d0b-9bd5-7ae8a4a13150/Navitus-Pharmacy-Network-Listing-National-and-Chains.pdf',
    kyPortalUrl: 'http://kyportal.medimpact.com/',
    HCS_URL: 'https://hearingcaresolutions.com/anthem/',
    LIBERTY_DENTAL_URL: 'https://client.libertydentalplan.com/anthem/FindADentist',
    DENTAL_QUEST_URL: 'http://www.dentaquest.com/members/',
    DENTAL_VSP_URL: 'https://www.vsp.com/find-eye-doctors.html?id:medicaid',
    VEP_BEH_HEALTH: 'http://www.mbh-eap.com/pd/'
  },
  pageAlertComponentLabels: {
    notice: 'Avisos',
    view: 'Ver',
    importantNotices: 'Avisos importantes',
    close: 'Cerrar',
  },
  pageAlertCloseTag: {
    PA_COUPE_ALERT: "closeIconCoupeAlertHomeFindCare"
  },
  modals: {
    MD_TRANSPORTATION: {
      title: 'Transporte',
      content: {
        header: 'Transporte',
        prefixBasedHeader: 'Transporte (que no sea de emergencia)',
        evidenceCoverageText: 'Consulta la Evidencia de Cobertura de tu plan para obtener información detallada sobre los beneficios.',
        medicalAppointmentText: 'Llama a los siguientes números para programar el transporte para tus citas médicas:',
        prefixBasedMedicalAppointmentText: 'Llame al siguiente número para programar el transporte para citas médicas:',
        countiesText: 'Condados de servicio: ',
        reservationsText: 'Reservas: ',
        rideAssistText: 'Asistencia con el transporte: ',
        ttyText: 'TTY: ',
        close: 'Cerrar',
        print: 'Imprimir',
        tryAgain: 'Intentar otra vez',
        goBack: 'Volver',
        ariaClose: 'Cerrar botón, haz clic aquí para cerrar la ventana modal del transporte',
        ariaPrint: 'Imprimir, haz clic aquí para imprimir los detalles del transporte',
        contactUsSecure:
          'Hay beneficios de transporte adicionales disponibles para usted a través de Medi-Cal. Para obtener más información, comuníquese con <a target="_blank" href="/member/contact-us">Servicios para Miembros.</a>',
        contactUsPublic:
          'Hay beneficios de transporte adicionales disponibles para usted a través de Medi-Cal. Para obtener más información, comuníquese con <a target="_blank" href="/contact-us">Servicios para Miembros.</a>'
      }
    },
    MD_MY_NEXUS_SECURE: {
      title: 'Servicios de cuidado médico en el hogar',
      content: {
        body: '<span id="carelon-label-text" class="pf-pre-wrap pf-inline-span"><span id="carelon-label-text" aria-label="Nos asociamos con las Soluciones Post Agudas de Carelon para ayudar a manejar los servicios de cuidado médico en el hogar.">Nos asociamos con las Soluciones Post Agudas de Carelon para ayudar a manejar los servicios de cuidado médico en el hogar. </span><span id="carelon-care-link-text" aria-label="Obtén más información visitando su sitio web o llamando al 844-411-9622.">Obtén más información <a target="_blank" aria-label=" visitando su sitio web" id="carelon-care-link-text-anchor" href="https://www.careloninsights.com/medical-benefits-management/post-acute-care">visitando su sitio web</a> o llamando al 844-411-9622. </span><span id="carelon-provider-link-text" aria-label="Encontrar servicios de cuidado médico en el hogar."><a target="_blank" aria-label=" Encontrar servicios de cuidado médico en el hogar" id="carelon-provider-link-text-anchor" href="https://providers.carelonmedicalbenefitsmanagement.com/postacute/finder/">Encontrar servicios de cuidado médico en el hogar</a>.</span></span>',
        Ok: 'De acuerdo',
        ariaLabel: 'OK botón, haz clic aquí para cerrar la ventana modal del carelon'
      }
    },
    MD_MY_NEXUS_PUBLIC: {
      title: 'Lo sentimos, no podemos completar tu búsqueda',
      content: {
        body: '<span>Si necesitas asistencia inmediata para encontrar Cuidado médico a domicilio, llama al número de Servicio a miembros que figura en tu tarjeta de identificación de miembro.</span>',
        Ok: 'De acuerdo',
        ariaLabel: 'OK botón, haz clic aquí para cerrar la ventana modal del carelon'
      }
    }
  },
  sidePanels: {
    MD_MEDICARE_SUPPLEMENT: {
      title: 'Información importante para clientes de seguro complementario de Medicare',
      content: {
        closeBtnText: 'botón, haga clic aquí para cerrar el modal',
        redirectBtnText: 'botón, haga clic aquí para redirigir a',
        description:
          'Para encontrar proveedores que aceptan la asignación de Medicare (aquellos proveedores que aceptan el monto aprobado por Medicare como pago total), deberás visitar Medicare.gov y hacer clic en la herramienta Physician Compare (Comparar médicos).',
        btnCancel: 'Cancelar',
        btnContinue: 'Continuar',
        redirect: 'Buscador de proveedores Medicare',
        url: 'https://es.medicare.gov/care-compare/',
        disclaimer1:
          'Al seleccionar el botón Continuar a continuación, saldrá de este sitio y se vinculará a un sitio creado y/o mantenido por otra entidad y reconoce que no controlamos, garantizamos, respaldamos o aprobamos la información, los productos o servicios disponibles en el Sitio externo o la seguridad de las transmisiones entre usted y el Sitio externo.',
        disclaimer2:
          'Una vez vinculado, estará sujeto a los términos de uso, privacidad, derechos de autor y políticas de seguridad del Sitio externo. Proporcionamos estos enlaces únicamente para su información y conveniencia. Miembros: puede que su plan de salud no cubra todos los servicios o productos descritos en el Sitio externo. Consulte su folleto de beneficios. La información contenida en el Sitio externo no debe interpretarse como un consejo o tratamiento médico.'
      }
    },
    MD_LEAVING_SITE: {
      title: 'Estás saliendo de {BRAND}.com',
      content: {
        closeBtnText: 'Volver a {BRAND}.com',
        continueBtnText: 'Continuar',
        disclaimer1:
          'Al seleccionar el botón Continuar a continuación, saldrá de este sitio y se vinculará a un sitio creado y/o mantenido por otra entidad y reconoce que no controlamos, garantizamos, respaldamos o aprobamos la información, los productos o servicios disponibles en el Sitio externo o la seguridad de las transmisiones entre usted y el Sitio externo.',
        disclaimer2:
          'Una vez vinculado, estará sujeto a los términos de uso, privacidad, derechos de autor y políticas de seguridad del Sitio externo. Proporcionamos estos enlaces únicamente para su información y conveniencia. Miembros: puede que su plan de salud no cubra todos los servicios o productos descritos en el Sitio externo. Consulte su folleto de beneficios. La información contenida en el Sitio externo no debe interpretarse como un consejo o tratamiento médico.',
        btnClose: 'Regresar a {BRAND}.com',
        btnContinue: 'Continuar',
        externalSite: 'botón, seleccione para navegar al sitio externo',
        closeModal: 'botón, seleccione para cerrar el modal'
      }
    },
    MD_LEAVING_SITE_VEP: {
      title: '¿Buscas un médico o un hospital en la red EPO de Anthem Alliance?',
      content: {
        discalimer1: 'Haz clic en el botón de proveedores médicos a continuación para buscar proveedores médicos.',
        disclaimer2: 'Haz clic en el botón de proveedores de salud mental a continuación para buscar proveedores de salud mental.',
        disclaimer3:
          'Para los servicios de salud conductual, cuentas con el respaldo de la red de Salud Conductual Administrada por Mercy (MMBH, por sus siglas en inglés). Al seleccionar "salud conductual", estarás saliendo del sitio de Anthem y dirigiéndote al sitio web de MMBH y reconoces que Anthem no controla, garantiza o aprueba la información disponible en el sitio externo ni la seguridad o la transmisión entre tú y el sitio externo.',
        closeBtnText: 'Proveedores médicos',
        continueBtnText: 'Proveedores de salud conductual'
      }
    },
    OON_INFORMATION: {
      titles: {
        OONTitle: 'Proveedores de atención fuera de la red',
        info2Title: '¿Qué significa "fuera de la red"?',
        info3Title: '¿Es más costoso consultar a un proveedor de atención fuera de la red de mi plan?',
        info4Title: '¿Qué significa "dentro de la red"?'
      },
      content: {
        info1:
          'Excepto cuando la ley aplicable exija lo contrario, no todos los planes brindan cobertura para servicios brindados por proveedores fuera de la red. Cuando un plan brinda cobertura, a menudo es limitada y puede conllevar costos más altos para usted. Saber si un proveedor está fuera de la red puede ayudarle a tomar decisiones informadas sobre su atención.',
        info2:
          'Fuera de la red se refiere a proveedores de atención (médicos, hospitales, etc.) que no están en la red de su plan. Estos proveedores no tienen un contrato para estar en la red que respalda su plan. Es posible que te cobren más porque no tienen una tarifa acordada con tu plan. Cuando utiliza proveedores fuera de la red, normalmente debe presentar sus propios reclamos y solicitar aprobaciones previas.',
        info3:
          'Sí, normalmente pagará más si acude a un proveedor fuera de la red. Si su plan tiene cobertura fuera de la red, su plan pagará una parte del costo del servicio. Tendrá que pagar su parte y cualquier cargo adicional. Si su plan no cubre la atención fuera de la red, es posible que deba pagar el 100 % de los costos de la atención que no sea de emergencia.',
        info4:
          'Dentro de la red se refiere a los proveedores de atención que forman parte de la red de su plan.  Estos proveedores tienen contrato con su plan. Acuerdan brindar servicios a los miembros del plan a una tarifa fija. Gracias a este acuerdo, usted paga menos cuando recibe servicios de un proveedor dentro de la red. Los proveedores dentro de la red también presentan sus reclamos por usted y lo ayudan a solicitar aprobaciones previas.',
        info5: 'Si un proveedor de atención no aparece en Find Care como "dentro de la red", debe asumir que el proveedor no está en la red de su plan.'
      },
      close: 'Cerrar'
    }
  }
};
