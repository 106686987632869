import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pharmacyHours'
})
export class pharmacyHoursPipe implements PipeTransform {
  transform(officeHours: string[]): string[] {
    const daysPattern = /^(Mon|Tue|Wed|Thur|Fri|Sat|Sun)\s+/;
    const allDays = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];
    const officeHoursMap = new Map<string, string>();

    officeHours.forEach((hour) => {
      const day = hour.match(daysPattern)?.[1];
      if (day) {
        const hours = hour.replace(daysPattern, '').replace(' TO ', ' - ');
        if (hours === '00:00 AM - 00:00 AM') {
          officeHoursMap.set(day, 'Not Open');
        } else {
          officeHoursMap.set(day, hours);
        }
      }
    });

    return allDays.map((day) => officeHoursMap.get(day) || 'Not Open');
  }
}
