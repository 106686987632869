import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IAffiliationOption } from '../../../../common/components/control-list/interfaces/iAffiliationOption';
import { ControlType } from '../../../../common/enums/controlType';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppConfig } from '../../../../common/values/appConfig';
import { AppSession } from '../../../../common/values/appSession';
import { IRulesResponse } from '../../../../fad/rules/interfaces/iRulesResponse';
import { IProviderSearchDdlValues } from '../../../../fad/search-providers/interfaces/iProviderSearchDdlValues';
import { IPillID } from '../../../../fad/search-providers/interfaces/iProviderType';
import { ISearchParameters } from '../../../../fad/search-providers/interfaces/iSearchParameters';
import { IGroupAffiliations } from '../../../../fad/search-results/interfaces/iGroupAffiliations';
import { ProviderSummaryService } from '../../../../fad/search-results/services/providerSummarySvc';
import { SearchResultsFilterService } from '../../../../fad/search-results/services/searchResultsfilterSvc';
import { FILTER, HEALTH_SYSTEM_ID, PILL_ID } from '../../../../fad/search-results/values/providerSearchConstants';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { ResultsUtil } from '../../../../fad/utilities/resultsUtil';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IOption } from '../../../common/interfaces/iOption';
import { IAffiliationData, IPillActionValue } from '../../interfaces/iAffiliations';
import { IBaseCode } from '../../interfaces/iProvider';
import { IAppliedSearchFilter } from '../../models/iAppliedSearchFilter';
import { AppliedSearchFilter } from '../../services/appliedSearchFilter';
import { cloneDeep } from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'app-fc-affiliation-searchbar-cmp',
  templateUrl: './affiliationSearchCmp.html'
})
export class AffiliationSearchComponent extends BaseComponent implements OnInit, OnDestroy {
  showGroupAffLoader: boolean = false;
  hospitalAffiliationList: IProviderSearchDdlValues;
  ctrlType = ControlType;
  groupAffiliationList: IProviderSearchDdlValues;
  appConfig: AppConfig;
  hospitalSearch: string = '';
  showHospitalAffLoader: boolean = false;
  searchCriteria: ISearchParameters;
  noPreference: string;
  pillID = PILL_ID;
  hasHealthSystem: boolean = false;
  isHealthSystemFormatDone: boolean = false;
  medicalGrpAffiliationList: IProviderSearchDdlValues;
  memberMedicalGrpAffltn?: string;
  isMedicalGrpAffiltnSearch = false;
  bindFilter: boolean = true;
  showCptFilters = false;
  @Output()
  selectedGroupFilter: EventEmitter<IAffiliationData> = new EventEmitter<IAffiliationData>();
  @Output()
  selectedHospitalFilter: EventEmitter<IAffiliationData> = new EventEmitter<IAffiliationData>();
  isGroupAffiliationSearch = false;
  feature: IRulesResponse;
  filter = FILTER;

  medicalGroupSearch: string = '';
  searchAffiliation: Subscription;
  groupAffiliations: IGroupAffiliations;
  groupAffiliationExpand: boolean = false;
  hospitalAffiliationExpand: boolean = false;

  @Input() itemChange: string;
  @Input() category: string;
  selectedGroupFilterData: string = '';
  selectedHospitalAffiliationData: string = '';
  appliedFilters: IAppliedSearchFilter[] = [];
  options: Array<IBaseCode> = [];

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _appSession: AppSession,
    public _searchResultsFilterService: SearchResultsFilterService,
    private _providerSummaryService: ProviderSummaryService,
    public _appliedSearchFilter: AppliedSearchFilter
  ) {
    super(_route, _eventHandler, _appSession);
    this.searchAffiliation = this._searchResultsFilterService.searchInput.subscribe((data) => {
      if (data) {
        this.loadAffiliations(data);
      }
    });
  }

  ngOnInit(): void {
    this.feature = this._appSession.feature;

    this.hasHealthSystem = this._appSession?.hasHealthSystem;
    this.showCptFilters = CommonUtil.isCptSearch(this._appSession);

    if (this._appSession && this._appSession.searchParams) {
      this._appSession.searchParams.includePrimeGroupSearch = true;
      this._appSession.searchParams.includePrimeGroupSearch = true;
      this.searchCriteria = cloneDeep(this._appSession.searchParams);
      if (typeof this._appSession.filterSearchParams === 'undefined') {
        this._appSession.filterSearchParams = cloneDeep(this._appSession.searchParams);
      }
      this.feature = this._appSession.feature;
    }
  }

  ngOnDestroy() {
    if (this.searchAffiliation) {
      this.searchAffiliation.unsubscribe();
    }
  }

  loadAffiliations(pillAction: IPillActionValue) {
    this.showHospitalAffLoader = false;
    this.showGroupAffLoader = false;
    switch (pillAction.pill.label) {
      case this.pillID.hospitalaffiliation.label:
        if (pillAction.value.length > 2) {
          this.showHospitalAffLoader = true;
          this._appSession.hospSearchTerm = pillAction.value;
          this._appSession.isHospAffSearch = true;
          this.pouplateHospitalAffiliation();
        }
        break;
      case this.pillID.groupaffiliation.label:
        if (pillAction.value.length > 2) {
          this.showGroupAffLoader = true;
          this._appSession.isMedicalAffSearch = true;
          this._appSession.medicalGrpSearchTerm = pillAction.value;
          this.pouplateGroupAffiliation();
        }
        break;
      default:
        break;
    }
  }

  validateSearchTerm(pill: IPillID, value: string): void {
    value = value.trim().toLocaleLowerCase();
    const pillValue = { pill, value };
    if (value.length > 2) {
      this.showGroupAffLoader = true;
      this._searchResultsFilterService.setSearchInput(pillValue);
    } else {
      if (pill.label === this.pillID.hospitalaffiliation.label) {
        this.hospitalAffiliationList = {} as IProviderSearchDdlValues;
      } else {
        // bring back prime group details to fiter if user clear the search keyword in filter
        if (this.groupAffiliations && (this.groupAffiliations.affiliationList || []).length > 0) {
          this.onGroupAffiliationSuccess(this.groupAffiliations);
          this._appSession.isMedicalAffSearch = false;
        } else {
          this.groupAffiliationList = {} as IProviderSearchDdlValues;
        }
      }
    }
  }

  getHospitalAffiliation() {
    let searchRequest = ResultsUtil.buildProviderRequest(this._appSession, '1', this.appConfig?.paginationSize.hospitalAffiliationPage, 'H');
    searchRequest = this.removeSpecialty(searchRequest);
    this._providerSummaryService.getAffiliationOptions(searchRequest).then(
      (result) => {
        if (this.hospitalSearch) {
          this.onHospitalAffiliationSuccess(result);
        }
        this.showHospitalAffLoader = false;
      },
      (error) => {
        this.onError('HospitalAffiliation', error);
        this.showHospitalAffLoader = false;
      }
    );
  }

  onHospitalAffiliationSuccess(data) {
    if (data) {
      this.hospitalAffiliationList = CommonUtil.buildDropDownValues(data.affiliationList, 'rb_result_filter_hospitalaffiliation', this.noPreference, '00', 'provider', false);
      this._appSession.isHospAffSearch = false;

      if (this.searchCriteria.hospitalAffiliationSelectNm && this.searchCriteria.hospitalAffiliationSelectNm !== '00') {
        this.onHospitalAffiliationChange(this.searchCriteria.hospitalAffiliationSelectNm);
      } else {
        this.onHospitalAffiliationChange('00');
      }
    }
  }

  removeSpecialty(searchRequest) {
    if (searchRequest.searchCriteria) {
      delete searchRequest.searchCriteria.specialtyCategoryList;
      delete searchRequest.searchCriteria.subSpecialtyList;
      delete searchRequest.searchCriteria.taxonomyList;
      delete searchRequest.searchCriteria.ableToServeAsPCP;
    }
    return searchRequest;
  }

  onError(type: string, error) {
    throw error;
  }

  onHospitalAffiliationChange($event) {
    this.options = [];
    this.selectedHospitalAffiliationData = '';
    let hospitalOptionList = [];
    this.groupAffiliationList = {} as IProviderSearchDdlValues;

    this.hospitalAffiliationList.controlListData.forEach((data) => {
      if (Object.values(data).includes($event)) {
        this.selectedHospitalAffiliationData = data.providerName;
        this.options = [{ code: this.selectedHospitalAffiliationData, name: this.selectedHospitalAffiliationData }];
        this.selectedHospitalFilter.emit(data);
      }
      this._appliedSearchFilter?.getAppliedFilters?.subscribe((dataList) => {
        hospitalOptionList = dataList.filter((item) => item.category === 'hospitalAffiliation').map((item) => item.name);

        if (hospitalOptionList.length > 1) {
          this._appliedSearchFilter?.removeFilterOption(this.category, this.getOptionObject(hospitalOptionList[0], hospitalOptionList[0], false));
        }
      });
      if ($event !== '' && this.selectedHospitalAffiliationData) {
        this._appliedSearchFilter.addFilterOption(this.category, this.getOptionObject(this.category, this.selectedHospitalAffiliationData, false));
      } else {
        this._appliedSearchFilter.removeFilterOption(this.category, this.getOptionObject(this.category, this.selectedHospitalAffiliationData, false));
      }
    });
  }

  onGroupAffiliationSuccess(groupAffiliations: IGroupAffiliations): void {
    if (groupAffiliations) {
      if (this._appSession.isMedicalAffSearch === true) {
        this.groupAffiliationList = CommonUtil.buildDropDownValues(groupAffiliations.affiliationList, 'cb_result_filter_groupaffiliation_', this.noPreference, '00', 'provider');
      } else {
        const affiliationList = this.hasHealthSystem && !this.isHealthSystemFormatDone ? this.getHealthSystemProviderName(groupAffiliations.affiliationList) : groupAffiliations.affiliationList;
        this.medicalGrpAffiliationList = CommonUtil.buildDropDownValues(affiliationList, 'cb_result_filter_membermedicalGrp_', this.noPreference, '00', 'provider');
      }

      if (this.memberMedicalGrpAffltn && this.memberMedicalGrpAffltn !== '00') {
        this.onMemberMedicalGrpChange(this.memberMedicalGrpAffltn, false);
      } else {
        this.onMemberMedicalGrpChange('00', false);
      }

      if (this.searchCriteria.groupAffiliationSelectNm && this.searchCriteria.groupAffiliationSelectNm !== '00') {
        this.memberMedicalGrpAffltn = '00';
      } else if (this.memberMedicalGrpAffltn && this.memberMedicalGrpAffltn !== '00') {
        this.searchCriteria.groupAffiliationSelectNm = '00';
      }
    }
  }

  getHealthSystemProviderName(affiliationList: Array<IAffiliationOption>): Array<IAffiliationOption> {
    affiliationList.forEach((prov) => {
      if (prov?.extendedProviderInfo?.isEligibleForHealthSystem && prov?.providerName) {
        prov.providerName =
          prov.providerName.indexOf(this.content?.result?.summaryComponent?.filter?.healthSystem) === -1
            ? this.content?.result?.summaryComponent?.filter?.healthSystem + prov.providerName.toUpperCase()
            : prov.providerName;
      } else {
        prov.providerName =
          prov.providerName.indexOf(this.content?.result?.summaryComponent?.filter?.medicalGroup) === -1
            ? this.content?.result?.summaryComponent?.filter?.medicalGroup + prov.providerName
            : prov.providerName;
      }
    });
    this.isHealthSystemFormatDone = true;
    return affiliationList;
  }

  onMemberMedicalGrpChange($event, applyFilter: boolean = true, itemChanged: boolean = false): void {
    if ($event !== '' && this.memberMedicalGrpAffltn !== $event) {
      if (applyFilter && itemChanged) {
        this.isMedicalGrpAffiltnSearch = true;
        this.isGroupAffiliationSearch = false;
        this.searchCriteria.groupAffiliationSelectNm = '00';
        this.memberMedicalGrpAffltn = $event;
        this._appSession.isMedicalAffSearch = false;
      }
      if (applyFilter && itemChanged && $event === '00') {
        this.searchCriteria.includePrimeGroupSearch = false;
        this.searchCriteria.includeHealthSystemSearch = false;
      } else if (applyFilter && itemChanged && $event === HEALTH_SYSTEM_ID) {
        this.searchCriteria.includeHealthSystemSearch = true;
      } else {
        this.searchCriteria.includePrimeGroupSearch = true;
        this.searchCriteria.includeHealthSystemSearch = false;
      }
    }
  }

  onGroupAffiliationChange($event): void {
    this.options = [];
    this.groupAffiliationList?.controlListData.forEach((data) => {
      if (Object.values(data).includes($event)) {
        this.selectedGroupFilterData = data.providerName;
        this.options = [{ code: this.selectedGroupFilterData, name: this.selectedGroupFilterData }];
        this.selectedGroupFilter.emit(data);
      }
      let medicalOptionList = [];
      this._appliedSearchFilter?.getAppliedFilters?.subscribe((dataList) => {
        medicalOptionList = dataList.filter((item) => item.category === 'medicalGroup').map((item) => item.name);

        if (medicalOptionList.length > 1) {
          this._appliedSearchFilter?.removeFilterOption(this.category, this.getOptionObject(medicalOptionList[0], medicalOptionList[0], false));
        }
      });
      if ($event !== '') {
        this._appliedSearchFilter.addFilterOption(this.category, this.getOptionObject(this.category, this.selectedGroupFilterData, false));
      } else {
        this._appliedSearchFilter.removeFilterOption(this.category, this.getOptionObject(this.category, this.selectedGroupFilterData, false));
      }
    });
  }

  pouplateHospitalAffiliation() {
    if (this.showHospitalAffLoader && this.feature && this.feature.showAffialition && this._appSession && this._appSession.searchParams) {
      this.getHospitalAffiliation();
    }
  }

  pouplateGroupAffiliation() {
    if (this.showGroupAffLoader && this.feature?.showMedicalGroupAffiliation && this._appSession && this._appSession.searchParams) {
      this.getGroupAffiliation();
    }
  }

  getGroupAffiliation() {
    let searchRequest = ResultsUtil.buildProviderRequest(this._appSession, '1', this.appConfig?.paginationSize.groupAffiliationPage, 'G');
    searchRequest = this.removeSpecialty(searchRequest);
    this._providerSummaryService.getAffiliationOptions(searchRequest).then(
      (result) => {
        if (this.medicalGroupSearch) {
          this.onGroupAffiliationSuccess(result);
          this._appSession.isMedicalAffSearch = false;
        }
        this.showGroupAffLoader = false;
      },
      (error: string) => {
        this.onError('GroupAffiliation', error);
        this.showGroupAffLoader = false;
      }
    );
  }

  getOptionObject(value: string, name: string, isChecked: boolean = false): IOption {
    return {
      label: name,
      value: value,
      id: `input-${this.category?.toLowerCase()}-${value?.toLowerCase()}`,
      name: `input-${this.category?.toLowerCase()}-${value?.toLowerCase()}`,
      trueValue: value,
      falseValue: '',
      isChecked: isChecked
    } as IOption;
  }
}
