import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AntTabGroupComponent } from '../../../../../../packages-dist/uxd/tabs';
import { MemberPCPHelper } from '../../../../care-circle/services/memberPCPHelper';
import { AnpSidePanelComponent } from '../../../../common/components/common-alert/anpSidePanelCmp';
import { SlideoutMessageComponent } from '../../../../common/components/common-alert/slideoutMsgCmp';
import { CaHmoPanelComponent } from '../../../../common/components/pcp/caHmoPanelCmp';
import { AssignPcpComponent } from '../../../../common/components/pcp/pfAssignPcpCmp';
import { ANP_ERROR_CODE, CA_HMO_DISCLAIMER, DOCTOR_NAME, MEMBER_NAME, TERMINATION_DATE } from '../../../../common/constants/app-constants';
import { IBaseCode } from '../../../../common/interfaces/iBaseCode';
import { IFinalProvider } from '../../../../common/interfaces/iCommonSearchResponse';
import { IMessage } from '../../../../common/interfaces/iMessage';
import { ISlideoutMessage } from '../../../../common/interfaces/iSlideoutMsg';
import { DataHelper } from '../../../../common/services/dataHelper';
import { EventHandler } from '../../../../common/services/eventHandler';
import { NavigationService } from '../../../../common/services/navigationService';
import { AppSession } from '../../../../common/values/appSession';
import { IPcpNavResponse } from '../../../../fad/assign-pcp/models/iMemberPcpDetails';
import { AssignPcpNavigationService } from '../../../../fad/assign-pcp/services/assignPcpNavigationSvc';
import { ISpecialty } from '../../../../fad/provider-details/models/iDetailsResponse';
import { ProviderProcedureInfo } from '../../../../fad/provider-details/models/iProcedureCostEst';
import { IProviderProcedureInfo } from '../../../../fad/provider-details/models/iProviderProcedureInfo';
import { ISelectedProviderProcedures } from '../../../../fad/provider-details/models/iSelectedProviderProcedures';
import { ProviderOrchestrationService } from '../../../../fad/provider-details/services/providerOrchestrationSvc';
import { PROVIDER_TYPE } from '../../../../fad/search-providers/values/providerSearchConstants';
import { ProviderAffiliationHandler } from '../../classes/providerAffiliationHandler';
import { ProviderLocationHandler } from '../../classes/providerLocationHandler';
import { ProviderPlanHandler } from '../../classes/providerPlanHandler';
import { ProviderSpecialityHandler } from '../../classes/providerSpecialityHandler';
import { ProviderCard, ProviderCardNavigation, ProviderTabs } from '../../constants/result';
import { IProvider } from '../../interfaces/iProvider';
import { IAffiliationProvider } from '../../interfaces/iProviderAffiliation';
import { IDetail, IProviderOverview } from '../../interfaces/iProviderDetail';
import { IProviderLocationAddress, IProviderLocationsResponse } from '../../interfaces/iProviderLocation';
import { IProviderPlan } from '../../interfaces/iProviderPlan';
import { ISpecialityResponse } from '../../interfaces/iProviderSpeciality';
import { ProviderDetail } from '../../services/providerDetail';
import { ICostInfo } from './../../../../fad/search-results/interfaces/iSummaryResp';
import { BaseComponent } from './../../../common/components/core/baseCmp';
import { PrintProviderCardComponent } from './printProviderCard/printProviderCardCmp';
@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-card-details-cmp',
  templateUrl: './providerCardDetailsCmp.html'
})
export class ProviderCardDetailsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() provider: IProvider;
  @Output() closeProviderCard: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('assignPcpModal')
  assignPcpModal: AssignPcpComponent;
  @ViewChild('slideoutMsgCmp')
  slideoutMsgCmp: SlideoutMessageComponent;
  @ViewChild('anpSidePanelCmp')
  anpSidePanelCmp: AnpSidePanelComponent;
  @ViewChild('caHmoPanelCmp') caHmoPanelCmp: CaHmoPanelComponent;
  @Output() cardNavigation: EventEmitter<ProviderCardNavigation> = new EventEmitter<ProviderCardNavigation>();
  providerCardDetailsContent = this.content?.result?.providerCardDetails;
  @Output()
  addCareAlertMsg: EventEmitter<IMessage[]> = new EventEmitter<IMessage[]>();
  showPCPList: boolean = false;
  showSpecialityCard: boolean = false;
  showProcedureCard: boolean = false;
  showRecognitionCard: boolean = false;
  showInsuranceCard: boolean = false;
  showServicesCard: boolean = false;
  showAffiliationCard: boolean = false;
  showLicenseCard: boolean = false;
  showPharmacyCard: boolean = false;
  providerTabs = ProviderTabs;
  activeTab: string = this.providerTabs.OVERVIEW;
  providerDetails: IDetail;
  @Input() showProviderLoader: boolean;
  private _providerDetailSubscription: Subscription;
  insurancePlans: IProviderPlan[];
  affiliations: Map<string, IAffiliationProvider[]>;
  showAffiliateFilter: boolean = false;
  showPriceTab: boolean;
  specialityDetails: ISpecialityResponse;
  procedureDetails: ProviderProcedureInfo;
  selectedProviderProcedure: ISelectedProviderProcedures;
  costInfo = {} as ICostInfo;
  showProcedureEstimate: boolean = false;
  selectedIndex: number = 0;
  @ViewChild('antTabs', { static: false }) antTabsComponent: AntTabGroupComponent;
  @Input() showSearchResultsFilter: boolean;
  isProcedureDetailsVisible = false;
  isVirtualProvider: boolean = false;
  @ViewChild(PrintProviderCardComponent, { static: false }) printProviderCardCmp: PrintProviderCardComponent;
  locations: IProviderLocationsResponse;
  officeServices: Array<IBaseCode>;
  visionServices: Array<IBaseCode>;
  @Input() showLocationLink: boolean;
  @Output()
  locationsEmit: EventEmitter<IProviderLocationAddress[]> = new EventEmitter<IProviderLocationAddress[]>();
  @Input() isLocationFlow: boolean;
  isMoreDetails: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _providerDetail: ProviderDetail,
    @Inject(DataHelper) private _dataHelper: DataHelper,
    private _navigationService: NavigationService,
    private _assignPcpNavSvc: AssignPcpNavigationService,
    private _titleCasePipe: TitleCasePipe,
    private _memberPCPHelper: MemberPCPHelper,
    private _providerAffiliation: ProviderAffiliationHandler,
    private _providerPlan: ProviderPlanHandler,
    private _providerSpeciality: ProviderSpecialityHandler,
    private _providerLocations: ProviderLocationHandler,
    private _providerOrchestrationService: ProviderOrchestrationService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    this.showProviderLoader = true;
    this._providerDetailSubscription = this._providerDetail.details.subscribe(
      (providerDetail: IProviderOverview) => {
        this.resetCardVisibility();
        this.providerDetails = providerDetail?.providerDetail;
        if (this.provider != null) {
          if (this.provider?.providerTypeCodeList[0] === PROVIDER_TYPE.PHARMACY) {
            this.showPharmacyCard = true;
          } else {
            if (providerDetail?.providerDetail) {
              this.showProviderLoader = false;
              this.loadProviderPlans();
              this.loadProviderAffiliations();
              this.isVirtualProvider = this.providerDetails.isVirtualProvider;
              if (this.isLocationFlow === false) {
                this.loadProviderLocations();
              }
            }
            if (this.providerDetails?.visibilityRule) {
              this.showPriceTab = this.providerDetails.visibilityRule?.showPricingTab;
            }
            if (this.isEycEligible()) {
              this.costInfo = this._appSession.selectedProvider?.eycCostInfo || {};
              if (this._appSession && this._appSession.searchParams && this._appSession.searchParams.eycSearchParam) {
                if (this._appSession.searchParams.eycSearchParam && this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName) {
                  this.costInfo.procedureName = this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName;
                }
                if (this._appSession.searchParams.eycSearchParam.costDescription) {
                  this.costInfo.procedureDefintion = this._appSession.searchParams.eycSearchParam.costDescription;
                }

                if (this._appSession.searchParams.eycSearchParam && this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureCode) {
                  this.costInfo.procedureCode = this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureCode;
                }
              }
            }
            if (this.isMemberEligibleForCptLookUp) {
              this.showProcedureEstimate = true;
              this.showPriceTab = this.providerDetails?.visibilityRule?.showPricingTab;
            }

            // TODO remove this after apex team adds location apiNameList
            if (this.providerDetails?.providerIdentifier && this.isEycEligible()) {
              this.getProviderProcedures();
            }
          }
        }
      },
      () => {
        this.showProviderLoader = false;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['provider']) {
      this.selectedIndex = 0;
      this.showProviderLoader = true;
      this.showProcedureCard = false;
      this.isProcedureDetailsVisible = false;
    }

    if (changes['selectedIndex']) {
      this.selectedIndex = changes['selectedIndex'].currentValue;
      this.antTabsComponent.selectedIndex = this.selectedIndex;
    }

    if (changes['isLocationFlow']) {
      if (changes['isLocationFlow'].currentValue === false) {
        this.showLocationLink = true;
      }
    }
  }

  isEycEligible() {
    if (this.isSecureState && this._appSession?.selectedProvider?.eycCostInfo && !this._appSession?.selectedProvider?.isRecordFromSPFI) {
      return true;
    }
    return false;
  }

  resetCardVisibility() {
    this.showPCPList = false;
    this.showRecognitionCard = false;
    this.showServicesCard = false;
    this.showInsuranceCard = false;
    this.showSpecialityCard = false;
    this.showProcedureCard = false;
    this.showAffiliationCard = false;
    this.showLicenseCard = false;
    this.showPharmacyCard = false;
    this.isProcedureDetailsVisible = false;
  }

  /**
   * Provider Details Card Close Event.
   * Emits boolean value true for the custom event 'closeProviderCard'.
   */
  onCloseProviderCard(status: boolean) {
    this.closeProviderCard.emit(status);
  }

  ngOnDestroy() {
    if (this._providerDetailSubscription) {
      this._providerDetailSubscription.unsubscribe();
    }
  }

  selectedCard(card: ProviderCard) {
    this.resetCardVisibility();
    switch (card) {
      case ProviderCard.SPECIALITY:
        this.showSpecialityCard = true;
        break;
      case ProviderCard.PROCEDURE:
        this.showProcedureCard = true;
        break;
      case ProviderCard.INSURANCE:
        this.showInsuranceCard = true;
        break;
      case ProviderCard.RECOGNITION:
        this.showRecognitionCard = true;
        break;
      case ProviderCard.SERVICE:
        this.showServicesCard = true;
        break;
      case ProviderCard.PCP:
        this.showPCPList = true;
        break;
      case ProviderCard.AFFILIATION:
        this.showAffiliationCard = true;
        break;
      case ProviderCard.LICENSE:
        this.showLicenseCard = true;
        break;
      case ProviderCard.MORE_DETAILS:
        this.selectedIndex = 1;
        break;
      case ProviderCard.PHARMACY:
        this.showPharmacyCard = true;
        break;
      case ProviderCard.COST_DETAILS:
        this.selectedIndex = 2;
        break;
      case ProviderCard.CPT:
        this.isProcedureDetailsVisible = true;
        break;
      case ProviderCard.OVERVIEW:
      default:
        break;
    }
  }

  async showSpecialtyDetails(specialty: ISpecialty) {
    if (specialty.specialtyCode) {
      const taxonomies = [specialty.specialtyCode];
      this.specialityDetails = await this._providerSpeciality.getProviderSpeciality(this.provider?.specialtyCategory, taxonomies, this.provider?.providerTypeCodeList);
    }
  }

  async showProcedureDetails(procedureInfo: ProviderProcedureInfo) {
    this.procedureDetails = procedureInfo;
  }

  /** Open Side Panel */
  onAssignPcpClick(direction: string, professional: IFinalProvider) {
    const navPromise = this._assignPcpNavSvc?.navigateToAssignPcp(professional);
    this.handleAssignPcpNavPromise(navPromise, professional, direction);
  }

  /** Method to continue for assign PCP and navigate to assign-PCP page in CA-HMO case */
  onContinueAssignPcp() {
    const navPromise = this._assignPcpNavSvc.navigateToAssignPcp(this._appSession.selectedProvider, true);
    this.handleAssignPcpNavPromise(navPromise, this._appSession.selectedProvider, 'right');
  }

  /** Method to handle assign PCP navigation promise or open different slide panel based on scenario */
  async handleAssignPcpNavPromise(navPromise: Promise<IPcpNavResponse>, professional: any, direction: string) {
    try {
      const resp = await navPromise;
      //Open the old Assign as PCP Flow
      if (!resp?.navigatedToAssignPcpPage) {
        this.assignPcpModal.openSidePanel(direction, professional, true);
      }
    } catch (error) {
      //Handle Errors using the error.code value
      if (error.code === ANP_ERROR_CODE) {
        this.anpSidePanelCmp.openSidePanel();
      } else if (error.code === CA_HMO_DISCLAIMER) {
        this.caHmoPanelCmp.openSidePanel();
      } else {
        //Display other Error messages in UI
        const slideoutMsgContent = this.content?.result?.providerCardDetails?.assignToPcp?.slideoutMsgCmpContent;
        let message = this._dataHelper.getValueByKey<string>(error.code, slideoutMsgContent);
        let title: string = slideoutMsgContent['title_' + error.code] ?? slideoutMsgContent.title;
        const mbrName = this._titleCasePipe.transform(this._appSession.getMemberName());
        message = message.replace(DOCTOR_NAME, professional?.providerName);
        message = message.replace(TERMINATION_DATE, error.terminationDt ?? '');
        message = message.replace(MEMBER_NAME, mbrName);
        const slideMessage: ISlideoutMessage = {
          title,
          close: slideoutMsgContent.close,
          message
        };
        this.slideoutMsgCmp.showMessage(slideMessage);
      }
      throw error;
    }
  }

  onCardNavigation(flowType: ProviderCardNavigation) {
    this.cardNavigation.emit(flowType);
  }

  private loadProviderPlans(): void {
    const provider = this.providerDetails;
    this._providerPlan.getProviderPlans(provider.providerIdentifier, provider?.address?.addressId, provider?.address?.state).then((result: IProviderPlan[]) => {
      this.insurancePlans = result;
    });
  }

  private loadProviderAffiliations(): void {
    const provider = this.providerDetails;
    this._providerAffiliation
      .getProviderAffiliation(provider?.providerIdentifier, provider?.address?.addressId, provider?.providerTypeCodeList)
      ?.then((result: Map<string, IAffiliationProvider[]>) => {
        this.affiliations = result;
      });
  }

  private loadProviderLocations(): void {
    this._providerLocations.getProviderLocations(this.providerDetails)?.then((result: IProviderLocationsResponse) => {
      this.locations = result;
      this.officeServices = result.locations[0].officeService;
      this.visionServices = result.locations[0].visionService;
      this.showLocationLink = this.locations?.locations?.length > 1;
      this.emitLocations(result.locations);
    });
  }

  emitLocations(locations: IProviderLocationAddress[]): void {
    this.locationsEmit.emit(locations);
  }

  private async getProviderProcedures() {
    const selectedContract = this._appSession.appState?.selectedEligibilityProduct?.selectedContract;
    const providerProcedureRequest: IProviderProcedureInfo = {
      providerIdentifier: this.providerDetails?.providerIdentifier || '',
      addressIdentifier: this.providerDetails?.address.addressId || '',
      isFuturePlan: this._appSession.isFutureCoverage,
      locale: String(this._appSession?.metaData?.locale) || '',
      pdtKey: this._appSession?.selectedProvider?.pdtKey,
      isDentalEyc: this._appSession.hasDentalEYC && this._appSession.searchParams?.eycSearchParam?.eycProcedureCriteria?.providerCategoryCode === PROVIDER_TYPE.DENTAL,
      contractUid: selectedContract?.contractUid
    };
    await this._providerOrchestrationService.getProviderProcedureDetails(providerProcedureRequest).then(
      (result: any) => {
        if (
          this.costInfo &&
          'procedureCode' in this.costInfo &&
          result &&
          result.providerCosts &&
          result.providerCosts.length > 0 &&
          result.providerCosts.findIndex((procedure) => procedure.procedureCode === this.costInfo.procedureCode) !== -1
        ) {
          this.selectedProviderProcedure = result;
          this.selectedProviderProcedure.providerTier = this.providerDetails?.providerTier || '';
          this.showProcedureEstimate = true;
          this.showPriceTab = this.providerDetails?.visibilityRule?.showPricingTab;
        }
      },
      (error) => {
        this.onError(error);
      }
    );
  }

  get isMemberEligibleForCptLookUp(): boolean {
    return this.isMedicalCodeSearchEnabled && !this._appSession?.searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode;
  }

  onError(error) {
    throw error;
  }

  // Emit AddCareAlert message
  showAddCareAlert(disclaimerCodes) {
    this.addCareAlertMsg.emit(disclaimerCodes);
  }

  onPrintProviderCard() {
    this.printProviderCardCmp.onPrintCard();
  }

  /**
   * Method to show Provider Locations.
   * Showing Provider Card based on provider type.
   */

  onShowProviderLocations(): void {
    this.onCardNavigation(ProviderCardNavigation.LOCATION);
    this.showLocationLink = false;
  }

  handleIsMoreDetailsChange(value: boolean) {
    this.isMoreDetails = value;
  }

  onGetLoaderStatus(status: boolean) {
    this.showProviderLoader = status;
  }
}
