import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { environment } from '../../../../environments/environment';
import { ISecureDentalSearchFilterRequest, SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import { ISecureDentalRequest } from '../../../common/interfaces/iDentalProcedureSearchRequest';
import { IDentalProcedureResponse } from '../../../common/interfaces/iDentalProcedureSearchResponse';
import { IPlanNetworkPrefixContent } from '../../../common/interfaces/iPlanNetworkPrefixContent';
import { ISecureTccDentalEmailRequest } from '../../../common/interfaces/iSecureTccDentalEmailRequest';
import { BaseService } from '../../../common/services/baseService';
import { SecureV1TccDental } from '../../../common/services/secureV1TccDentalSvc';
import { SummaryEmailSvc } from '../../../common/services/summaryEmailSvc';
import { SummaryPrintSvc } from '../../../common/services/summaryPrintSvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';

import { API_NAME } from '../../../fad/search-results/values/providerSearchConstants';
import { SearchRequestUtil } from '../../../fad/utilities/searchRequestUtil';
import { SearchResponseUtil } from '../../../fad/utilities/searchResponseUtil';
import { IAddressSummary, IProvider, IProviderList } from '../../result/interfaces/iProvider';
import { SearchFilter } from '../../result/services/searchFilter';
import { HttpClientService } from './../../../common/services/httpClientService';

@Injectable({
  providedIn: 'root'
})
export class SecureDentalProcedureAdapterSvc extends BaseService {
  private pdfPageNumber = 1;
  private pdfPageSize = environment.paginationSize.summaryPrintPage;
  constructor(
    appSession: AppSession,
    private _secureTccDentalSvc: SecureV1TccDental,
    private _summaryPrintSvc: SummaryPrintSvc,
    private _summaryEmailSvc: SummaryEmailSvc,
    appUtility: AppUtility,
    httpClientSvc: HttpClientService,
    private _searchFilter: SearchFilter
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  async search(pageNumber: number, pageSize: number) {
    const transformedRequest = this.transformRequest(pageNumber, pageSize);
    const apiResponse = await this._secureTccDentalSvc.execute(transformedRequest);
    this._searchFilter.initialize(this.transformSearchFilterRequest(pageNumber, pageSize));
    return this.transformResponse(apiResponse);
  }

  print<T extends { summaryComponent: IPlanNetworkPrefixContent }>(content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const printRequest: ISecureDentalRequest = { ...searchRequest };
    const apiName = SearchRequestUtil.getApiName(API_NAME.TCC_DENTAL);
    return this._summaryPrintSvc.execute(printRequest, apiName);
  }

  email<T extends { summaryComponent: IPlanNetworkPrefixContent }>(email: string, content: T) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const emailRequest: ISecureTccDentalEmailRequest = { ...searchRequest, email };
    const apiName = SearchRequestUtil.getApiName(API_NAME.TCC_DENTAL);
    return this._summaryEmailSvc.execute(emailRequest, apiName);
  }

  transformSearchFilterRequest(pageNumber: number, pageSize: number): ISecureDentalSearchFilterRequest {
    const tccDentalSecureSearchReq = this.transformRequest(pageNumber, pageSize);
    const filterReq: ISecureDentalSearchFilterRequest = {
      ...tccDentalSecureSearchReq,
      scenario: SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL
    };
    return filterReq;
  }

  private transformRequest(pageNumber: number, pageSize: number): ISecureDentalRequest {
    return SearchRequestUtil.buildDentalProcedureRequest(this.appSession, pageNumber, pageSize);
  }

  private transformResponse(apiResponse: IDentalProcedureResponse) {
    let response = {} as IProviderList;
    const finalProviders: IProvider[] = [];
    if (!isEmpty(apiResponse)) {
      response.rules = apiResponse?.rules || [];
      if (apiResponse?.providers?.length) {
        (apiResponse.providers || []).forEach((provider) => {
          let finalProvider = {} as IProvider;
          if (provider) {
            finalProvider = SearchResponseUtil.getCommonProviderInformation(provider);
            finalProvider.eycCostInfo = provider.eycCostInfo;
            const { address } = provider.location;
            const { taxonomies } = provider.specialty;
            finalProvider.addressSummary = {
              phoneNumberList: [address.phone],
              taxonomies: taxonomies.map(({ code, name }) => ({ code, name })),
              addressIdentifier: address.addressId,
              addressOne: address.addressOne,
              addressTwo: address.addressTwo,
              cityName: address.city,
              stateCode: address.state,
              postalCode: address.postalCode,
              latitude: address.latitude,
              longitude: address.longitude,
              distance: address.distance.toString()
            } as IAddressSummary;
            finalProviders.push(finalProvider);
          }
        });
        response = { ...apiResponse, providers: finalProviders };
      }
    }
    response.providers = finalProviders;

    return response;
  }
}
