import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { IBaseCode } from '../../../../common/interfaces/iBaseCode';
import { IPlaceOfServices } from '../../../../common/interfaces/iCptMedicalResponse';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { IRulesResponse } from '../../../../fad/rules/interfaces/iRulesResponse';
import { ISearchParameters } from '../../../../fad/search-providers/interfaces/iSearchParameters';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IBaseOption } from '../../../common/interfaces/iOption';
import { FilterCategory, FilterValue, INDEX_FIFTY, INDEX_ZERO } from '../../constants/result';
import { IAppliedSearchFilter } from '../../models/iAppliedSearchFilter';
import { AppliedSearchFilter } from '../../services/appliedSearchFilter';
import { CptSummaryModel } from '../../services/cptSummaryModel';
import { IAffiliationOption } from '../control-list/interfaces/iAffiliationOption';
import { ISearchFilterResponse } from './../../../common/interfaces/iSearchFilter';
import { ActionService } from './../../../common/services/actionSvc';
import { SearchFilter } from './../../services/searchFilter';

@Component({
  moduleId: module.id,
  selector: 'app-fc-filter-side-panel-cmp',
  templateUrl: './filterSidePanelCmp.html'
})
export class FilterSidePanelComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('searchResultsFilterModal') searchResultsFilterModal: TemplateRef<HTMLElement>;
  selectedFilterCount: number;
  filterContent = this.content?.result?.filter;
  filterCategory = FilterCategory;
  filterOptions: ISearchFilterResponse;
  appliedFilterItems: IAppliedSearchFilter[] = [];
  selectedPosCode: string = '';
  selectedModifierCode: string = '';
  posList: IPlaceOfServices[] = [];
  posCodes: IBaseOption[] = [];
  modifierCodes: IBaseOption[] = [];
  medicalModifierOptionCode: string;
  medicalPosCode: string;
  showCptFilters = false;
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;
  distanceOptions = this.filterContent?.distanceOptions;
  searchCriteria: ISearchParameters;
  keyFilterPreSelection: string[] = [];
  private searchFilterSubscription: Subscription;
  private appliedFilter: Subscription;
  groupAffiliationFilterValue: string = '';
  hospitalAffiliationFilterValue: string = '';
  feature: IRulesResponse;

  private appliedFiltersSubscription: Subscription;
  constructor(
    @Inject(AppSession) private _appSession: AppSession,
    private _eventHandler: EventHandler,
    route: ActivatedRoute,
    public _sidePanel: SidePanel,
    private _searchFilter: SearchFilter,
    private _appliedFilter: AppliedSearchFilter,
    private _actionService: ActionService,
    private _cptSummaryModel: CptSummaryModel
  ) {
    super(route, _eventHandler, _appSession);
  }

  /**
   * Returns the text indicating the number of applied filters.
   * @returns A string indicating the number of applied filters.
   */
  get appliedFilterCountText(): string {
    return `${this.selectedFilterCount} ${this.filterContent.filterApplied}`;
  }

  /**
   * Initializes the component and subscribes to necessary observables.
   */
  ngOnInit(): void {
    this.feature = this._appSession.feature;
    this.searchFilterSubscription = this._searchFilter.filters.subscribe((filters: ISearchFilterResponse) => {
      if (filters) {
        this.filterOptions = filters;
        this.setKeyFilters(filters);
      }
    });
    this.setBillingCodeFilters();
    this.appliedFilter = this._appliedFilter.getAppliedFilters.subscribe((data) => {
      this.appliedFilterItems = data || [];
      this.selectedFilterCount = this.appliedFilterItems.length || 0;
    });
  }

  setKeyFilters(filters: ISearchFilterResponse) {
    this.filterOptions.keyFilters = [];
    this.keyFilterPreSelection = [];
    this.searchCriteria = this._appSession.searchParams;
    if (filters?.ableToServeAsPCP) {
      this.filterOptions.keyFilters.push({
        code: filters.ableToServeAsPCP.code,
        name: filters.ableToServeAsPCP.name,
        description: filters.ableToServeAsPCP.description
      } as IBaseCode);
      if (this.searchCriteria?.ableToServePcp) {
        this.keyFilterPreSelection.push(filters.ableToServeAsPCP.code);
      }
    }
    if (filters?.acceptsPatients) {
      this.filterOptions.keyFilters.push({
        code: filters.acceptsPatients.code,
        name: filters.acceptsPatients.name,
        description: filters.acceptsPatients.description
      } as IBaseCode);
      if (this.searchCriteria?.acceptingNewPatients) {
        this.keyFilterPreSelection.push(filters.acceptsPatients.code);
      }
    }
  }

  /**
   * Cleans up subscriptions when the component is destroyed.
   */
  ngOnDestroy(): void {
    if (this.searchFilterSubscription) {
      this.searchFilterSubscription.unsubscribe();
    }
    if (this.appliedFilter) {
      this.appliedFilter.unsubscribe();
    }
  }

  /**
   * Opens the side panel in the specified direction.
   * @param direction The direction to open the side panel.
   */
  openSidePanel(direction: any): void {
    this.showCptFilters = CommonUtil.isCptSearch(this._appSession);
    this.sidePanelRef = this._sidePanel.open(direction, this.searchResultsFilterModal);
  }

  /**
   * Event handler for the cancel button click.
   * Closes the side panel.
   */
  onCancel(): void {
    this._appliedFilter.resetFilters(this._appliedFilter.initialAppliedFilterItems);
    this.sidePanelRef.close();
  }

  /**
   * Calls the undoFilter method on the AppliedSearchFilter service to undo the last applied filter.
   */
  undoFilter(): void {
    this._appliedFilter.undoFilter();
  }

  onApply(): void {
    this._appSession.initialSearch = false;
    this._appSession.searchParams.genderCodeList = [];
    this._appSession.searchParams.specialtySelectNm = [];
    this._appSession.searchParams.taxonomySelectNm = [];
    this._appSession.searchParams.typeSelectNm = '';
    this._appSession.searchParams.providerTypeCodeList = [];
    this._appSession.searchParams.languages = [];
    this._appSession.searchParams.adaSelectNm = [];
    this._appSession.searchParams.aoeSelectNm = [];
    this._appSession.searchParams.ethnicityList = [];
    this._appSession.searchParams.levelOfCareList = [];
    this._appSession.searchParams.ofcSrvcSelectNm = [];
    this._appSession.searchParams.ofcFeatureList = [];
    this._appSession.medicalPosCode = this.medicalPosCode;
    this._appSession.medicalModifierOptionCode = '';
    this._appSession.searchParams.groupAffiliationSelectNm = '';
    this._appSession.searchParams.hospitalAffiliationSelectNm = '';
    this._appSession.medicalGrpSearchTerm = '';
    this._appSession.hospSearchTerm = '';
    this._appSession.searchParams.patientPreferenceSelectNm = [];
    this._appSession.searchParams.visionSrvcAvailableList = [];
    this._appSession.searchParams.teleHealthInd = false;
    this._appSession.searchParams.extendedHoursOnly = false;
    this._appSession.searchParams.medicaidInd = false;
    this._appSession.searchParams.ableToServePcp = false;
    this._appSession.searchParams.acceptingNewPatients = false;

    const filterItems = this._appliedFilter?.mapAppliedFilterItemsByCategory();
    if (filterItems) {
      Object.keys(filterItems).forEach((category) => {
        const values = filterItems[category].map((filter) => filter.value) || [];
        switch (category) {
          case FilterCategory.GENDER:
            this._appSession.searchParams.genderCodeList = values;
            break;
          case FilterCategory.SPECIALTY:
            this._appSession.searchParams.specialtySelectNm = values;
            break;
          case FilterCategory.TAXONOMY:
            this._appSession.searchParams.taxonomySelectNm = values;
            break;
          case FilterCategory.PROVIDER_TYPE:
            this._appSession.searchParams.providerTypeCodeList = values;
            break;
          case FilterCategory.LANGUAGE:
            this._appSession.searchParams.languages = values;
            break;
          case FilterCategory.ADAACCSIBILE:
            this._appSession.searchParams.adaSelectNm = values;
            break;
          case FilterCategory.AREAOFEXPERTISE:
            this._appSession.searchParams.aoeSelectNm = values;
            break;
          case FilterCategory.ETHNICITY:
            this._appSession.searchParams.ethnicityList = values;
            break;
          case FilterCategory.LEVELOFCARE:
            this._appSession.searchParams.levelOfCareList = values;
            break;
          case FilterCategory.OFFICESERVICES:
            this._appSession.searchParams.ofcSrvcSelectNm = values;
            break;
          case FilterCategory.OFFICEFEATURES:
            this._appSession.searchParams.ofcFeatureList = values;
            break;
          case FilterCategory.PATIENT_POPULATION:
            this._appSession.searchParams.patientPreferenceSelectNm = values;
            break;
          case FilterCategory.SERVICES_AVAILABLE:
            this._appSession.searchParams.visionSrvcAvailableList = values;
            break;
          case FilterCategory.MEDICALGROUP:
            this._appSession.searchParams.groupAffiliationSelectNm = this.groupAffiliationFilterValue;
            break;
          case FilterCategory.HOSPITALAFFILIATION:
            this._appSession.searchParams.hospitalAffiliationSelectNm = this.hospitalAffiliationFilterValue;
            break;
          case FilterCategory.PROFILE:
            this.applyProfileFilters(values);
            break;
          case FilterCategory.KEYFILTERS:
            this.applyKeyFilters(values);
            break;
        }
      });
    }
    this._actionService.setSearchClick(false);
    this.sidePanelRef?.close();
  }

  getModifierCodes(posCd: string): IBaseOption[] {
    const selectedPos = (this.posList || []).find((item) => {
      return item.pos.code === posCd;
    });
    return (selectedPos?.modifierList || []).map((modifierCd) => {
      const modifierCode = {} as IBaseOption;
      modifierCode.label = modifierCode.value = modifierCd?.name?.slice(INDEX_ZERO, INDEX_FIFTY) ?? '';
      modifierCode.id = modifierCd.code;
      return modifierCode;
    });
  }

  onChangePosCodes(event: IBaseOption): void {
    this.selectedPosCode = event.value;
    this.medicalPosCode = event.id;
    this.modifierCodes = this.getModifierCodes(event.id);
    this.medicalModifierOptionCode = this.modifierCodes[this.modifierCodes.length - 1]?.id;
    this.selectedModifierCode = this.modifierCodes[this.modifierCodes.length - 1]?.value;
  }

  onChangeModifierCodes(event: IBaseOption): void {
    this.selectedModifierCode = event.value;
    this.medicalModifierOptionCode = event.id;
  }

  setBillingCodeFilters(): void {
    this._cptSummaryModel.cptSummaryResponse.subscribe((data) => {
      if (data) {
        this.posList = data.posInfos || [];
        this.posCodes = this.posList.map((posCd) => {
          const posCode = {} as IBaseOption;
          posCode.value = posCode.label = posCd?.pos?.name?.slice(INDEX_ZERO, INDEX_FIFTY) ?? '';
          posCode.id = posCd.pos.code;
          return posCode;
        });
        this.modifierCodes = this.getModifierCodes(data.pos?.code);
        this.selectedPosCode = data.pos?.name;
        this._appSession.selectedPosCode = this.selectedPosCode;
        this.selectedModifierCode = data.modifier?.name;
        this._appSession.selectedModifierCode = this.selectedModifierCode;
      }
    });
  }

  clearFilters(): void {
    this._appliedFilter.resetFilters(this._appliedFilter.initialAppliedFilterItems);
    this._appSession.initialSearch = true;
    if (this._appSession.filterSearchParams) {
      this._appSession.searchParams = this._appSession.filterSearchParams;
    }
  }

  updateDeselectionMedical(event: IAffiliationOption) {
    this.groupAffiliationFilterValue = event.providerIdentifier;
    this._appSession.searchParams.groupAffiliationSelectNm = this.groupAffiliationFilterValue;
  }
  updateDeselectionHospital(event: IAffiliationOption) {
    this.hospitalAffiliationFilterValue = event.providerIdentifier;
    this._appSession.searchParams.hospitalAffiliationSelectNm = this.hospitalAffiliationFilterValue;
  }

  profileFilterOptions() {
    const options = [this.filterOptions?.acceptsMedicaid, this.filterOptions?.teleHealth, this.filterOptions?.extendedHours];
    return options;
  }

  /**
   * Applies the profile filters to the search parameters based on the provided values.
   * @param values The profile filter values to apply.
   */
  private applyProfileFilters(values: string[]): void {
    if (this._appSession.searchParams) {
      (values || []).forEach((value) => {
        switch (value) {
          case FilterValue.TELEHEALTH:
            this._appSession.searchParams.teleHealthInd = true;
            break;
          case FilterValue.EXTENDED_HOURS:
            this._appSession.searchParams.extendedHoursOnly = true;
            break;
          case FilterValue.MEDICAID:
            this._appSession.searchParams.medicaidInd = true;
            break;
        }
      });
    }
  }

  /**
   * Applies the key filters to the search parameters based on the provided values.
   * @param values The key filter values to apply.
   */
  private applyKeyFilters(values: string[]): void {
    if (this._appSession.searchParams) {
      (values || []).forEach((value) => {
        switch (value) {
          case FilterValue.ABLE_TO_SERVE_AS_PCP:
            this._appSession.searchParams.ableToServePcp = true;
            break;
          case FilterValue.ACCEPT_PATIENTS:
            this._appSession.searchParams.acceptingNewPatients = true;
            break;
        }
      });
    }
  }
}
