import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { SEARCH_TYPE } from '../../../../common/constants/app-constants';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { ITranslation } from '../../../../fad/search-providers/interfaces/iAdaListResp';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IFtsSuggestion } from '../../../common/interfaces/iFtsClientInteraction';
import { FtsAvailableSuggestionService } from '../../../common/services/ftsAvailableSuggestionSvc';
import { FtsSuggestionSelectService } from '../../services/ftsSuggestionSelectSvc';

@Component({
  moduleId: module.id,
  selector: 'app-fc-fts-similarSearches-cmp',
  templateUrl: './ftsSimilarSearches.html',
  providers: []
})
export class FtsSimilarSearchesComponent extends BaseComponent implements OnInit, OnDestroy {
  otherSuggestions: IFtsSuggestion<ITranslation>[];
  otherSuggestionsDescriptions: string[];
  sidePanelRef: ModalRef<HTMLElement>;
  @Output()
  closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input()
  suggestions: IFtsSuggestion<ITranslation>[];
  @Output()
  onSuggestionClick: EventEmitter<IFtsSuggestion<ITranslation>> = new EventEmitter<IFtsSuggestion<ITranslation>>();

  private suggestionSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _availableSuggestionSvc: FtsAvailableSuggestionService,
    private _suggestionSelectService: FtsSuggestionSelectService
  ) {
    super(_route, _eventHandler, _appSession);
    this.suggestionSubscription = this._availableSuggestionSvc.getSuggestions.subscribe((data: IFtsSuggestion<ITranslation>[]) => {
      if (data) {
        this.suggestions = data;
        this.otherSuggestions = [];
        this.otherSuggestionsDescriptions = [];
        if (this.suggestions.length > 1) {
          const otherSuggestionsList = this.suggestions.slice(1, 6);
          for (let suggestion of otherSuggestionsList) {
            if (this.hasSpecialty(suggestion) || this.hasProcedure(suggestion) || this.hasMedicalCode(suggestion)) {
              this.otherSuggestions.push(suggestion);
              this.otherSuggestionsDescriptions.push(this.displaySearchCriteria(suggestion));
            }
          }
        }
      }
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngOnDestroy(): void {
    this.suggestionSubscription?.unsubscribe();
  }

  onClose() {
    this.closeModal.emit();
    this.suggestionSubscription?.unsubscribe();
  }

  generateSpecialtyCriteriaText(suggestion: IFtsSuggestion<ITranslation>): string {
    const criteriaDisplaySet = new Set<string>();
    (suggestion.criteria.specialtyCategoryList || []).forEach((category: ITranslation) => {
      criteriaDisplaySet.add(category.name);
    });
    (suggestion.criteria.taxonomyList || []).forEach((taxonomy: ITranslation) => {
      criteriaDisplaySet.add(taxonomy.name);
    });
    let searchCriteria = Array.from(criteriaDisplaySet);
    //Add NPI if any
    if (suggestion.criteria?.npi) {
      searchCriteria.push(this.content.common.freeTextSearchResults?.npi + ' ' + suggestion.criteria?.npi);
    }
    //Add provider name if any
    if (suggestion.criteria?.providerName) {
      searchCriteria.push(this.content.common.freeTextSearchResults?.name + suggestion.criteria?.providerName);
    }
    //Add gender if any
    if (suggestion.criteria?.genderList?.length === 1) {
      if (suggestion.criteria?.genderList[0] === 'F') {
        searchCriteria.push(this.content.common.freeTextSearchResults?.femaleDoctor);
      } else {
        searchCriteria.push(this.content.common.freeTextSearchResults?.maleDoctor);
      }
    }
    if (suggestion.criteria?.ableToServeAsPcp) {
      searchCriteria.push(this.content.common.freeTextSearchResults?.serveAsPcp);
    }
    if (suggestion.criteria?.acceptsNewPatient) {
      searchCriteria.push(this.content.common.freeTextSearchResults?.acceptsNewPatients);
    }
    if (suggestion.criteria?.languageList?.length > 0) {
      const criteriaLanguageSet = new Set<string>();
      (suggestion.criteria.languageList || []).forEach((language) => {
        criteriaLanguageSet.add(language.name);
      });
      searchCriteria.push(this.content.common.freeTextSearchResults.whoSpeaks + Array.from(criteriaLanguageSet).join(', '));
    }
    return searchCriteria.join(', ');
  }

  displaySearchCriteria(suggestion: IFtsSuggestion<ITranslation>): string {
    switch (suggestion.type) {
      case SEARCH_TYPE.SPECIALTY:
        return this.generateSpecialtyCriteriaText(suggestion);
      case SEARCH_TYPE.PROCEDURE:
        return suggestion.criteria.procedure?.name;
      case SEARCH_TYPE.MEDICAL_CODE:
        return suggestion.text;
      default:
        break;
    }
  }

  hasSpecialty(suggestion: IFtsSuggestion<ITranslation>): boolean {
    const criteria = suggestion?.criteria;
    return criteria?.specialtyCategoryList?.length > 0 || criteria?.taxonomyList?.length > 0 || criteria?.providerName !== '' || criteria?.npi !== '' || false;
  }

  hasProcedure(suggestion: IFtsSuggestion<ITranslation>): boolean {
    return (suggestion?.procedureCode !== '' && suggestion?.metaData?.providerCategoryCode !== '' && suggestion?.criteria?.procedure?.name !== '') || false;
  }

  hasMedicalCode(suggestion: IFtsSuggestion<ITranslation>): boolean {
    return (suggestion?.medicalCode !== '' && suggestion?.text !== '' && suggestion?.metaData?.medicalTypeCode !== '') || false;
  }

  getSuggestionDescription(index): string {
    const description = this.otherSuggestionsDescriptions[index];
    const descriptionMaxLength = this._appSession.appConfig?.features.freeTextSearch.descriptionMaxLength;
    return description.length > descriptionMaxLength ? `${description.slice(0, descriptionMaxLength)}...` : description;
  }

  onSuggestionSelect(suggestion: IFtsSuggestion<ITranslation>) {
    this.onClose();
    const searchTerm = this.displaySearchCriteria(suggestion);
    this._suggestionSelectService.setSelectedSuggestion(searchTerm, suggestion);
  }
}
