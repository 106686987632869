import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { PFCostInfoComponent } from '../../../../../common/components/cost-info/pfCostInfoCmp';
import { TAB_KEYCODE } from '../../../../../common/constants/app-constants';
import { AppNavigations } from '../../../../../common/constants/app-navigations';
import { ICptCostInfo, PFSearchCostEstimateContent, PFSearchCostEstimateCostInfoVM, PFSearchCostEstimateSidePanelVM, PFSearchCostInfo } from '../../../../../common/interfaces/iCptMedicalResponse';
import { DataHelper } from '../../../../../common/services/dataHelper';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { NavigationService } from '../../../../../common/services/navigationService';
import { AppSession } from '../../../../../common/values/appSession';
import { IProviderCptCodes } from '../../../../../fad/provider-details/models/iProviderCptCodesResponse';
import { COST_ESTIMATE_ORDER, COST_SCENARIO } from '../../../../../fad/search-providers/enums/personalizationEnums';
import { BaseComponent } from '../../../../../findcare/common/components/core/baseCmp';
import { PageAlertHandler } from '../../../../common/components/shareable/page-alert/service/pageAlertHandler';
import { FindCarePageType } from '../../../../common/enums/findCarePages';
import { PageAlertID } from '../../../../common/enums/pageAlertId';
import { ProviderCard } from '../../../constants/result';

@Component({
  moduleId: module.id,
  selector: 'app-fc-cpt-cost-info-cmp',
  templateUrl: './providerCptCostInfoCmp.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ProviderCptCostInfoComponent extends BaseComponent {
  @Input()
  costInfo: ICptCostInfo;
  isExpanded: boolean = false;

  @Input()
  selectedCptObj: IProviderCptCodes;
  @Input()
  showCostLoader: boolean;
  @Input()
  selectedPos: string;
  @Input()
  selectedModifier: string;
  @Input()
  showError = false;
  @Input()
  hideCostDetails = false;
  isException: boolean;
  @ViewChild('costDetailModal')
  costDetailModal: PFCostInfoComponent;
  @ViewChild('disclaimerSidePanel') disclaimerSidePanel: TemplateRef<HTMLElement>;
  disclaimerSidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;
  sidePanelTabKeyListener: { (): void };
  printCostInfo: PFSearchCostEstimateSidePanelVM;
  navigateToUrl: AppNavigations;
  @Output() cptValue = new EventEmitter<boolean>();
  @Output() cptCardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  costInfoObj: PFSearchCostInfo;
  vm: PFSearchCostEstimateSidePanelVM;
  costEstimateContent = this.content.result.cptCostEstimateSidePanelContent;
  isProcedureDetailsVisible = false;

  constructor(
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _dataHelper: DataHelper,
    private _renderer: Renderer2,
    private _sidePanel: SidePanel,
    private _navigationService: NavigationService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    //Show alert
    if (this.hideCostDetails) {
      PageAlertHandler.buildStaticPageAlerts([PageAlertID.PA_CPT_PD_BACK_PROVIDER], this._appSession, FindCarePageType.DetailCostEstimate);
    }
    this.costInfoObj = {
      placeOfService: this.selectedPos,
      modifierCode: this.selectedModifier,
      costInfo: this.costInfo
    };
    const estimatedContent = this.costEstimateWithContent(this.costInfoObj, this.costEstimateContent.costContent, this.costEstimateContent.costContentForPercentage);
    estimatedContent.costInfo = estimatedContent?.costInfo?.filter((data) => data?.name);
    estimatedContent.serviceLimits = this.costInfoObj.serviceLimits;
    estimatedContent.hasServiceLimit = this.costInfoObj.hasServiceLimit;
    estimatedContent.orthoTypeFlag = this.costInfoObj.orthoTypeFlag;
    estimatedContent.missingToothPeriodFlag = this.costInfoObj.missingToothPeriodFlag;
    estimatedContent.hasDentalServiceLimits = this.costInfoObj?.hasDentalServiceLimits;
    estimatedContent.isOfficeVisitCost = this.costInfoObj?.costInfo?.isOfficeVisitCost;
    this.vm = estimatedContent;
    // show alert
    this.showNoCostDataAlert();
    this.showCostBenefirAlert();
  }

  showNoCostDataAlert() {
    if (!this.showCostLoader && (this.isException || this.showError)) {
      PageAlertHandler.buildStaticPageAlerts([PageAlertID.PA_NO_COST], this._appSession, FindCarePageType.DetailCostEstimate);
    }
    if (!this.showError && this.selectedPos === this.content.result.pricingCmpContent.defaultPos) {
      PageAlertHandler.removePageAlert([PageAlertID.PA_NO_COST], this._appSession, FindCarePageType.DetailCostEstimate);
    }
  }
  showCostBenefirAlert() {
    if (!this.showCostLoader && (this.costInfo?.isBenefitDenied || this.costInfo?.isPlanNotCovered)) {
      const alertId = this.costInfo?.isPlanNotCovered ? PageAlertID.PA_PLAN_NOT_COVER : PageAlertID.PA_BENEFIT_DENIED;
      PageAlertHandler.buildStaticPageAlerts([alertId], this._appSession, FindCarePageType.DetailCostEstimate);
    }
  }

  costEstimateWithContent(oonCostDetails: PFSearchCostInfo, costContent: PFSearchCostEstimateContent, costContentForPercentage: PFSearchCostEstimateContent): PFSearchCostEstimateSidePanelVM {
    const content: PFSearchCostEstimateContent =
      oonCostDetails.costInfo?.scenario?.toLowerCase() === COST_SCENARIO.PERCENTAGE.toLowerCase() ? { ...costContent, ...costContentForPercentage } : { ...costContent };
    return this.costEstimate(content, oonCostDetails);
  }

  costEstimate(costEstimateContent: PFSearchCostEstimateContent, value: PFSearchCostInfo) {
    // create object with properties needed by UI Model
    const requiredCostInfo = {
      costInfo: {
        coinsurance: value.costInfo.coinsurance,
        copay: value.costInfo.copay,
        appliedDeductible: value.costInfo.appliedDeductible
      },
      placeOfService: value.placeOfService,
      modifierCode: value.modifierCode
    };

    const costEstimate: PFSearchCostEstimateCostInfoVM[] = [];
    const { costInfo, modifierCode, placeOfService } = requiredCostInfo;
    const sortedAndFilteredCostEstimate = Object.keys(costInfo)
      .sort(this.sortCostKeys)
      .reduce((accumulator, key) => {
        // if key is undefined from API then do not add it in the final UI model
        if (costInfo[key]) {
          accumulator[key] = costInfo[key];
        }
        return accumulator;
      }, {});
    Object.keys(sortedAndFilteredCostEstimate).forEach((key: keyof Partial<ICptCostInfo>) => {
      const vm: PFSearchCostEstimateCostInfoVM = { ...costEstimateContent[key], value: '', id: key };
      vm.value = costInfo[key];
      costEstimate.push(vm);
    });
    const searchCostEstimateVM: PFSearchCostEstimateSidePanelVM = {
      placeOfService,
      modifierCode,
      costInfo: costEstimate
    };
    return searchCostEstimateVM;
  }

  ngOnChanges(args: SimpleChanges) {
    this.isException = !this.costInfo || (this.costInfo && this._dataHelper.isObject(this.costInfo) && Object.keys(this.costInfo).length === 0);
    if (!this.isException && !this.costInfo.isBenefitDenied && !this.costInfo.isPlanNotCovered) {
      this.getPrintObj();
    } else {
      this.printCostInfo = null;
    }
    if (args['costInfo']) {
      this.ngOnInit();
    }
  }

  sortCostKeys(element1, element2) {
    return COST_ESTIMATE_ORDER[element1] - COST_ESTIMATE_ORDER[element2];
  }

  openDisclaimerSidePanelMsg() {
    this.disclaimerSidePanelRef = this._sidePanel.open('right', this.disclaimerSidePanel);
    this.sidePanelTabKeyListener = this._renderer.listen('document', 'keydown', (event) => {
      if (event.keyCode === TAB_KEYCODE) {
        event.preventDefault();
      }
    });
  }

  closeDisclaimerSidePanel() {
    this.disclaimerSidePanelRef.close();
    this.sidePanelTabKeyListener();
  }

  getPrintObj() {
    const costInfoObj: PFSearchCostInfo = {
      placeOfService: this.selectedPos,
      modifierCode: this.selectedModifier,
      costInfo: this.costInfo
    };
    this.printCostInfo = this.costEstimate(this.content.result.cptCostEstimateSidePanelContent.costContent, costInfoObj);
  }

  goToProviderList() {
    this._appSession.isCPTCodeSearch = true;
    this.cptValue.emit(this._appSession.isCPTCodeSearch);
    this._appSession.initialSearch = true;
    this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
  }

  showProcedureDetails() {
    this.isProcedureDetailsVisible = true;
    this.cptCardSelected.emit(ProviderCard.CPT);
  }
}
