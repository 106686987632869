import { Inject, Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppSession } from '../../../common/values/appSession';
import { IProviderLocationRequest, IProviderLocationsResponse } from '../../result/interfaces/iProviderLocation';
import { BaseService } from './../../../common/services/baseService';
import { AppUtility } from './../../../common/utilities/appUtil';
@Injectable({
  providedIn: 'root'
})
export class ProviderLocationService extends BaseService {
  constructor(
    _httpClientSvc: HttpClientService,
    @Inject(AppSession)
    _appSession: AppSession,
    _appUtility: AppUtility
  ) {
    super(_appSession, _appUtility, _httpClientSvc);
  }
  getProviderLocations(request: IProviderLocationRequest): Promise<IProviderLocationsResponse> {
    const apiUrl = apiNameList.restApi.locationsV1;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: request,
      headers: this.getHeaders()
    });
  }
}
