export enum ContentKey {
  COMMON = 'common',
  HOME = 'home',
  LANDING = 'landing',
  RESULT = 'result'
}

export enum ProviderFlag {
  EVENING_HOURS = 'EVENINGHOURS',
  WEEKEND_HOURS = 'WEEKENDHOURS',
  IHSP = 'IHSP',
  REMOTE_PROVIDER = 'REMOTE_PROVIDER',
  SSO = 'SSO',
  HOME_HEALTH_CARE = 'HOMEHEALTH_CARE',
  TELEHEALTH_PROVIDER = 'TELEHEALTH',
  DIRECTORY_PROVIDER = 'DIRECTORY_PROVIDER',
  REGIONAL_PROVIDER = 'REGIONAL_PROVIDER',
  VIRTUAL_PROVIDER = 'VIRTUAL_PROVIDER',
  MDCFLG = 'ACCEPTS_MEDICAID',
  NIMDCFLG = 'NOINFO_ACCEPTS_MEDICAID',
  ONLINE_PROVIDER = 'ONLINE_PROVIDER',
  PRIME_GROUP = 'PRIMEGROUP'
}

export enum PROVIDER_TYPE {
  HEARING = 'A',
  VISION = 'V',
  RETAIL_CLINIC = 'C',
  DOCTOR_PROFESSIONAL = 'P',
  MEDICAL_GROUP = 'G',
  HOSPITAL = 'H',
  BEHAVIORAL_HEALTH = 'B',
  DENTAL = 'D',
  URGENT_CARE = 'U',
  OTHER_MEDICAL = 'O',
  PHARMACY = 'R',
  MEDICAL_EQUIPMENT = 'M',
  OTHER_SERVICES_PROVIDER = 'CA-OSP',
  LAB = 'L'
}

export const PROVIDER_SEARCH_DISTANCE = '20';

export enum COVERAGE_TYPE {
  MEDICAL = 'M',
  DENTAL = 'D',
  VISION = 'V',
  PHARMACY = 'R',
  HEARING = 'A'
}

export enum LOOKUP_TYPE {
  SPECIALITY = 'specialtyList',
  PROCEDURE = 'procedureList',
  PROCEDURE_LICENSE = 'providerLicenseList',
  AOE = 'aoeList',
  OFC_SERVICE = 'ofcSrvcList'
}

export enum CareTeamActionType {
  REMOVE_CARE_TEAM_MENU_FIND_CARE = 'removeCareTeamMenuFindCare',
  CHANGE_PCP_TEAM_MENU_FIND_CARE = 'changePcpTeamMenuFindCare',
  BOOK_APPT_TEAM_MENU_FIND_CARE = 'bookApptTeamMenuFindCare'
}

export enum ContractCoverageType {
  MED = 'Medical',
  PHAR = 'Pharmacy',
  DEN = 'Dental',
  VSN = 'Vision'
}

export enum ContractStatus {
  ACTIVE = 'A',
  FUTURE = 'F'
}
export interface IGeoCoordinate {
  longitude: string;
  latitude: string;
}

export enum LookupType {
  NAME = 'NM',
  SMART_SEARCH = 'SS',
  NPI = 'NPI',
  LICENSE_NUMBER = 'LN',
  AOE = 'AOE',
  OFFICE_SERVICES = 'OS',
  MEDICAL_CODE = 'CPT'
}

export enum SliderDirection {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom'
}

export const VIEW_COUNT_LIMIT = 5;
export const MAX_PLAN_LENGTH = 22;
export const MAX_MOB_PLAN_LENGTH = 12;
export const MAX_DESK_RESPONSIVE_WIDTH_M = 1025;
export const MAX_DESK_RESPONSIVE_WIDTH_S = 680;
export const MAX_DESK_RESPONSIVE_WIDTH_XS = 600;
export const MAX_MOB_RESPONSIVE_WIDTH_L = 481;
export const MAX_MOB_RESPONSIVE_WIDTH_M = 375;
export const MAX_MOB_RESPONSIVE_WIDTH_S = 320;
export const DOLLAR_SYMBOL = '$';

export enum ACCOUNT_TYPE {
  HIA = 'HIA',
  HRA = 'HRA',
  HSA = 'HSA'
}

export enum StateCode {
  CA = 'CA'
}

export enum PageAlertType {
  INFORMATION = 'information',
  WARNING = 'warning',
  NEGATIVE = 'negative'
}

export enum PageAlertTypeStyle {
  INFORMATION = 'motif-icon fcr-page-alert-icon motif-info',
  WARNING = 'fa fa-exclamation-triangle',
  NEGATIVE = 'motif-icon motif-cancel'
}

export const PROV_NETWORK_STATUS = {
  UNDETERMINED: 'UNDETERMINED',
  INNETWORK: 'INNETWORK',
  INN_ASOF: 'INNETWORK_AS_OF',
  INN_UNTIL: 'INNETWORK_UNTIL',
  NOT_INNTWK_ASOF: 'NOTINNETWORK_ASOF',
  OUTNETWORK: 'OUTOFNETWORK'
};

export enum SearchExecutionMode {
  PLAN_SELECTION = 'PLAN_SELECTION',
  PREFIX_SEARCH = 'PREFIX_SEARCH',
  HCID_SEARCH = 'HCID_SEARCH',
  SECURE = 'SECURE',
  UNDEFINED = 'UNDEFINED'
}

export enum MbrAddressType {
  HOME = 'HOME',
  WORK = 'WORK',
  OTHER = 'OTHER'
}

export enum ProviderCareType {
  MEDICAL = 'MEDICAL',
  DENTAL = 'DENTAL',
  VISION = 'VISION',
  PHARMACY = 'PHARMACY'
}
