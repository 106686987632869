import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TIER } from '../../../common/constants/app-constants';
import { IOption } from '../../common/interfaces/iOption';
import { PERSONALIZED_MATCH } from '../constants/result';
import { DataHelper } from './../../../common/services/dataHelper';
import { AppSession } from './../../../common/values/appSession';

@Injectable({
  providedIn: 'root'
})
export class SortOptionHandler {
  private _sortOptions: IOption[] = [];
  private sortOptions = new BehaviorSubject<IOption[]>(this._sortOptions);
  selectedOption: IOption;

  get getSortOptions() {
    return this.sortOptions.asObservable();
  }

  constructor(
    private _dataHlpr: DataHelper,
    private _appSession: AppSession
  ) {
    this._sortOptions = [];
  }

  /**
   * Initializes the sort options based on the response and default sort option.
   * @param responseSortOptions The sort options from the response.
   * @param defaultSort The default sort option.
   * @param allSortOptions All available sort options.
   */
  init(responseSortOptions: string[], defaultSort: string, allSortOptions: any): void {
    if (!Array.isArray(responseSortOptions) || responseSortOptions.length === 0) {
      return;
    }

    // Reset personalized match flag
    if (this._appSession.searchParams) {
      this._appSession.searchParams.hasPersonalizedMatch = false;
    }

    this._sortOptions = [];
    this.selectedOption = undefined;
    const options: IOption[] = [];

    responseSortOptions.forEach((option, index) => {
      const label = allSortOptions[option.toLowerCase().replace(/ /gi, '_')] || option;
      const sortValue = this._dataHlpr.isEmptyString(label) ? TIER : option;
      const sortOption: IOption = {
        label,
        value: sortValue,
        id: `cb_sort_${index}`,
        name: `cb_sort_${index}`,
        trueValue: sortValue,
        falseValue: ''
      };
      options.push(sortOption);

      // Set personalized match flag if the option is 'PersonalizedMatch'
      if (option.toLowerCase() === PERSONALIZED_MATCH.toLowerCase() && this._appSession.searchParams) {
        this._appSession.searchParams.hasPersonalizedMatch = true;
      }
    });

    if (!this.isValidDefaultOption(responseSortOptions, defaultSort)) {
      this.selectedOption = options[0];
    } else {
      this.selectedOption = options.find((sortOption) => sortOption.value === defaultSort);
    }

    this.sortOptions.next(options);
  }

  /**
   * Checks if the default sort option is valid based on the response sort options.
   * @param responseSortOptions The sort options from the response.
   * @param defaultSort The default sort option.
   * @returns {boolean} True if the default sort option is valid, otherwise false.
   */
  private isValidDefaultOption(responseSortOptions: string[], defaultSort: string): boolean {
    if (this._dataHlpr.isEmptyString(defaultSort)) {
      return false;
    }

    return Array.isArray(responseSortOptions) && responseSortOptions.length > 0 ? responseSortOptions.some((opt) => this._dataHlpr.areEqualStrings(opt, defaultSort)) : false;
  }
}
