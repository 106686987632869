import { Component, HostListener, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, SnackbarAlertContainerComponent } from '@anthem/uxd/alert';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { AppNavigations } from '../../../../../common/constants/app-navigations';
import { IWindow } from '../../../../../common/interfaces/iWindow';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { NavigationService } from '../../../../../common/services/navigationService';
import { AppSession } from '../../../../../common/values/appSession';
import { SliderDirection } from '../../../constants/common';
import { FindCareFeature } from '../../../enums/findCareFeature';
import { IAppContract } from '../../../interfaces/iAppContract';
import { IContract } from '../../../interfaces/iContract';
import { IMember } from '../../../interfaces/iMember';
import { BootstrapService } from '../../../store/bootstrapSvc';
import { BaseComponent } from '../../core/baseCmp';
import { CHINESE_LANG_BRANDS, Locale, SNACKBAR } from './../../../../../common/constants/app-constants';
import { SearchWrapperComponent } from './components/search-wrapper/searchWrapperCmp';

@Component({
  selector: 'app-fc-search-header-cmp',
  templateUrl: './searchHeaderCmp.html'
})
export class SearchHeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  members: IMember[] = [];
  activeMember: IMember = undefined;
  modalActiveMember: IMember = undefined;
  sidePanelRef: ModalRef<HTMLElement>;
  sliderDirection = SliderDirection;
  @ViewChild('viewFamilyMemberModal') viewFamilyMemberModal: TemplateRef<HTMLElement>;
  @ViewChild('snackbarModal') snackbarModal: SnackbarAlertContainerComponent;
  @ViewChild('contractChangeAlertTemplate', { static: false }) contractChangeAlertTemplate: TemplateRef<HTMLElement>;
  @Input() showStartOver: boolean = false;
  @ViewChild(SearchWrapperComponent) searchWrapper: SearchWrapperComponent;

  isMultipleMember = false;
  private _contractSubscription: Subscription;
  private _brand: string;
  private chineseMotionPointUrl: string;
  englishSpanishToggleLabel: string;
  memChipCmpClass: string = '';
  headerMemberWrapClass: string = '';
  tryBetaBtnClass: string = '';

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession) private _appSession: AppSession,
    @Inject('Window') private _window: IWindow,
    private _bootstrapService: BootstrapService,
    private _sidePanel: SidePanel,
    private _alertService: AlertService,
    private _navigationService: NavigationService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.englishSpanishToggleLabel = this.content?.common?.pageHeader?.englishSpanishToggleLabel;
    this._brand = this._appSession.metaData?.brandCd;
    this._contractSubscription = this._bootstrapService.contract.subscribe((appContract: IAppContract) => {
      this.activeMember = appContract?.selectedContract;
      this.initializeMembers();
    });
    this.getClassChangesForSpanish();
  }

  // to enable / disable Chinese Language link on page
  get showChineseLink(): boolean {
    return !this.isSecureState && this.hasFeature(FindCareFeature.FC_CHN_LOCALE) && CHINESE_LANG_BRANDS.includes(this._brand.toUpperCase());
  }

  get isIntegratedMode() {
    return this._appSession.isIntegratedMode;
  }

  // Get Chinese MotionPoint URL based on brand from appConfig
  // @param _brand - Brand code
  getchineseMotionPointUrl(_brand: string): string {
    return this._appSession.appConfig?.chineseMotionPointUrl[_brand.toUpperCase()];
  }

  // Open MotionPoint site in new tab if brand is ABC, EBC or EBCBS and not in secure state
  openMotionPointSite() {
    // below url will not become blank as it this will be called only if isShowChinese is true
    this.chineseMotionPointUrl = this.getchineseMotionPointUrl(this._brand);
    const windowParams = 'scrollbars=1,width=1140,height=800,toolbar=0,resizable=1';
    // Add protocol if not present
    this.chineseMotionPointUrl = this.chineseMotionPointUrl.indexOf('://') <= 0 ? 'http://' + this.chineseMotionPointUrl : this.chineseMotionPointUrl;
    const winArgs = [this.chineseMotionPointUrl, '_blank'];
    winArgs.push(windowParams);
    // Open the window
    this._window.open.apply(this._window, winArgs);
  }

  // English / Spanish language toggle
  onLanguageClick() {
    if (this._appSession?.metaData?.locale) {
      this._appSession.metaData.locale = this._appSession.metaData.locale === Locale.SPANISH ? Locale.ENGLISH : Locale.SPANISH;
    }
    this._navigationService?.navigateByUrl(AppNavigations.FCR_HOME_PATH);
  }

  /**
   * Method to open the side panel to view all family members
   * @param direction Direction to open the side panel
   */
  viewAllFamilyMembers(direction: SliderDirection) {
    this.modalActiveMember = this.activeMember;
    this.sidePanelRef = this._sidePanel.open(direction, this.viewFamilyMemberModal);
  }

  viewAllMembersChange(viewMember: IContract) {
    this.modalActiveMember = viewMember;
  }

  /**
   * Set the selected member in bootstrap service and close the modal
   */
  onViewAllContinue() {
    this._bootstrapService.setAppContract(this.modalActiveMember.mbrUid);
    this.sidePanelRef.close();
  }

  /**
   * Close the modal without changing the selected member
   */
  onViewAllCancel() {
    this.sidePanelRef.close();
  }

  /**
   * Gets the updated image URL based on the condition.
   * @returns The updated image URL.
   */
  getUpdatedImageUrl() {
    const imgUrl = this.isFtsFeatureActive ? 'fcr_refresh.svg' : 'fcr_Idea_bulb.svg';
    return this.getCommonImageURL(imgUrl);
  }

  /**
   * Opens a Snackbar alert with the contract change alert template.
   */
  showContractChangeAlert() {
    if (!this.snackbarModal?.alerts?.length) {
      this._alertService.success(this.contractChangeAlertTemplate, {
        regionName: SNACKBAR,
        isDissmisable: true
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this._appSession.metaData.locale === Locale.SPANISH) {
      this.getClassChangesForSpanish();
    }
  }

  private getClassChangesForSpanish() {
    const locale = this._appSession.metaData.locale;
    if (locale === Locale.SPANISH && window.innerWidth < 1025) {
      this.memChipCmpClass = 'clear-left';
      this.headerMemberWrapClass = (this.isMultipleMember ? 'multiple-members' : 'single-member') + ' col-wise';
      this.tryBetaBtnClass = 'mt-10';
    } else {
      this.memChipCmpClass = '';
      this.headerMemberWrapClass = this.isMultipleMember ? 'multiple-members' : 'single-member';
      this.tryBetaBtnClass = '';
    }
  }
  /**
   * Initializes members from the bootstrap service.
   */
  private initializeMembers() {
    this.members = this._bootstrapService.members;
    this.isMultipleMember = this.members.length > 1;
    this.addInitialsToMembers();
  }

  /**
   * Adds initials to the members.
   */
  private addInitialsToMembers() {
    for (const member of this.members) {
      const initials = member.firstNm?.charAt(0) + member.lastNm?.charAt(0);
      member.initials = initials;
    }
  }

  ngOnDestroy(): void {
    this._contractSubscription?.unsubscribe();
  }

  onStartOver() {
    if (this.searchWrapper) {
      this.searchWrapper.setSearchParams();
      this._appSession.searchTerm = '';
    }
    this._navigationService.navigateByUrl(AppNavigations.FCR_LANDING_PATH);
  }
}
