import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewMoreLocationsComponent } from '../../../../common/components/location/pfViewMoreLocationsCmp';
import { ControlType } from '../../../../common/enums/controlType';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IAffiliationOption } from './interfaces/iAffiliationOption';

@Component({
  moduleId: module.id,
  selector: 'app-control-list',
  templateUrl: './controlListCmp.html'
})
export class ControlListComponent extends BaseComponent implements OnInit {
  @Input()
  options: any;
  @Input()
  controlType: ControlType;
  @Input()
  category: string;
  @Input()
  selectedItem: string = '00';
  @Input()
  customCSSClass: string = 'searchFilterItem';
  @Input()
  controlListData: any;

  @ViewChild('viewMoreLocationsModal')
  viewMoreLocationsModal: ViewMoreLocationsComponent;

  @Output()
  itemChange: EventEmitter<any> = new EventEmitter<any>();

  type = ControlType;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  onItemChange(data) {
    if (data) {
      this.selectedItem = data;
      this.itemChange.emit(data);
    }
  }

  /** Open Side Panel */
  openSidePanel(direction: string, controlListItem: IAffiliationOption, category: string) {
    this.viewMoreLocationsModal.openSidePanel(direction, controlListItem, category);
  }

  removeBracesForFilterItems(value: any) {
    return value.toString().slice(0, value.length - 1);
  }
}
