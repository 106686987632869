import { TitleCasePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertHelper, AlertService } from '@anthem/uxd/alert';
import { SidePanel } from '@anthem/uxd/modal';
import { AntTabGroupComponent } from '@anthem/uxd/tabs';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { MemberPCPHelper } from '../../../care-circle/services/memberPCPHelper';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { AnpSidePanelComponent } from '../../../common/components/common-alert/anpSidePanelCmp';
import { SlideoutMessageComponent } from '../../../common/components/common-alert/slideoutMsgCmp';
import { ViewHealthwiseComponent } from '../../../common/components/healthwise/pfViewHealthwiseCmp';
import { ViewHealthwiseComponent2 } from '../../../common/components/healthwise/pfViewHealthwiseCmp2';
import { PFMapComponent } from '../../../common/components/map/pfMapCmp';
import { CaHmoPanelComponent } from '../../../common/components/pcp/caHmoPanelCmp';
import { AssignPcpComponent } from '../../../common/components/pcp/pfAssignPcpCmp';
import {
  ANP_ERROR_CODE,
  BILLING_TYPE,
  CA_HMO_DISCLAIMER,
  COUNT,
  DOCTOR_NAME,
  Locale,
  MEMBER_NAME,
  NETWORK_TYPECODE,
  OFFICE_VISIT_MEDICALCODE,
  RIGHT,
  TERMINATION_DATE,
  VIRTUAL_CARE_HUB
} from '../../../common/constants/app-constants';
import { AppEvents } from '../../../common/enums/appEvents';
import { IAppointmentLoader } from '../../../common/interfaces/iAppointment';
import { IAssignCareLoader } from '../../../common/interfaces/iAssignCare';
import {
  IFinalProvider,
  IFinalSearchResponse,
  IProviderSelect,
  IRecognition,
  ISearchResultsCheckbox,
  ISpfiDisclaimerResponse,
  IVitalsSsoConfig
} from '../../../common/interfaces/iCommonSearchResponse';
import { SidePanelDirections } from '../../../common/interfaces/iCptMedicalResponse';
import { IEventDetail } from '../../../common/interfaces/iEventDetail';
import { IVirtualVisitSSO } from '../../../common/interfaces/iMessage';
import { IProvider } from '../../../common/interfaces/iSearchStrategyResponse';
import { ISlideoutMessage } from '../../../common/interfaces/iSlideoutMsg';
import { IWindow } from '../../../common/interfaces/iWindow';
import { PhonePatternPipe } from '../../../common/pipes/phonePatternPipe';
import { DataHelper } from '../../../common/services/dataHelper';
import { DataService } from '../../../common/services/dataService';
import { EventHandler } from '../../../common/services/eventHandler';
import { NetworkProgramSvc } from '../../../common/services/networkProgramSvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { IProviderCostEstimateResponse } from '../../../eyc/cost-details/models/iProviderCostEstimateResponse';
import { IPcpNavResponse } from '../../assign-pcp/models/iMemberPcpDetails';
import { AssignPcpNavigationService } from '../../assign-pcp/services/assignPcpNavigationSvc';
import { ProviderDetailsNavigationService } from '../../provider-details/services/providerDetailsNavigationSvc';
import { ProviderOrchestrationService } from '../../provider-details/services/providerOrchestrationSvc';
import { VpcMagicLinkSvc } from '../../search-providers/services/vpcMagicLinkSvc';
import { PERSONALIZED_MATCH, PHARMACY_TYPE, PROVIDER_CATEGORY_CODE, PROVIDER_TYPE } from '../../search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../utilities/commonUtil';
import { IMapRequest } from '../interfaces/iMapRequest';
import { IOfficeVisitRequest } from '../interfaces/iOfficeVisitRequest';
import { ISortInput } from '../interfaces/iSortInput';
import { ICostInfo, IField, IHealthwiseAdobeAnalyticsObj, IRule, ISortOption, ISpecialty, ReviewResponse } from '../interfaces/iSummaryResp';
import { SearchResultsFilterService } from '../services/searchResultsfilterSvc';
import { NORATING, OUTNETWORK, SEARCH_IMPROVEMENTS, TAB_VIEW } from '../values/providerSearchConstants';
import { ContactUs, HpnSearch, ResultActions } from '../values/resultActions';
import { IVirtualProviderConfig } from './../../../care-circle/interfaces/iCareProvider';
import { FindCareWebIntegrationClient } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { IBaseCode } from './../../../common/interfaces/iBaseCode';
import { NavigationService } from './../../../common/services/navigationService';

@Component({
  moduleId: module.id,
  selector: 'app-fad-search-result-cmp3',
  templateUrl: '../views/pfSearchResultsCmp3.html',
  providers: [AlertService]
})
export class PFSearchResultsComponent3 extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  isEnhancedMapView: boolean;
  @Input()
  set summaryresponse(value: IFinalSearchResponse) {
    if (typeof value === 'undefined' || value === null) {
      this.hasApplyError = false;
      this.noResults = false;
      this.isHPNPlan = false;
      this.showResultTemplate = false;
      return;
    }
    const coordinatesFromSession = this._appSession?.searchParams?.coordinates;
    this.isShowDistance = !(
      coordinatesFromSession &&
      ((coordinatesFromSession.latitude === '0' && coordinatesFromSession.longitude === '0') ||
        (typeof coordinatesFromSession.latitude === 'undefined' && typeof coordinatesFromSession.longitude === 'undefined'))
    );

    this.summaryResponse = value;
    this.isMemberSecure = this.showBeFirstToReview = CommonUtil.isMemberSecure(this._appSession);
    if (this.summaryResponse.accntBalanceInfo && this.summaryResponse.accntBalanceInfo.accountBalanceText) {
      this.expansion = CommonUtil.getAccountExpansion(this.summaryResponse.accntBalanceInfo.accountBalanceText);
    }
    this.bindProviderSummary(value);
    this.suppressCheckbox = CommonUtil.supressCheckbox(this.summaryResponse.providerList, this._appSession);
    this.addPrimeGroupAffiliationToFilter(value.isPrimeGroupSearchResponse);
    if (value.ruleList && value.ruleList.length > 0) {
      this.setRemovedPrimeGroupRule(value.ruleList);
    }
  }
  @Input()
  set mapData(value: IMapRequest[]) {
    if (typeof value === 'undefined' || value === null || value.length === 0) {
      this.showMapCmp = false;
      this.toggleMapBool = false;
      this.locationData = [];
    } else if (this._isMapView === true) {
      this.showMapCmp = true;
      this.toggleMapBool = true;
    }
    this.locationData = value.filter((mapData) => mapData.latitude !== '' && mapData.longitude !== '');
  }

  @Input()
  virtualProviders: IFinalProvider[];

  @Input()
  set drivingTimeProvList(value: IFinalProvider[]) {
    if (typeof value === 'undefined' || value === null || value.length === 0) {
      this.drivingTimeSortList = [];
      return;
    } else {
      //load map when the integrated search changes happens on Map view page
      if (this._isMapView === true) {
        this.onTabChange({ textLabel: this.tabView.MAP_VIEW });
      } else {
        this.mapViewSelected.emit(this._isMapView);
      }
      this.drivingTimeSortList = value;
    }
  }

  get procedureName(): string {
    if (
      this._appSession.searchParams &&
      this._appSession.searchParams.eycSearchParam &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName
    ) {
      return this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.procedureName;
    }
    return '';
  }

  get isEYC(): boolean {
    return this._appSession.isEyc;
  }

  get isDentalSearch(): boolean {
    return (
      this._appSession.hasDentalEYC &&
      this._appSession.searchParams &&
      this._appSession.searchParams.eycSearchParam &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
      this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL
    );
  }

  get pageNumber(): number {
    return !this._appSession.pageNumber ? 1 : +this._appSession.pageNumber;
  }
  get isSNYMember(): boolean {
    return this._appSession?.feature?.isSNYMember;
  }
  get eycPMFlag(): boolean {
    return this._appSession.hasPersonalizedMatch;
  }

  get showProviderServiceLocationName(): boolean {
    return this._appSession?.feature?.showServiceLocationName || false;
  }

  get showAssignPCPPanel() {
    return this.isMemberSecure || this.customizedFeatures.showAssignPCPPanel;
  }
  expansion = '';
  @Output()
  actions: EventEmitter<ResultActions> = new EventEmitter<ResultActions>();

  sortFilter: ISortOption[];
  sortSelected: string;
  providerList: IFinalProvider[];
  drivingTimeSortList: IFinalProvider[];

  @Input()
  selectedChkBox: string[] = [];
  @Input()
  hasApplyError: boolean;
  @Output()
  openRecognition: EventEmitter<IRecognition> = new EventEmitter<IRecognition>();
  @Output()
  openPairRecognition: EventEmitter<IFinalProvider> = new EventEmitter<IFinalProvider>();
  @Output()
  openDomain: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  openReview: EventEmitter<IFinalProvider> = new EventEmitter<IFinalProvider>();
  @Output()
  openMapModal: EventEmitter<IFinalProvider> = new EventEmitter<IFinalProvider>();
  @Output()
  sortProviderSearch: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  mapViewSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(AntTabGroupComponent)
  groupEl: AntTabGroupComponent;
  @ViewChild('mapComponent')
  mapComponent: PFMapComponent;
  @Output()
  providerSelect: EventEmitter<IProviderSelect> = new EventEmitter<IProviderSelect>();
  @Output()
  scheduleAppointment: EventEmitter<IAppointmentLoader> = new EventEmitter<IAppointmentLoader>();
  @Output()
  resultLoadedEvent = new EventEmitter();
  @Output()
  assignCare: EventEmitter<IAssignCareLoader> = new EventEmitter<IAssignCareLoader>();
  @Output()
  compareProviderDetails: EventEmitter<object> = new EventEmitter<object>();
  @ViewChildren('gridcontainer')
  gridContainer: QueryList<any>;
  @ViewChild('slideoutMsgCmp')
  slideoutMsgCmp: SlideoutMessageComponent;
  @ViewChild('anpSidePanelCmp')
  anpSidePanelCmp: AnpSidePanelComponent;
  @ViewChild('caHmoPanelCmp') caHmoPanelCmp: CaHmoPanelComponent;
  showSearchLoader = false;
  showLocationsLoader = false;
  hasVirtualCareHub: boolean;
  hasTalkSpaceSSO: boolean;
  noResults = false;
  isShowDistance = true;
  proSearchResultsCheckboxs: ISearchResultsCheckbox[];
  toggleMapBool = false;
  showMapCmp = false;
  showResultTemplate = true;
  locationData: IMapRequest[];
  vitalsSsoConfig: IVitalsSsoConfig;
  networkTypeConst = NETWORK_TYPECODE;
  summaryResponse: IFinalSearchResponse;
  spfiDisclaimer: ISpfiDisclaimerResponse = {} as ISpfiDisclaimerResponse;
  spfiDisclaimerHoverText: string = '';

  isHPNPlan = false;
  showMoreLink = false;
  showCostLoader = false;

  @ViewChild('assignPcpModal')
  assignPcpModal: AssignPcpComponent;

  @ViewChild('viewHealthwiseContentModal')
  viewHealthwiseContentModal: ViewHealthwiseComponent;

  @ViewChild('viewHealthwiseContentModal2')
  viewHealthwiseContentModal2: ViewHealthwiseComponent2;

  showBeFirstToReview = true;
  isMemberSecure = false;
  tabView = TAB_VIEW;
  tabKeyEvent: boolean;
  isVirtualCare: boolean;
  showGender: boolean;
  suppressCheckbox: boolean;
  topChoiceTooltip: string;
  moreOptionsTooltip: string;
  ssoConfig: IVirtualVisitSSO;
  outNetworkCode: string = OUTNETWORK;
  hasCostInfo = false;

  private _inNetwork = 'TP_INNETWORK';
  private _subGridContainer: Subscription;
  private _isMapView = false;
  private sortClickSubscription: Subscription;
  private _onMapPushpinClickSubscription: Subscription;
  private pfOfficeVisitCostSubscription: Subscription;

  private _adobeAnalyticsObj: IHealthwiseAdobeAnalyticsObj = {
    isSummary: true
  };

  private _pfOfficeVisitCost: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_CPT_OFC_VISIT_COST]);

  constructor(
    private _appSession: AppSession,
    private _alertHlpr: AlertHelper,
    private _phonePatternPipe: PhonePatternPipe,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper,
    private _eventHandler: EventHandler,
    private _ref: ChangeDetectorRef,
    private _appUtility: AppUtility,
    private _dataService: DataService,
    private _sidePanel: SidePanel,
    private _searchResultsFilterService: SearchResultsFilterService,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService,
    private _providerOrchestration: ProviderOrchestrationService,
    private _vpcMagicLinkService: VpcMagicLinkSvc,
    private _networkProgramSvc: NetworkProgramSvc,
    private _titleCasePipe: TitleCasePipe,
    @Inject('Window')
    private _window: IWindow,
    private _assignPcpNavSvc: AssignPcpNavigationService,
    private _dataHelper: DataHelper,
    private _memberPCPHelper: MemberPCPHelper,
    private _navigationService: NavigationService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');
    this.sortClickSubscription = this._searchResultsFilterService.onSortFilterClick.subscribe((sortOption) => {
      if (typeof sortOption !== 'undefined' && sortOption !== null && sortOption !== '') {
        this.sortSearchFilter(sortOption);
      }
    });

    this.pfOfficeVisitCostSubscription = this._pfOfficeVisitCost.subscribe((resp) => {
      const costDetails = resp.object;
      if (costDetails.providerList) {
        this.summaryResponse.providerList.forEach((provider) => {
          costDetails.providerList.forEach((prov) => {
            if (provider.providerIdentifier === prov.providerKey && provider.location?.address?.addressId === prov.addressKey) {
              provider.costInfo = prov.costInfo;
            }
          });
        });
        this.hasCostInfo = true;
      }
    });

    this._onMapPushpinClickSubscription = this._providerDetailsNavigationService.onMapPushpinClick.subscribe((providerMetadata) => {
      if (
        providerMetadata !== null &&
        typeof providerMetadata !== 'undefined' &&
        providerMetadata !== '' &&
        this.drivingTimeSortList &&
        this.drivingTimeSortList.length > 0 &&
        providerMetadata.addressId &&
        providerMetadata.providerIdentifier
      ) {
        this.drivingTimeSortList = this.drivingTimeSortList.map((professional) => {
          professional.location = CommonUtil.swapOfficeLocation(this.showSPFI, professional);
          professional.isPushpinClicked =
            professional &&
            professional.providerIdentifier &&
            professional.location &&
            professional.location.address &&
            professional.location.address.addressId &&
            professional.providerIdentifier === providerMetadata.providerIdentifier &&
            professional.location.address.addressId === providerMetadata.addressId
              ? true
              : false;
          return professional;
        });
      }
    });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    //capture tab key event globally in resultpage.
    this.tabKeyEvent = event.key === 'TAB' || event.keyCode === 9 ? true : false;
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.isVirtualCare = this._appSession.isSecureState && this._appSession.hasVirtualUrgentCare && this.customizedFeatures.showKHealthFeatures;
    this.showGender = !CommonUtil.isCptSearch(this._appSession);
    this.getPersonalizedMatchPdfLink();
    this.hasVirtualCareHub = this._appSession.isSecureState && this._appSession.hasVirtualCareHubWeb;
    this.hasTalkSpaceSSO = this._appSession.isSecureState && this._appSession.hasTalkSpaceSSO;
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    this.isEnhancedMapView = true;
  }

  ngAfterViewInit(): void {
    this.resultLoadedEvent.emit();
    this._subGridContainer = this.gridContainer.changes.subscribe(() => this.resultLoadedEvent.emit());
    const ele: HTMLElement = document.getElementById('txt-summary-results-success');
    if (ele) {
      ele.focus();
    }
  }

  ngOnDestroy(): void {
    if (this._subGridContainer) {
      this._subGridContainer.unsubscribe();
      this._subGridContainer = null;
    }
    if (this.sortClickSubscription) {
      this.sortClickSubscription.unsubscribe();
    }
    if (typeof this._onMapPushpinClickSubscription !== 'undefined' && this._onMapPushpinClickSubscription !== null) {
      this._onMapPushpinClickSubscription.unsubscribe();
    }

    if (this.pfOfficeVisitCostSubscription) {
      this.pfOfficeVisitCostSubscription.unsubscribe();
    }
  }

  bindProviderSummary(result: IFinalSearchResponse) {
    this.proSearchResultsCheckboxs = [];
    this.buildCompareCheckBox(result);
    this.providerList = Object.assign([], result.providerList);

    //sorting specialty based on the search criteria
    this.providerList.forEach((provider) => (provider.specialties = CommonUtil.getSpecialtyOrders(provider.specialties, this._appSession)));
    if (this.providerList.length > 0) {
      this.hasApplyError = false;
      this.isHPNPlan = false;
      if (this.isOfficeVisitCostEligible()) {
        this.setOfficeVisitCost(result.providerList);
      }
    } else {
      if (result.isHPNPlan) {
        this.isHPNPlan = result.isHPNPlan;
      }
      this.hasApplyError = true;
      this.noResults = true;
    }
    this.bindSortOptions(result.sortOptions, result.defaultSort);
    this.showResultTemplate = true;
  }

  isOfficeVisitCostEligible() {
    if (
      this.isMemberSecure &&
      this._appSession &&
      this.showOfficeVisitCost &&
      this._appSession.searchParams &&
      this._appSession.searchParams.eycSearchParam === undefined &&
      this._appSession.searchParams.providerTypeCodeList.length > 0 &&
      this._appSession.searchParams.providerTypeCodeList.indexOf(PROVIDER_TYPE.DOCTOR_PROFESSIONAL) !== -1
    ) {
      return true;
    }
    return false;
  }

  setOfficeVisitCost(providersList: IFinalProvider[]) {
    this.showCostLoader = true;
    const providersWithPDTKey = providersList.filter((provider) => {
      return !isEmpty(provider.pdtKey);
    });
    if (providersWithPDTKey && providersWithPDTKey.length > 0) {
      providersWithPDTKey.forEach((prov) => {
        if (prov.providerIdentifier !== undefined && prov.location && prov.location.address && prov.location.address.addressId !== undefined) {
          const req: IOfficeVisitRequest = {
            providerIdentifier: prov.providerIdentifier,
            addressIdentifier: prov.location.address.addressId,
            isFuturePlan: this._appSession.isFutureCoverage,
            pdtKey: prov.pdtKey,
            locale: this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH,
            contractUid: this._appSession.metaData.contractUid,
            mbrUid: this._appSession.metaData.appContract.mbrUid,
            identificationNumber: this._appSession.searchParams.identificationNumber,
            providerTier: prov && prov.providerTier ? prov.providerTier : undefined,
            providerSituations: prov && prov.providerSituations ? prov.providerSituations : undefined,
            oopEligible: this._appSession.oopEligible
          };
          this._providerOrchestration.getOfficeVisitCost(req).then(
            (result: IProviderCostEstimateResponse) => {
              this.showCostLoader = false;
              if (this.providerList && this.providerList.length > 0 && result && result.providerEycCostInfo && result.providerEycCostInfo.length > 0 && result.providerIdentifier) {
                const index = this.providerList.findIndex((x) => x.providerIdentifier === result.providerIdentifier && x.location.address.addressId === result.addressIdentifier);
                if (index !== -1) {
                  this.providerList[index].eycCostInfo = result.providerEycCostInfo[0];
                  this.providerList[index].eycCostInfo.procedureCode = result.procedureCode || '';
                }
              }
            },
            (error: unknown) => {
              this.showCostLoader = false;
              throw error;
            }
          );
        }
      });
    } else {
      this.showCostLoader = false;
    }
  }

  getReviewRatingImage(rating: string) {
    let imageName = '';
    let imagePath = '';
    if (rating) {
      imageName = rating + 'stars_sm.png';
      imagePath = this.getQualityImageURL(imageName);
    }
    return imagePath;
  }

  formatDistance(distance: string) {
    return distance ? parseFloat(distance).toFixed(2) : '';
  }

  formatPhone(phone: string) {
    return this._phonePatternPipe.transform(phone);
  }

  openRecognitionModal(recognition: IRecognition) {
    this.openRecognition.emit(recognition);
  }

  openPairRecognitionModal(professional: IFinalProvider) {
    this.openPairRecognition.emit(professional);
  }

  showRecognition(professional: IFinalProvider): boolean {
    return this._networkProgramSvc.getCount({ ...professional?.performance, tier: [] }) > 0;
  }

  openDomainDisclaimerModal(url: string) {
    this.openDomain.emit(url);
  }

  openReviewModal(professional: IFinalProvider) {
    this.openReview.emit(professional);
  }

  vitalsSsoConfigValue(professional: IFinalProvider, showAddReviewLabel?: boolean, useClassForAddReview?: boolean) {
    this.vitalsSsoConfig = this.WcsUtility.getVitalsSsoConfig(professional, showAddReviewLabel, useClassForAddReview);
    return this.vitalsSsoConfig;
  }

  checkIndividualProvWithProvTypeP(provCategoryCode: string, providerTypeCodeList: Array<string>) {
    return CommonUtil.checkIndividualProvWithProvTypeP(provCategoryCode, providerTypeCodeList);
  }

  openLocationMapModal(professional: IFinalProvider) {
    this.openMapModal.emit(professional);
  }

  isReviewRating(rating: ReviewResponse, provCategoryCode: string, providerTypeCodeList: Array<string>) {
    return CommonUtil.isReviewRating(rating, provCategoryCode, providerTypeCodeList);
  }

  onCheckboxClick(event: MouseEvent | KeyboardEvent, provider: IFinalProvider, id: string) {
    const chkBox = event.target as HTMLInputElement;
    if (chkBox != null && chkBox.checked !== undefined) {
      const isChecked = chkBox.checked;
      if (isChecked && this.selectedChkBox.length === 3) {
        this._alertHlpr.openAlert(this.content.alerts.compareInfo, 'information', 10000);
        this.proSearchResultsCheckboxs.find((cb) => cb.id === id).value = 'false';
        return false;
      }
      this.providerSelect.emit({
        checked: isChecked,
        ctrlId: id,
        provider
      });
      return true;
    }
  }

  showProviderDetails(professional: IFinalProvider) {
    const pageFadObj = {
      header: this.content.globalHeaderComponent.pageHeader.fadPageTitle,
      title: this.content.globalHeaderComponent.pageTitle.details
    };
    if (professional?.costInfo?.isOfficeVisitCost && CommonUtil.isValidString(professional.costInfo?.planPays)) {
      this._appSession.medicalCode = OFFICE_VISIT_MEDICALCODE.MEDICAL_CODE;
      this._appSession.billingType = BILLING_TYPE.CPT;
      this._appSession.selectedPosCode = this._appSession.metaData.locale === Locale.ENGLISH ? OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_EN : OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_ES;
      this._appSession.searchTerm = OFFICE_VISIT_MEDICALCODE.SEARCH_TERM;
      this._appSession.isSpecialtyOfficeVisitCost = true;
    }
    this._dataService.setPageTitle(pageFadObj);
    this._providerDetailsNavigationService.navigateToProviderDetails(professional);
  }

  sortSearchFilter(sortOption: { value: string }) {
    this.showMapCmp = false;
    this.toggleMapBool = false;
    if (sortOption && sortOption.value !== this.sortSelected) {
      this.showResultTemplate = false;
      this.sortSelected = sortOption.value;
      this.sortProviderSearch.emit(sortOption);
    }
  }

  bindSortOptions(sortOptions: string[], defaultSort: string) {
    const filters: ISortOption[] = [];

    // Reset personalized match flag
    if (typeof this._appSession.searchParams !== 'undefined' && this._appSession.searchParams !== null) {
      this._appSession.searchParams.hasPersonalizedMatch = false;
    }

    if (sortOptions && sortOptions.length > 0) {
      sortOptions.forEach((option, index) => {
        if (option) {
          const name = this.content.summaryComponent.sortOptions[option.toLowerCase().replace(/ /gi, '_')];
          filters.push({
            label: name && name !== '' ? name : option,
            value: option,
            id: 'cb_sort_' + index,
            name: 'cb_sort_' + index,
            trueValue: option,
            falseValue: '',
            isChecked: false
          });

          //Set personalize match only if sort options has personalize match
          if (option === 'PersonalizedMatch' && typeof this._appSession.searchParams !== 'undefined' && this._appSession.searchParams !== null) {
            this._appSession.searchParams.hasPersonalizedMatch = true;
          }
        }
      });
      if (
        defaultSort !== PERSONALIZED_MATCH &&
        this._appSession.searchParams &&
        !this._appSession.searchParams.hasPersonalizedMatch &&
        this._appSession.filterSearchParams &&
        this._appSession.filterSearchParams.hasPersonalizedMatch &&
        this._appSession.isSecureState
      ) {
        this._appSession.searchParams.hasPersonalizedMatch = this._appSession.filterSearchParams.hasPersonalizedMatch;
      }
    }
    const sortInput: ISortInput = {} as ISortInput;
    this.sortFilter = sortInput.sortFilter = filters;
    this.sortSelected = sortInput.sortSelected = defaultSort;
    sortInput.hasApplyError = this.hasApplyError;
    sortInput.noResults = this.noResults;
  }

  getGenderText(genderCode: string) {
    return this.WcsUtility.getGenderText(genderCode);
  }

  getVisionServicesAvailable(visionServiceList: IBaseCode[]) {
    return this.WcsUtility.getFormattedVisionServices(visionServiceList);
  }

  showSpfiDisclaimer(spfiDisclaimer: ISpfiDisclaimerResponse): boolean {
    if (spfiDisclaimer) {
      this.spfiDisclaimer.description = this.content?.summaryComponent?.personalizedMatchContent?.messages?.noRanking?.noRankingDesc;
      this.spfiDisclaimer.hoverText = this.spfiDisclaimerHoverText;
      return true;
    }
    return false;
  }

  administrativeOffice(activeFlagList: Array<string>): boolean {
    return this.WcsUtility.isAdministrativeOffice(activeFlagList);
  }

  onAppointmentSchedule(data: IAppointmentLoader) {
    this.scheduleAppointment.emit(data);
  }

  onAssignCare(data: IAssignCareLoader) {
    this.assignCare.emit(data);
  }

  onTabChange(value: { textLabel: string }) {
    if (typeof value === 'undefined') {
      return;
    }

    switch (value.textLabel) {
      case this.tabView.LIST_VIEW:
        this._isMapView = false;
        this.mapViewSelected.emit(this._isMapView);
        break;
      case this.tabView.TILE_VIEW:
        this._isMapView = false;
        this.mapViewSelected.emit(this._isMapView);
        break;
      case this.tabView.MAP_VIEW:
        this.showMapView();
        break;
      default:
        break;
    }
  }

  showMapView() {
    this._isMapView = true;
    this.mapViewSelected.emit(this._isMapView);
    if (this.locationData && this.locationData.length > 0) {
      setTimeout(() => {
        if (!this.showMapCmp) {
          this.toggleMapBool = !this.toggleMapBool;
          this.showMapCmp = true;
        }

        if (this._ref !== null && this._ref !== undefined && !(this._ref as ViewRef).destroyed) {
          this._ref.detectChanges();
        }
      }, 100);
    } else {
      this.showMapCmp = true;
    }
  }

  showInNetworkLabel(professional: IFinalProvider): boolean {
    if (professional?.providerNetworkStatus?.statusCode?.toLocaleLowerCase() !== this._inNetwork.toLocaleLowerCase()) {
      return true;
    }
    return false;
  }

  //TODO: New Search - move to transformResponse
  isGreyedOut(professional: IFinalProvider, enableFutureProvider: boolean): boolean {
    // Feature flag to disable future effective provider navigation to details page.
    if (professional?.pharmacyType === PHARMACY_TYPE.AHD && professional?.isShipDirectToDoor) {
      return true;
    }

    if (!this.isFutureEffectiveProviderEnabled) {
      enableFutureProvider = false;
    }
    return !!(professional?.eycCostInfo?.isGreyedout || (!enableFutureProvider && professional?.isFutureEffective));
  }

  showAssignPCPBtn(professional: IFinalProvider): boolean {
    if (
      professional &&
      professional.visibilityRule &&
      professional.visibilityRule.showAssignPCP &&
      this.customizedFeatures.showAssignPCPBtn &&
      this._memberPCPHelper.isNotAMemberPCPProvider(professional) &&
      this._memberPCPHelper.isNotAMemberFuturePCPProvider(professional)
    ) {
      return true;
    }
    return false;
  }

  showAssignPCPBadge(professional: IFinalProvider): boolean {
    return this._memberPCPHelper.showAssignPCPBadge(professional);
  }

  showFutureAssignPCPBadge(professional: IFinalProvider): boolean {
    return this._memberPCPHelper.showFutureAssignPCPBadge(professional);
  }

  showAddCareTeamBtn(professional: IFinalProvider): boolean {
    if (
      this.isAddCareTeamEnabled &&
      professional &&
      professional.visibilityRule &&
      professional.visibilityRule.showAddCareTeam &&
      this.customizedFeatures.showAdddCareTeam &&
      this._memberPCPHelper.isNotAMemberPCPProvider(professional) &&
      this._memberPCPHelper.isNotAMemberFuturePCPProvider(professional)
    ) {
      return true;
    }
    return false;
  }

  getPCPLabelText(professional: IFinalProvider): string {
    return professional.gender && professional.gender !== '' ? ', ' + this.content.summaryComponent.pcpid : this.content.summaryComponent.pcpid;
  }

  /** Open Side Panel */
  onAssignPcpClick(direction: string, professional: IFinalProvider) {
    const navPromise = this._assignPcpNavSvc.navigateToAssignPcp(professional);
    this.handleAssignPcpNavPromise(navPromise, professional, direction);
  }

  onContinueAssignPcp() {
    const navPromise = this._assignPcpNavSvc.navigateToAssignPcp(this._appSession.selectedProvider, true);
    this.handleAssignPcpNavPromise(navPromise, this._appSession.selectedProvider, RIGHT);
  }

  async handleAssignPcpNavPromise(navPromise: Promise<IPcpNavResponse>, professional: any, direction: string) {
    try {
      const resp = await navPromise;
      //Open the old Assign as PCP Flow
      if (!resp?.navigatedToAssignPcpPage) {
        this.assignPcpModal.openSidePanel(direction, professional, true);
      }
    } catch (error) {
      //Handle Errors using the error.code value
      if (error.code === ANP_ERROR_CODE) {
        this.anpSidePanelCmp.openSidePanel();
      } else if (error.code === CA_HMO_DISCLAIMER) {
        this.caHmoPanelCmp.openSidePanel();
      } else {
        //Display other Error messages in UI
        const slideoutMsgContent = this.content.assignPcpCmpContent.slideoutMsgCmpContent;
        let message = this._dataHelper.getValueByKey<string>(error.code, slideoutMsgContent);
        let title: string = slideoutMsgContent['title_' + error.code] ?? slideoutMsgContent.title;
        const mbrName = this._titleCasePipe.transform(this._appSession.getMemberName());
        message = message.replace(DOCTOR_NAME, professional.providerName);
        message = message.replace(TERMINATION_DATE, error.terminationDt ?? '');
        message = message.replace(MEMBER_NAME, mbrName);
        const slideMessage: ISlideoutMessage = {
          title,
          close: slideoutMsgContent.close,
          message
        };
        this.slideoutMsgCmp.showMessage(slideMessage);
      }
      throw error;
    }
  }

  showReviewLink(totalReviews: string) {
    return CommonUtil.showReviewLink(totalReviews);
  }

  //  on keypress focus checkbox for reading aria-label
  onCheckboxKeypress(proSearchId: string) {
    document.getElementById(proSearchId).focus();
  }

  onContactUsClick() {
    this.actions.emit(new ContactUs());
  }

  invokeHPNSearch(type: string) {
    const hpnSearch = new HpnSearch();
    hpnSearch.navigationType = type;
    this.actions.emit(hpnSearch);
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  onCompareProviderDetails() {
    if (this.selectedChkBox.length > 1 && this.selectedChkBox.length < 4) {
      this.compareProviderDetails.emit();
    }
  }

  onMapOpenSidePanel(data: { direction: SidePanelDirections; professional: IFinalProvider }) {
    if (data && typeof data !== 'undefined' && data.direction && data.professional) {
      this.onAssignPcpClick(data.direction, data.professional);
    }
  }

  onClearCompareProvDetails() {
    if (this.selectedChkBox.length > 0) {
      this.proSearchResultsCheckboxs.forEach((cb) => {
        if (cb.value !== '') {
          cb.value = 'false';
          this.providerSelect.emit({
            checked: cb.value === 'false' ? false : true,
            ctrlId: cb.id,
            provider: {} as IFinalProvider
          });
        }
      });
      this.selectedChkBox = [];
    }
  }

  showmoreLinkVisibility(prov: IFinalProvider) {
    if (prov && prov.reviewRatings && prov.reviewRatings.length > 1) {
      const _index = prov.reviewRatings.findIndex((review) => review.starRating && review.starRating.toString().toLowerCase() === NORATING.toLowerCase());
      if (_index > -1) {
        prov.reviewRatings.splice(_index, 1);
      }
      return prov.reviewRatings && prov.reviewRatings.length > 1;
    }
    return false;
  }

  getMoreRating(event: Event): void {
    this.showMoreLink = !this.showMoreLink;
    event.preventDefault();
  }

  getAddress(professional: IFinalProvider) {
    return CommonUtil.swapOfficeLocation(this.showSPFI, professional);
  }

  showAffiliation(professional: IFinalProvider) {
    return this.isEYC && this.showSPFI && professional && professional.eycAffliation && professional.eycAffliation.affiliationList[0] && professional.eycAffliation.isVisibleAffiliation;
  }

  getPairedProviderLabel(code: string): string {
    if (code) {
      return code === PROVIDER_CATEGORY_CODE.Individual ? this.content.summaryComponent.providingCareAt : this.content.summaryComponent.performedBy;
    }
    return '';
  }

  getRecognitionText(professional: IFinalProvider): string {
    const count = this._networkProgramSvc.getCount({ ...professional?.performance, tier: [] });
    return count > 1
      ? this.content.recognitionSlideModalContent.recognitionsCount.replace(COUNT, count.toString())
      : this.content.recognitionSlideModalContent.recognitionCount.replace(COUNT, count.toString());
  }

  getRecognitionAriaLabel(professional: IFinalProvider): string {
    let ariaLabel = '';
    const count = professional ? this.getRecognitionText(professional) : '';
    if (count && professional && professional.providerName) {
      ariaLabel = this.getRecognitionText(professional) + ' ' + this.content.summaryComponent.forLabel + professional.providerName;
    }
    return ariaLabel && ariaLabel !== '' ? ariaLabel : count;
  }

  /** Open healthwise side Panel */
  openHealthwiseSidePanel({ specialty, provider, procedureCode }: { specialty: ISpecialty; provider: IFinalProvider; procedureCode?: string }) {
    if (procedureCode) {
      this._adobeAnalyticsObj.procedureName = this.procedureName;
    }
    if (specialty && specialty.specialtyCodeName) {
      this._adobeAnalyticsObj.specialtyName = specialty.specialtyCodeName;
    }
    this.viewHealthwiseContentModal2.openSidePanel(RIGHT, provider, procedureCode);
  }

  getEycCostInfo(professional: IFinalProvider): ICostInfo {
    return CommonUtil.swapEycCostInfo(this.showSPFI, professional);
  }

  getPersonalizedMatchPdfLink() {
    let pdfUrl: string;
    if (this.client === FindCareWebIntegrationClient.SYDNEYMEDICAID) {
      const sydMedicaidState = `explanation_of_personalization_medicaid_${this._appSession?.searchParams?.stateCd?.code}_${this._appSession?.metaData?.locale}`;
      const baseUrl = this._dataHelper.getValueByKey<string>(sydMedicaidState, this.content?.summaryComponent?.personalizedMatchContent?.personalizedMatchPdf?.medicaid);
      pdfUrl = CommonUtil.personalizedMatchPdfLink(this._appSession, baseUrl);
      if (pdfUrl) {
        this.topChoiceTooltip = this.content?.summaryComponent?.personalizedMatchContent?.tooltips?.topChoiceTooltipSydMedicaidLearnMore.replace(/{pdfUrl}/gi, pdfUrl);
        this.moreOptionsTooltip = this.content?.summaryComponent?.personalizedMatchContent?.tooltips?.moreOptionsTooltipSydMedicaidLearnMore.replace(/{pdfUrl}/gi, pdfUrl);
      } else {
        this.topChoiceTooltip = this.content?.summaryComponent?.personalizedMatchContent?.tooltips?.topChoiceTooltipSydMedicaid.replace(/{pdfUrl}/gi, pdfUrl);
        this.moreOptionsTooltip = this.content?.summaryComponent?.personalizedMatchContent?.tooltips?.moreOptionsTooltipSydMedicaid.replace(/{pdfUrl}/gi, pdfUrl);
      }
    } else {
      pdfUrl = this._dataHelper.getValueByKey<string>(
        this._appSession.mbu,
        this.content?.summaryComponent?.personalizedMatchContent?.personalizedMatchPdf,
        this.content?.summaryComponent?.personalizedMatchContent?.personalizedMatchPdf?.commercial
      );
      this.topChoiceTooltip = this.content?.summaryComponent?.personalizedMatchContent?.tooltips?.topChoiceTooltip.replace(/{pdfUrl}/gi, pdfUrl);
      this.moreOptionsTooltip = this.content?.summaryComponent?.personalizedMatchContent?.tooltips?.moreOptionsTooltip.replace(/{pdfUrl}/gi, pdfUrl);
    }
    this.spfiDisclaimerHoverText = this.content?.summaryComponent?.personalizedMatchContent?.messages?.noRanking?.spfiDisclaimerHoverText.replace(/{pdfUrl}/gi, pdfUrl);
  }

  showAddReviewButton(professional: IFinalProvider): boolean {
    if (professional?.reviewRatings?.length && !isEmpty(professional.reviewRatings[0])) {
      return (
        this.checkIndividualProvWithProvTypeP(professional.providerCategoryCode?.code, professional.providerTypeCodeList) &&
        professional.reviewRatings[0].pdtStrKey &&
        this.showBeFirstToReview &&
        !this.isGreyedOut(professional, false)
      );
    }
    return false;
  }

  private buildCompareCheckBox(result: IFinalSearchResponse) {
    const provList = result.providerList;
    for (let i = 0; i < provList?.length; i++) {
      const thisCheckbox = {
        id: 'pfSearchResultsCb-' + result.pageNumber + '-' + i,
        name: 'pfSearchResultsCb-' + result.pageNumber + '-' + i,
        label: '',
        trueValue: provList[i].providerIdentifier,
        falseValue: '',
        value: '',
        show: false,
        analyticsText: 'selectPhysicianResults'
      };
      this.proSearchResultsCheckboxs.push(thisCheckbox);
    }
    if (this.selectedChkBox.length === 0) {
      return;
    }
    this.proSearchResultsCheckboxs.filter((cb: ISearchResultsCheckbox) => this.selectedChkBox.some((id: string) => cb.id === id)).forEach((x: ISearchResultsCheckbox) => (x.value = x.trueValue));
  }

  /**
   * this method will add prime group affiliation to Medical Group filter
   * @param isPrimeGroupSearchResponse
   */
  private addPrimeGroupAffiliationToFilter(isPrimeGroupSearchResponse: boolean): void {
    this._searchResultsFilterService.setPrimeGroupAffiliation(isPrimeGroupSearchResponse);
  }

  private setRemovedPrimeGroupRule(ruleList: IRule[]): void {
    let isPrimeGroupRemoved = false;
    if (ruleList && ruleList.length > 0) {
      const improvedSearchCriteria: IRule = ruleList.find((rule: IRule) => rule.code === SEARCH_IMPROVEMENTS.IMPROVED_SEARCH_CRITERIA);
      if (improvedSearchCriteria && improvedSearchCriteria.fieldList && improvedSearchCriteria.fieldList.length > 0) {
        const removedPrimeGroup: IField = improvedSearchCriteria.fieldList.find((field: IField) => field.name === SEARCH_IMPROVEMENTS.REMOVED_PRIMEGROUP);
        if (removedPrimeGroup && removedPrimeGroup.value && removedPrimeGroup.value === 'Y') {
          isPrimeGroupRemoved = true;
        }
      }
    }
    this._searchResultsFilterService.setRemovedPrimeGroupRule(isPrimeGroupRemoved);
  }

  goToVirtualCare() {
    this._navigationService.navigateByUrl(AppNavigations.VIRTUAL_CARE_PATH);
  }

  getProviderName(provider: IProvider): string {
    return CommonUtil.getProviderTitles(provider.titleList, provider.networkRegisteredName, provider.providerName);
  }

  goToPublicUrl(url: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  handleVirtualProviderCallToAction(callToAction: IVirtualProviderConfig) {
    if (callToAction?.internalRedirectId && callToAction.internalRedirectId === VIRTUAL_CARE_HUB) {
      this.goToVirtualCare();
    } else if (callToAction?.virtualCareUrl) {
      this.goToPublicUrl(callToAction.virtualCareUrl);
    }
  }

  isCallToActionEnabled(callToAction: IVirtualProviderConfig) {
    return !isEmpty(callToAction);
  }

  isCallToActionInternal(callToAction: IVirtualProviderConfig) {
    return !isEmpty(callToAction) && callToAction?.internalRedirectId === VIRTUAL_CARE_HUB;
  }

  virtualURlSsoConfigValue(callToAction: IVirtualProviderConfig): IVirtualVisitSSO {
    this.ssoConfig = this.WcsUtility.getVirtualCareUrlSsoConfig(callToAction.ssoKey);
    return this.ssoConfig;
  }

  checkOutNetworkStatus(statusCode: string): boolean {
    return statusCode === this.outNetworkCode;
  }
}
