import { EventEmitter, HostListener, Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomizedFeatures } from '../../../../../app/fad/rules/models/customizedFeatures';
import { AppConstants, AppExternalCommands, AppInfoMsg, ContentLanguageCode, FindCareWebIntegrationClient, Locale, USER_INTERACTION } from '../../../../common/constants/app-constants';
import { AppEvents } from '../../../../common/enums/appEvents';
import { IEventDetail } from '../../../../common/interfaces/iEventDetail';
import { EventHandler } from '../../../../common/services/eventHandler';
import { UserInteractionUtil } from '../../../../common/utilities/userInteractionUtil';
import { AppConfig } from '../../../../common/values/appConfig';
import { AppSession } from '../../../../common/values/appSession';
import { FcLabel } from '../../contents/labels';
import { FindCareFeature } from '../../enums/findCareFeature';
import { FindCareFlag } from '../../enums/findCareFlag';
import { ContentUtility } from '../../utilities/contentUtil';
import { FeatureManagerUtility } from '../../utilities/featureManagerUtil';

declare let window: any;

@Injectable()
export class BaseComponent {
  /** ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  //** Private Variables under this comment */
  /** ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  private _contentUtility: ContentUtility;
  private _content: FcLabel;
  private _pfLoadSuccessEvent: EventEmitter<IEventDetail> = this.eventHandler.get(AppEvents[AppEvents.PF_APP_LOAD_SUCCESS]);
  private _pfAppCommandEventForProvider: EventEmitter<IEventDetail> = this.eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);
  private _userInteractionUtil: UserInteractionUtil;
  private _appConfig: AppConfig;

  /** ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /** Public Variables under this comment */
  /** ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  userInteraction = USER_INTERACTION;

  /** ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  //** constructor */
  /** ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  constructor(
    private route: ActivatedRoute,
    private eventHandler: EventHandler,
    @Inject(AppSession)
    private appSession: AppSession
  ) {
    this._appConfig = this.appSession.appConfig;
    // Subscribe to the route data to get the content
    this.route.data.subscribe((data) => {
      // Assign the content from the route data to the content property
      this._content = data['content'];

      this._contentUtility = new ContentUtility(this._content, this.appSession);
      this._userInteractionUtil = new UserInteractionUtil(this.appSession);
    });
  }

  /** ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  //** Public methods under this comment */
  /** ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  /**
   * Emits a load success event if it's the first time the app is loaded.
   */
  onPreLoad() {
    if (!this.appSession.isFirstTimeLoad) {
      return;
    }

    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_LOAD_SUCCESS];
    eventDetail.details = eventDetail.message = AppInfoMsg.FAD_APP_LOADED;

    this._pfLoadSuccessEvent.emit(eventDetail);
    this.appSession.isFirstTimeLoad = false;
  }

  /**
   * Return true if the feature flag(s) are enabled and available for the application state.
   * @param feature Represents the feature flag(s) to check.
   * @param logicBetween Represents the logic to apply between the feature flags presented in the {feature} parameter. @default 'OR'.
   * @returns boolean
   */
  hasFeature(feature: FindCareFeature | Array<FindCareFeature>, logicBetween: 'AND' | 'OR' = 'OR'): boolean {
    return FeatureManagerUtility.isFeatureLive(feature, this.appSession, logicBetween);
  }

  /**
   * Return true if the flag(s) are enabled and available for the application state.
   * @param flag Represents the flag(s) to check.
   * @param logicBetween Represents the logic to apply between the flags presented in the {flag} parameter. @default 'OR'.
   * @returns boolean
   */
  hasFlag(flag: FindCareFlag | Array<FindCareFlag>, logicBetween: 'AND' | 'OR' = 'OR'): boolean {
    return FeatureManagerUtility.isFlagLive(flag, this.appSession, logicBetween);
  }

  /**
   * Removes a particular flag from bootstrap response in appSessiona and appState in appSession.
   * @note DO NOT use the capability to temporarily ignore a flag. The flag would be refreshed only after next bootstrap pull.
   * @param flag Represents the flag to be removed.
   */
  removeFlag(flag: FindCareFlag): void {
    this.appSession.appState.flags = this.appSession.appState.flags.filter((f) => f !== flag);
    this.appSession.bootstrap.flags = this.appSession.bootstrap.flags.filter((f) => f !== flag);
    (this.appSession.bootstrap.contracts || []).forEach((contract) => {
      contract.productFlags = (contract.productFlags || []).filter((f) => f !== flag);
    });
  }

  /**
   * Constructs the full URL for a common image.
   * @param {string} imageName - The name of the image.
   * @returns {string} The full URL of the image.
   */
  getCommonImageURL(imageName: string): string {
    return imageName ? `${this.baseURL}${this._appConfig?.images.commonBaseUrl}${imageName}` : '';
  }

  /**
   * Constructs the full URL for a quality image.
   * @param {string} imageName - The name of the image.
   * @returns {string} The full URL of the image.
   */
  getQualityImageURL(imageName: string): string {
    return imageName ? `${this.baseURL}${this._appConfig?.images.qualityBaseUrl}${imageName}` : '';
  }

  /**
   * Gets the brand-specific URL append string.
   * @returns {string} The brand-specific URL append string.
   */
  getBrandAppendURL(): string {
    const brandCode = this.appSession.metaData.brandCd;
    const brand = AppConstants.BRAND.find((x) => x.brand === brandCode);
    return brand ? brand.appendUrl : '';
  }

  /**
   * Updates the content with brand-specific URLs and document paths.
   * @param {string} content - The content to update.
   * @returns {string | undefined} The updated content.
   */
  getDocumentURL(content: string): string | undefined {
    if (!content || typeof content !== 'string') {
      return;
    }

    const linkPattern = / href='(.*?)'/g;
    const urlPattern = /{BRAND_URL}/gi;
    const appendUrl = this.getBrandAppendURL();
    let updatedContent = content;

    /** Content to bind Brand specific URL. eg: ABC brand URL append '/ca' */
    let match;
    while ((match = linkPattern.exec(content)) !== null) {
      const url = match[1].replace(urlPattern, appendUrl);
      updatedContent = updatedContent.replace(match[1], url);
    }

    return updatedContent.replace(/\/assets\/documents\//g, `${this.baseURL}/assets/documents/`);
  }

  toggleFtsAppState() {
    this.appSession.appState.opsState.isFtsActive = !this.isFtsFeatureActive;
    if (window?.digitalData?.page?.pageInfo) {
      if (this.isFtsFeatureActive) {
        window.digitalData.page.pageInfo.findCareExperience = 'new';
      } else {
        window.digitalData.page.pageInfo.findCareExperience = 'old';
      }
    }
  }

  /**
   * Gets the current geographical position of the user.
   * @param {boolean} [highAccuracy=true] - Whether to use high accuracy.
   * @param {number} [timeout=5000] - The maximum time (in milliseconds) to wait for a position.
   * @param {number} [maximumAge=0] - The maximum age (in milliseconds) of a cached position.
   * @returns {Promise<GeolocationPosition>} A promise that resolves with the geographical position.
   */
  getCurrentGeoPosition(highAccuracy: boolean = true, timeout: number = 5000, maximumAge: number = 0): Promise<GeolocationPosition> {
    const options = {
      enableHighAccuracy: highAccuracy,
      timeout: timeout,
      maximumAge: maximumAge
    };

    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

  /**
   * Gets the stripped HTML from the given HTML string.
   * @param htmlString - The HTML string.
   * @returns The stripped HTML.
   */
  getStripedHtml(htmlString: string) {
    return (htmlString || '').replace(/<[^>]+>/g, '');
  }

  /**
   * Emits IEventDetail.
   */
  emitLiveChatEvent() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.message = AppExternalCommands.RCP_FIND_CARE_LIVE_CHAT.MSG;
    eventDetail.target = AppExternalCommands.RCP_FIND_CARE_LIVE_CHAT.CMD;
    this._pfAppCommandEventForProvider.emit(eventDetail);
  }

  /** Return true if the screen resize to mobile responsiveness */
  @HostListener('window:resize', ['$event'])
  onScreenResize(): boolean {
    return window.innerWidth <= 480 ? true : false;
  }

  /** ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /** Private methods under this comment */
  /** ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

  /**
   * Checks if the current browser is a mobile web browser.
   * @returns {boolean} True if the browser is a mobile web browser, false otherwise.
   */
  private isMobileWebBrowser(): boolean {
    try {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const mobilePattern = /android|webos|iphone|ipad|ipod|pocket|psp|kindle|avantgo|blazer|midori|palm|maemo|plucker|phone|blackberry|symbian|iemobile|mobile|zunewp7|windows phone|opera mini/i;
      return mobilePattern.test(userAgent);
    } catch (e) {
      return false;
    }
  }

  /**
   * Checks if the token is initialized.
   * @returns {boolean} True if the token is initialized, false otherwise.
   */
  private waitUntilTokenInitialized(): boolean {
    return !this.appSession.provDataModifiedOn && this.appSession.isIntegratedMode === true;
  }

  /** ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  /** GETTERS under this comment */
  /** ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
  get content(): FcLabel {
    return this._content;
  }

  get contentUtility(): ContentUtility {
    return this._contentUtility;
  }

  get baseURL(): string {
    return this._appConfig?.baseUrl[this.appSession.state];
  }

  get baseSSOURL(): string {
    return this._appConfig?.sso.baseUrl;
  }

  get waitUntilAppReload(): boolean {
    return this.appSession.waitUntilAppReLoad || this.waitUntilTokenInitialized();
  }

  get userInteractionUtil(): UserInteractionUtil {
    return this._userInteractionUtil;
  }

  // Represents whether Find Care system is executed in secure mode.
  get isSecureState(): boolean {
    return this.appSession?.isSecureState === true;
  }

  // Represents whether Find Care system is executed in secure mode and member contracts exists!
  get isMemberSecure(): boolean {
    return this.isSecureState && this.appSession.appState?.opsState?.hasMemberContract === true;
  }

  /** Represents code used to define content in the application.  */
  get contentLanguageCode(): string {
    return this.appSession.metaData?.locale === Locale.SPANISH ? ContentLanguageCode.SPANISH : ContentLanguageCode.ENGLISH;
  }

  /***
   * To check the application is accessed through mobile webview or not.
   * if Mobile web view is true then disable the print icon.
   * previously PF used this._appUtility.isWebkitBrowser(),
   * but it was not identifying all mobile web views so modified the logic
   * a bit to know weather accessing device is mobile or not by using
   * this.isMobileWebBrowser() or by using isWebView flag
   * isWebView value is receive from RCP metaData
   */
  get isMobileView(): boolean {
    return this.appSession.isWebView || this.isMobileWebBrowser();
  }

  /**
   * Defines current state of FTS feature in Find Care. This property gives ability to check whether FTS is already enabled by user in this session.
   */
  get isFtsFeatureActive(): boolean {
    return this.appSession?.appState?.opsState?.isFtsActive ?? false;
  }

  /** @deprecated DO NOT use this capability in FCR components without consulting with Architect. */
  get customizedFeatures(): CustomizedFeatures {
    const { customizedFeatures } = this.appSession.feature;
    return customizedFeatures;
  }

  /**
   * Return true when Find Care state represent definition for enabling COLD state procedure search.
   */
  get isColdStateTccSearchEnabled(): boolean {
    return Boolean(this.appSession.appState?.opsIndicator?.isTccColdStateMedicalEnabled && this.isTccSearchEnabled);
  }

  /** Returns true if Procedure search is enabled in Find Care. */
  get isTccSearchEnabled(): boolean {
    return this.appSession.appState?.opsIndicator?.isTccMedicalEnabled ?? false;
  }

  /** Returns if Medical Code Search is enabled in Find Care. */
  get isMedicalCodeSearchEnabled(): boolean {
    return this.appSession.appState?.opsIndicator?.isMedicalCodeSearchEnabled ?? false;
  }

  /** Returns true if the FTS feature should be enabled for the given member.*/
  get isFtsEnabled(): boolean {
    return this.appSession.appState?.opsIndicator?.isFtsEnabled ?? false;
  }

  get isLiveChatEnabled(): boolean {
    return this.appSession.appState?.opsIndicator?.isLiveChatEnabled ?? false;
  }

  /***
   * Returns true if macro cost information should be displayed.
   */
  get showOfficeVisitCost(): boolean {
    return this.appSession.appState?.opsIndicator?.isOfficeVisitCostEnabled ?? false;
  }

  /** Returns true if maintenance has to be turned ON for Find Care application. */
  get isFindCareMaintenance(): boolean {
    return !this.hasFeature(FindCareFeature.FC_LIVE);
  }

  get isTccSearchMaintenance(): boolean {
    return !this.hasFeature(FindCareFeature.FC_SEARCH_TCC);
  }

  /** Returns true if FCR (Find Care v5) is enabled for the Application State and Scenario */
  get isFcrEnabled(): boolean {
    return this.appSession.appState?.opsIndicator?.isFcrEnabled ?? false;
  }

  get client(): string {
    return this.appSession.isIntegratedMode ? this.appSession?.deeplinkParams?.client || FindCareWebIntegrationClient.SYDNEYWEB : FindCareWebIntegrationClient.FINDCARE;
  }

  get sydneyMedicaid(): boolean {
    return this.client === FindCareWebIntegrationClient.SYDNEYMEDICAID;
  }

  /** Returns true if the Intent feature should be enabled for the given member.*/
  get isIntentEnabled(): boolean {
    return this.appSession.appState?.opsIndicator?.isIntentEnabled ?? false;
  }

  get hasMedicalCoverage(): boolean {
    return this.appSession.appState?.opsIndicator?.hasMedicalCoverage ?? false;
  }
}
