import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { PROVIDER_TYPE } from '../../../common/constants/common';
import { ComapreProviderHandler } from '../../classes/comapreProviderHandler';
import { ProviderAffiliationHandler } from '../../classes/providerAffiliationHandler';
import { ProviderPlanHandler } from '../../classes/providerPlanHandler';
import { ICompareResponse } from '../../interfaces/iCompareProvider';
import { IProvider } from '../../interfaces/iProvider';
import { IAffiliationProvider } from '../../interfaces/iProviderAffiliation';
import { CompareProvider } from '../../services/compareProvider';
import { ProviderCompareComponent } from '../providerCompare/providerCompareCmp';
import { ProviderCompareSidePanelComponent } from '../providerCompareSidePanel/providerCompareSidePanelCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-selected-provider',
  templateUrl: './selectedProviderCmp.html'
})
export class SelectedProviderComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() resultContent: any;
  comapreSelectedProviders: IProvider[] = [];
  selectedProviderSubscription: Subscription;
  selectedProvidersDetailsResponse: ICompareResponse[];
  @ViewChild(ProviderCompareComponent) providerCompareCmp: ProviderCompareComponent;
  @ViewChild(ProviderCompareSidePanelComponent) providerCompareSidePanelCmp: ProviderCompareSidePanelComponent;
  isMobile: boolean = false;
  toggleProviders: boolean = false;
  @Output() providerRemoved: EventEmitter<IProvider> = new EventEmitter<IProvider>();
  @Output() clearAllProviders = new EventEmitter<void>();
  @Output() compareProvidersVisible = new EventEmitter();

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _compareProvider: CompareProvider,
    private _providerCompareService: ComapreProviderHandler,
    private _currencyPipe: CurrencyPipe,
    private _providerPlan: ProviderPlanHandler,
    private _providerAffiliation: ProviderAffiliationHandler,
    private _eventHandler: EventHandler,
    route: ActivatedRoute
  ) {
    super(route, _eventHandler, _appSession);
  }

  ngOnInit(): void {
    this.subscribeProviderSelection();
    this.isMobile = this.onScreenResize();
    if (!this.isMobile) {
      this.toggleProviders = true;
    }
  }
  ngOnDestroy(): void {
    this.selectedProviderSubscription?.unsubscribe();
  }

  removeProviderSelection(provider: IProvider) {
    let removeProvider = { ...provider };
    this._compareProvider.remove(provider);
    this.providerRemoved.emit(removeProvider);
    if (this.comapreSelectedProviders.length == 0) {
      this.compareProvidersVisible.emit(false);
    }
  }

  compareCancel() {
    this._compareProvider.clear();
    this.clearAllProviders.emit();
    this.compareProvidersVisible.emit(false);
  }

  subscribeProviderSelection() {
    this.selectedProviderSubscription = this._compareProvider.compareProvider.subscribe((data) => {
      this.comapreSelectedProviders = data || [];
    });
  }

  /** Function to open compare modal on click of compare button */
  openCompareModel() {
    this.getCompareDetails();
  }

  /** Function to call Compare API */
  getCompareDetails() {
    this._providerCompareService.getProviders(this.comapreSelectedProviders).then(
      (result) => {
        this.onCompareResponseSuccess(result);
      },
      (error) => {
        throw error;
      }
    );
  }

  /** Function to capture successful API response */
  onCompareResponseSuccess(result) {
    if (!result?.providerDetail) {
      return;
    }

    this.selectedProvidersDetailsResponse = result.providerDetail;
    const apiCalls = this.selectedProvidersDetailsResponse.map((provider) => {
      const plansPromise = this._providerPlan
        .getProviderPlans(provider.providerIdentifier, provider.locations[0].address.addressId, provider.locations[0].address.state)
        .then((plansResult) => {
          provider.insurancePlans = plansResult;
        });
      const affiliationsPromise = this._providerAffiliation
        .getProviderAffiliation(provider.providerIdentifier, provider.locations[0].address.addressId, provider.providerTypeCodeList)
        ?.then((affiliations: Map<string, IAffiliationProvider[]>) => {
          provider.affiliationsSummaryList = affiliations;
        });
      return Promise.all([plansPromise, affiliationsPromise]);
    });

    forkJoin(apiCalls).subscribe({
      next: () => {
        this.handleDisplay();
      },
      error: (err) => {
        throw err;
      }
    });
  }

  /** Function to display popup/sidepanel based on device width */
  handleDisplay() {
    if (this.selectedProvidersDetailsResponse.length) {
      this.selectedProvidersDetailsResponse.forEach((providerDetail, index) => {
        const matchingProvider = this.comapreSelectedProviders.find((selectedProvider) => selectedProvider.providerIdentifier === providerDetail.providerIdentifier);
        if (matchingProvider) {
          this.selectedProvidersDetailsResponse[index] = {
            ...providerDetail,
            memberCostEstimate: this.assignMemberCostEstimate(matchingProvider),
            providerBillEstimate: this.assignProviderBillEstimate(matchingProvider)
          } as ICompareResponse;
        }
      });
      const isMobile = this.onScreenResize();
      if (isMobile) {
        this.providerCompareSidePanelCmp.openSidePanel('right', this.selectedProvidersDetailsResponse);
      } else {
        if (this.comapreSelectedProviders.length) {
          this.providerCompareCmp.showModal(this.selectedProvidersDetailsResponse);
        }
      }
    }
  }

  private assignMemberCostEstimate(provider): string | undefined {
    let memberCostEstimate;
    if (provider?.eycCostInfo?.isVisibleTotalYouPay) {
      memberCostEstimate = this._currencyPipe.transform(provider.eycCostInfo?.totalYouPay, 'USD', 'symbol', '1.0') ?? undefined;
    } else if (provider?.eycCostInfo?.isVisibleTotalAverageCost) {
      memberCostEstimate = provider.eycCostInfo?.totalAverageCost;
    } else if (provider?.costInfo?.memberResponsibility) {
      memberCostEstimate = provider.costInfo.memberResponsibility;
    }
    return memberCostEstimate;
  }

  private assignProviderBillEstimate(provider): string | undefined {
    let providerBillEstimate;
    if (provider?.eycCostInfo?.isVisibleTotalEstimateCost) {
      providerBillEstimate = this._currencyPipe.transform(provider.eycCostInfo?.totalEstimateCost, 'USD', 'symbol', '1.0') ?? undefined;
    } else if (provider?.eycCostInfo?.isVisibleTotalAverageCostRange && !this.isDentalSearch) {
      providerBillEstimate = provider.eycCostInfo?.totalAverageCostRange;
    } else if (provider?.costInfo?.estimatedBill) {
      providerBillEstimate = provider.costInfo.estimatedBill;
    }
    return providerBillEstimate;
  }

  get isDentalSearch(): boolean {
    return (
      (this._appSession.hasDentalEYC &&
        this._appSession.searchParams &&
        this._appSession.searchParams.eycSearchParam &&
        this._appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
        this._appSession.searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL) ??
      false
    );
  }

  removeSelectedProvider(provider) {
    let removeProvider;
    if ((provider?.providerTypeCodeList || []).includes(PROVIDER_TYPE.PHARMACY)) {
      removeProvider = this.comapreSelectedProviders.find(
        (obj) => obj.pharmacyNumber === provider.providerIdentifier
      );
    }
    else {
      removeProvider = this.comapreSelectedProviders.find(
        (obj) => obj.providerIdentifier === provider.providerIdentifier && obj.location?.address?.addressId === provider.locations[0]?.address?.addressId
      );
    }
    this._compareProvider.remove(removeProvider);
    this.providerRemoved.emit(removeProvider);
  }

  toggleSelectedProviders() {
    this.toggleProviders = !this.toggleProviders;
  }
}
