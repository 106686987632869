export const apiNameList = {
  restApi: {
    baseUrl: '/',
    dataModifiedPublic: '/fad/api/utility/data-modifiedon',
    dataModifiedSecure: '/fad/api/utility/data-modifiedon/:mbrUid',
    memberKey: '/fad/api/dcs/member-key',
    providerTypes: '/fad/api/utility/provider-types',
    providerTypesFilter: '/fad/api/utility/provider-types-filter',
    stateList: '/precare/api/utility/v1/public-plan/:brand/states',
    planList: '/precare/api/utility/v1/public-plan/:brand/states/:state/categories/:category/plans',
    allPlanList: '/precare/api/utility/v1/public-plan/:brand/states/:state/plans',
    planCategoryList: '/precare/api/utility/v1/public-plan/:brand/states/:state/categories',
    specialityList: '/fad/api/utility/specialties',
    summary: '/fad/api/provider/summary',
    summaryPrint: '/fad/api/provider/summary/pdf',
    summaryEmail: '/fad/api/provider/summary/email',
    summaryFilter: '/fad/api/provider/summary/filters',
    dynamicFilter: '/precare/api/search/v1/filters',
    affiliationOptions: '/fad/api/provider/affiliation-options',
    providerOptions: '/fad/api/provider/provider-options',
    providerLookUp: '/fad/api/provider/lookup',
    providerFeatureLookUp: '/fad/api/provider/provider-features',
    details: '/fad/api/provider/details',
    plansAccepted: '/precare/api/provider/v1/plans',
    locations: '/fad/api/provider/locations',
    affiliations: '/fad/api/provider/affiliations',
    affiliationsV2: '/fad/api/provider/v2/affiliations',
    compare: '/fad/api/provider/compare',
    rule: '/fad/api/provider/rules',
    disclaimers: '/fad/api/utility/disclaimers',
    disclaimersV1: '/precare/api/utility/v1/disclaimers',
    procedures: '/fad/api/utility/procedures',
    proceduresV2: '/fad/api/utility/:contractuid/procedures',
    secureSummary: '/precare/api/member/secure/v1/summary',
    medicaidSecureSummary: '/precare/api/medicaid/secure/v1/summary',
    medicaidPublicSummary: '/precare/api/medicaid/public/v1/summary',
    publicSummary: '/precare/api/member/public/v1/summary',
    multiMemberContract: '/precare/api/member/secure/v1/contracts',
    alphaPrefix: '/fad/api/utility/alpha-prefix',
    secureV2CptMedical: '/precare/api/search/secure/v2/cpt/medical',
    publicV1Specialty: '/precare/api/search/public/v1/specialty',
    publicV1TccMedical: '/precare/api/search/public/v1/tcc/medical',
    publicV2TccMedical: '/precare/api/search/public/v2/tcc/medical',
    secureV1CptMedical: '/precare/api/search/secure/v1/cpt/medical',
    secureV1RxSpecialty: '/precare/api/search/secure/v1/specialty/rx',
    secureV1Specialty: '/precare/api/search/secure/v1/specialty',
    publicSpecialtyV2: '/precare/api/search/public/v2/specialty',
    secureSpecialtyV2: '/precare/api/search/secure/v2/specialty',
    secureV1TccDental: '/precare/api/search/secure/v1/tcc/dental',
    secureV1TccMedical: '/precare/api/search/secure/v1/tcc/medical',
    secureV2TccMedical: '/precare/api/search/secure/v2/tcc/medical',
    vitalsSSO: '/fad/api/vitals/sso/:mbrUid',
    patientPreferences: '/fad/api/utility/patient-preferences',
    loc: '/fad/api/utility/levelofcare',
    ethnicities: '/fad/api/utility/ethnicities',
    languages: '/fad/api/utility/languages',
    pharmacyLanguages: '/fad/api/utility/pharmacy-languages',
    appInitializationRules: '/fad/api/provider/app-init-rules',
    countyByState: '/fad/api/utility/counties-by-state',
    cacheData: '/fad/api/utility/cache-by-key',
    removePCP: '/fad/api/pcp/remove',
    modifyPCP: '/fad/api/pcp/modify',
    pcpMemberInfo: '/fad/api/pcp/pcp-info',
    fetchFeaturesFlag: '/fad/api/feature-flags/find-care-features',
    procedureLookup: '/fad/api/utility/procedure-lookup',
    procedureLookupv2: '/fad/api/utility/v2/procedure-lookup',
    smartSearchLookup: '/precare/api/lookup/v1/smart-search',
    specialtyLookup: '/fad/api/utility/specialty-lookup',
    specialtyLookupv2: '/fad/api/utility/v2/specialty-lookup',
    countyLookup: '/fad/api/utility/counties-lookup',
    careTeam: '/fad/api/careteam/providers',
    careTeamUpdate: '/fad/api/careteam/provider/update',
    careTeamAdd: '/fad/api/careteam/provider/add',
    careTeamEmail: '/fad/api/careteam/provider/email',
    vcard: '/fad/api/communication/vcard',
    providerProceduresInfo: '/fad/api/provider/providerproceduresinfo',
    providerCostEstimate: '/fad/api/provider/providercostestimate',
    getMemberHierarchy: '/fad/api/benefit/:contractuid/:mbrUid/list',
    getBenefitId: '/fad/api/benefit/:contractuid/:mbrUid/list/:procedurecode',
    costFormula: '/fad/api/benefit/cost-formula',
    preConfigureSearch: '/fad/api/provider/preconfigured-search',
    getProcedueDetails: '/fad/api/utility/procedure-benefit/:procedurecode',
    getFindCareCost: '/fad/api/provider/procedure-search',
    traveltime: '/fad/api/geo/traveltime',
    officeVisitCost: '/fad/api/provider/cost/office-visit',
    affiliationLocations: '/fad/api/provider/address-locations',
    cacheSearchParams: '/fad/api/utility/cache-search-params',
    messaging: '/fad/api/messaging/search-publisher',
    getGeoLocation: '/fad/api/geo/location',
    getAddressbylatlong: '/fad/api/geo/address-by-latlong',
    getAddressbyQuery: '/fad/api/geo/address-by-query',
    quickLinks: '/fad/api/utility/quick-links',
    quickLinksV2: '/fad/api/utility/v2/quick-links',
    getProcedueAndSpecialityList: '/fad/api/utility/criteria-description',
    getCareActionItems: '/fad/api/messages/care',
    promotedProcedure: '/fad/api/utility/promoted-procedures',
    reportInvalid: '/fad/api/provider/report-invalid',
    prefixNetworks: '/fad/api/utility/prefix-networks',
    serviceAreaValidation: '/precare/api/network/validate/v1/servicearea',
    getZipcodeList: '/precare/api/utility/v1/zipcodes',
    prefixMetadata: '/fad/api/utility/prefix-metadata',
    personalizedActions: '/fad/api/messages/personalized-actions',
    personalizationUpdateAction: '/fad/api/messages/personalization',
    userInteraction: '/precare/api/event/v1/interaction',
    aoeLookup: '/precare/api/lookup/v1/area-of-expertise',
    aoe: '/precare/api/utility/v1/area-of-expertise',
    ada: '/precare/api/utility/v1/ada',
    ofcSrvcLookup: '/precare/api/lookup/v1/office-service',
    officeService: '/precare/api/utility/v1/office-service',
    kHealthRedirectURL: '/precare/api/virtualcare/v1/khealth/redirect-url',
    virtualCareSSOURL: '/precare/api/sso/v1/provider',
    userImpression: '/precare/api/event/v1/impression',
    cptLookup: '/precare/api/lookup/v1/cpt',
    scenarioAlert: '/precare/api/message/v1/scenario-alert',
    careCircle: '/precare/api/carecircle/v1/get',
    providerCptCodes: '/precare/api/provider/cpt/v1/provider-cpt-codes',
    providerCptCostDetail: '/precare/api/provider/cpt/v1/provider-cpt-cost-detail',
    memberPcpDetails: '/precare/api/member/v1/pcp/detail',
    assignPcpValidate: '/precare/api/member/v1/pcp/validate',
    locationsV1: '/precare/api/provider/v1/locations',
    providerLookupV1: '/precare/api/lookup/v1/provider',
    detailsV1: '/precare/api/provider/v1/details',
    providerPcpInfo: '/precare/api/member/v1/pcp/provider-pcp-info',
    getMoreOptions: '/precare/api/utility/v1/more-search-options',
    pcpReasonCodes: '/precare/api/member/v1/pcp/reason-codes',
    quickLinksV3: '/precare/api/member/v2/quick-links',
    getMemberAgeLimit: '/precare/api/member/v1/pcp/member-age-limit',
    getAuthMetaData: '/precare/api/auth/client/v1/metadata',
    getByQuery: '/precare/api/geolocation/bing/v1/query',
    specialtyFilterV1: '/precare/api/utility/v1/specialty-filter',
    assistanceV1: '/precare/api/fts/v1/assistance',
    suggestionsV1: '/precare/api/fts/v1/suggestions',
    providerMetaDataLookup: '/precare/api/lookup/v1/provider-metadata',
    feedbackV1: '/precare/api/event/v1/feedback',
    feedbackV2: '/precare/api/event/v2/feedback',
    taxonomyCodes: '/precare/api/utility/v1/taxonomy/validate',
    geolocationLookup: '/precare/api/geolocation/v1/lookup',
    geolocationAddressValidator: '/precare/api/geolocation/v1/validate/address',
    geolocationLatLongValidator: '/precare/api/geolocation/v1/validate/geocode',
    publicBootstrapV1: '/precare/api/init/public/v1/bootstrap',
    secureBootstrapV1: '/precare/api/init/secure/v1/bootstrap',
    providerCptOffiveVisitCost: '/precare/api/provider/cpt/v1/office-visit',
    searchFilterPublicV1: '/precare/api/search/public/v1/search-filter',
    searchFilterSecureV1: '/precare/api/search/secure/v1/search-filter',
    action: '/precare/api/event/v1/action',
    memberBenefitV1: '/precare/api/member/v1/benefits',
    publicSearchBoxV1: '/precare/api/lookup/public/v1/search-box',
    secureSearchBoxV1: '/precare/api/lookup/secure/v1/search-box',
    publicPageAlert: '/precare/api/message/public/v1/page-alert',
    securePageAlert: '/precare/api/message/secure/v1/page-alert',
    secureIntentLookup: '/precare/api/lookup/secure/v1/intent'
  }
};
