import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertHelper } from '@anthem/uxd/alert';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { CARE_TEAM_TYPE } from '../../../../care-circle/constants/careCircleConstants';
import { ICareCircle, ICareTeam } from '../../../../care-circle/interfaces/iCareCircle';
import { CareTeamProvider, CareTeamResponse, ICareProvider, IProvider } from '../../../../care-circle/interfaces/iCareProvider';
import { CareCircleService } from '../../../../care-circle/services/careCircleSvc';
import { MemberPCPHelper } from '../../../../care-circle/services/memberPCPHelper';
import { CareCircleUtil } from '../../../../care-circle/utilities/careCircleUtil';
import { AppConstants, AppExternalCommands, ORIGIN } from '../../../../common/constants/app-constants';
import { AppNavigations } from '../../../../common/constants/app-navigations';
import { SEARCH_SCENARIOS } from '../../../../common/constants/strategy-search-constant';
import { AppEvents } from '../../../../common/enums/appEvents';
import { IAppointment, IAppointmentLoader } from '../../../../common/interfaces/iAppointment';
import { IApiStatusEvent } from '../../../../common/interfaces/iEvent';
import { IEventDetail } from '../../../../common/interfaces/iEventDetail';
import { IMemberKeyRequest } from '../../../../common/interfaces/iMemberKey';
import { PhonePatternPipe } from '../../../../common/pipes/phonePatternPipe';
import { DataService } from '../../../../common/services/dataService';
import { EventHandler } from '../../../../common/services/eventHandler';
import { MemberKeyService } from '../../../../common/services/memberKeySvc';
import { NavigationService } from '../../../../common/services/navigationService';
import { SearchSvc } from '../../../../common/services/searchSvc';
import { RouteUtil } from '../../../../common/utilities/routeUtil';
import { ISearchParameters } from '../../../../fad/search-providers/interfaces/iSearchParameters';
import { SAVNavigationService } from '../../../../fad/search-providers/services/savNavigationSvc';
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE } from '../../../../fad/search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IAppContract } from '../../../common/interfaces/iAppContract';
import { IMember } from '../../../common/interfaces/iMember';
import { ActionService } from '../../../common/services/actionSvc';
import { BootstrapService } from '../../../common/store/bootstrapSvc';
import { CARE_TEAM_VIEW_COUNT } from '../../constants/landing';
import { IAddress } from '../../interfaces/iCareTeam';
import { MoreOptionsService } from '../../services/moreOptionSvc';
import { AppSession } from './../../../../common/values/appSession';
import { SliderDirection } from './../../../common/constants/common';

@Component({
  moduleId: module.id,
  selector: 'app-fc-care-team-cmp',
  templateUrl: './careTeamCmp.html',
  providers: []
})
export class CareTeamComponent extends BaseComponent implements OnInit {
  careProviders: any = [];
  careTeamResponse: CareTeamResponse;
  careTeamViewCount: number = CARE_TEAM_VIEW_COUNT;
  hasPCP = false;
  hasPCPFeature = false;
  removeProvider: ICareProvider;
  removeProviderConfirm: string;
  searchParams: ISearchParameters;
  selectedMbr: IMember;
  showCareTeamLoader = false;
  showPageLoader: boolean;
  showPCPInfo = false;
  showReadOnlyMessage = false;
  hasMultipleMoreOptions = false;
  sliderDirection = SliderDirection;

  pcpSidePanelRef: ModalRef<HTMLElement>;
  @ViewChild('pcpSidePanel')
  pcpSidePanel: TemplateRef<HTMLElement>;
  removeProviderPanelRef: ModalRef<HTMLElement>;
  @ViewChild('removeProviderModal')
  removeProviderModal: TemplateRef<HTMLElement>;
  activeMember: IAppContract = undefined;
  careTeamContent = this.content?.landing?.careTeams;
  private _contractSubscription: Subscription;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _phonePatternPipe: PhonePatternPipe,
    private _eventHandler: EventHandler,
    private _sidePanel: SidePanel,
    private _dataService: DataService,
    private _careTeamSvc: CareCircleService,
    private _memberPCPHelper: MemberPCPHelper,
    private _titlecasePipe: TitleCasePipe,
    private _careCircleSvc: CareCircleService,
    private _navigationService: NavigationService,
    private _routeUtil: RouteUtil,
    private _memberKeySvc: MemberKeyService,
    private _alertHlpr: AlertHelper,
    private moreOptionsService: MoreOptionsService,
    private _bootstrapService: BootstrapService,
    private _searchService: SearchSvc,
    private _savNavigationService: SAVNavigationService,
    private _actionService: ActionService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    this.searchParams = this._appSession?.searchParams;
    this._contractSubscription = this._bootstrapService.contract.subscribe((appContract: IAppContract) => {
      this.activeMember = appContract;
      this.getCareCircleInfo();
    });
  }

  viewFullCareTeam() {
    this._navigationService.navigateByUrl(AppNavigations.CARE_CIRCLE_PATH);
  }

  /**
   * Set the hasMultipleMoreOptions flag based on the number of more options for care team responsive
   * @returns void
   */
  setMutilpleMoreOptions() {
    // Conditionally set the hasMultipleMoreOptions flag based on the number of more options for care team responsive
    if (this.careProviders?.length && !this.showPCPInfo) {
      this.hasMultipleMoreOptions = false;
    } else {
      this.moreOptionsService?.moreOptionsList?.subscribe((options) => {
        this.hasMultipleMoreOptions = options.length > 1 ? true : false;
      });
    }
  }

  /**
   * Open the side panel
   * @param direction - side panel direction
   */
  openSidePanel(direction: SliderDirection) {
    this.pcpSidePanelRef = this._sidePanel.open(direction, this.pcpSidePanel);
  }

  /**
   * Close the side panel
   */
  onClose() {
    this.pcpSidePanelRef.close();
  }

  /**
   * Get the care circle info for the selected member and update the care team response
   * @returns void
   */
  getCareCircleInfo() {
    const statusEvent: IApiStatusEvent = {
      isProgress: true,
      isSuccess: false,
      isFailure: false,
      hasValidData: false
    };
    this.showCareTeamLoader = true;
    this._careTeamSvc.getCareCircle(CareCircleUtil.buildCareCircleRequest(this._appSession, CARE_TEAM_TYPE.SELECTED)).then(
      (result: ICareCircle) => {
        statusEvent.isProgress = false;
        statusEvent.isSuccess = true;
        this.careTeamResponse = {} as CareTeamResponse;
        if (result?.pcp) {
          this.careTeamResponse = {
            ...this.careTeamResponse,
            careTeam: [...result.pcp.data],
            hasPCPFeature: result.pcp.hasPCPFeature,
            hasPCPRemoveFeature: result.pcp.hasPCPRemoveFeature,
            isPCPReadOnly: result.pcp.isPCPReadOnly
          };
        }
        if (result?.careTeam) {
          this.careTeamResponse.careTeam = [...(this.careTeamResponse.careTeam || []), ...result.careTeam.data];
        }
        const hasCareTeamData = this.careTeamResponse?.careTeam?.length > 0;
        const hasPCPFeature = this.careTeamResponse?.hasPCPFeature;
        if (hasCareTeamData || hasPCPFeature) {
          statusEvent.hasValidData = true;
        } else {
          statusEvent.hasValidData = false;
        }
        this.showCareTeamLoader = false;
        this.careProviders = [];
        if (hasCareTeamData) {
          let _careProviders = this.careTeamResponse.careTeam;
          if (_careProviders.length > this.careTeamViewCount) {
            _careProviders = _careProviders.slice(0, this.careTeamViewCount);
          }
          this.careProviders = _careProviders;
          CareCircleUtil.showOnlinePharmacy(this.careProviders);
        }
        this.managePCPMessage(this.careTeamResponse);
        this._memberPCPHelper.updatePcpProviderSession(result);
        this.setMutilpleMoreOptions();
      },
      (error) => {
        statusEvent.isProgress = false;
        statusEvent.isFailure = true;
        this.showCareTeamLoader = false;
      }
    );
  }

  /**
   * Manage PCP Message - Show PCP Info and Read Only Message
   * @returns void
   * @param careTeamResponse - Care Team Response
   */
  managePCPMessage(careTeamResponse: CareTeamResponse) {
    this.showPCPInfo = false;
    this.showReadOnlyMessage = false;
    if (!careTeamResponse?.hasPCPFeature) return;
    const noPCP = careTeamResponse.careTeam?.findIndex((x) => x.isPCP) < 0;
    if (careTeamResponse.isPCPReadOnly) {
      this.showPCPInfo = noPCP;
      this.showReadOnlyMessage = true;
    } else if (noPCP && !this.careProviders.length) {
      this.showPCPInfo = true;
    }
  }

  /**
   * Format Address - Format the Address using AddressLineOne, City, State and Zip Code
   * @param address - Address Object
   * @returns string
   */
  formatAddress(address: IAddress) {
    if (!address) return '';
    const addressLineOne = address.addressLineOne || '';
    const city = address.city ? `, ${address.city}` : '';
    const stateCd = address.stateCd?.code ? `, ${address.stateCd.code}` : '';
    const zipCd = address.zipCd ? ` ${address.zipCd}` : '';

    return `${addressLineOne}${city}${stateCd}${zipCd}`.trim();
  }

  /**
   * Format Phone number using PhonePatternPipe
   * @param phone - Phone Number
   * @returns string
   */
  formatPhone(phone: string) {
    return this._phonePatternPipe?.transform(phone);
  }

  /**
   * Get Action Items - Change PCP, Remove from Care Team, Book Appointment
   * @param careTeam - Care Team Provider
   * @returns Array<string> - Action Items List
   */
  getActionItems(careTeam: CareTeamProvider): Array<string> {
    const actionItems: Array<string> = [];
    const labels = this.careTeamContent?.actionItemsLabel;
    if (careTeam.isPCP) {
      const pcpAction = this.showReadOnlyMessage ? labels?.memberSvcChangePCP : labels?.changePCP;
      if (pcpAction) actionItems.push(pcpAction);
    } else {
      if (labels?.removeFromCareTeam) actionItems.push(labels.removeFromCareTeam);
    }
    const canBookAppointment =
      careTeam?.appointmentAvailable &&
      careTeam?.provider?.address?.addressId &&
      this.isSecureState &&
      !this._appSession.isEyc &&
      !this._appSession.isChangePCP &&
      this._appSession.feature.showScheduleAppointment;
    if (canBookAppointment && labels?.bookAppointment) {
      actionItems.push(labels.bookAppointment);
    }
    return actionItems;
  }
  /**
   * Remove Care Team Provider - Remove Provider from Care Team
   * @param provider - Care Provider
   * @returns void
   */
  removeCareTeamProvider(provider: ICareProvider) {
    this.removeProviderConfirm = '';
    this.selectedMbr = undefined;
    const appSessionContract = this._appSession?.appState?.selectedEligibilityProduct?.selectedContract;
    const memberList = this._appSession?.appState?.selectedEligibilityProduct?.members;

    if (!memberList || !appSessionContract) return;

    this.selectedMbr = memberList.find((x) => x.mbrUid === appSessionContract.mbrUid);

    if (this.selectedMbr) {
      this.updateProviderRemoval(provider);
    }
  }

  /**
   * Add PCP Click - Navigate to Search Results Page for searching PCP Providers
   * @returns void
   */
  onAddPCPClick() {
    const searchParams = this._appSession?.searchParams;
    const isMyPlan = searchParams?.plan?.securePlanLabelKey === AppConstants.MyPlan;
    if (this.isLocationValid(searchParams) && isMyPlan) {
      Object.assign(searchParams, {
        typeSelectNm: PROVIDER_TYPE.DOCTOR_PROFESSIONAL,
        ableToServePcp: true
      });
      this.resetAppSessionParams();
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
    }
  }

  /**
   * To Reset the Search Parameters in App Session
   * @returns void
   */
  resetAppSessionParams() {
    this.searchParams = {
      ...this.searchParams,
      provNm: '',
      coverageTypeCode: '',
      taxonomySelectNm: [],
      standAloneDental: undefined,
      standAloneVision: undefined,
      distanceNm: PROVIDER_SEARCH_DISTANCE
    };
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchParams;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = false;
  }

  /**
   * Is Location Valid - Check if Location is Valid
   * @param searchParams - Search Parameters
   * @returns boolean
   */
  isLocationValid(searchParams: ISearchParameters) {
    if (searchParams?.coordinates?.latitude !== '' && searchParams?.coordinates?.longitude !== '') {
      return true;
    }
    return false;
  }

  /**
   * Update Provider Removal - Update Provider Removal in Care Team
   * @param provider - Care Provider
   * @returns void
   */
  updateProviderRemoval(provider: ICareProvider) {
    if (!provider || !this.selectedMbr) return;
    const _memberName = `${this.titleCase(this.selectedMbr.firstNm)} ${this.titleCase(this.selectedMbr.lastNm)}`;
    const providerName = provider.fullNm?.trim() || '';
    this.removeProviderConfirm = this.careTeamContent?.removeCareProviderConfirm.replace(/{NAME}/gi, providerName).replace(/{MEMBER}/gi, _memberName.trim());
    this.removeProvider = provider;
    this.removeProviderPanelRef = this._sidePanel.open('right', this.removeProviderModal);
  }

  /**
   * Title Case - Convert the Name to Title Case
   * @param name - Name
   * @returns string
   */
  titleCase(name: string) {
    return this._titlecasePipe.transform(name);
  }

  /**
   * Close the Remove Provider Modal
   * @returns void
   */
  closeRemoveProviderModal() {
    this.removeProviderPanelRef.close();
  }

  /**
   * Remove Care Provider - Remove Provider from Care Team
   * @returns void
   */
  removeCareProvider() {
    if (!this.removeProvider) return;
    this.removeProvider.isRemoved = true;
    const _provider: IProvider = {
      isRemoved: this.removeProvider.isRemoved,
      providerId: this.removeProvider.id
    };
    this.careProviders.forEach((careProvider) => {
      if (careProvider.provider.id === this.removeProvider.id) {
        careProvider.provider.isRemoved = true;
      }
    });
    this.updateCareTeam(_provider);
    this.getCareCircleInfo();
  }

  /**
   * Update Care  Team - Update Care Team with Provider Removal
   * @param providerObj - Provider Object
   * @returns void
   */
  updateCareTeam(providerObj: IProvider) {
    if (!providerObj) return;
    this.removeProviderConfirm = '';
    const _isRemoved = providerObj.isRemoved;
    this._careTeamSvc.updateCareTeam(CareCircleUtil.buildUpdateCareTeamRequest(this._appSession, [providerObj])).then(
      (result) => this.handleUpdateCareTeamResponse(_isRemoved, true),
      (error) => this.handleUpdateCareTeamResponse(_isRemoved, false)
    );
  }

  /**
   * Handle Update Care Team Response - Handle Provider Removal Response
   * @param isRemoved - Is Provider Removed : boolean
   * @param isSuccess - Is Success : boolean
   * @returns void
   */
  handleUpdateCareTeamResponse(isRemoved: boolean, isSuccess: boolean) {
    if (isRemoved) {
      const msg = this.buildMessage(isSuccess);
      this.removeProviderConfirm = msg;
      this.removeProvider = null;
    }
  }

  /**
   * Error Handler
   * @param type - Error Type
   * @param error - Error Object
   */
  onCategoryError(type: string, error: any) {
    throw error;
  }

  /**
   * Build Message - Build Success or Failure Message
   * @param isSuccess - Is Success : boolean
   * @returns string - Message
   */
  buildMessage(isSuccess: boolean): string {
    if (!this.selectedMbr) return '';
    const memberName = `${this.titleCase(this.selectedMbr.firstNm)} ${this.titleCase(this.selectedMbr.lastNm)}`.trim();
    const providerName = this.titleCase(this.removeProvider.fullNm.trim());
    const messageTemplate = isSuccess ? this.careTeamContent?.removeCareTeamSuccessMsg : this.careTeamContent?.removeCareTeamFailureMsg;
    return messageTemplate?.replace(/{MEMBER}/gi, memberName)?.replace(/{PROVIDER}/gi, providerName) || '';
  }

  /**
   *  Get Care  Team - Get Care Team Info
   * @returns void
   */
  getCareTeam() {
    this.careProviders = [];
    const careCircleRequest = CareCircleUtil?.buildCareCircleRequest(this._appSession, CARE_TEAM_TYPE?.SELECTED);
    this._careCircleSvc?.getCareCircle(careCircleRequest)?.then(
      (result: ICareCircle) => {
        this.hasPCP = false;
        this.hasPCPFeature = false;
        if (result.pcp?.data?.length) {
          this.processPCP(result.pcp);
        }
        if (result.pcp?.hasPCPFeature) {
          this.hasPCPFeature = true;
        }
        this.processCareTeam(result);
        CareCircleUtil.showOnlinePharmacy(this.careProviders);
        this._memberPCPHelper.updatePcpProviderSession(result);
      },
      (error) => {
        this.onCategoryError('CARETEAM', error);
      }
    );
  }

  /**
   * Process Primary Care Provider
   * @param pcp - Primary Care Provider
   */
  private processPCP(pcp: ICareTeam) {
    const pcpList = CareCircleUtil.bindCareProviders(pcp);
    this.careProviders = [...this.careProviders, ...pcpList];
    this.hasPCP = true;
  }

  /**
   * Process Care Team Providers
   * @param result - Care Circle Response
   */
  private processCareTeam(result: ICareCircle) {
    if (result.careTeam?.data?.length) {
      result.careTeam.hasPCPFeature = result.pcp?.hasPCPFeature;
      result.careTeam.hasPCPRemoveFeature = result.pcp?.hasPCPRemoveFeature;
      const careTeamList = CareCircleUtil.bindCareProviders(result.careTeam);
      this.careProviders = [...this.careProviders, ...careTeamList];
    }
  }

  /**
   * Book Appointment - Book Appointment for Care Team Provider
   * @param careTeam - Care Team Provider
   * @returns void
   */
  appointmentSchedule(careTeam: CareTeamProvider) {
    const eventDetail: IEventDetail = {
      type: AppEvents[AppEvents.PF_APP_CMD_NAV],
      details: AppExternalCommands.RCP_SCHEDULER_LANDING.MSG,
      message: AppExternalCommands.RCP_SCHEDULER_LANDING.MSG,
      target: AppExternalCommands.RCP_SCHEDULER_LANDING.CMD,
      errorCode: 0
    };
    const appointmentObj: IAppointment = {};
    const appointmentLoaderObj: IAppointmentLoader = {
      loader: true,
      apiError: false
    };
    this.showPageLoader = true;
    const _memberKeyReq: IMemberKeyRequest = {
      npi: careTeam?.npiList,
      facilityId: careTeam?.provider?.address?.addressId,
      idToken: this._appSession?.metaData?.idToken,
      searchParameters: {
        ...this._appSession.searchParams,
        providerTypeDdlValues: undefined,
        speicalityDdlValues: undefined,
        subSpeicalityDdlValues: undefined,
        categoryDescription: undefined
      },
      routeUrl: this._appSession.isCareProvider ? AppNavigations.CARE_TEAM_PATH : this._routeUtil.getResolvedUrl(this._route.snapshot)
    };
    this._memberKeySvc.getMemberKey(_memberKeyReq).then(
      (resp) => {
        if (resp?.memberkey) {
          appointmentObj.appKey = resp.memberkey;
          if (resp.cacheKey) {
            appointmentObj.cacheKey = resp.cacheKey;
          }
          appointmentObj.origin = this.getOrigin(_memberKeyReq.routeUrl);
          eventDetail.object = appointmentObj;
          appointmentLoaderObj.loader = false;
          this.showPageLoader = false;
        } else {
          this.showPageLoader = false;
        }
      },
      (error) => {
        this.showPageLoader = false;
        this._alertHlpr.openAlert(this.careTeamContent?.apiError, 'negative');
      }
    );
  }

  /**
   * Get Origin - Get Origin for Appointment Scheduling
   * @param routeUrl - Route URL
   * @returns string
   */
  getOrigin(routeUrl: string) {
    switch (routeUrl) {
      case AppNavigations.CARE_TEAM_PATH:
        return ORIGIN.CARE_TEAM;
      case AppNavigations.CARE_CIRCLE_PATH:
        return ORIGIN.CARE_CIRCLE;
      default:
        return ORIGIN.SEARCH_RESULTS;
    }
  }

  /**
   * Contact Us Click - Contact Us Click Event
   * @returns void
   */
  onContactUsClick() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents?.PF_APP_CMD_NAV];
    eventDetail.message = AppExternalCommands?.RCP_CONTACT_US.MSG;
    eventDetail.target = AppExternalCommands?.RCP_CONTACT_US.CMD;
  }

  /**
   * Cleans up the component by unsubscribing from the contract observable.
   * This method is called when the component is destroyed.
   */
  ngOnDestroy(): void {
    if (this._contractSubscription) {
      this._contractSubscription.unsubscribe();
    }
  }

  /**
   * Method navigates to results page with selected care team provider card open
   * This method is called when clicked in care team component.
   */
  onProviderClick(careTeamProvider: CareTeamProvider) {
    if (careTeamProvider.provider.address) {
      let geoLoc = this._appSession.geolocationInfo;
      geoLoc.isHomeAddressSearch = false;
      geoLoc.isOtherAddressSearch = true;
      geoLoc.latitude = careTeamProvider.provider.address.latitude ?? '';
      geoLoc.longitude = careTeamProvider.provider.address.longitude ?? '';
      geoLoc.stateCd = careTeamProvider.provider.address.stateCd.code ?? '';
      geoLoc.zipcode = careTeamProvider.provider.address.zipCd ?? '';
    }

    let searchCriteria = this._appSession.searchParams;
    searchCriteria.provNm = careTeamProvider.provider.fullNm;
    searchCriteria.typeSelectNm = careTeamProvider.providerTypes?.[0];
    searchCriteria.providerTypeCodeList = careTeamProvider.providerTypes;

    this._searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureSpecialtyScenario(careTeamProvider.providerTypes as PROVIDER_TYPE[]) : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
    this._appSession.searchTerm = careTeamProvider.provider.fullNm;
    this._appSession.openDetailPopup = true;
    this._savNavigationService.navigate();
    this._actionService.setSearchClick(true);
  }
}
