import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, Output, QueryList, Renderer2, SimpleChanges, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { BILLING_TYPE, Locale, OFFICE_VISIT_MEDICALCODE } from '../../../../common/constants/app-constants';
import { AppEvents } from '../../../../common/enums/appEvents';
import { IEventDetail } from '../../../../common/interfaces/iEventDetail';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { IProvider } from '../../interfaces/iProvider';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-list-cmp',
  templateUrl: './providerListCmp.html'
})
export class ProviderListComponent implements AfterViewInit, OnChanges {
  @Input() providerRemoved: IProvider = null;
  @Input() clearAll: boolean = false;
  @Input() providers: IProvider[] = [];
  @Input() mapVisible = true;
  @Output() providerHover = new EventEmitter<IProvider>();
  @Output() providerLeave = new EventEmitter<IProvider>();
  @Output() selectedProvider = new EventEmitter<IProvider>();
  @Output() selectedPairedProvider = new EventEmitter<IProvider>();
  @Output() cardSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showProviderCompare: EventEmitter<boolean> = new EventEmitter<boolean>();
  compareSelectedProviders: number = 0;
  activeIndex: number | null;
  private _selectedPushpinProvider: IProvider;
  @ViewChildren('providerCard') providerCards: QueryList<ElementRef>;
  @Input() showProviderCard: boolean;
  private _pfOfficeVisitCost: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_CPT_OFC_VISIT_COST]);
  private pfOfficeVisitCostSubscription: Subscription;
  @Input()
  set selectedPushpinProvider(provider: IProvider) {
    this._selectedPushpinProvider = provider;
    this.scrollToActiveProvider();
  }

  constructor(
    private _renderer: Renderer2,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    this.pfOfficeVisitCostSubscription = this._pfOfficeVisitCost.subscribe((resp) => {
      const costDetails = resp?.object;
      if (costDetails?.providerList?.length) {
        this.providers = this.providers.map((providerInfo) => {
          const matchedProvider = costDetails.providerList.find(
            (provider) => provider.providerKey === providerInfo.providerIdentifier && provider.addressKey === providerInfo.addressSummary?.addressIdentifier
          );
          if (matchedProvider) {
            return { ...providerInfo, costInfo: matchedProvider.costInfo };
          }
          return providerInfo;
        });
        if (this._selectedPushpinProvider) {
          const matchedProvider = costDetails.providerList.find(
            (provider) => provider.providerKey === this._selectedPushpinProvider.providerIdentifier && provider.addressKey === this._selectedPushpinProvider.addressSummary?.addressIdentifier
          );
          if (matchedProvider) {
            this._selectedPushpinProvider = { ...this._selectedPushpinProvider, costInfo: matchedProvider.costInfo };
          }
        }
      }

      if (this._selectedPushpinProvider?.costInfo?.isOfficeVisitCost && CommonUtil.isValidString(this._selectedPushpinProvider.costInfo?.copay)) {
        this._appSession.medicalCode = OFFICE_VISIT_MEDICALCODE.MEDICAL_CODE;
        this._appSession.billingType = BILLING_TYPE.CPT;
        this._appSession.selectedPosCode = this._appSession.metaData?.locale === Locale.ENGLISH ? OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_EN : OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_ES;
        this._appSession.searchTerm = OFFICE_VISIT_MEDICALCODE.SEARCH_TERM;
        this._appSession.isSpecialtyOfficeVisitCost = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showProviderCard'] && !this.showProviderCard) {
      this.activeIndex = null;
    }
  }

  ngAfterViewInit() {
    this.scrollToActiveProvider();
  }

  get selectedPushpinProvider(): IProvider {
    return this._selectedPushpinProvider;
  }

  onProviderHover(provider: IProvider) {
    this.providerHover.emit(provider);
  }

  onProviderLeave(provider: IProvider) {
    this.providerLeave.emit(provider);
  }

  /**
   * Method to share provider card data.
   * Getting the selected provider details that needs to be shown in provider details card.
   * @param provider Provider data.
   */
  onShowProviderCard(provider: IProvider) {
    if (this.showProviderCard) {
      this.selectedProvider.emit(provider);
    }
  }

  /**
   * Method to share provider card data.
   * Getting the selected provider details that needs to be shown in provider details card.
   * @param pairedProvider Provider data from eyc affliation.
   */
  onShowPairedProviderDetails(pairedProvider: IProvider) {
    pairedProvider.addressSummary = {} as any;
    pairedProvider.addressSummary.addressIdentifier = pairedProvider?.location?.address?.addressId;
    this.selectedProvider.emit(pairedProvider);
    this.onSendCardStatus(true);
  }

  /**
   * Method to set selected Provider.
   * Method to highlight currently selected provider card from the scrollable cards list on left side.
   * @param index selected card index.
   */
  setProviderSelected(index: number) {
    if (this.showProviderCard) {
      this.activeIndex = null;
      this.activeIndex = index;
      if (this.activeIndex === index) {
        this.selectedPushpinProvider = null;
      }
    }
  }

  /**
   * Method to select a Provider.
   * Method to emit status which is for selected provider card to be shown or closed.
   * @param status boolean value to show or close.
   */
  onSendCardStatus(status: boolean) {
    this.cardSelected.emit(status);
  }

  /**
   * Method to Set selected Provider Card Active.
   * Method to highlight currently selected provider card from the scrollable cards list on left side
   * when user clicks through map marker pin
   */
  scrollToActiveProvider() {
    if (this._selectedPushpinProvider) {
      const element = this._renderer.selectRootElement('#provider-' + this._selectedPushpinProvider?.providerIdentifier, true);
      if (element) {
        // Scroll to view removed to prevent page flickering
        this.showProviderCard = true;
        this.onSendCardStatus(true);
        this.onShowProviderCard(this._selectedPushpinProvider);
        const index = this.findProviderIndexById(this._selectedPushpinProvider?.providerIdentifier);
        if (index !== -1) {
          this.setProviderSelected(index);
        }
      }
    }
  }

  /**
   * Method to Find Provider Card.
   * Getting index of selected provider from list of providers based on unique Provider Identifier.
   * @param providerID Unique Provider Identifer.
   */
  findProviderIndexById(providerID: string): number {
    return this.providers.findIndex((provider) => provider.providerIdentifier === providerID);
  }

  ngOnDestroy(): void {
    if (this.pfOfficeVisitCostSubscription) {
      this.pfOfficeVisitCostSubscription.unsubscribe();
    }
  }

  compareSelectedProvider(data) {
    this.compareSelectedProviders += data ? 1 : -1;
    if (data || this.compareSelectedProviders === 0) {
      this.showProviderCompare.emit(data);
    }
  }
}
