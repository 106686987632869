import { LANDING_CONTENT_EN } from './landingContent';

//  Define a type based on the structure of the English content
export type LadingContent = typeof LANDING_CONTENT_EN;

// Define the Spanish content for the landing page, ensuring each key in the English content has a corresponding key in the Spanish content
export const LANDING_CONTENT_ES: LadingContent = {
  title: 'Aterrizaje',
  quickLinks: {
    providerLabel: 'Buscar por proveedor de cuidado médico',
    procedureLabel: 'Buscar por procedimiento',
    secureToolTip: 'Los miembros con antecedentes de salud similares han mostrado interés en algunos de estos proveedores de cuidado médico.',
    coldToolTip: 'Nuestros datos nos dicen que los miembros con antecedentes de salud similares a los tuyos han buscado cuidado de algunos de estos proveedores.',
    procedureTooltip: 'Los miembros con antecedentes de salud similares han mostrado interés en algunos de estos procedimientos.',
    selectforMoreInfo: 'selecciona para mas informacion de ',
    ariaLabelForprovSearch: 'seleccionar para la búsqueda de proveedores de {provider_name}',
    ariaLabelForprocedSearch: 'selecciona para la búsqueda de procedimientos basados en {provider_name}',
    viewMoreLabel: 'Ver más',
    viewMoreAriaLabel: 'viewMoreProceduresLinkFindCare'
  },
  moreOptions: {
    moreOptionsLabel: 'Mas Opciones',
    labels: {
      urgentCareCardLabel: 'Atención de urgencias',
      urgentCareCardText: 'Considera utilizar estas opciones cuando necesites cuidado médico rápidamente, pero no puedas visitar a tu médico habitual.',
      urgentCareCardAriaLabel: 'Considera utilizar estas opciones cuando necesites cuidado médico rápidamente, pero no puedas visitar a tu médico habitual.',
      virtualCareLabel: 'Cuidado médico virtual',
      virtualCareCardText: 'Visite a un médico las 24 horas del día, los 7 días de la semana para cientos de condiciones de salud.',
      visionPlus: 'Vision PLUS',
      getCareNow: 'Obtener atención ahora',
      getCareNowCard: 'Vea sus opciones cuando necesite atención rápidamente.',
      visionPlusCard: 'Los proveedores de Vision PLUS pueden maximizar tus beneficios con cobertura adicional para ayudarte a ahorrar todavía más.',
      otherServicesProviderCardLabel: 'Otros proveedores de servicios',
      otherServicesProviderCardText: 'Estos profesionales y centros médicos brindan apoyo de vivienda y otros servicios.',
      dentaQuestCardLabel: 'Encontrar un dentista',
      dentaQuestCardText: 'Ir a DentaQuest para encontrar un dentista en la red Anthem BCBS Ohio de Medicaid.',
      libertyDentalCardLabel: 'Encontrar un dentista',
      libertyDentalCardText: 'Ir a LibertyDental para encontrar un dentista en la red Anthem BCBS Ohio de Medicaid.',
      eyeMedCardLabel: 'Encontrar un oculista',
      eyeMedCardText: 'Ir a EyeMed para encontrar un oftalmólogo en la red Anthem BCBS Ohio de Medicaid.',
      dentalDiscount: 'Proveedores del programa de descuentos dentales.',
      dentalDiscountCard: 'Estos proveedores dentales pueden cobrarte menos que su cargo normal por servicios no reembolsados por el Plan dental SEHP.'
    }
  },

  lastUpdated: {
    medicareContent: {
      lastUpdated: 'Última actualización',
      lastUpdatedText: 'Las actualizaciones recibidas de nuestros proveedores dentro de la red se agregan semanalmente.',
      lastUpdatedDate: '05/01/2020',
      medicareCompliance: 'Las fechas a continuación se utilizan para cumplir con Medicare y no aplican a la actualización de los datos de los proveedores.',
      cmsApproved: 'Y0114_20_122379_U Aprobado por los CMS el 05/08/2020',
      lastUpdatedDateMedicare: ' 09/01/2019'
    }
  },
  machineReadable: {
    linkText: 'Directorio de proveedores legible por máquina',
    ariaLabel: 'Selecciona aquí para navegar a la página del directorio de proveedores legible por máquina',
    brand: {
      amv: { url: 'https://www.amerigroup.com/microsites/cms/index.html' },
      hbncm: { url: 'https://www.healthybluenc.com/microsites/cms/index.html' },
      healthblue: { url: 'https://www.healthybluemo.com/microsites/cms/index.html' },
      txdcm: { url: 'https://findcare.mydellchildrens.com/microsites/cms/index.html' },
      abcbs: { url: 'https://www22.anthem.com/cms-data-index.json/index.html' },
      ahkp: { url: 'https://www22.anthem.com/cms-data-index.json/index.html' },
      kymbs: { url: 'https://www22.anthem.com/cms-data-index.json/index.html' },
      bcbswn: { url: 'https://www.mybcbswny.com/microsites/cms/index.html' },
      shc: { url: 'https://www.simplyhealthcareplans.com/microsites/cms/index.html' },
      scc: { url: 'https://www.summitcommunitycare.com/microsites/cms/index.html' },
      cha: { url: 'https://www.clearhealthalliance.com/microsites/cms/index.html' },
      wlp: { url: 'https://www.wellpoint.com/microsites/cms/index.html' },
      abc: { url: 'https://www.anthem.com/ca/machine-readable-file/provider.html' }
    }
  },
  memberSelectionModal: {
    memberDesc1: 'Las opciones de cobertura y red pueden variar según el miembro de la familia.',
    memberDesc2: 'Elija una para personalizar los resultados de su búsqueda según su plan específico.',
    memberHeading: 'Buscar por miembro de la familia'
  },
  careTeams: {
    closeProviderModal: 'Seleccione el botón para cerrar el modal de eliminación de proveedor',
    clickToCloseModal: 'Haga clic aquí para cerrar el modal',
    careTeam: 'Equipo de cuidado médico',
    providerName: 'Nombre del proveedor',
    goToCareTeam: 'Ir a Equipo de cuidado médico',
    pcp: 'ASIGNADO COMO PCP',
    call: 'Llamar',
    virtualCareOnlyLabel: 'SOLO CUIDADO MÉDICO VIRTUAL',
    addPrimaryCarePhysician: 'Agregar un médico de atención primaria a tu equipo de cuidado médico',
    importantPCP: '¿Por qué es importante tener un médico de atención primaria?',
    searchForPCP: 'Buscar un médico de atención primaria',
    addSomeOneToCareTeam: 'Agrega a alguien a tu equipo de cuidado médico',
    TP_AddCareTeams:
      'Es fácil agregar a alguien a tu equipo de cuidado médico. Primero, usa el cuadro de búsqueda para encontrar a tu proveedor de cuidado por nombre. Luego, selecciona ese proveedor de cuidado  y usa el botón Agregar al equipo de cuidado médico.',
    closeModal: 'botón, seleccione para cerrar el modal',
    whyImportantPCP: '¿Por qué es importante tener un médico de atención primaria?',
    whyImportantPCPContentFirstLine: 'Tú estás a cargo de tu salud. Pero necesitas un experto a quien acudir, así que elige un médico de atención primaria.',
    whyImportantPCPContent:
      'La elección de un médico de atención primaria (PCP, por su siglas en inglés) es una decisión personal importante. No quieres simplemente elegir un nombre aleatorio en una búsqueda rápida en la web. Encontrar a alguien con quien puedas relacionarte, sentirte cómodo y a quien puedas confiarle tus asuntos privados es muy importante. Y cuando encuentres el médico de atención primaria adecuado, ese médico puede ayudarte a marcar una diferencia en tu salud.',
    pcpImpLabel: '<b>Por qué es importante un médico de atención primaria</b>',
    pcpBulletPoint: [
      '<b> Tu médico de atención primaria llega a conocer “tu persona completa”, no solo partes o fragmentos</b> Tu médico tendrá una imagen más completa, con tu historial médico y tus hábitos, y podrá ver más fácilmente los cambios y recomendar acciones para evitar problemas más graves en el futuro.',
      '<b>Tienes un punto de partida.</b> Si no estás seguro de a quién acudir para un determinado problema, si tienes alguna pregunta sobre diferentes recetas médicas o si te preocupa lo que te sugirió un especialista, habla con tu médico de atención primaria.',
      '<b>Puede que ahorres tiempo y dinero. </b> Un médico de atención primaria te ayuda a mantenerte saludable y puede ayudarte a atender los problemas de salud en curso. Esto puede significar costos de atención médica más bajos, menos días de enfermedad y un mejor acceso al cuidado especializado cuando la necesites'
    ],
    whyImporatantPCPLink: 'Buscar un médico de atención primaria ahora',
    removeCareProvider: '¿Eliminar del equipo de cuidado médico?',
    remove: 'Eliminar',
    cancel: 'Cancelar',
    actionItemsLabel: {
      removeFromCareTeam: 'Eliminar del equipo de cuidado médico',
      changePCP: 'Cambiar PCP',
      memberSvcChangePCP: 'Cambiar PCP (servicios para miembros)',
      bookAppointment: 'Reserva una cita en el sitio'
    },
    removeCareProviderConfirm:
      '¿Estás seguro de que quieres eliminar a <strong>{NAME}</strong> del Equipo de cuidado médico de <strong>{MEMBER}</strong>? (Siempre puedes agregarlo de nuevo si cambias de opinión.)',
    removeCareTeamSuccessMsg: "Has eliminado correctamente a <strong class='text-uppercase'>{PROVIDER}</strong> del Equipo de cuidado médico de <strong>{MEMBER}</strong>.",
    removeCareTeamFailureMsg:
      "Hubo un problema y no pudimos eliminar a <strong class='text-uppercase'>{PROVIDER}</strong> del Equipo de cuidado médico de <strong>{MEMBER}</strong>. Por favor, inténtalo de nuevo más tarde.",
    apiError: 'Lo sentimos, parece que algo no funciona. Por favor, danos un tiempo para solucionarlo. Luego, inténtalo de nuevo.',
    close: 'Cerrar'
  }
};
