import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { IReportValues } from '../../../../app/common/components/report-invalid/interfaces/iReportValues';
import { ViewHealthwiseComponent } from '../../../common/components/healthwise/pfViewHealthwiseCmp';
import { AppNavigations } from '../../../common/constants/app-navigations';
import { DeeplinkUrl } from '../../../common/enums/deepLinkUrls';
import { PhonePatternPipe } from '../../../common/pipes/phonePatternPipe';
import { NavigationService } from '../../../common/services/navigationService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { IRulesResponse } from '../../rules/interfaces/iRulesResponse';
import { IHealthwiseAdobeAnalyticsObj, IProvider, ISpecialty, ReviewResponse } from '../../search-results/interfaces/iSummaryResp';
import { IDetailsResponse } from '../models/iDetailsResponse';
import { ILocationAddress } from '../models/iLocations';
import { ProviderDetailsService } from '../services/providerDetailsSvc';
import { AccessibilityCodes } from '../values/providerDetailsConstants';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { NETWORK_TYPECODE } from './../../../common/constants/app-constants';
import { EventHandler } from './../../../common/services/eventHandler';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { CommonUtil } from './../../../fad/utilities/commonUtil';
import { ENHNCMNT_TXT_LNGTH } from './../../search-providers/values/providerSearchConstants';
import { ProviderDetailsUtils } from './../../utilities/providerDetailsUtils';

@Component({
  moduleId: module.id,
  selector: 'app-fad-provider-details-cmp',
  templateUrl: '../views/pfDetailsCmp.html'
})
export class PFDetailsComponent extends BaseComponent implements OnInit {
  @Input()
  showDetailsError: boolean;
  @Input()
  selectedProvider: any;
  @Input()
  selectedAddressId?: any;
  @Input()
  detailsResponse: IDetailsResponse;
  providerDetail: any;
  showDetailsLoader: boolean = true;
  showPlansLoader: boolean = false;
  showLocationsLoader: boolean = false;
  showCostLoader: boolean = true;
  @Output()
  setDisclaimerLoader: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  setTabVisibility: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  openDomain: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  locationData: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  scheduleAppointment: EventEmitter<any> = new EventEmitter<any>();
  detailVisibility: IRulesResponse;
  @Output()
  setProviderDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  openTelehealthModal: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  setMinnesotaDisclaimerVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  detailsLoaded: EventEmitter<IDetailsResponse> = new EventEmitter<IDetailsResponse>();
  _showAssignPCPFlg: boolean;
  visionAvailReferralCode = '13';
  visionDiscountSrvice = 'VD';
  selectedProvType: string = '';
  activeFlagList: Array<string> = [];
  vitalsSsoConfig: any;
  showBeFirstToReview: boolean = true;
  selectedDistance: any;
  //To register the new Event variable to get the detail results
  // private _getDetailResult: EventEmitter<any> =
  //   this._eventHandler.get(AppEvents[AppEvents.PF_DETAIL_LOAD]);
  selectedDetailsParams: any;
  networkTypeConst: any = NETWORK_TYPECODE;
  inNetworkList: Array<string> = [];
  showMoreLink: boolean = false;
  showReadMore: boolean = false;
  showReadLess: boolean = false;
  biography: string;
  reportValues: IReportValues = {} as IReportValues;
  @Input() locationsList: Array<ILocationAddress>;
  defaultAddressId: string;

  @ViewChild('viewHealthwiseContentModal')
  viewHealthwiseContentModal: ViewHealthwiseComponent;
  showKeyInfo: boolean = false;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _appUtility: AppUtility,
    private _phonePatternPipe: PhonePatternPipe,
    private _providerDetailsSvc: ProviderDetailsService,
    private _navigationService: NavigationService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFProviderDetailsContainerComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.showBeFirstToReview = CommonUtil.isMemberSecure(this._appSession);

    if (this.detailsResponse) {
      this.onProviderDetailsSuccess(this.detailsResponse);
    } else {
      this.getProviderDetails('');
    }
  }

  ngOnChanges(args: unknown): void {
    if (!this.detailsResponse) {
      if (ProviderDetailsUtils.hasValueChanged(args, 'selectedAddressId')) {
        this.getProviderDetails(args['selectedAddressId'].currentValue);
      } else if (ProviderDetailsUtils.hasValueChanged(args, 'selectedProvider', 'providerIdentifier')) {
        this.getProviderDetails('');
      }
    }
  }

  async getProviderDetails(changedAddressId: string) {
    this.selectedProvType = this._appSession?.searchParams?.typeSelectNm?.trim();
    let detailsResponse: IDetailsResponse;

    if (isEmpty(changedAddressId)) {
      this.defaultAddressId = this.selectedProvider?.location?.address?.addressId;
    }
    this._providerDetailsSvc.search(this.selectedProvider, changedAddressId, this.defaultAddressId).then((result: IDetailsResponse) => {
      detailsResponse = result;
      this.onProviderDetailsSuccess(result);
    },
      (error) => {
        this.onError(error);
      }).finally(() => {
        this.detailsLoaded.emit(detailsResponse);
      });
  }

  onProviderDetailsSuccess(result: IDetailsResponse) {
    this.showDetailsLoader = false;
    this.showPlansLoader = true;
    if (result && result.ruleList && result.ruleList.length > 0) {
      const disclaimerInfo = {
        ruleList: result.ruleList,
        isVitals: !isEmpty(this.selectedProvider?.pdtKey)
      };
      this.setDisclaimerLoader.emit(disclaimerInfo);
    }
    if (result == null) {
      this.showDetailsError = true;
      return;
    }

    if (typeof result.providerDetail !== 'undefined' && result.providerDetail !== null) {
      this.providerDetail = result.providerDetail;

      //Get the unique license value, DESPRECARE - 64631
      if (this.providerDetail?.licenseTypes?.length) {
        this.providerDetail.licenseTypes = this.providerDetail.licenseTypes.filter((value: string, index: number, array: string) => array.indexOf(value) === index)
     }
      //DSEPRECARE - 12322 appending county to Countylist
      if (this.providerDetail.countiesList && this.providerDetail.countiesList.length > 0) {
        this.providerDetail.countiesList.forEach((county, i) => {
          this.providerDetail.countiesList[i] =
            county.replace(',', ` ${this.content.detailsComponent.labels.servingCounty},`);
        });
      }
      this.detailVisibility = result.providerDetail.visibilityRule;
      this.setTabVisibility.emit(result.providerDetail.visibilityRule);
      this.setMinnesotaDisclaimerVisibility.emit(this.providerDetail.visibilityRule?.showMinnesotaDisclaimer);
      if (result.providerDetail.activeFlagList && result.providerDetail.activeFlagList.length > 0) {
        this.activeFlagList = result.providerDetail.activeFlagList;
      }
      if (result.providerDetail.inNetworkList && result.providerDetail.inNetworkList.length > 0) {
        this.inNetworkList = result.providerDetail.inNetworkList;
      } else {
        this.inNetworkList = [];
      }
      if (typeof result.providerDetail.profilePicUrl !== 'undefined' && result.providerDetail.profilePicUrl !== null) {
        this.providerDetail.profilePicUrl = result.providerDetail.profilePicUrl;
      }
      if (typeof result.providerDetail.biography !== 'undefined' && result.providerDetail.biography !== null) {
        this.providerDetail.biography = result.providerDetail.biography;
      }
      if (this.providerDetail && this.providerDetail.biography) {
        this.biography = this.providerDetail.biography;
        if (this.providerDetail.biography.length >= ENHNCMNT_TXT_LNGTH) {
          this.trimString();
          this.showReadMore = true;
        }
      }
      this.selectedProvider.visibilityRule = this.detailVisibility;
      if(this.providerDetail?.reviewRatings
        || this.detailVisibility?.showNpi
        || this.detailVisibility?.showPharmacyNpi
        || this.detailVisibility?.showStateLicense
        || this.providerDetail?.licenseTypes?.length > 0
        || this.detailVisibility?.showLicenseNumber
        || this.providerDetail?.pcpInfoList?.length > 0
        || this.providerDetail?.pcp?.identifier?.length > 0) {
        this.showKeyInfo = true;
      }
      //to pass the details response to parent page using emit event.
      this.setProviderDetails.emit(this.providerDetail);
    }
    if (this._appSession.deeplinkParams?.deeplinkUrl === DeeplinkUrl.PROVIDER_DETAILS && !result?.providerDetail?.providerIdentifier) {
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
    }
  }

  onError(error: any) {
    this.showDetailsLoader = false;
    this.showDetailsError = true;
    throw error;
  }
  getGenderText(genderCode: any) {
    return this.WcsUtility.getGenderText(genderCode);
  }
  getAcceptsNewPatientsMsg(code: string) {
    return this.WcsUtility.getAcceptsNewPatientsMsg(code);
  }

  getAcceptsMedicaidMsg(provider: any) {
    if (provider && provider.performance && provider.performance.acceptMedicaid) {
      return this.WcsUtility.getAcceptsMedicaidMsg(provider.performance.acceptMedicaid);
    } else {
      return this.WcsUtility.getAcceptsMedicaidMsg('');
    }
  }

  getSpecialtyMsg(specialty: any) {
    return this.WcsUtility.getSpecialtyMsg(specialty);
  }
  getAgePreference(lowRange: string, highRange: string) {
    return this.WcsUtility.getAgePreference(lowRange, highRange);
  }
  getGenderPreference(genderPref: string) {
    return this.WcsUtility.getGenderPreference(genderPref);
  }
  getIhspProvider(ihspPref: boolean): string {
    return this.WcsUtility.getIhspPreference(ihspPref);
  }
  /*getVisionServicesAvailable(visionServiceList: any) {
    return this.WcsUtility.getFormattedVisionServices(visionServiceList);
  }*/
  isSrviceAvailDiscountCode(visionService: any) {
    return (visionService && visionService.code && visionService.code.toUpperCase() === this.visionDiscountSrvice);
  }

  openDomainDisclaimerModal(url: any) {
    this.openDomain.emit(url);
  }

  vitalsSsoConfigValue(professional: any) {
    this.vitalsSsoConfig = this.WcsUtility.getVitalsSsoConfig(professional);
    return this.vitalsSsoConfig;
  }

  locationResponceData(location: any) {
    if (location && location !== undefined && location.length > 0) {
      this.providerDetail.open24hours = location[0].open24hours;
      this.providerDetail.officeHours = location[0].officeHours;
      this.providerDetail.afterHoursPhone = location[0].afterHoursPhone;
      this.providerDetail.email = location[0].email;
      this.providerDetail.onlineUrl = location[0].onlineUrl;
      this.providerDetail.officeFeature = location[0].officeFeature;
      this.providerDetail.adaAccessibilityList = location[0].adaAccessibilityList;
      this.providerDetail.physicalDisabilityInd = location[0].physicalDisabilityInd;
      this.providerDetail.storeNumber = location[0].storeNumber;
      this.locationData.emit(location);
      this.setProvInfoForReportInvalidData();
    }
  }

  showReviewLink(totalReviews: any) {
    return CommonUtil.showReviewLink(totalReviews);
  }

  checkIndividualProvWithProvTypeP(provCategoryCode: string, providerTypeCodeList: Array<string>) {
    return CommonUtil.checkIndividualProvWithProvTypeP(provCategoryCode, providerTypeCodeList);
  }

  isReviewRating(rating: ReviewResponse, provCategoryCode: string, providerTypeCodeList: Array<string>) {
    return CommonUtil.isReviewRating(rating, provCategoryCode, providerTypeCodeList);
  }

  showAssignPCPBtn(detailVisibility: any): boolean {
    if (detailVisibility && detailVisibility.showAssignPCP) {
      return true;
    }
    return false;
  }
  checkPcpIndicator(val: string): boolean {
    const _pcpInd = val.split(';').filter((x) => x === 'U');
    if (_pcpInd) {
      return true;
    }
    return false;
  }
  onAppointmentSchedule(data: any) {
    this.scheduleAppointment.emit(data);
  }

  /**
   * Return true if accessibility tooltip need to display
   * @param code
   * @returns
   */
  showAccessibilityTooltip(code: string): boolean {
    switch ((code || '').toLowerCase()) {
      case AccessibilityCodes.HACC:
      case AccessibilityCodes.NHACC:
      case AccessibilityCodes.CCT:
        return true;
      default:
        return false;
    }
  }

  /**
   * Return disclaimer content for corresponding accessibility code
   * @param code
   * @returns
   */
  getAccessibilityDisclaimer(code: string): string {
    switch ((code || '').toLowerCase()) {
      case AccessibilityCodes.HACC:
      case AccessibilityCodes.NHACC:
        return this.content?.locationsComponent?.tooltips?.adaAccDisclaimer;
      case AccessibilityCodes.CCT:
        return this.content?.locationsComponent?.tooltips?.cctDisclaimer;
      default:
        return '';
    }
  }

  private showInfoNA() {
    return (this.providerDetail.reviewRatings[0] &&
      this.providerDetail.reviewRatings[0].starRating &&
      this.providerDetail.reviewRatings[0].starRating !== 'No Rating Available') ||
      (this.providerDetail.reviewRatings[0] &&
        this.providerDetail.reviewRatings[0].pdtStrKey && this.showBeFirstToReview);
  }

  private getMoreRating(event: Event) {
    this.showMoreLink = !this.showMoreLink;
    event.preventDefault();
  }
  private onReadMore() {
    this.showReadLess = true;
    this.showReadMore = false;
    this.providerDetail.biography = this.biography;
  }

  private onReadLess() {
    this.showReadLess = false;
    this.showReadMore = true;
    this.trimString();
  }
  private trimString() {
    const length = ENHNCMNT_TXT_LNGTH;
    var trimmedString = this.providerDetail.biography.substring(0, length);
    const index = trimmedString.lastIndexOf(' ');
    trimmedString = this.providerDetail.biography.substring(0, index);
    this.providerDetail.biography = trimmedString;
  }

  openTelehealth() {
    this.openTelehealthModal.emit();
  }

  /** get Provider details for selected */
  getProviderRequestDetails(): IProvider {
    const providerDetails: IProvider = {} as IProvider;
    providerDetails.providerTypeCodeList = this.selectedProvider.providerTypeCodeList;
    return providerDetails;
  }

  private _adobeAnalyticsObj: IHealthwiseAdobeAnalyticsObj = {
    isSummary: false
  }

  /** Open healthwise side Panel */
  openHealthwiseSidePanel(specialty?: ISpecialty, provider?: IProvider) {
    const providerInfo = this.getProviderRequestDetails();
    providerInfo.specialtyCategory = provider.specialtyCategory;
    this._adobeAnalyticsObj.specialtyName = specialty.specialtyCodeName;
    this.viewHealthwiseContentModal.openSidePanel('right', specialty, providerInfo, null, this._adobeAnalyticsObj);
  }

  private setProvInfoForReportInvalidData() {
    if (this.detailVisibility?.showReportInvalid) {
      this.reportValues.providerName = this.selectedProvider?.providerName;
      this.reportValues.addressOne = this.selectedProvider?.location?.address?.addressOne;
      this.reportValues.city = this.selectedProvider?.location?.address?.city;
      this.reportValues.nationalProviderIdentifier = this.selectedProvider?.nationalProviderIdentifier;
      this.reportValues.specialities = (this.selectedProvider?.specialties || []).map((splty) => this.getSpecialtyMsg(splty));
      this.reportValues.state = this.selectedProvider?.location?.address?.state;
    }
  }
}
