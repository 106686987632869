import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataHelper } from '../../../../common/services/dataHelper';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { ISearchParameters } from '../../../../fad/search-providers/interfaces/iSearchParameters';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { PersonalizedPDFMapping } from '../../../common/constants/personalizedDownloadPdfMapping';
import { IOption } from '../../../common/interfaces/iOption';
import { ISearchFilterResponse } from '../../../common/interfaces/iSearchFilter';
import { SortOptionHandler } from '../../classes/sortOptionHandler';
import { FilterCategory, FilterValue, PERSONALIZED_MATCH } from '../../constants/result';
import { IAppliedSearchFilter } from '../../models/iAppliedSearchFilter';
import { AppliedSearchFilter } from '../../services/appliedSearchFilter';
import { FilterSidePanelComponent } from '../filterSidePanel/filterSidePanelCmp';
import { SliderDirection } from './../../../common/constants/common';
import { SearchFilter } from './../../services/searchFilter';

@Component({
  moduleId: module.id,
  selector: 'app-fc-filter-header-cmp',
  templateUrl: './filterHeaderCmp.html'
})
export class FilterHeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  filterContent = this.content?.result?.filter;
  sortFilter = this.content?.result?.summaryComponent;
  sortOptions: IOption[] = [];
  isSortContainerVisible: boolean = false;
  selectedSort = '';
  selectedSortLabel = '';
  personalizedMatch = PERSONALIZED_MATCH;
  @ViewChild('searchResultsFilterModal')
  searchResultsFilterModal: FilterSidePanelComponent;
  @Input() disableFilter: boolean;
  @Output()
  selectedSortOption: EventEmitter<any> = new EventEmitter<any>();
  @Output() keyFiltersClicked = new EventEmitter();
  selectedFilterCount: number = 0;
  filtersLoaded: boolean = false;
  showPcpFilter: boolean = false;
  showAnpFilter: boolean = false;
  searchCriteria: ISearchParameters;
  appliedFilters: IAppliedSearchFilter[] = [];
  private appliedFilterSubscription: Subscription;
  private searchFilterSubscription: Subscription;
  private sortSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _appliedFilter: AppliedSearchFilter,
    private _dataHlpr: DataHelper,
    private _searchFilter: SearchFilter,
    private _sortOption: SortOptionHandler
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit(): void {
    this.appliedFilterSubscription = this._appliedFilter.getAppliedFilters.subscribe((data) => {
      this.appliedFilters = data;
      this.selectedFilterCount = data?.length;
    });
    this.searchFilterSubscription = this._searchFilter.filters.subscribe((filters: ISearchFilterResponse) => {
      if (filters) {
        this.filtersLoaded = true;
        const initialFilterCount = this.getInitialFilterCount(filters);
        if (!this.selectedFilterCount && initialFilterCount) {
          this.selectedFilterCount = initialFilterCount;
        }
      }
    });
    this.sortSubscription = this._sortOption.getSortOptions.subscribe((options: IOption[]) => {
      if (options?.length) {
        this.sortOptions = options;
        const selectedOption = this._sortOption.selectedOption;
        if (selectedOption) {
          this.selectedSortLabel = selectedOption.label;
          this.selectedSort = selectedOption.value;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.appliedFilterSubscription?.unsubscribe();
    this.searchFilterSubscription?.unsubscribe();
    this.sortSubscription?.unsubscribe();
  }

  openFilterPanel() {
    this.searchResultsFilterModal.openSidePanel(SliderDirection.RIGHT);
  }

  showSortContainer() {
    setTimeout(() => {
      this.isSortContainerVisible = true;
    });
  }

  selectSortingOption(option: any) {
    this.selectedSort = option.value;
    this.selectedSortLabel = option.label;
  }

  onApplyClick() {
    this.isSortContainerVisible = false;
    this.selectedSortOption.emit(this.selectedSort);
  }

  onCancelClick() {
    if (this.isSortContainerVisible) {
      this.isSortContainerVisible = false;
    }
  }

  getSelectedFilterCount(): string {
    const filterCount = this.selectedFilterCount || 0;
    if (filterCount > 0) {
      return ' (' + filterCount + ')';
    } else {
      return '';
    }
  }

  /**
   * Calculates the initial count of applied filters based on the search parameters and available filters.
   * @param filters The available filters.
   * @returns {number} The count of initially applied filters.
   */
  private getInitialFilterCount(filters: ISearchFilterResponse): number {
    if (!filters || !this._appSession?.searchParams) {
      return 0;
    }

    let filterCount = 0;
    const { specialtySelectNm, taxonomySelectNm, providerTypeCodeList, ableToServePcp, acceptingNewPatients, aoeSelectNm, ofcSrvcSelectNm } = this._appSession.searchParams;
    const { specialtyCategories, taxonomies, providerTypes, ableToServeAsPCP, acceptsPatients, areaOfExpertise, officeServices } = filters;

    const countMatchingFilters = (selectedCodes: string[], availableFilters: { code: string }[]): number => {
      const uniqueSelectedCodes = Array.from(new Set(selectedCodes));
      return uniqueSelectedCodes.reduce((count, code) => {
        return count + (availableFilters?.some((filter) => filter.code === code) ? 1 : 0);
      }, 0);
    };

    const specialtyCount = countMatchingFilters(specialtySelectNm, specialtyCategories);
    const taxonomyCount = countMatchingFilters(taxonomySelectNm, taxonomies);
    const typeCount = countMatchingFilters(providerTypeCodeList, providerTypes);
    const aoeCount = countMatchingFilters(aoeSelectNm, areaOfExpertise);
    const ofcSrvcCount = countMatchingFilters(ofcSrvcSelectNm, officeServices);

    filterCount = specialtyCount + taxonomyCount + typeCount + aoeCount + ofcSrvcCount;
    if (ableToServePcp) {
      filterCount++;
    }
    if (acceptingNewPatients) {
      filterCount++;
    }
    this.showPcpFilter = ableToServeAsPCP ? true : false;
    this.showAnpFilter = acceptsPatients ? true : false;

    return filterCount;
  }

  getPersonalizedMatchContent(): string {
    return this.sortFilter?.moreOptionsTooltip?.replace(/{pdfUrl}/gi, this.getPersonalizedMatchPdfLink());
  }

  getPersonalizedMatchPdfLink(): string {
    let personalizedMatchLink = '';
    let appendUrl = '';
    if (!this.sydneyMedicaid) {
      const snr = 'snr_' + this._appSession?.metaData?.locale;
      const commercial = 'commercial_' + this._appSession?.metaData?.locale;
      const link = this._dataHlpr.getValueByKey<string>(this._appSession.mbu, snr, commercial);
      appendUrl = this._dataHlpr.getValueByKey<string>(link, PersonalizedPDFMapping.personalizedMatchPdf);
    } else {
      const sydMedicaidState = `medicaid${this._appSession?.searchParams?.stateCd?.code}${this._appSession?.searchParams?.brandCode}_${this._appSession?.metaData?.locale}`;
      appendUrl = this._dataHlpr.getValueByKey<string>(sydMedicaidState, PersonalizedPDFMapping.medicaidStateByPersonalizedMatchPdf);
    }
    if (appendUrl) {
      personalizedMatchLink = CommonUtil.personalizedMatchPdfLink(this._appSession, appendUrl);
    }
    return personalizedMatchLink;
  }

  applyKeyFilter(data: string) {
    if (this._appSession?.searchParams) {
      if (data === FilterValue.ABLE_TO_SERVE_AS_PCP) {
        const pcpFilterOption = {
          label: this.filterContent?.serveAsPcp,
          value: FilterValue.ABLE_TO_SERVE_AS_PCP,
        } as IOption;
        this._appSession.searchParams.ableToServePcp = !this._appSession?.searchParams?.ableToServePcp;
        if (this._appSession.searchParams.ableToServePcp) {
          this.selectedFilterCount++;
        } else {
          this.selectedFilterCount--;
          this._appliedFilter.removeFilterOption(FilterCategory.KEYFILTERS, pcpFilterOption);
        }
      }
      if (data === FilterValue.ACCEPT_PATIENTS) {
        const anpFilterOption = {
          label: this.filterContent?.acceptsNewPatients,
          value: FilterValue.ACCEPT_PATIENTS,
        } as IOption;
        this._appSession.searchParams.acceptingNewPatients = !this._appSession?.searchParams?.acceptingNewPatients;
        if (this._appSession.searchParams.acceptingNewPatients) {
          this.selectedFilterCount++;
        } else {
          this.selectedFilterCount--;
          this._appliedFilter.removeFilterOption(FilterCategory.KEYFILTERS, anpFilterOption);
        }
      }
    }
    this.keyFiltersClicked.emit();
  }
}
