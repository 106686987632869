import { AppSession } from '../../../../../../common/values/appSession';
import { ISearchParameters } from '../../../../../../fad/search-providers/interfaces/iSearchParameters';
import { MEDICAL_COVERAGETYPE, PROVIDER_TYPE } from '../../../../../../fad/search-providers/values/providerSearchConstants';
import { ResultsUtil } from '../../../../../../fad/utilities/resultsUtil';
import { PageAlertType, PageAlertTypeStyle, SearchExecutionMode } from '../../../../../../findcare/common/constants/common';
import { ALERT_CUSTOMER_SUPPORT_BRAND_URL_CONFIG, PAGE_ALERT_TYPE } from '../../../../../../findcare/common/contents/common/alertTypeConfig';
import { FindCarePageType } from '../../../../../../findcare/common/enums/findCarePages';
import { IPageAlertRequest, IPageAlertResponse } from '../../../../../../findcare/common/interfaces/iPageAlert';
import { IProviderList } from '../../../../../result/interfaces/iProvider';
import { IMemberCriteria } from '../../../../interfaces/iMemberCriteria';
import { CommonUtility } from '../../../../utilities/commonUtil';

export class PageAlertHandler {
  static buildPageAlertRequest(pageType: FindCarePageType, appSession: AppSession): IPageAlertRequest {
    const request: IPageAlertRequest = {
      page: pageType
    };
    //Brands
    if (appSession?.metaData?.brandCd) {
      request.brand = [appSession.metaData.brandCd];
    }
    //prefix
    const prefix = this.getPrefix(appSession.searchParams, appSession)?.toLocaleUpperCase();
    if (prefix) {
      request.prefix = [prefix];
    }
    //searhState
    request.searchState = appSession.searchParams && appSession.searchParams.stateCd && appSession.searchParams?.stateCd?.code ? [appSession.searchParams.stateCd.code] : [];
    if (appSession?.searchParams) {
      request.specialties = appSession.searchParams.specialtySelectNm || [];
      request.taxonomies = appSession.searchParams.taxonomySelectNm || [];
      request.provTypeCodes = this.getProviderType(appSession);
    }

    //state - plan state code / prefix state code /
    request.state = appSession.searchParams?.plan && appSession.searchParams?.plan.stateCd ? [appSession.searchParams?.plan?.stateCd] : [appSession.alphaPrefixStateCode];
    if (appSession?.searchParams?.plan?.isNationalPlan) {
      request.state.push('NT');
    }
    request.mbu = appSession.searchParams?.plan?.category?.catName ? [appSession.searchParams.plan.category.catName] : [];
    //plan category
    if (appSession?.searchParams?.plan?.category?.catCode) {
      request.planCategory = [appSession?.searchParams?.plan?.category?.catCode];
    }

    //member info
    if (appSession.appState?.executionMode === SearchExecutionMode.SECURE) {
      const selectedContract = appSession.appState?.selectedEligibilityProduct?.selectedContract;
      request.memberCriteria = {};
      request.memberCriteria.mbrUid = selectedContract?.mbrUid;
      request.memberCriteria.contractUid = selectedContract.contractUid;
    }
    //hcid-prefix
    const hcidPrefix = appSession.getHcidPrefix();
    if (hcidPrefix) {
      request.hcidPrefix = [appSession.hcidPrefix];
    }
    return request;
  }

  static getMemberInfo(appSession: AppSession): IMemberCriteria | undefined {
    const member: IMemberCriteria = {};
    return member;
  }

  static getPrefix(searchParams: ISearchParameters, appSession: AppSession): string | undefined {
    if (searchParams?.plan?.alphaPrefix) {
      return searchParams.plan.alphaPrefix;
    } else if (appSession?.searchParams?.plan?.alphaPrefix) {
      return appSession?.searchParams.plan.alphaPrefix;
    } else if (appSession?.deeplinkParams?.alphaprefix) {
      return appSession.deeplinkParams.alphaprefix;
    }
    return undefined;
  }

  static buildPageAlertMessage(alertID: IPageAlertResponse, appSession: AppSession, pageName: FindCarePageType) {
    const upcomingPageAlertIds: string[] = alertID.pageAlerts ?? [];
    appSession.pageAlerts[pageName] = [];
    this.updateNewPageAlerts(upcomingPageAlertIds, pageName, appSession, false);
    CommonUtility.displayStaticAlerts(appSession, pageName);
  }

  // TODO: this functon will be removed in future
  static buildStaticPageAlerts(alertIds: string[], appSession: AppSession, pageName: FindCarePageType) {
    if (appSession.pageAlerts[pageName].length === 0) {
      this.updateNewPageAlerts(alertIds, pageName, appSession, true);
    } else {
      const newAlertIds = alertIds.filter((id) => !appSession.pageAlerts[pageName].map((alert) => alert.id).includes(id));
      if (newAlertIds.length > 0) {
        this.updateNewPageAlerts(newAlertIds, pageName, appSession, true);
      }
    }
  }

  static removePageAlert(alertIds: string[], appSession: AppSession, pageName: FindCarePageType) {
    // Get the page alerts for the specified page
    const pageAlert = appSession.pageAlerts[pageName];

    // Filter out the alerts that match the alertIds to be removed
    const updatedPageAlert = pageAlert.filter((alert) => !alertIds.includes(alert.id));

    // Update the appSession with the filtered alerts
    appSession.pageAlerts[pageName] = updatedPageAlert;
  }

  static updateNewPageAlerts(alertID: string[], page: FindCarePageType, appSession: AppSession, isStatic: boolean = false) {
    appSession.pageAlerts[page].unshift(
      ...alertID.map((id) => {
        return {
          sequence: isStatic ? '1' : '2',
          type: PAGE_ALERT_TYPE[id] ?? PageAlertType.INFORMATION,
          id: id
        };
      })
    );
    appSession.pageAlerts[page].sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence));
  }

  static getProviderType(appSession: AppSession): string[] {
    if (appSession.searchParams?.providerTypeCodeList?.length) {
      return appSession.searchParams?.providerTypeCodeList;
    } else if (appSession.searchParams?.typeSelectNm) {
      return [appSession.searchParams.typeSelectNm];
    } else if (appSession.providerTypeCodes?.length > 0) {
      return appSession.providerTypeCodes;
    } else {
      return [];
    }
  }

  static replaceLiteralwithContent(id: string, providersInfo: IProviderList, appSession, content): string {
    const _provName = appSession.searchTerm ? appSession.searchTerm.trim() : '';
    let _distance = '100';
    let alertMessage = content.common.pageAlerts[id];
    if (/{RDS}/gi.test(alertMessage)) {
      alertMessage = alertMessage.replace(/{RDS}/gi, providersInfo?.criteriaChange?.increasedRadius ?? _distance);
    }
    if (/{LOC}/gi.test(alertMessage)) {
      alertMessage = alertMessage.replace(/{LOC}/gi, ResultsUtil.getLocationText(appSession));
    }
    if (/{CNT}/gi.test(alertMessage)) {
      alertMessage = alertMessage.replace(/{CNT}/gi, providersInfo?.totalRecords ?? 0);
    }
    if (/{PVD}/gi.test(alertMessage)) {
      alertMessage = alertMessage.replace(/{PVD}/gi, this.getProviderTypeText(appSession, content));
    }
    if (/{PLAN}/gi.test(alertMessage)) {
      alertMessage = alertMessage.replace(/{PLAN}/gi, CommonUtility.getPlanNetworkPrefixText(appSession, content));
    }
    if (/{MATCH}/gi.test(alertMessage)) {
      alertMessage = alertMessage.replace(/{MATCH}/gi, _provName && _provName.length > 0 ? content?.result?.summaryComponent.resultsHeader.matchingNew.replace(/{NAM}/gi, _provName) : '');
    }
    if (/{REFINE}/gi.test(alertMessage)) {
      alertMessage = alertMessage.replace(/{REFINE}/gi, content.result.summaryComponent.resultsHeader.refineResultsNew);
    }
    if (/{brandUrl}/gi.test(alertMessage)) {
      alertMessage = this.replaceBrnadsInAlert(alertMessage, appSession, ALERT_CUSTOMER_SUPPORT_BRAND_URL_CONFIG[id]);
    }
    if (/{VISITS_VALUE}/gi.test(alertMessage) && providersInfo && providersInfo.providers) {
      alertMessage = alertMessage.replace(/{VISITS_VALUE}/gi, providersInfo.providers[0]?.eycCostInfo?.noOfVisitsVal ?? '');
    }

    return alertMessage;
  }

  static replaceBrnadsInAlert(alertMessage: string, appSession: AppSession, alertBrandContent): string {
    const brandCd = appSession?.metaData?.brandCd;
    const locale = appSession?.metaData?.locale;
    const urlToBeReplaced = alertBrandContent[locale][brandCd] ?? alertBrandContent[locale]['ABCBS'];
    if (brandCd && locale && alertBrandContent && urlToBeReplaced) {
      alertMessage = alertMessage.replace(/{brandUrl}/gi, urlToBeReplaced);
      return alertMessage;
    }
    return alertMessage;
  }

  static getProviderTypeText(_appSession: AppSession, content: any) {
    const defaultName = content.result.summaryComponent.searchCriteriaComponent.labels.careProviders;

    if (!_appSession.searchParams) {
      return defaultName;
    }

    const { coverageTypeCode, providerTypeCodeList } = _appSession.searchParams;
    const typeSelected = providerTypeCodeList?.[0];

    if (!typeSelected) {
      return defaultName;
    }

    const providerTypes = [PROVIDER_TYPE.DENTAL.toString(), PROVIDER_TYPE.VISION.toString()];
    const resultType = content.result?.summaryComponent.resultsProvType[typeSelected + coverageTypeCode];

    if (providerTypes.includes(typeSelected) && coverageTypeCode === MEDICAL_COVERAGETYPE && resultType) {
      return resultType;
    }

    const providers = content.commonHealthCareContent?.providers ?? [];
    const type = providers.find((x) => x.code === typeSelected);

    if (type?.name && !ResultsUtil.isDentalSearch(_appSession)) {
      return type.name;
    } else if (ResultsUtil.isDentalSearch(_appSession)) {
      return content.summaryComponent.resultsProvType[PROVIDER_TYPE.DENTAL];
    } else {
      return content.result?.summaryComponent.resultsProvType[typeSelected] || defaultName;
    }
  }

  static getIconStyle(type: string): string {
    switch (type) {
      case PageAlertType.INFORMATION:
        return PageAlertTypeStyle.INFORMATION;
      case PageAlertType.WARNING:
        return PageAlertTypeStyle.WARNING;
      case PageAlertType.NEGATIVE:
        return PageAlertTypeStyle.NEGATIVE;
      default:
        return '';
    }
  }
}
