import {
  AfterViewInit,
  Component,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BING_MAPS_CUSTOM_STYLE } from '../../constants/result';
import { IMapIcon, IMapInfoboxContext, IMapInfoboxEvent, IMapLocation } from '../../interfaces/iMap';
import { IAddress, IProvider } from '../../interfaces/iProvider';
import { IProviderOverview } from '../../interfaces/iProviderDetail';
import { IProviderLocationAddress } from '../../interfaces/iProviderLocation';
import { ProviderDetail } from '../../services/providerDetail';
import { environment } from './../../../../../environments/environment';
import { BingMapsLoader } from './../../../../common/services/bingMapsLoader';
import { EventHandler } from './../../../../common/services/eventHandler';
import { AppSession } from './../../../../common/values/appSession';
import { BaseComponent } from './../../../common/components/core/baseCmp';
import { ProviderCardNavigation } from './../../constants/result';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-map-cmp',
  templateUrl: './providerMapCmp.html'
})
export class ProviderMapComponent extends BaseComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() mapType: ProviderCardNavigation = ProviderCardNavigation.PROVIDER;
  @Input() providers: IProvider[] = [];
  @Input() activeProvider: IProvider;
  @Input() providerLocations: IProviderLocationAddress[] = [];
  @Input() compareProviderAddress: IAddress;
  @Output() mapSearch: EventEmitter<IMapLocation> = new EventEmitter();
  @Output() openProviderCard: EventEmitter<boolean> = new EventEmitter();
  @Output() selectedPushpinProvider: EventEmitter<IProvider> = new EventEmitter();

  @ViewChild('mapView') mapView: ElementRef;
  @ViewChild('affiliationMapView') affiliationMapView: ElementRef;
  @ViewChild('locationMapView') locationMapView: ElementRef;
  @ViewChild('compareMapView') compareMapView: ElementRef;
  @ViewChild('pushpinInfobox') pushpinInfobox: TemplateRef<IMapInfoboxContext>;
  @ViewChild('clusterInfobox') clusterInfobox: TemplateRef<IMapInfoboxContext>;
  @ViewChild('infoboxContainer', { read: ViewContainerRef }) infoboxContainer: ViewContainerRef;

  providerCardNavigation = ProviderCardNavigation;
  infoboxEvent = IMapInfoboxEvent;
  affiliationProvider: IProviderOverview = undefined;
  providerDetailSubscription: Subscription;
  providerMap: Microsoft.Maps.Map = undefined;
  affiliationMap: Microsoft.Maps.Map = undefined;
  locationMap: Microsoft.Maps.Map = undefined;
  compareMap: Microsoft.Maps.Map = undefined;
  pinInfobox: Microsoft.Maps.Infobox = undefined;
  showSearchMapArea: boolean = false;
  activePushpin: Microsoft.Maps.Pushpin = undefined;
  pushpins: Microsoft.Maps.Pushpin[] = [];
  locationPushpins: Microsoft.Maps.Pushpin[] = [];
  clusterLayer: Microsoft.Maps.ClusterLayer = undefined;
  mapLoaded: boolean = false;
  @Input() navbarVisible: boolean = true;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _loader: BingMapsLoader,
    private _providerDetailHandler: ProviderDetail
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngAfterViewInit() {
    this.loadMap().then(() => {
      this.mapLoaded = true;
      this.checkAndSetMapData();
    });
    this.providerDetailSubscription = this._providerDetailHandler.details.subscribe((providerDetail: IProviderOverview) => {
      this.affiliationProvider = providerDetail;
      this.checkAndSetMapData();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['providers'] || changes['providerLocations'] || changes['mapType']) {
      this.checkAndSetMapData();
    }
  }

  get activeMap(): Microsoft.Maps.Map {
    switch (this.mapType) {
      case ProviderCardNavigation.AFFILIATION:
        return this.affiliationMap;
      case ProviderCardNavigation.LOCATION:
        return this.locationMap;
      case ProviderCardNavigation.COMPARE:
        return this.compareMap;
      default:
        return this.providerMap;
    }
  }

  /**
   * Load the Bing Maps library and initialize the map
   */
  async loadMap() {
    await this._loader.loadMapsLibrary();

    // Fallback center latitude and longitude
    const fallbackCoordinates = { latitude: '34.197972948629776', longitude: '-84.1564135' };

    const { latitude, longitude } = this._appSession.searchParams?.coordinates || fallbackCoordinates;

    const mapOptions: Microsoft.Maps.IMapLoadOptions = {
      mapTypeId: Microsoft.Maps.MapTypeId.road,
      credentials: environment.bingMaps.apiKey,
      showMapTypeSelector: false,
      showZoomButtons: false,
      showLocateMeButton: false,
      customMapStyle: BING_MAPS_CUSTOM_STYLE,
      center: new Microsoft.Maps.Location(latitude, longitude)
    };

    this.providerMap = new Microsoft.Maps.Map(this.mapView.nativeElement, mapOptions);
    this.affiliationMap = new Microsoft.Maps.Map(this.affiliationMapView.nativeElement, mapOptions);
    this.locationMap = new Microsoft.Maps.Map(this.locationMapView.nativeElement, mapOptions);
    this.compareMap = new Microsoft.Maps.Map(this.compareMapView.nativeElement, mapOptions);
  }

  /**
   * Check if the map is loaded and the providers data is available
   */
  checkAndSetMapData() {
    if (!this.mapLoaded) {
      return;
    }
    if (this.mapType === ProviderCardNavigation.PROVIDER && this.providers?.length > 0) {
      this.setMapData();
    }
    if (this.mapType === ProviderCardNavigation.AFFILIATION && this.affiliationProvider) {
      this.setAffiliationMapData();
    }
    if (this.mapType === ProviderCardNavigation.COMPARE) {
      this.setCompareMapData();
    }
    if (this.mapType === ProviderCardNavigation.LOCATION && this.providerLocations?.length > 0) {
      this.setLocationsMapData();
    }
    this.setupPinInfobox(this.activeMap);
  }

  /**
   * Set the map data with the providers data
   */
  setMapData() {
    if (this.clusterLayer) {
      this.providerMap.layers.remove(this.clusterLayer);
    }

    // Filter out the virtual providers with invalid latitude and longitude
    // Added extra check for Pharmacy coming without latitide and longitude
    this.pushpins = this.providers
      .filter(
        (provider) =>
          provider.addressSummary?.latitude != null && provider.addressSummary?.latitude !== '-1' && provider.addressSummary?.longitude != null && provider.addressSummary?.longitude !== '-1'
      )
      .map((provider) => this.createPushpin(provider));

    Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', () => {
      this.clusterLayer = new Microsoft.Maps.ClusterLayer(this.pushpins, {
        clusteredPinCallback: this.clusteredPinCallback.bind(this)
      });

      this.providerMap.layers.insert(this.clusterLayer);

      Microsoft.Maps.Events.addHandler(this.providerMap, 'viewchangeend', this.onMapPositionChange.bind(this));
      Microsoft.Maps.Events.addHandler(this.providerMap, 'click', this.onMapClick.bind(this));
    });

    if (!this.showSearchMapArea) {
      this.setMapBounds(this.pushpins);
    }
    this.showSearchMapArea = false;
  }

  /**
   * Set the map data with the affiliation provider data
   */
  setAffiliationMapData() {
    this.affiliationMap.entities.clear();

    const address = this.affiliationProvider.providerDetail.address;
    const affiliationPushpin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(address.latitude, address.longitude), this.getPushpinOptions(IMapIcon.AFFILIATION_PUSHPIN));

    this.affiliationMap.entities.push(affiliationPushpin);
    this.affiliationMap.setView({ center: affiliationPushpin.getLocation(), zoom: 15 });
  }

   /**
   * Set the map data with the compare provider data
   */
   setCompareMapData() {
    this.compareMap.entities.clear();

    const address = this.compareProviderAddress;
    const comparePushpin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(address?.latitude, address?.longitude), this.getPushpinOptions(IMapIcon.DEFAULT_PUSHPIN));

    this.compareMap.entities.push(comparePushpin);
    this.compareMap.setView({ center: comparePushpin.getLocation(), zoom: 15 });
  }

  /**
   * Set the map data with the provider locations data
   */
  setLocationsMapData() {
    this.locationMap.entities.clear();

    let activeLocationPushpin: Microsoft.Maps.Pushpin;

    this.locationPushpins = this.providerLocations.map((location) => {
      const { addressId, addressOne, addressTwo, city, distance, state, postalCode, latitude, longitude } = location;
      const isActiveLocation = addressId === this.affiliationProvider.providerDetail.address.addressId;
      const pushpinIcon = isActiveLocation ? IMapIcon.AFFILIATION_PUSHPIN : IMapIcon.DEFAULT_PUSHPIN;
      const pushpinOptions = this.getPushpinOptions(pushpinIcon);
      const pushpin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(latitude, longitude), pushpinOptions);

      if (isActiveLocation) {
        activeLocationPushpin = pushpin;
        this.locationMap.setView({ center: activeLocationPushpin.getLocation(), zoom: 12 });
      }

      const addressSummary = {
        addressIdentifier: addressId,
        addressOne,
        addressTwo,
        cityName: city,
        distance,
        stateCode: state,
        postalCode,
        latitude,
        longitude
      };

      const pushpinMetadata: IProvider = {
        ...this.activeProvider,
        addressSummary
      };

      pushpin.metadata = { ...pushpinMetadata, isActiveLocation };

      this.addPushpinEventHandlers(pushpin, pushpinMetadata);

      return pushpin;
    });

    this.locationMap.entities.push(this.locationPushpins);
  }

  /**
   * Create a pushpin for the provider
   * @param provider Provider data
   * @returns Microsoft.Maps.Pushpin instance
   */
  createPushpin(provider: IProvider): Microsoft.Maps.Pushpin {
    const { latitude, longitude } = provider?.addressSummary;
    const pushpin = new Microsoft.Maps.Pushpin(new Microsoft.Maps.Location(latitude, longitude), this.getPushpinOptions(IMapIcon.DEFAULT_PUSHPIN));
    pushpin.metadata = provider;

    this.addPushpinEventHandlers(pushpin, provider);

    return pushpin;
  }

  /**
   * Callback function for clustered pushpin
   * @param cluster Clustered pushpin
   */
  clusteredPinCallback(cluster: Microsoft.Maps.ClusterPushpin, provider: IProvider) {
    cluster.setOptions(this.getPushpinOptions(IMapIcon.DEFAULT_CLUSTER));

    this.addPushpinEventHandlers(cluster, provider);
  }

  /**
   * Add event handlers for the pushpin
   * @param pushpin Pushpin instance
   */
  addPushpinEventHandlers(pushpin: Microsoft.Maps.Pushpin | Microsoft.Maps.ClusterPushpin, provider: IProvider) {
    Microsoft.Maps.Events.addHandler(pushpin, 'mouseover', () => {
      this.showInfobox(pushpin);
    });

    Microsoft.Maps.Events.addHandler(pushpin, 'mouseout', () => {
      this.hideInfobox(pushpin);
    });

    Microsoft.Maps.Events.addHandler(pushpin, 'click', (event) => {
      this.showInfobox(pushpin);
    });
  }

  /**
   * Get pushpin options based on the icon type
   * @param iconType type of icon
   * @param iconText text to be displayed on the icon
   * @returns Microsoft.Maps.IPushpinOptions instance
   */
  getPushpinOptions(iconType: IMapIcon, iconText?: string): Microsoft.Maps.IPushpinOptions {
    const mapIconOptions = {
      DEFAULT_PUSHPIN: {
        icon: this.getCommonImageURL('map-pushpin.svg'),
        anchor: new Microsoft.Maps.Point(17, 48)
      },
      HOVER_PUSHPIN: {
        icon: this.getCommonImageURL('map-pushpin-hover.svg'),
        anchor: new Microsoft.Maps.Point(21, 59)
      },
      DEFAULT_CLUSTER: {
        icon: this.getCommonImageURL('map-pushpin.svg'),
        anchor: new Microsoft.Maps.Point(17, 48),
        color: '#286CE2',
        textOffset: new Microsoft.Maps.Point(0, 12)
      },
      AFFILIATION_PUSHPIN: {
        icon: this.getCommonImageURL('map-pushpin-affiliation.svg'),
        anchor: new Microsoft.Maps.Point(21, 59)
      },
      HOVER_CLUSTER: {
        icon: this.createClusterHoverIcon(iconText),
        anchor: new Microsoft.Maps.Point(21, 59),
        text: ''
      }
    };

    return mapIconOptions[iconType];
  }

  /**
   * Create a cluster hover icon with the text
   * @param text Text to be displayed on the cluster icon
   * @returns SVG string with text
   */
  createClusterHoverIcon(text: string): string {
    const textColor = '#286CE2';
    // To add text to the cluster icon, we need to create an SVG string with the text
    return `
      <svg xmlns="http://www.w3.org/2000/svg" width="42" height="59" viewBox="0 0 42 59" fill="none">
        <mask id="path-1-inside-1_4884_303327" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M38.5216 32.4822C40.7198 29.1726 42 25.2039 42 20.9371C42 9.37388 32.598 0 21 0C9.40202 0 0 9.37388 0 20.9371C0 25.204 1.28018 29.1727 3.4785 32.4824L18.4477 56.7165C19.62 58.6144 22.38 58.6144 23.5523 56.7165L38.5216 32.4822Z"/>
        </mask>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M38.5216 32.4822C40.7198 29.1726 42 25.2039 42 20.9371C42 9.37388 32.598 0 21 0C9.40202 0 0 9.37388 0 20.9371C0 25.204 1.28018 29.1727 3.4785 32.4824L18.4477 56.7165C19.62 58.6144 22.38 58.6144 23.5523 56.7165L38.5216 32.4822Z" fill="white"/>
        <path d="M38.5216 32.4822L34.3566 29.7158L34.311 29.7845L34.2677 29.8546L38.5216 32.4822ZM3.4785 32.4824L7.73241 29.8548L7.68908 29.7846L7.64346 29.7159L3.4785 32.4824ZM18.4477 56.7165L22.7016 54.0889L22.7016 54.0889L18.4477 56.7165ZM23.5523 56.7165L27.8063 59.3441L27.8063 59.3441L23.5523 56.7165ZM37 20.9371C37 24.1888 36.0277 27.1999 34.3566 29.7158L42.6866 35.2486C45.412 31.1453 47 26.2191 47 20.9371H37ZM21 5C29.8507 5 37 12.1494 37 20.9371H47C47 6.59833 35.3453 -5 21 -5V5ZM5 20.9371C5 12.1494 12.1493 5 21 5V-5C6.65474 -5 -5 6.59833 -5 20.9371H5ZM7.64346 29.7159C5.97234 27.2 5 24.1888 5 20.9371H-5C-5 26.2191 -3.41197 31.1454 -0.686462 35.2488L7.64346 29.7159ZM22.7016 54.0889L7.73241 29.8548L-0.77541 35.1099L14.1937 59.3441L22.7016 54.0889ZM19.2984 54.0889C20.08 52.8236 21.92 52.8236 22.7016 54.0889L14.1937 59.3441C17.32 64.4052 24.6801 64.4052 27.8063 59.3441L19.2984 54.0889ZM34.2677 29.8546L19.2984 54.0889L27.8063 59.3441L42.7755 35.1098L34.2677 29.8546Z" fill="#286CE2" mask="url(#path-1-inside-1_4884_303327)"/>
        <text x="50%" y="50%" font-size="16" font-family="Arial, sans-serif" text-anchor="middle" fill="${textColor}" font-weight="700">${text}</text>
      </svg>
    `;
  }

  /**
   * setup the pin infobox and add it to the map
   */
  setupPinInfobox(mapRef: Microsoft.Maps.Map) {
    if (this.pinInfobox) {
      this.pinInfobox.setMap(null);
    }

    const mapCenter: Microsoft.Maps.Location = this.activeMap.getCenter();

    this.pinInfobox = new Microsoft.Maps.Infobox(mapCenter, {
      visible: false,
      showPointer: true,
      showCloseButton: true,
      offset: new Microsoft.Maps.Point(16, 48),
      //@ts-ignore
      autoAlignment: true
    });

    this.pinInfobox.setMap(mapRef);

    Microsoft.Maps.Events.addHandler(this.pinInfobox, 'click', (event) => {
      this.onInfoboxClick(event.originalEvent);
    });
  }

  /**
   * Handle the click events on the infobox based on the infobox event data attribute
   * @param eventArg Mouse event arguments with original event
   */
  onInfoboxClick(event: Event) {
    const targetElement = event.target as HTMLElement;
    const infoboxEvent = targetElement.getAttribute('data-infobox-event') as IMapInfoboxEvent;

    switch (infoboxEvent) {
      case IMapInfoboxEvent.CLOSE:
        this.hideInfobox(this.activePushpin);
        break;
      case IMapInfoboxEvent.VIEW_DETAILS:
        const providerId = targetElement.getAttribute('data-provider-id');
        const provider = this.providers.find((p) => p.providerIdentifier === providerId);
        this.onOpenProviderCard(provider);
        break;
    }
  }

  /**
   * Show the infobox for the pushpin
   * @param pushpin Pushpin instance
   */
  showInfobox(pushpin: Microsoft.Maps.Pushpin) {
    const iconType = pushpin instanceof Microsoft.Maps.ClusterPushpin ? IMapIcon.HOVER_CLUSTER : IMapIcon.HOVER_PUSHPIN;

    if (this.activePushpin && this.activePushpin !== pushpin) {
      this.hideInfobox(this.activePushpin);
    }
    this.activePushpin = pushpin;

    pushpin.setOptions(this.getPushpinOptions(iconType, pushpin.getText()));

    this.pinInfobox.setOptions({
      location: pushpin.getLocation(),
      htmlContent: this.getPinContent(pushpin),
      visible: true
    });
  }

  /**
   * Hide the infobox for the pushpin
   * @param pushpin Pushpin instance
   */
  hideInfobox(pushpin?: Microsoft.Maps.Pushpin) {
    let iconType = pushpin instanceof Microsoft.Maps.ClusterPushpin ? IMapIcon.DEFAULT_CLUSTER : IMapIcon.DEFAULT_PUSHPIN;

    // Override icon type if it is an active location in locations map
    if (pushpin.metadata?.isActiveLocation && this.mapType === ProviderCardNavigation.LOCATION) {
      iconType = IMapIcon.AFFILIATION_PUSHPIN;
    }

    if (iconType === IMapIcon.DEFAULT_CLUSTER && pushpin instanceof Microsoft.Maps.ClusterPushpin) {
      pushpin.setOptions({ text: pushpin.containedPushpins?.length.toString() });
    }

    pushpin.setOptions(this.getPushpinOptions(iconType));

    this.pinInfobox.setOptions({ visible: false });
  }

  /**
   * Get the content for the infobox based on the pushpin type
   * @param pin pushpin instance
   * @returns HTML content for the infobox
   */
  getPinContent(pin: Microsoft.Maps.Pushpin): string {
    this.infoboxContainer.clear();
    let infoboxViewRef: EmbeddedViewRef<IMapInfoboxContext>;

    if (pin instanceof Microsoft.Maps.ClusterPushpin) {
      const providers: IProvider[] = pin.containedPushpins.map((p) => p.metadata);
      infoboxViewRef = this.infoboxContainer.createEmbeddedView(this.clusterInfobox, { providers: providers });
    } else {
      infoboxViewRef = this.infoboxContainer.createEmbeddedView(this.pushpinInfobox, { provider: pin.metadata });
    }

    infoboxViewRef?.detectChanges();
    return infoboxViewRef?.rootNodes[0]?.outerHTML || '';
  }

  /**
   * Set the map bounds based on the pushpins to make all the pushpins visible
   * @param pushpins pushpin instances
   */
  setMapBounds(pushpins: Microsoft.Maps.Pushpin[]) {
    if (pushpins.length === 0) {
      return;
    }

    const bounds = Microsoft.Maps.LocationRect.fromLocations(pushpins.map((pin) => pin.getLocation()));

    this.providerMap.setView({ bounds: bounds, padding: 24 });

    const mapZoomLevel = this.providerMap.getZoom();
    const defaultZoomLevel = 15;
    if (mapZoomLevel > defaultZoomLevel) {
      this.providerMap.setView({ zoom: defaultZoomLevel });
    }
  }

  /**
   * Search the map area based on the search criteria
   */
  onSearchMapArea() {
    const mapCenter = this.providerMap.getCenter();
    const center: IMapLocation = { latitude: mapCenter.latitude.toString(), longitude: mapCenter.longitude.toString() };

    this.mapSearch.emit(center);
  }

  /**
   * Show the search map area section on map position change
   */
  onMapPositionChange() {
    this.showSearchMapArea = true;
  }

  /**
   * Map click event handler
   * @param e Mouse event
   */
  onMapClick(e: Microsoft.Maps.IMouseEventArgs) {
    if (e.targetType !== 'pushpin' && e.targetType !== 'clusteredPushpin' && this.activePushpin) {
      this.hideInfobox(this.activePushpin);
    }
  }

  /**
   * Recenter the map to the user's current location
   * @returns void
   */
  onRecenter() {
    if (!navigator.geolocation) {
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const location = new Microsoft.Maps.Location(latitude, longitude);
        const userLocationPushpin = new Microsoft.Maps.Pushpin(location, {
          icon: this.getCommonImageURL('user-location.svg'),
          anchor: new Microsoft.Maps.Point(16, 16)
        });

        this.activeMap.entities.push(userLocationPushpin);
        this.activeMap.setView({ center: location });
      },
      (error) => {
        // TODO: Handle geolocation error scenario
      }
    );
  }

  /**
   * Set the zoom level of the map
   * @param n Zoom level to be set
   */
  setZoomLevel(n: number) {
    const curLevel = this.activeMap.getZoom();
    this.activeMap.setView({ zoom: curLevel + n });
  }

  /**
   * Toggle the infobox for the provider based on the show flag
   * @param provider provider data
   * @param show boolean flag to show/hide the infobox
   * @returns void
   */
  toggleProviderInfobox(provider: IProvider, show: boolean) {
    let pushpin = this.pushpins.find((pin) => pin.metadata.providerIdentifier === provider.providerIdentifier);
    if (!pushpin) {
      return;
    }

    show ? this.showInfobox(pushpin) : this.hideInfobox(pushpin);
  }

  onOpenProviderCard(provider: IProvider) {
    this.openProviderCard.emit(true);
    this.selectedPushpinProvider.emit(provider);
  }

  ngOnDestroy() {
    if (this.providerDetailSubscription) {
      this.providerDetailSubscription.unsubscribe();
    }
  }
}
