// TODO: others message need to be updated
export const PAGE_ALERTS_ES = {
  PA_COVID_FINDER_ALERT:
    "Encuentra un centro de pruebas cerca de ti con nuestro <a target='_blank' href='https://www.anthem.com/microsites/covid19-assessment/covid-finder.html'>Buscador de sitios de pruebas de COVID-19</a>",

  PA_LABS_ALERT: "Si un laboratorio está 'dentro de la red' también depende de la ubicación del médico que ordenó la prueba. Actualiza la ubicación de búsqueda con el código postal del médico.",

  PA_WLP_BH_ALERT:
    "Carelon Behavioral Health proporciona los servicios de salud conductual para el Plan de Indemnización Estatal de Wellpoint. Para encontrar un profesional de salud conductual, vaya al sitio web de <a target='_blank' href='{brandUrl}'>Carelon Behavioral Health</a>",
  PA_BEHAVIORAL_ALERT: "Tu plan no incluye cobertura para salud conductual. Comunícate con <a target='_blank' href='{brandUrl}'>Servicios para miembros</a> si tienes alguna pregunta sobre tu plan.",

  PA_MSSP_ALERT:
    "Para más información sobre el <a target='_blank' href='https://www.aging.ca.gov/Providers_and_Partners/Multipurpose_Senior_Services_Program/'>Programa de Servicio Multipropósito para Adultos Mayores (MSSP)</a> y ayuda con el proceso de solicitud, comuníquese con su coordinador de cuidado.",

  PA_UNIMASS_SECURE_EYC_ALERT:
    "<span id=\"lnk-member-service\" class=\"pf-pre-wrap pf-inline-span\"><span id=\"lnk-member-service\" aria-label=\"click here to navigate to Member Service\">No podemos mostrar estimados de costos de procedimientos para proveedores de cuidado ubicados fuera de Massachusetts. Si tienes alguna pregunta, comunícate con <a target=\"_blank\" aria-label=\" Servicios para miembros.\" id=\"lnk-member-service-anchor\" href=\"/contact-us\">Servicios para miembros.</a> </span></span>",

  PA_MEMBER_32BJ_ALERT:
    'A partir del 1 de enero de 2025, las cirugías de reemplazo de articulaciones (cadera, rodilla, hombro, tobillo), de columna y bariátricas deben ser realizadas por un médico de Lantern Surgery Care a través de los Centros de Excelencia (COE) del Fondo de Salud 32BJ para que la cirugía esté cubierta. Para comenzar, llama a Lantern al 855-413-7197.',

  PA_GA_ALERT:
    "<span id=\"termination-Providers-link-text\" class=\"pf-pre-wrap pf-inline-span\"><span id=\"termination-Providers-link-text\" aria-label=\"haga clic aquí para navegar a proveedores de cuidado médico que pueden dejar la red de tu plan\">Revisa esta lista de <a target=\"_blank\" aria-label=\" proveedores de cuidado médico que pueden dejar la red de tu plan\" id=\"termination-Providers-link-text-anchor\" href=\"/assets/documents/gaterminatingproviders_es.pdf\">proveedores de cuidado médico que pueden dejar la red de tu plan</a> durante el próximo año del plan.</span></span>",

  PA_EMORY_ALERT:
    "Emory se ha unido a la red Pathway a partir del 01/01/21. Esto significa que los proveedores de Emory estarán 'dentro de la red' para nuestros planes de beneficios individuales de 2021. Estamos trabajando para actualizar nuestro directorio de proveedores y reflejar esta actualización.",

  PA_MSK_WINFERTILITY:
    "Tus beneficios de fertilidad son administrados por <a target='_blank' href='https://managed.winfertility.com/memorial-sloan-kettering/'>WINFertility</a>. Para acceder a tus beneficios, debes inscribirte con una enfermera administradora de casos de WINFertility llamando al 844-343-0656.",

  PA_PROMOTED_PROCEDURES_ALERT:
    'Algunos miembros con antecedentes de salud similares al suyo han necesitado determinados procedimientos.<br><a target="_self" href="javascript:void(0);" class="promotedProcedures" data-analytics="compareProvFindCare" aria-label="Procedures You May Be Considering.link">Procedimientos que puede estar considerando</a>',

  PA_DENTAQUEST:
    '<span id="dentaquest-label-text" class="pf-pre-wrap pf-inline-span"><span id="dentaquest-label-text" aria-label="DentaQuest administra algunas partes de tu beneficio dental.">DentaQuest administra algunas partes de tu beneficio dental.</span><span id="dentaquest-link-text" aria-label="Visita el sitio web de DentaQuest para encontrar un profesional dental en tu red.">Visita el <a target="_blank" aria-label=" sitio web de DentaQuest" id="dentaquest-link-text-anchor" href="http://www.dentaquest.com/members/">sitio web de DentaQuest</a> para encontrar un profesional dental en tu red.</span></span>',

  PA_TN_DENTAQUEST:
    '<span id="tndentaquest-label-text" class="pf-pre-wrap pf-inline-span"><span id="tndentaquest-label-text" aria-label="Algunos de tus beneficios dentales para 2022 son administrados por DentaQuest">Algunos de tus beneficios dentales para 2022 son administrados por DentaQuest. </span><span id="tndentaquest-link-text" aria-label="Para encontrar un profesional dental en tu plan dental 2022, visita el sitio web de DentaQuest">Para encontrar un profesional dental en tu plan dental 2022, visita el <a target="_blank" aria-label=" sitio web de DentaQuest" id="tndentaquest-link-text-anchor" href="http://www.dentaquest.com/members/">sitio web de DentaQuest</a>.</span></span>',
  PA_MAGELLAN_PHARMACY:
    '<span id="pharmacy-label-text" class="pf-pre-wrap pf-inline-span"><span id="pharmacy-label-text" aria-label="Magellan administra algunas partes de su beneficio de Farmacia.">Magellan administra algunas partes de su beneficio de Farmacia.</span><span id="pharmacy-label-text" aria-label="Vaya al sitio web de la farmacia para encontrar una farmacia dentro de su red.">Vaya a <a target="_blank" aria-label=" Sitio web de Magellanl" id="pharmacy-label-text-anchor" href="https://www.lamcopbmpharmacy.com">Sitio web de Magellanl</a> para encontrar una farmacia dentro de su red.</span></span>',
  PA_EMPTY_REULTS_RESULT_PAGE:
    '<span class="ant-font-b pf-inline-span">No hay resultados.</span> Ampliamos automáticamente tu búsqueda, pero no encontramos ninguna coincidencia dentro de <span class="ant-font-b pf-inline-span">{RDS} millas</span> de <span class="ant-font-b pf-inline-span">{LOC}</span>. Intenta cambiar el código postal o comunícate con nosotros si tienes preguntas sobre tus beneficios.',
  PA_INCREASED_RADIUS_ALERT:
    'No se encontraron resultados, así que aumentamos la distancia de búsqueda a <span class="ant-font-b">{RDS}</span> millas de <span class="ant-font-b">{LOC}</span>. Encontramos <span class="ant-font-b">{CNT}</span> dentro de esa distancia {MATCH}.',
  PA_TENNCARE_PHARMACY:
    'Programa de farmacia de TennCare: si tienes cobertura de medicamentos recetados a través de TennCare, un plan de salud llamado Magellan Health Services se encarga de tus medicamentos recetados. Llama para preguntar sobre tus servicios de farmacia al 1-888-816-1680.',
  PA_NPN_PDF:
    '<span id="lnk-navituspdf" class="pf-pre-wrap pf-inline-span"><span id="lnk-navituspdf" aria-label="click here to open Navitus Pharmacy Network PDF">Encuentra una farmacia en tu plan consultando la Lista de la Red  <a target="_blank" aria-label=" de Farmacias de Navitus (PDF)." id="lnk-navituspdf-anchor" href="https://www.navitus.com/getmedia/25f6cef8-46f9-4d0b-9bd5-7ae8a4a13150/Navitus-Pharmacy-Network-Listing-National-and-Chains.pdf">de Farmacias de Navitus (PDF).</a></span></span>',
  PA_KYPORTAL:
    '<span id="lnk-kyportal" class="pf-pre-wrap pf-inline-span"><span id="lnk-kyportal" aria-label="click here to open Pharmacy locator tool">Para encontrar una farmacia cerca de ti, usa nuestra <a target="_blank" aria-label=" herramienta de localización de farmacias." id="lnk-navituspdf-anchor" href="http://kyportal.medimpact.com/">herramienta de localización de farmacias.</a></span></span>',
  PA_BH_SIMPLYMPL: 'Si necesitas ayuda para encontrar a un profesional de salud conductual, llama al <b>844-375-7215.</b>',
  PA_HOME_HEALTH_SIMPLYMPL: 'Si necesitas ayuda para encontrar atención médica en el hogar, llama al número de servicios para miembros que aparece en tu tarjeta de identificación.',
  PA_DME_SIMPLYMPL:
    'Si necesitas ayuda para encontrar un profesional médico/quirúrgico/equipo médico duradero (DME), llama al número de servicios para miembros que aparece en tu tarjeta de identificación.',
  PA_NO_OO_RESULT_PAGE:
    '<strong>Este proveedor de cuidado médico no está en la red de tu plan.</strong> Hemos aumentado automáticamente la distancia de búsqueda, pero no se encontraron resultados para <span class="ant-font-b">{PVD}</span> en el <span class="ant-font-b">{PLAN}</span> dentro de <span class="ant-font-b">{RDS}</span> millas de <span class="ant-font-b">{LOC}</span>{MATCH}.',
  PA_OON_SEARCH_NO_RESULT:
    'No hay resultados. Hemos ampliado automáticamente tu búsqueda, pero no encontramos ninguna coincidencia dentro de <span class="ant-font-b">{RDS}</span> millas de <span class="ant-font-b">{LOC}</span>. Intenta cambiar el código postal o <a href="https://www.anthem.com/contact-us">Contáctanos</a> si tienes preguntas sobre tus beneficios.',
  summaryMsgNew:
    '<span class="ant-font-b">{CNT}</span> en el <span class="ant-font-b">{PLAN}</span> dentro de <span class="ant-font-b">{RDS} millas</span> de <span class="ant-font-b">{LOC}</span>{MATCH}. {REFINE}',
  PA_NO_RESULT_DEFAULT_MSG:
    'No se encontraron resultados para <span class="ant-font-b">{PVD}</span> en el <span class="ant-font-b">{PLAN}</span> dentro de <span class="ant-font-b">{RDS}</span> millas de <span class="ant-font-b">{LOC}</span>{MATCH}.',
  PA_DME_ALERT: 'Si buscas equipo médico duradero, busca en el área donde se enviará el equipo o dónde se comprará en una tienda minorista.',
  PA_HCS:
    '<span id="hcs-label-text" class="pf-pre-wrap pf-inline-span"><span id="hcs-label-text" aria-label="Hearing Care Solutions maneja algunas partes de tu beneficio de audición.">Hearing Care Solutions maneja algunas partes de tu beneficio de audición.</span><span id="hcs-link-text" aria-label="Visita el sitio web de Hearing Care Solutions para encontrar un profesional de la audición en tu red.">Visita el <a target="_blank" aria-label=" sitio web de Hearing Care Solutions" id="hcs-link-text-anchor" href="https://hearingcaresolutions.com/anthem/">sitio web de Hearing Care Solutions</a> para encontrar un profesional de la audición en tu red.</span></span>',
  PA_LIBERTY:
    '<span id="liberty-label-text" class="pf-pre-wrap pf-inline-span"><span id="liberty-label-text" aria-label="Liberty Dental maneja algunas partes de tu beneficio dental.">Liberty Dental maneja algunas partes de tu beneficio dental.</span><span id="liberty-link-text" aria-label="Visita el sitio web de Liberty Dental para encontrar un profesional dental en tu red.">Visita el <a target="_blank" aria-label=" sitio web de Liberty Dental" id="liberty-link-text-anchor" href="https://client.libertydentalplan.com/anthem/FindADentist">sitio web de Liberty Dental</a> para encontrar un profesional dental en tu red.</span></span>',
  PA_EYEQUEST:
    '<span id="eyequest-label-text" class="pf-pre-wrap pf-inline-span"><span id="eyequest-label-text" aria-label="EyeQuest maneja algunas partes de tu beneficio de visión.">EyeQuest maneja algunas partes de tu beneficio de visión.</span><span id="eyequest-link-text" aria-label="Visita el sitio web de EyeQuest para encontrar un profesional de la visión en tu red.">Visita el <a target="_blank" aria-label=" sitio web de EyeQuest" id="eyequest-link-text-anchor" href="https://govservices10.dentaquest.com/Router.jsp?component=Main&amp;source=Logon&amp;action=ProviderDirectory">sitio web de EyeQuest</a> para encontrar un profesional de la visión en tu red.</span></span>',
  PA_GREEN_MEDICAL_BH:
    'Los servicios de salud conductual para el Plan de indemnización estatal de UniCare son proporcionados por Carelon Behavioral Health. Para encontrar un profesional de salud conductual, visite el <a target="_blank" href="https://plan.carelonbehavioralhealth.com/find-a-provider/unicare_gic">sitio web de Carelon Behavioral Health.</ un>',
  PA_MARCH:
    '<span id="lnk-march" class="pf-pre-wrap pf-inline-span"><span id="lnk-march" aria-label="click here to navigate to March website">March Vision Care maneja algunas partes de tu beneficio de visión. Visita el <a target="_blank" aria-label=" sitio web de March Vision Care" id="lnk-march-anchor" href="https://www.marchvisioncare.com/">sitio web de March Vision Care</a> para encontrar un profesional de la visión en tu red.</span></span>',
  PA_DNTAQST_FWDHLTH:
    '<span id="lnk-dentaquest" class="pf-pre-wrap pf-inline-span"><span id="lnk-dentaquest" aria-label="click here to navigate to DentaQuest website">Si vives en los condados de Kenosha, Milwaukee, Ozaukee, Racine, Washington y Waukesha, DentaQuest administra algunas partes de tu beneficio dental. Ve al <a target="_blank" aria-label=" sitio web de DentaQuest" id="lnk-dentaquest-anchor" href="http://www.dentaquest.com/members/">sitio web de DentaQuest</a> para encontrar un profesional dental dentro de tu red. Los miembros que viven en otros condados de Wisconsin, llamen a Servicios para miembros de ForwardHealth al 1-800-362-3002 para conocer sus beneficios dentales.</span></span>',
  PA_LCS:
    'Los servicios de consejería para la lactancia materna pueden ser ofrecidos o respaldados por un proveedor dentro de la red (participante), como un pediatra, ginecólogo o un hospital. Comunícate con el proveedor para determinar si hay servicios de consejería para la lactancia disponibles. Si tu búsqueda no arrojó ningún resultado, busca en Servicios de consultorio por servicios de lactancia.',
  PA_DENTAL_PROC_MSG:
    'Puede que este procedimiento no esté cubierto por tu plan dental. Consulta <a target="_blank" tabindex=\'0\' aria-label=\'tu plan dental\' href="/member/benefits?covtype=den"> tu plan dental </a> o <a target="_blank" href="/member/contact-us" tabindex=\'0\' aria-label=\'comunícate con nosotros\'> comunícate con nosotros </a> si tienes preguntas sobre tus beneficios dentales.',
  PA_EycAlert: 'La capacidad de buscar por procedimiento médico no estará disponible durante 12 a 18 horas debido a mantenimiento.',
  PA_SUPERIOR:
    'Superior administra algunas partes de su beneficio de visión. Vaya a <a target="_blank" aria-label="haga clic aquí para navegar al sitio web de Superior" href="https://superiorvision.com">web de Superior</a>para encontrar un profesional de la visión en su red.',
  PA_HEALTHY_BLUE_EYEMED:
    'EyeMed administra algunas partes de su beneficio de la vista. Vaya a <a target="_blank" aria-label="haga clic aquí para navegar al sitio web de EyeMed" href="https://eyedoclocator.eyemedvisioncare.com /hlthyblue/es">web de EyeMed</a>para encontrar un profesional de la visión en su red.',
  PA_SKYGEN:
    'Skygen administra algunas partes de su beneficio dental. Vaya a <a target="_blank" aria-label="haga clic aquí para navegar al sitio web de Skygen" href="https://healthybluemwp.sciondental.com/">web de Skygen</a> para encontrar un profesional dental en su red.',
  PA_TEXCHIP: 'CHIP Dental (Texas) ofrece beneficios dentales para los miembros. Llamen al 1-877-KIDS-NOW (1-877-543-7669) para ayuda',
  PA_TEXSTAR: 'beneficios dentales del proveedor de Texas Health Steps para miembros menores de 21 años. Llamen al 1-877-847-8377 para ayuda.',
  PA_TEXCHIP_PERINATAL: 'para encontrar un proveedor dental, llamen a DentaQuest al 1-855-418-1621.',
  PA_DELTA: 'Para encontrar un proveedor dental, llama a Delta Dental al 1-800-774-9049 (TTY 711).',
  PA_MD_DENTAL:
    '<span id="lnk-maryland" class="pf-pre-wrap pf-inline-span"><span id="lnk-maryland" aria-label="click here to navigate to Maryland Health Smiles website">Para niños menores de 21 años y mujeres embarazadas, Maryland Health Smiles maneja algunas partes de sus beneficios dentales. Visiten el <a target="_blank" aria-label=" para encontrar un profesional dental dentro de su red." id="lnk-maryland-anchor" href="https://mdmwp.sciondental.com/MWP/Landing">sitio web de Maryland Health Smiles</a> para encontrar un profesional dental dentro de su red.</span><span id="lnk-dentaquest" aria-label="click here to navigate to DentaQuest website">\n\nPara adultos mayores de 21 años, DentaQuest maneja algunas partes de sus beneficios dentales. Visiten el <a target="_blank" aria-label=" para encontrar un profesional dental dentro de su red." id="lnk-dentaquest-anchor" href="http://www.dentaquest.com/members/">sitio web de DentaQuest</a> para encontrar un profesional dental dentro de su red.</span></span>',
  PA_OHDENTAQUEST:
    "<span id=\"lbl-info-PA_OHLIBERTYDENTAL\" class=\"pf-pre-wrap pf-inline-span\"><span id=\"lbl-info-PA_OHLIBERTYDENTAL\" aria-label=\"click here to navigate to Liberty Dental website\">Liberty Dental administra algunas partes de tu beneficio dental.Visita el <a target=\"_blank\" aria-label=\" Liberty Dental website\" id=\"lbl-info-PA_OHLIBERTYDENTAL-anchor\" href=\"https://client.libertydentalplan.com/anthemoh\">Liberty Dental website</a> para encontrar un profesional dental en tu red.</span></span>",
  PA_AVESIS:
    '<span id="lnk-avesis" class="pf-pre-wrap pf-inline-span"><span id="lnk-avesis" aria-label="click here to navigate to Avesis website">Avesis maneja algunas partes de tu beneficio de visión. Visita el <a target="_blank" aria-label=" sitio web de Avesis" id="lnk-avesis-anchor" href="https://www.fap.avesis.com/amerigroupga/provider/search">sitio web de Avesis</a> para encontrar un profesional de la visión en tu red.</span></span>',
  PA_OHEYEMED:
    '<span id="lnk-oheyemed" class="pf-pre-wrap pf-inline-span"><span id="lnk-oheyemed" aria-label="haz clic aquí para navegar al sitio web de OHEyeMed">EyeMed maneja algunas partes de tu beneficio de visión. Visita el <a target="_blank" aria-label=" sitio web de EyeMed" id="lnk-oheyemed-anchor" href="https://eyedoclocator.eyemedvisioncare.com/anthemohmd">sitio web de EyeMed</a> para encontrar un profesional de la visión en tu red.</span></span>',
  PA_EYEMED:
    '<span id="lnk-eyemed" class="pf-pre-wrap pf-inline-span"><span id="lnk-eyemed" aria-label="click here to navigate to EyeMed website">EyeMed maneja algunas partes de tu beneficio de visión. Visita el <a target="_blank" aria-label="  sitio web de EyeMed" id="lnk-eyemed-anchor" href="https://eyedoclocator.eyemedvisioncare.com/ahkplus/?networkDDDisabled=true"> sitio web de EyeMed</a> para encontrar un profesional de la visión en tu red.</span></span>',
  PA_VSP:
    'VSP administra algunas partes de su beneficio de la vista. Vaya a <a target="_blank" aria-label="VSP administra algunas partes de su beneficio de la vista." href="https://www.vsp .com/find-eye-doctors.html?id=medicaid">sitio web de VSP</a> para encontrar un profesional de la visión en su red.',
  PA_D136:
    'No todos los patólogos de este hospital son proveedores participantes de Anthem. Aquellos que no participan pueden cobrarle la diferencia entre el reembolso de Anthem y su cargo facturado, lo que podría generar una responsabilidad financiera significativa para usted. En el caso de que se lo trate por una afección de emergencia y sea usted miembro de un plan HMO, solo debe pagar los copagos o coseguros dentro de la red. Consulte su cuadernillo de beneficios del miembro o su certificado para determinar su nivel de responsabilidad financiera, cuando reciba servicios de patólogos no participantes. Si tiene alguna pregunta, comuníquese con nosotros.',
  PA_D254: '<div>Las actualizaciones recibidas de los proveedores de nuestra red generalmente se agregan a esta herramienta una vez por semana.</div>',
  PA_D262:
    '<div><strong>IMPORTANTE</strong><br />A nuestro saber, la informaci&oacute;n sobre los proveedores contenida en este directorio impreso est&aacute; correcta a la fecha de impresi&oacute;n, pero toda informaci&oacute;n est&aacute; sujeta a cambios y actualizaciones. Puede haber cargos m&aacute;s altos asociados con visitar un m&eacute;dico que no est&aacute; en nuestra red o por obtener un servicio que no est&aacute; cubierto por tu plan. T&uacute; puedes ser responsable de esos costos. Para evitar cargos m&aacute;s altos, te recomendamos confirmar si tu m&eacute;dico est&aacute; en la red y si el servicio deseado est&aacute; cubierto cuando programes tu cita.</div>',
  PA_D284:
    'No todos los patólogos de este hospital son proveedores participantes de Anthem. Aquellos que no lo son pueden cobrarle la diferencia entre el reembolso de Anthem y el cargo facturado, lo que puede representar una carga financiera significativa para usted. No obstante, en caso de que sea tratado por un patólogo no participante por una afección de emergencia o de que sea miembro de un plan HMO, tenga en cuenta que solo deberá pagar los copagos o coaseguros dentro de la red. Consulte su cuadernillo de beneficios para miembros o certificado para determinar su nivel de responsabilidad financiera, si hubiere, cuando reciba servicios de un patólogo no participante. Si tiene preguntas, comuníquese con nosotros.',
  PA_D37:
    'Especialidad: enfermero clínico especializado autorizado<br> Este proveedor no está acreditado. El hecho de que un proveedor no esté acreditado por Anthem Blue Cross and Blue Shield no es un indicador de calidad. Simplemente significa que Anthem Blue Cross and Blue Shield no ha tomado medidas para determinar la formación de ese proveedor en particular. Todos los proveedores han manifestado estar autorizados y contar con distintos tipos de seguros de mala praxis que cumplen con los requisitos mínimos de Anthem Blue Cross and Blue Shield. Anthem Blue Cross and Blue Shield le recomienda hablar con su proveedor sobre su formación. ',
  PA_D268:
    '<div><strong>IMPORTANTE</strong><br />Aunque hacemos esfuerzos para asegurar que nuestras listas de m&eacute;dicos y hospitales est&eacute;n actualizadas y la informaci&oacute;n sea correcta, de vez en cuando los proveedores dejan de participar en nuestras redes y las listas cambian. Hay hospitales, m&eacute;dicos y otros proveedores que no est&aacute;n incluidos en todas las redes de planes. <strong>Aseg&uacute;rate de estar buscando en la red correcta.</strong> Visitar a un proveedor que no est&aacute; dentro de la red de tu plan puede resultar en costos m&aacute;s altos para ti. Te recomendamos comunicarte con el proveedor para confirmar que se encuentra dentro de la red de tu plan y que el servicio deseado est&aacute; cubierto.</div>',
  PA_D264:
    '<div><strong>IMPORTANTE</strong><br />Aunque hacemos esfuerzos para asegurar que nuestras listas de m&eacute;dicos y hospitales est&eacute;n actualizadas y la informaci&oacute;n sea correcta, de vez en cuando los proveedores dejan de participar en nuestras redes y las listas cambian. Hay hospitales, m&eacute;dicos y otros proveedores que no est&aacute;n incluidos en todas las redes de planes. Aseg&uacute;rate de estar buscando en la red correcta. Visitar a un proveedor que no est&aacute; dentro de la red de tu plan puede resultar en costos m&aacute;s altos para ti. Te recomendamos comunicarte con el proveedor para confirmar que se encuentra dentro de la red de tu plan y que el servicio deseado est&aacute; cubierto.</div>',
  PA_CPT: 'Antes de programar una cita, consulta con este proveedor de cuidado médico para confirmar que realiza este procedimiento o servicio.',
  PA_CPT_PD_BACK_PROVIDER:
    '<span><span [innerText]="No tenemos un presupuesto para este proveedor de cuidado médico que realiza este servicio. Busca otro código médico o"> </span><span><a class="pf-back-link" href="javascript:void(0);" (click)="goToProviderList()" tabindex="0"><span class="pf-back-provider-link" [innerText]="encuentra proveedores de cuidado médico que realicen este servicio."></span></a></span></span>',
  PA_NO_COST:
    'No tenemos un presupuesto para este proveedor de cuidado médico que realiza este servicio. Busca otro código médico o encuentra proveedores de cuidado médico que realicen este servicio.',
  PA_PLAN_NOT_COVER: 'Tu plan no cubre este procedimiento. <a target="_blank" href="/member/contact-us">Contáctanos</a> si tienes preguntas sobre los beneficios de tu plan.',
  PA_BENEFIT_DENIED: 'No se pueden mostrar algunos datos de costos',
  PA_SUPPRESSED_MEDSUPP:
    "Para encontrar un proveedor de cuidado médico que acepte la Asignación de Medicare (uno que acepte el monto aprobado por Medicare como pago total), visita <a target='_blank' href='https://www.medicare.gov/care-compare/'>medicare.gov/care-compare</a>.",
  PA_MORE_THAN_ONE_VISIT_COST_MESSAGE:
    "Los costos estimados que mostramos son para las visitas 1-{VISITS_VALUE}. Para ver la información de cobertura para este procedimiento, incluidos los límites de servicio, ve a <a target='_blank' href='/member/benefits?covtype=med'>Buscar beneficios</a>.",
  PA_INITIAL_VISIT_COST_MESSAGE:
    "Los costos estimados que mostramos corresponden a una visita inicial. Para ver información detallada sobre los beneficios de este procedimiento, incluidos los límites de servicio, ve a <a target='_blank' href='/member/benefits?covtype=med'>Buscar beneficios</a>.",
  PA_SPCLTY_PHMCY_ALERT:
    'El hecho de que una farmacia de especialidad médica esté "dentro de la red" también depende de la ubicación del médico que solicitó la receta. Actualiza la ubicación de búsqueda con el código postal del médico',
  PA_TN_LIBERTY:
    '<span id="tnliberty-label-text" class="pf-pre-wrap pf-inline-span"><span id="tnliberty-label-text" aria-label="Liberty Dental administrará algunos de tus beneficios dentales para 2023">Liberty Dental administrará algunos de tus beneficios dentales para 2023. </span><span id="tnliberty-link-text" aria-label="Para encontrar un profesional dental en tu plan dental 2023, visita el sitio web de Liberty Dental">Para encontrar un profesional dental en tu plan dental 2023, visita el <a target="_blank" aria-label=" sitio web de Liberty Dental" id="tnliberty-link-text-anchor" href="https://client.libertydentalplan.com/anthem/FindADentist">sitio web de Liberty Dental</a>.</span></span>',
  PA_32BJ_MEMBER_ALERT:
    '<span id="32BJ-Member-Alert-label-text" class="pf-pre-wrap pf-inline-span"><span id="32BJ-Member-Alert-label-text" aria-label="El reemplazo de articulaciones y la cirugía bariátrica deben ser proporcionados por un médico de un Centro de Excelencia (COE, por sus siglas en inglés) del Fondo de Salud 32BJ y realizado en un Centro de Excelencia. Llama a Servicios para miembros de 32BJ: 800-551-3225.">El reemplazo de articulaciones y la cirugía bariátrica deben ser proporcionados por un médico de un Centro de Excelencia (COE, por sus siglas en inglés) del Fondo de Salud 32BJ y realizado en un Centro de Excelencia. Llama a Servicios para miembros de 32BJ: 800-551-3225.</span></span>',
  PA_GA_TERMINATION_PROVIDERS:
    '<span id="termination-Providers-link-text" class="pf-pre-wrap pf-inline-span"><span id="termination-Providers-link-text" aria-label="Revisa esta lista de proveedores de cuidado médico que pueden dejar la red de tu plan durante el próximo año del plan.">Revisa esta lista de <a target="_blank" aria-label=" proveedores de cuidado médico que pueden dejar la red de tu plan" id="termination-Providers-link-text-anchor" href="/assets/documents/gaterminatingproviders_es.pdf" data-analytics="providersLeftNetworkAlertFindCare">proveedores de cuidado médico que pueden dejar la red de tu plan</a> durante el próximo año del plan.</span></span>',
  PA_MPLVISION: '": "Si necesitas ayuda para encontrar a un profesional de la visión, llama al número de servicios para miembros que aparece en tu tarjeta de identificación.',
  PA_NON_EMG_SVC:
    'Necesitarás ser referido por tu médico de atención primaria (PCP, por sus siglas en inglés) antes de recibir servicios que no sean de emergencia de una clínica en local comercial, de un laboratorio, de patología o de un proveedor de atención radiológica.',
  PA_PUBLICPHARMACY:
    '<span id="lnk-pharmacy-login" class="pf-pre-wrap pf-inline-span"><span id="lnk-pharmacy-login" aria-label="click here to log in to find a pharmacy">Para encontrar una farmacia en tu plan: Los miembros pueden <a target="_blank" aria-label=" iniciar sesión para encontrar una farmacia" id="lnk-pharmacy-login-anchor" href="/login">iniciar sesión para encontrar una farmacia</a></span><span id="lnk-pharmacy-without-login" aria-label="click here to view our pharmacy networks without logging in">o  <a target="_blank" aria-label=" ver nuestras redes de farmacias sin iniciar sesión." id="lnk-pharmacy-without-login-anchor" href="{brandUrl}pharmacyinformation/rxnetworks.html">ver nuestras redes de farmacias sin iniciar sesión.</a> </span><span id="lnk-download-pharmacy-directory" aria-label="click here to download their pharmacy directory">Los miembros que tienen un plan de jubilación a través de su empleador pueden <a target="_blank" aria-label=" descargar su directorio de farmacias" id="lnk-download-pharmacy-directory-anchor" href="{brandUrl}provider/f0/s0/t0/pw_g344160.pdf">descargar su directorio de farmacias</a> o llamar al número del Equipo de bienvenida de First Impressions que se encuentra en la guía de inscripción. (Sugerencia: después de descargar el directorio, prueba buscar con tu código postal para encontrar una farmacia en tu área).</span></span>',
  PA_WLP_MEDICAL_BH:
    '<span id="lnk-wlp-medical-BH" class="pf-pre-wrap pf-inline-span"><span id="lnk-wlp-medical-BH" aria-label="haga clic aquí para navegar a wlp">Los servicios de salud conductual para el plan Wellpoint Massachusetts son proporcionados por Carelon Behavioral Health. Para encontrar un profesional de salud conductual, visite el <a target="_blank" aria-label=" sitio web de Carelon Behavioral Health" id="lnk-wlp-medical-BH-anchor" href="https://www.carelonbh.com/wellpoint/es/home">sitio web de Carelon Behavioral Health</a></span></span>',
  PA_COUPE_ALERT: "<span id=\"coupe-health-text\" class=\"pf-pre-wrap pf-inline-span\"><span id=\"coupe-health-text\" aria-label=\"Para encontrar cuidado médico usando sus beneficios médicos de Coupe Health, visite coupehealth.com\">Para encontrar cuidado médico usando sus beneficios médicos de Coupe Health, visite <a target=\"_blank\" aria-label=\" coupehealth.com\" id=\"coupe-health-text-anchor\" href=\"https://www.coupehealth.com\" data-analytics=\"linkCoupeAlertHomeFindCare\">coupehealth.com</a></span></span>",
  PA_PRIME_GROUP_SEARCH: 'Estos resultados de búsqueda muestran proveedores de cuidado en tu grupo médico. Si quieres buscar fuera de tu grupo médico, borra el filtro \"Grupo médico o IPA\". Necesitarás ser referido por tu médico de atención primaria (PCP, por sus siglas en inglés) antes de recibir servicios que no son de emergencia.',
  PA_NO_RESULT_SNY_MEMBER: "<span id=\"results-info\" class=\"pf-pre-wrap pf-inline-span\"><span id=\"results-info\" aria-label=\"click here to navigate to other provider\">Ten en cuenta que en este sitio solo se pueden buscar hospitales de atención general o aguda, centros de cuidados paliativos y centros de enfermería especializada. Si estás buscando otro proveedor, visita <a target=\"_blank\" aria-label=\" www.cs.ny.gov/employee-benefits/nyship/shared/providers\" id=\"results-info-anchor\" href=\"https://www.cs.ny.gov/employee-benefits/nyship/shared/providers/index.cfm\">www.cs.ny.gov/employee-benefits/nyship/shared/providers</a></span></span>",
  PA_NO_RESULT:
    '<span class="ant-font-b">No hay resultados.</span> Pruebe con un código postal diferente o usa &quot;Volver a Encontrar cuidado médico &quot; para comenzar de nuevo. <a target="_blank" href="/member/contact-us">Contáctanos</a> si tienes preguntas sobre los beneficios de tu plan.',
    PA_CPT_ALERT: 'Si el propósito de este procedimiento o servicio es atención preventiva y usted utiliza un proveedor de atención dentro de la red, su parte de los costos puede ser tan baja como $0.'


};
