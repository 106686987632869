import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { SEARCH_TYPE } from '../../../../common/constants/app-constants';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { ITranslation } from '../../../../fad/search-providers/interfaces/iAdaListResp';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IFtsSuggestion } from '../../../common/interfaces/iFtsClientInteraction';
import { FtsAvailableSuggestionService } from '../../../common/services/ftsAvailableSuggestionSvc';

@Component({
  moduleId: module.id,
  selector: 'app-fc-fts-available-suggestions-cmp',
  templateUrl: './ftsAvailableSuggestions.html',
  providers: []
})
export class FtsAvailableSuggestionsComponent extends BaseComponent implements OnInit {
  searchTerm: string = '';
  searchCriteriaToDisplay: string = '';
  showViewSimilarSearches: boolean = false;
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;

  @Input()
  suggestions: IFtsSuggestion<ITranslation>[];
  @ViewChild('ftsSimilarSearches')
  ftsSimilarSearches: TemplateRef<HTMLElement>;
  showAvailableSuggestions: boolean = false;

  private suggestionSubscription: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _availableSuggestionSvc: FtsAvailableSuggestionService,
    private _sidePanel: SidePanel
  ) {
    super(_route, _eventHandler, _appSession);
    this.suggestionSubscription = this._availableSuggestionSvc.getSuggestions.subscribe((data: IFtsSuggestion<ITranslation>[]) => {
      if (data) {
        this.bindSuggestions(data);
      }
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngOnDestroy(): void {
    if (this.suggestionSubscription) {
      this.suggestionSubscription.unsubscribe();
    }
  }

  hasSpecialty(suggestion: IFtsSuggestion<ITranslation>): boolean {
    const criteria = suggestion?.criteria;
    return criteria?.specialtyCategoryList?.length > 0 || criteria?.taxonomyList?.length > 0 || criteria?.providerName !== '' || criteria?.npi !== '' || false;
  }

  hasProcedure(suggestion: IFtsSuggestion<ITranslation>): boolean {
    return (suggestion?.procedureCode !== '' && suggestion?.metaData?.providerCategoryCode !== '' && suggestion?.criteria?.procedure?.name !== '') || false;
  }

  hasMedicalCode(suggestion: IFtsSuggestion<ITranslation>): boolean {
    return (suggestion?.medicalCode !== '' && suggestion?.text !== '' && suggestion?.metaData?.medicalTypeCode !== '') || false;
  }

  bindSuggestions(suggestion: IFtsSuggestion<ITranslation>[]) {
    this.searchTerm = this._appSession.searchTerm;
    this.searchCriteriaToDisplay = '';
    if (suggestion?.length && (this.hasSpecialty(suggestion[0]) || this.hasProcedure(suggestion[0]) || this.hasMedicalCode(suggestion[0]))) {
      this.suggestions = suggestion;
      this.searchCriteriaToDisplay = this.displaySearchCriteria(suggestion[0]);
      this.showAvailableSuggestions = this.isFtsFeatureActive && this.searchCriteriaToDisplay.length > 0;
      if (this.suggestions.length > 1) {
        this.showViewSimilarSearches = this.suggestions.some((suggestion) => this.hasSpecialty(suggestion) || this.hasProcedure(suggestion) || this.hasMedicalCode(suggestion));
      }
    }
  }

  generateSpecialtyCriteriaText(suggestion: IFtsSuggestion<ITranslation>): string {
    const criteriaDisplaySet = new Set<string>();
    (suggestion.criteria.specialtyCategoryList || []).forEach((category: ITranslation) => {
      criteriaDisplaySet.add(category.name);
    });
    (suggestion.criteria.taxonomyList || []).forEach((taxonomy: ITranslation) => {
      criteriaDisplaySet.add(taxonomy.name);
    });
    let searchCriteria = Array.from(criteriaDisplaySet);
    //Add NPI if any
    if (suggestion.criteria?.npi) {
      searchCriteria.push(this.content.common.freeTextSearchResults?.npi + ' ' + suggestion.criteria?.npi);
    }
    //Add provider name if any
    if (suggestion.criteria?.providerName) {
      searchCriteria.push(this.content.common.freeTextSearchResults?.name + suggestion.criteria?.providerName);
    }
    //Add gender if any
    if (suggestion.criteria?.genderList?.length === 1) {
      if (suggestion.criteria?.genderList[0] === 'F') {
        searchCriteria.push(this.content.common.freeTextSearchResults?.femaleDoctor);
      } else {
        searchCriteria.push(this.content.common.freeTextSearchResults?.maleDoctor);
      }
    }
    if (suggestion.criteria?.ableToServeAsPcp) {
      searchCriteria.push(this.content.common.freeTextSearchResults?.serveAsPcp);
    }
    if (suggestion.criteria?.acceptsNewPatient) {
      searchCriteria.push(this.content.common.freeTextSearchResults?.acceptsNewPatients);
    }
    if (suggestion.criteria?.languageList?.length > 0) {
      const criteriaLanguageSet = new Set<string>();
      (suggestion.criteria.languageList || []).forEach((language) => {
        criteriaLanguageSet.add(language.name);
      });
      searchCriteria.push(this.content.common.freeTextSearchResults.whoSpeaks + Array.from(criteriaLanguageSet).join(', '));
    }
    return searchCriteria.join(', ');
  }

  displaySearchCriteria(suggestion: IFtsSuggestion<ITranslation>): string {
    switch (suggestion.type) {
      case SEARCH_TYPE.SPECIALTY:
        return this.generateSpecialtyCriteriaText(suggestion);
      case SEARCH_TYPE.PROCEDURE:
        return suggestion.criteria.procedure?.name;
      case SEARCH_TYPE.MEDICAL_CODE:
        return suggestion.text;
      default:
        return '';
    }
  }

  get searchResultMsg() {
    return this.content?.common.freeTextSearchResults?.yourSearch + '  ' + `<strong>${this.searchCriteriaToDisplay}</strong>`;
  }
  dismissResultSummary() {
    this.showAvailableSuggestions = false;
  }

  onViewSimilarSearchClick() {
    this.sidePanelRef = this._sidePanel.open('right', this.ftsSimilarSearches);
  }

  onClose() {
    this.sidePanelRef.close();
  }
}
