import { Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { Locale } from '../../../../../common/constants/app-constants';
import {
  MAX_DESK_RESPONSIVE_WIDTH_M,
  MAX_DESK_RESPONSIVE_WIDTH_S,
  MAX_DESK_RESPONSIVE_WIDTH_XS,
  MAX_MOB_RESPONSIVE_WIDTH_L,
  MAX_MOB_RESPONSIVE_WIDTH_M,
  MAX_MOB_RESPONSIVE_WIDTH_S,
  VIEW_COUNT_LIMIT
} from '../../../constants/common';
import { IAppContract } from '../../../interfaces/iAppContract';
import { IContract } from '../../../interfaces/iContract';
import { IMember } from '../../../interfaces/iMember';
import { BootstrapService } from '../../../store/bootstrapSvc';
import { BaseComponent } from '../../core/baseCmp';
import { EventHandler } from './../../../../../common/services/eventHandler';
import { AppSession } from './../../../../../common/values/appSession';

@Component({
  moduleId: module.id,
  selector: 'app-fc-member-chips-cmp',
  templateUrl: './memberChipsCmp.html'
})
export class MemberChipsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() showAllMembers = new EventEmitter<void>();
  members: IMember[] = [];
  membersData: IMember[] = []; // To store the original members data for memberchip component
  activeMember: IContract;
  isMultipleMember = false;
  private _contractSubscription: Subscription;
  maxMemberInView: number = VIEW_COUNT_LIMIT;
  isHovered = false;
  hoveredButtonIndex: number | null = null;
  eligibleMembersArray = '';
  eligibleMembers: string[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession) private _appSession: AppSession,
    private _bootstrapService: BootstrapService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }

    this._contractSubscription = this._bootstrapService.contract.subscribe((appContract: IAppContract) => {
      this.activeMember = appContract?.selectedContract;
      this.checkAndSwapActiveMember();
    });
    /**
     * Clone the members data from the bootstrap service to avoid any changes to the original data
     */
    this.members = cloneDeep(this._bootstrapService.members);
    this.membersData = this._bootstrapService.members;
    this.isMultipleMember = this.members.length > 1;
    this.addInitials();
    this.getMaxMemberInView();
  }

  /**
   * Add initials to the member object
   */
  addInitials() {
    for (const member of this.members) {
      const initials = member.firstNm?.charAt(0) + member.lastNm?.charAt(0);
      member.initials = initials;
    }
  }

  /**
   * Method to check if member is eligible for the selected contract
   * @param member Selected member from the list
   */
  isMemberEligible(member: IContract): boolean {
    if (this.activeMember.associatedMembers.find((m) => m.mbrUid === member.mbrUid)) {
      return true;
    }
    return false;
  }

  /**
   * Method to handle member click
   * @param member Selected member from the list
   */
  onMemberClick(member: IContract) {
    const selectedMbrContractIndex = this.activeMember.associatedMembers?.find((m) => m.mbrUid === member.mbrUid)?.mbrContractIndex;
    this._bootstrapService.setAppContract(member.mbrUid, this.activeMember.contractUid, this.activeMember.statusCd, undefined, selectedMbrContractIndex);
  }

  /**
   * Method to emit event to show all members
   */
  viewAllMembers() {
    this.showAllMembers.emit();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getMaxMemberInView(event.target.screen.availWidth);
  }
  /**
   * Event handler for mouse over event.
   * Changes the isHovered flag to true.
   * @param index
   * @param member
   */
  onMouseOver(index: number, member: IContract) {
    if (!this.isMemberEligible(member)) {
      this.isHovered = true;
      this.hoveredButtonIndex = index;
    }
  }
  /**
   * Event handler for mouse out event.
   * Changes the isHovered flag to true.
   */
  onMouseOut() {
    this.isHovered = false;
  }
  getMaxMemberInView(availWidth?: any) {
    const totalMembers = this.members.length;
    const windowWidth = availWidth ? (window.innerWidth <= availWidth ? window.innerWidth : availWidth) : window.innerWidth;
    const locale = this._appSession.metaData.locale;
    if (windowWidth) {
      //Handle maximumn number of memberchips to be shown for mobile screen.
      if (windowWidth <= MAX_DESK_RESPONSIVE_WIDTH_XS) {
        if (windowWidth <= MAX_MOB_RESPONSIVE_WIDTH_S) {
          this.maxMemberInView = 2;
        } else if (windowWidth > MAX_MOB_RESPONSIVE_WIDTH_S && windowWidth <= MAX_MOB_RESPONSIVE_WIDTH_M) {
          this.maxMemberInView = totalMembers > 3 ? 2 : 3;
        } else if (windowWidth > MAX_MOB_RESPONSIVE_WIDTH_M && windowWidth <= MAX_MOB_RESPONSIVE_WIDTH_L) {
          this.maxMemberInView = totalMembers > 4 ? 3 : 4;
        } else {
          this.maxMemberInView = VIEW_COUNT_LIMIT;
        }
      } else {
        if (windowWidth < MAX_DESK_RESPONSIVE_WIDTH_S) {
          this.maxMemberInView = totalMembers > 2 ? 1 : 2;
        } else if (windowWidth < MAX_DESK_RESPONSIVE_WIDTH_M) {
          this.maxMemberInView = totalMembers > 3 ? 2 : 3;
        } else {
          if (locale === Locale.SPANISH) {
            if (windowWidth >= MAX_DESK_RESPONSIVE_WIDTH_M) {
              if (windowWidth < 1201) {
                this.maxMemberInView = totalMembers > 2 ? 1 : 2;
              } else {
                this.maxMemberInView = totalMembers > 4 ? 3 : 4;
              }
            }
          } else {
            this.maxMemberInView = VIEW_COUNT_LIMIT;
          }
        }
      }
      //Swap last memberchip based on screen size
      this.checkAndSwapActiveMember();
    }
  }
  /**
   * Checks if the active member is not in the first 5 members and swaps the active member with the last member
   */
  checkAndSwapActiveMember() {
    const activeMemberIndex = this.membersData.findIndex((member) => member.mbrUid === this.activeMember?.mbrUid);
    this.members = cloneDeep(this._bootstrapService.members);
    if (this.maxMemberInView == 5) {
      if (activeMemberIndex > 4) {
        this.members[4] = this.activeMember;
      }
    } else if (this.maxMemberInView == 3) {
      if (activeMemberIndex > 2) {
        this.members[2] = this.activeMember;
      }
    } else if (this.maxMemberInView == 2) {
      if (activeMemberIndex > 1) {
        this.members[1] = this.activeMember;
      }
    } else if (this.maxMemberInView == 1) {
      this.members[0] = this.activeMember;
    } else {
      this.members[activeMemberIndex] = this.activeMember;
    }
  }

  ngOnDestroy(): void {
    if (this._contractSubscription) {
      this._contractSubscription.unsubscribe();
    }
  }
}
