import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IProvider } from '../../interfaces/iProvider';

@Component({
  moduleId: module.id,
  selector: 'app-fc-virtual-connect-now-cmp',
  templateUrl: './virtualConnectNowCmp.html'
})
export class VirtualConnectNowComponent extends BaseComponent implements OnInit {
  @Input() provider: IProvider;
  virtualNavigationButton: string;
  resultContent = this.content?.result?.virtualCareProvider;

  ngOnInit() {
    this.virtualNavigationButton =
      this.provider?.providerName?.includes('Khealth') || this.provider?.providerName?.includes('LHO') ? this.resultContent?.goToVirtualCare : this.resultContent?.connectNow;
  }
  navigateToUrl(data: string) {
    window.open(data);
  }
}
