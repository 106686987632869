import { DatePipe, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { ChangePlanService } from '../../../../../../../common/components/change-plan/services/changePlanSvc';
import { DATE_FORMAT } from '../../../../../../../common/constants/app-constants';
import { DataHelper } from '../../../../../../../common/services/dataHelper';
import { EventHandler } from '../../../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../../../common/values/appSession';
import { ContractStatus, MAX_MOB_PLAN_LENGTH, MAX_MOB_RESPONSIVE_WIDTH_L, MAX_PLAN_LENGTH, SearchExecutionMode, SliderDirection } from '../../../../../../common/constants/common';
import { IAppContract } from '../../../../../../common/interfaces/iAppContract';
import { IContract } from '../../../../../../common/interfaces/iContract';
import { CommonUtility } from '../../../../../utilities/commonUtil';
import { BaseComponent } from '../../../../core/baseCmp';
import { AppNavigations } from './../../../../../../../common/constants/app-navigations';
import { NavigationService } from './../../../../../../../common/services/navigationService';
import { RouteUtil } from './../../../../../../../common/utilities/routeUtil';
import { BootstrapService } from './../../../../../store/bootstrapSvc';
import { ContractUtility } from './../../../../../utilities/contractUtil';

@Component({
  moduleId: module.id,
  selector: 'app-fc-change-plan',
  templateUrl: './changePlanCmp.html'
})
export class ChangePlanComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('changePlanSidePanel')
  changePlanSidePanel: TemplateRef<HTMLElement>;

  @Output()
  contractChanged: EventEmitter<void> = new EventEmitter<void>();
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;
  planText = '';
  contracts: IContract[] = [];
  selectedContract: IContract;
  isHovered = false;
  planTextLength: number = MAX_PLAN_LENGTH;
  sliderDirection = SliderDirection;
  public _contractSubscription: Subscription;

  constructor(
    @Inject(AppSession) protected _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _changePlanService: ChangePlanService,
    private _routeUtil: RouteUtil,
    private _sidePanel: SidePanel,
    private _bootstrapService: BootstrapService,
    private _titlecasePipe: TitleCasePipe,
    private _datePipe: DatePipe,
    private _dataHelper: DataHelper,
    private _navigationService: NavigationService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * Subscribes to the contract observable from the BootstrapService.
   */
  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.subscribeToContractChanges();
  }

  /**
   * Subscribes to the contract observable from the BootstrapService.
   */
  private subscribeToContractChanges() {
    this._contractSubscription = this._bootstrapService.contract.subscribe((appContract: IAppContract | null) => {
      if (appContract) {
        this.contracts = appContract.contracts || [];

        const selectedContract = appContract.selectedContract;
        if (selectedContract) {
          this.planText = this._appSession.appState.executionMode === SearchExecutionMode.HCID_SEARCH ? this.content.common.labels.myPlan : this.getPlanText(selectedContract);
          this.selectedContract = this.contracts.find(
            (contract) =>
              contract.contractUid === selectedContract.contractUid &&
              contract.statusCd === selectedContract.statusCd &&
              CommonUtility.areStringArraysEqual(contract.productFlags, selectedContract.productFlags)
          );
        }
      }
    });
  }

  /**
   * Event handler for mouse over event.
   * Changes the isHovered flag to true.
   */
  onMouseOver() {
    this.isHovered = true;
  }

  /**
   * Event handler for mouse out event.
   * Resets the outer box height to default height.
   */
  onMouseOut() {
    this.isHovered = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //Handle search text box watermark for mobile screen.
    if (event.target?.screen?.availWidth < MAX_MOB_RESPONSIVE_WIDTH_L) {
      this.planTextLength = MAX_MOB_PLAN_LENGTH;
    }
  }

  /**
   * Checks if the plan text is long.
   * @returns True if the plan text is long, false otherwise.
   */
  isTextLong() {
    return this.planText.length > this.planTextLength;
  }

  /**
   * Gets the network text for a given contract.
   * @param contract The contract to get the network text for.
   * @returns The formatted network text.
   */
  getContractNetworkText(contract: IContract): string {
    const coverages = ContractUtility.getCoverages(contract);
    const planNetworkLabel = this.content?.common?.pageHeader?.carePlanWrapper?.planNetworkLabel;

    if (!planNetworkLabel) {
      return '';
    }

    let formattedNetworkText = '';
    if (coverages.length > 1) {
      const lastName = coverages.pop();
      formattedNetworkText = `${coverages.join(', ')} & ${lastName}`;
    } else if (coverages.length === 1) {
      formattedNetworkText = coverages[0];
    }

    return planNetworkLabel.replace(/{{NETWORK}}/gi, formattedNetworkText);
  }

  /**
   * Gets the coverage plan text for a given contract.
   * @param contract The contract to get the coverage plan text for.
   * @returns The formatted coverage plan text.
   */
  getCoveragePlanText(contract: IContract): string {
    const plans = ContractUtility.getCoveragePlans(contract);
    return plans.join(', ');
  }

  /**
   * Gets the coverage plan Effective date for a given contract.
   * @param contract The contract to get the coverage plan text for.
   * @returns The formatted coverage Effective date.
   */
  getCoveragePlanDate(contract: IContract): string {
    const planStart = this.getPlanStartText(contract.statusCd, contract.coverages?.[0]?.effectiveDt);
    return `${planStart}`;
  }
  /**
   * Gets the associated members for a given contract.
   * @param contract The contract to get the associated members for.
   * @returns An array of associated member names.
   */
  getAssociatedMembers(contract: IContract): string[] {
    return contract.associatedMembers?.map((member) => `${this.toTitleCase(member.firstNm)} ${member.yearOfBirth}`) || [];
  }

  get getPrefixText(): string {
    if (!this._appSession?.isSecureState && !this._dataHelper.isEmptyString(this._appSession.searchParams?.plan?.name)) {
      return this._appSession.searchParams?.plan?.name;
    }
    return '';
  }
  /**
   * Converts a name to title case.
   * @param name The name to convert.
   * @returns The name in title case.
   */
  private toTitleCase(name: string): string {
    return this._titlecasePipe.transform(name);
  }

  /**
   * Lifecycle hook that is called when a directive, pipe, or service is destroyed.
   * Unsubscribes from the contract observable to prevent memory leaks.
   */
  ngOnDestroy() {
    this.unsubscribeFromContractChanges();
  }

  /**
   * Unsubscribes from the contract observable to prevent memory leaks.
   */
  private unsubscribeFromContractChanges() {
    if (this._contractSubscription) {
      this._contractSubscription.unsubscribe();
    }
  }

  /**
   * Event on click of change plan link
   */
  onChangePlan() {
    if (this.isSecureState) {
      if (this.contracts.length > 1) {
        this.openPlanSlidePanel(this.sliderDirection.RIGHT);
      }
    } else {
      this._appSession.deeplinkParams.alphaprefix = null;
      this._navigationService.navigateByUrl(AppNavigations.FCR_HOME_PATH);
    }
  }

  /**
   * Opens the plan slide panel in the specified direction.
   * @param direction The direction to open the slide panel.
   */
  openPlanSlidePanel(direction: SliderDirection): void {
    this.sidePanelRef = this._sidePanel.open(direction, this.changePlanSidePanel);
  }

  /**
   * Event handler for click of close button.
   */
  closePlanSlidePanel() {
    if (this.sidePanelRef) {
      this.sidePanelRef.close();
    }
  }

  /**
   * Saves the current state and closes the side panel.
   * Also opens a snackbar alert.
   */
  savePlanChanges(): void {
    const previousContract = this._bootstrapService.appContract?.selectedContract;
    const currentContract = this.selectedContract;

    // Determine the selected member UID
    let selectedMbrUid = previousContract?.mbrUid;
    let selectedMbrContractIndex = currentContract.mbrContractIndex;
    const currentMember = currentContract.associatedMembers.find((member) => member.mbrUid === selectedMbrUid);
    if (currentMember) {
      selectedMbrContractIndex = currentMember.mbrContractIndex;
      selectedMbrUid = currentMember.mbrUid;
    } else {
      selectedMbrUid = currentContract.mbrUid;
    }

    // Check if the contract or status has changed
    const hasContractChanged = previousContract?.contractUid !== currentContract?.contractUid;
    const hasStatusChanged = previousContract?.statusCd !== currentContract?.statusCd;
    const hasProductFlagsChanged = !CommonUtility.areStringArraysEqual(previousContract?.productFlags, currentContract?.productFlags);
    const shouldChangeAppContract = hasContractChanged || (previousContract?.contractUid === currentContract?.contractUid && (hasStatusChanged || hasProductFlagsChanged));

    // Update the app contract if necessary
    if (shouldChangeAppContract) {
      this._bootstrapService.setAppContract(selectedMbrUid, currentContract.contractUid, currentContract.statusCd, undefined, selectedMbrContractIndex);
    }

    // Update the plan text and enable eligible members
    this.planText = this.getPlanText(currentContract);
    const eligibleMembers = currentContract.associatedMembers?.map((member) => member.firstNm).join(', ');
    this._bootstrapService.enableEligibleMembers(eligibleMembers);

    // Close the plan slide panel and emit contract change event if necessary
    this.closePlanSlidePanel();
    if (shouldChangeAppContract) {
      this.contractChanged.emit();
      let path = this._routeUtil.getResolvedUrl(this._route.snapshot);
      if (this._routeUtil.isSearchResultRoute(path)) {
        this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH);
      }
      this._changePlanService.setChangePlanSave(path);
    }
  }

  /**
   * Generates the plan text by combining the plan name and plan date.
   * @param contract The contract object containing coverage details.
   * @returns The concatenated plan name and plan date.
   */
  private getPlanText(contract: IContract): string {
    if (!contract.coverages || contract.coverages.length === 0) {
      return '';
    }

    const coveragePlanName = this.getCoveragePlanText(contract);
    const coveragePlanDate = this.getCoveragePlanDate(contract);
    return `${coveragePlanName}${coveragePlanDate}`;
  }

  /**
   * Gets the plan start text with the effective date if the contract status is FUTURE.
   * @param statusCd The status code of the contract.
   * @param effectiveDt The effective date of the contract.
   * @returns The formatted plan start text or an empty string if not applicable.
   */
  private getPlanStartText(statusCd: ContractStatus, effectiveDt: string): string {
    if (statusCd === ContractStatus.FUTURE && effectiveDt) {
      const formattedEffectiveDt = this._datePipe.transform(effectiveDt, DATE_FORMAT);
      return this.content.common.pageHeader?.carePlanWrapper?.planStart.replace(/{DATE}/gi, formattedEffectiveDt) || '';
    }
    return '';
  }
}
