import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { INetworkStatus } from '../../../common/interfaces/iCommonSearchResponse';
import { IPlanNetworkPrefixContent } from '../../../common/interfaces/iPlanNetworkPrefixContent';
import { ISecureSpecialtyEmailRequest } from '../../../common/interfaces/iSecureSpecialtyEmailRequest';
import { ISecureSpecialtySearchCriteria, ISecureSpecialtySearchRequest } from '../../../common/interfaces/iSecureSpecialtySearchRequest';
import { BaseService } from '../../../common/services/baseService';
import { HttpClientService } from '../../../common/services/httpClientService';
import { SummaryEmailSvc } from '../../../common/services/summaryEmailSvc';
import { SummaryPrintSvc } from '../../../common/services/summaryPrintSvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { API_NAME } from '../../../fad/search-results/values/providerSearchConstants';
import { SearchRequestUtil } from '../../../fad/utilities/searchRequestUtil';
import { IAddressSummary, IProvider, IProviderList } from '../../result/interfaces/iProvider';
import { SearchFilter } from '../../result/services/searchFilter';
import { PROV_NETWORK_STATUS } from '../constants/common';
import { SearchRequestUtility } from '../utilities/searchRequestUtil';
import { ISecureSearchStrategyResponse } from './../../../common/interfaces/iSecureSpecialtySearchResponse';
import { SearchResponseUtil } from './../../../fad/utilities/searchResponseUtil';
import { SpecialtyService } from './specialtySvc';

@Injectable({
  providedIn: 'root'
})
export class SpecialtySearchSvc extends BaseService {
  private pdfPageNumber = 1;
  private pdfPageSize = environment.paginationSize.summaryPrintPage;
  constructor(
    appSession: AppSession,
    private _summaryPrintSvc: SummaryPrintSvc,
    private _summaryEmailSvc: SummaryEmailSvc,
    private _specialtySearchSvc: SpecialtyService,
    appUtility: AppUtility,
    httpClientSvc: HttpClientService,
    private _searchFilter: SearchFilter
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  async search(pageNumber: number, pageSize: number) {
    const transformedRequest = this.transformRequest(pageNumber, pageSize);
    if (transformedRequest.searchCriteria.isInitialSearch) {
      this.appSession.filterSearchParams = this.appSession.searchParams;
    }
    const apiResponse = await this._specialtySearchSvc.execute(transformedRequest);
    const transformedResponse = this.transformResponse(apiResponse);
    this._searchFilter.initialize(transformedRequest, apiResponse?.searchKey);
    return transformedResponse;
  }

  private transformRequest(pageNumber: number, pageSize: number): ISecureSpecialtySearchRequest {
    const searchParams = this.appSession.searchParams;
    const commonSearchRequest = SearchRequestUtil.buildCommonSearchRequest(this.appSession, pageNumber, pageSize);
    const demographicFilter = SearchRequestUtil.buildDemographicFilterRequest(this.appSession);
    const miscFilter = SearchRequestUtil.buildMiscFilterRequest(searchParams);
    const rxFilter = SearchRequestUtility.buildRxFilterRequest(searchParams);

    const searchCriteria: ISecureSpecialtySearchCriteria = {
      miscFilter,
      demographicFilter,
      brandCode: this.appSession.metaData.brandCd,
      cobrandCode: SearchRequestUtil.setCobrandCode(this.appSession),
      isInitialSearch: this.appSession?.initialSearch
    };
    if (Object.keys(rxFilter).length > 0) {
      searchCriteria.rxFilter = rxFilter;
    }
    if (this.appSession?.searchParams?.npi) {
      searchCriteria.npi = this.appSession.searchParams.npi;
    }
    if (this.appSession?.searchParams?.pcpId) {
      searchCriteria.pcpId = this.appSession.searchParams.pcpId;
    }

    return {
      ...commonSearchRequest,
      searchCriteria
    };
  }

  private transformResponse(apiResponse: ISecureSearchStrategyResponse): IProviderList {
    let response = {} as IProviderList;
    const finalProviders: IProvider[] = [];
    if (apiResponse) {
      (apiResponse.providers || []).forEach((provider) => {
        if (provider) {
          const providerResp = SearchResponseUtil.getCommonProviderInformation(provider);
          let finalProvider = {
            ...providerResp
          } as IProvider;
          const { address } = provider.location || {};
          const { taxonomies } = provider.specialty ? provider.specialty : { taxonomies: [] };
          if (address) {
            finalProvider.addressSummary = {
              phoneNumberList: [address.phone],
              addressIdentifier: address.addressId || '',
              addressOne: address.addressOne,
              addressTwo: address.addressTwo,
              cityName: address.city,
              stateCode: address.state,
              postalCode: address.postalCode,
              latitude: address.latitude,
              longitude: address.longitude,
              distance: address.distance ? address.distance.toString() : ''
            } as IAddressSummary;
          }
          // in pharmacy search, we are not getting specialty
          if (taxonomies?.length) {
            finalProvider.addressSummary = {
              ...finalProvider.addressSummary,
              taxonomies: taxonomies?.map(({ code, name }) => ({ code, name }))
            };
          }
          if (provider?.pharmacyNumber) {
            finalProvider.pharmacyNumber = provider?.pharmacyNumber;
          }
          finalProvider.networkStatus = provider?.networkStatus || ({ status: PROV_NETWORK_STATUS.UNDETERMINED } as INetworkStatus);
          finalProviders.push(finalProvider);
        }
      });
      response = { ...apiResponse, providers: finalProviders };
    }
    response.providers = finalProviders;

    return response;
  }

  print<T extends { summaryComponent: IPlanNetworkPrefixContent }>(content: T, isSecure: boolean) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const printRequest: ISecureSpecialtySearchRequest = { ...searchRequest };
    const apiURL = isSecure ? API_NAME.SECURE_SPECIALTY_V2 : API_NAME.PUBLIC_SPECIALITY_V2;
    const apiName = SearchRequestUtil.getApiName(apiURL);
    return this._summaryPrintSvc.execute(printRequest, apiName);
  }

  email<T extends { summaryComponent: IPlanNetworkPrefixContent }>(email: string, content: T, isSecure: boolean) {
    const searchRequest = this.transformRequest(this.pdfPageNumber, this.pdfPageSize);
    const emailRequest: ISecureSpecialtyEmailRequest = { ...searchRequest, email };
    const apiURL = isSecure ? API_NAME.SECURE_SPECIALTY_V2 : API_NAME.PUBLIC_SPECIALITY_V2;
    const apiName = SearchRequestUtil.getApiName(apiURL);
    return this._summaryEmailSvc.execute(emailRequest, apiName);
  }
}
